<ion-header class="ion-no-border no-border-radius" [translucent]="true">
  <div class="pop-header notch-space title-with-button">
    <div class="close">
      <app-back-icon (click)="close()"></app-back-icon>
    </div>
    <h2>{{'marketing.update_campaign' | translate}}</h2>
  </div>
</ion-header>
<ion-content class="grey-bg no-border-radius">
  <div class="ad-wizard-page-web" *ngIf="campaignData">
    <div class="questions-section">
      <div class="form-area">
        <form [formGroup]="campaignForm">

          <ng-container *ngFor="let field of campaignData.mutable_config | keyvalue">
            <ng-container>
              <div class="fields-section">
                <ng-container *ngTemplateOutlet="formTemplate; context: { form: campaignForm, field: field, isFormSubmitted: isCampaignFormSubmitted }"></ng-container>
              </div>
            </ng-container>
          </ng-container>

        </form>
      </div>
      <div class="button-section">
        <ion-button class="ion-padding custom-btn-create" shape="round" expand="block" (click)="updateCampaign()">{{'buttons.update' | translate}}</ion-button>
      </div>
    </div>
  </div>

  <ng-template #formTemplate let-field="field" let-form="form" let-isFormSubmitted="isFormSubmitted">
    <div class="form-lable-sec" *ngIf="!field.value.options">
      <div class="label">
        {{field.value.label}}
        <span> * </span>
      </div>
      <div class="form-cover-single">
        <ion-list>
          <ion-item lines="none">
            <ion-input [formControl]="form.get(field.key)"></ion-input>
          </ion-item>
        </ion-list>
      </div>
      <ion-note color="danger" class="error" slot="error" *ngIf="isFormSubmitted && form.get(field.key).invalid">
        {{ getFormFieldError(form, field.key) }}
      </ion-note>
    </div>

    <div class="form-lable-sec web-options-align" *ngIf="field.value.options">
      <div class="label">
        {{field.value.label}}
        <span> * </span>
      </div>
      <div class="form-cover-single">
        <ion-list>
          <ion-item lines="none">
            <ion-select class="custom-select" interface="action-sheet" justify="space-between" [formControl]="form.get(field.key)" [interfaceOptions]="{cssClass: 'custom-action-sheet', header: field.value.label}" mode="md">
              <ion-select-option *ngFor="let option of field.value.options" [value]="option.value">
                {{ option.name }}
              </ion-select-option>
            </ion-select>
          </ion-item>
        </ion-list>
      </div>
      <ion-note color="danger" class="error" slot="error" *ngIf="isFormSubmitted && form.get(field.key).invalid">
        {{ getFormFieldError(form, field.key) }}
      </ion-note>
    </div>
  </ng-template>
</ion-content>