<svg width="29px" height="25px" viewBox="0 0 29 25" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <g id="EFFEKTIFY" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Insights" transform="translate(-35.000000, -269.000000)" fill="#14181C" fill-rule="nonzero">
          <g id="Content" transform="translate(0.000000, 162.000000)">
              <g id="Medium-chart" transform="translate(35.000000, 107.000000)">
                  <path d="M28.275,25 L0.725,25 C0.324075,25 0,24.7052709 0,24.3421226 C0,23.9789742 0.324075,23.6842452 0.725,23.6842452 L28.275,23.6842452 C28.6752,23.6842452 29,23.9789742 29,24.3421226 C29,24.7052709 28.6752,25 28.275,25 Z M25.375,21.0520776 C24.9748,21.0520776 24.65,20.7580064 24.65,20.3942002 L24.65,11.1839162 C24.65,10.8207679 24.9748,10.5260388 25.375,10.5260388 C25.7752,10.5260388 26.1,10.8207679 26.1,11.1839162 L26.1,20.3942002 C26.1,20.7580064 25.7752,21.0520776 25.375,21.0520776 Z M18.125,21.0520776 C17.7248,21.0520776 17.4,20.7580064 17.4,20.3942002 L17.4,0.657877424 C17.4,0.294729086 17.7248,0 18.125,0 C18.525925,0 18.85,0.294729086 18.85,0.657877424 L18.85,20.3942002 C18.85,20.7580064 18.5252,21.0520776 18.125,21.0520776 Z M10.875,21.0520776 C10.474075,21.0520776 10.15,20.7580064 10.15,20.3942002 L10.15,8.55240652 C10.15,8.18925818 10.4748,7.89452909 10.875,7.89452909 C11.2752,7.89452909 11.6,8.18925818 11.6,8.55240652 L11.6,20.3942002 C11.6,20.7580064 11.2752,21.0520776 10.875,21.0520776 Z M3.625,21.0520776 L3.625,21.0520776 C3.2248,21.0520776 2.9,20.7580064 2.9,20.3942002 L2.9,5.92089682 C2.9,5.55774848 3.2248,5.26301939 3.625,5.26301939 L3.625,5.26301939 C4.0252,5.26301939 4.35,5.55774848 4.35,5.92089682 L4.35,20.3942002 C4.35,20.7580064 4.0252,21.0520776 3.625,21.0520776 Z" id="Shape"></path>
              </g>
          </g>
      </g>
  </g>
</svg>
