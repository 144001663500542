import { Component, OnInit, OnChanges, OnDestroy, Input, ViewEncapsulation } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-svg',
  template: `<span [innerHTML]="svgContent"></span>`,
  encapsulation: ViewEncapsulation.None  // enable styling from parent
})

export class SvgComponent implements OnInit, OnChanges, OnDestroy {
  @Input() public name?: string;
  public svgContent: string|SafeHtml = '';
  private susbscriptionRef: Subscription;

  constructor(
    private http: HttpClient,
    private sanitizer: DomSanitizer,
  ) {}

  ngOnInit(): void {
    this.loadSvgImage();
  }

  ngOnChanges(changes): void {
    if (changes?.name) {
      if (!changes?.name) {
        this.svgContent = '';
      } else {
        this.loadSvgImage();
      }
    }
  }

  ngOnDestroy(): void {
    this.cancelFetch();
  }

  loadSvgImage(): void {
  this.cancelFetch();
  this.susbscriptionRef =  this.http.get(`assets/svg/${this.name}`, { responseType: 'text' })
    .subscribe((value: string) => {
      this.svgContent = this.sanitizer.bypassSecurityTrustHtml(value);
    });
  }

  cancelFetch(): void {
    if(this.susbscriptionRef && this.susbscriptionRef.unsubscribe) {
      this.susbscriptionRef.unsubscribe();
    }
  }
}
