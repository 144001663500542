// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

const environmentConstants = {
  appId: 'com.effektify.app',
  iOSAppId: 'id1642461638',
  production: false, // Angular production flag
  development: false, // Dev flag to hide unavailable features
  appVersionYear: '2025',
  appVersionRelease: '01',
  appVersionPatch: '01',
  apiDomainBaseURL: '.core.app-resolve.effektify.io/api/',
  apiAppName: 'app',
  apiVersion: 'v1',
  integrationAuthSuccessRedirect: 'com.effektify.app/auth-success',
  integrationAuthFailureRedirect: 'com.effektify.app/auth-fail',
  appWebsite: 'app.effektify.com/',
  effektifyWebsite: 'https://effektify.com/',
  appPlayStoreOpenUrl: 'https://play.google.com/store/apps/details?id=com.effektify.app',
  appAppStoreOpenUrl: 'itms-apps://itunes.apple.com/app/effektify/id1642461638',
  appsFlyerDevKey: 'uCNWg9oDFHa8KgPu6x29dm',
  appsFlyerWebId: '7e0780b8-d143-4ddd-b6e3-0c4c730cd0d7',
  intercomAPIBase: 'https://api-iam.intercom.io',
  intercomAppID: 'qv132hws',
  // FBAppID: '729273612258470', // Live
  FBAppID: '628007072388061', // Staging
  socialLoginsuccessReturnPath: 'social-login-success',
  externalBrowserIntegrationSuccessPath: 'social-login-success',
  externalBrowserIntegrationFailPath: 'social-login-success',

  // Web configurations
  webUrlString: 'app.effektify.com',
  webIntegrationAuthSuccessRedirect: 'https://staging.app.effektify.com/store',
  webIntegrationAuthFailureRedirect: 'https://staging.app.effektify.com/store',
  appleLoginSDKRedirectURI: 'https://core.staging.effektify.io/auth/social/landing'
};

const environmentAPI = {
  // apiDomain: 'https://' + environmentConstants.appVersionYear + '-' + environmentConstants.appVersionRelease + environmentConstants.apiDomainBaseURL,
  // apiDomain: 'https://0586-13-50-216-182.ngrok-free.app/api/',
  // apiDomain: 'http://localhost:10000/api/',
  apiDomain: 'https://core.staging.effektify.io/api/',
  appVersion: environmentConstants.appVersionYear + '.' + environmentConstants.appVersionRelease + '.' + environmentConstants.appVersionPatch
}

export const environment = { ...environmentConstants, ...environmentAPI };

/*
  ** Old configurations for backup
  apiDomain: 'https://core.staging.effektify.io/api/', // staging server
  apiDomain: 'https://platform-staging.effektify.com/api/', // old dev server
*/

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
