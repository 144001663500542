<ng-container *ngIf="platform === adwizardPlatforms.google">
  <div class="google-ad-preview">
    <div class="sponsored">{{'campaigns_preview.sponsored' | translate}}</div>
    <div class="url">
      <img src="assets/images/ads/facebook/earth.svg" />
      <span>
        {{previewData?.link}}
      </span>
    </div>
    <div class="title" *ngIf="canRenderData(previewData, 'headlines')">
      <span>
        {{previewData?.headlines[0] || ''}}
      </span> 
      - 
      <span>
        {{previewData?.headlines[1] || ''}}
      </span>
    </div>
    <div class="description" *ngIf="canRenderData(previewData, 'texts')">
      <span>
        {{ previewData?.texts[0] || '' }}.
      </span>
      <span>
        {{ previewData?.texts[1] || '' }}.
      </span>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="platform === adwizardPlatforms.facebook">
  <div class="post">
    <div class="post-header">
        <div class="post-header-container">
          <div class="profile-picture"></div>
          <div class="page-name-sponsored-container">
            <span class="page-name">{{'campaigns_preview.pagename' | translate}}</span>
            <span class="sponsored">{{'campaigns_preview.sponsored' | translate}}  · <img src="assets/images/ads/facebook/earth.svg" /> </span>
          </div>
        </div>
        <div class="options">...</div>
    </div>
    <div class="caption" *ngIf="canRenderData(previewData, 'texts')">
      {{ previewData?.texts[0] }}
    </div>
    <div class="image-placeholder">

    <img *ngIf="previewData?.image_uri" [src]="previewData?.image_uri" alt="Ad Image">
    <video *ngIf="previewData?.video_uri" [src]="previewData?.video_uri" class="video-item" [muted]="true" autoplay loop playsinline></video>
    </div>
    <div class="website-info">
        <div class="website-url">{{ previewData?.link }}</div>
        <div class="headline" *ngIf="canRenderData(previewData, 'headlines')">{{ previewData?.headlines[0] }}</div>
        <button class="cta-button">{{'campaigns_preview.shop_now' | translate}}</button>
    </div>
    <div class="engagement">
        <div class="reactions">
            <span class="reaction-icons">
              <img src="assets/images/ads/facebook/facebook-like.svg" />
              <img style="width:19px" src="assets/images/ads/facebook/facebook-surprise.svg" />
              <img src="assets/images/ads/facebook/facebook-laugh.svg" />
            </span>
            {{'campaigns_preview.user_details' | translate}}
        </div>
        <div>1 {{'campaigns_preview.comment' | translate}} 1 {{'campaigns_preview.share' | translate}}</div>
    </div>
    <div class="actions">
        <span class="action"><img src="assets/images/ads/facebook/fb-like.svg" /><span>{{'campaigns_preview.like' | translate}}</span></span>
        <span class="action"><img src="assets/images/ads/facebook/fb-comment.svg" /><span>{{'campaigns_preview.comment' | translate}}</span></span>
        <span class="action"><img src="assets/images/ads/facebook/fb-share.svg" /><span>{{'campaigns_preview.share' | translate}}</span></span>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="platform === adwizardPlatforms.tiktok">
  <div class="tiktok-container">
    <div class="container">
      <video class="background-video" [src]="previewData?.video_uri" [muted]="true" autoplay loop playsinline></video>
      <div class="content-wrapper">
        <div class="header">
          <span class="header-item">{{'campaigns_preview.following' | translate}}</span>
          <span class="header-item active">{{'campaigns_preview.for_you' | translate}}</span>
        </div>
        <div class="bottom-section">
          <div class="content">
            <div class="left-section">
              <span class="brand-name">
                {{previewData?.sender}}
              </span>
              <p class="description" *ngIf="canRenderData(previewData, 'texts')">
                {{previewData?.texts[0]}}
              </p>
              <div class="sponsored-tag">
                <div>{{'campaigns_preview.sponsored' | translate}}</div>
              </div>
              <div class="music-info">
                <span class="music-icon">
                  <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="12"
                    height="12"
                    viewBox="0 0 24 24"
                    fill="none"
                    stroke="currentColor"
                    stroke-width="2"
                    stroke-linecap="round"
                    stroke-linejoin="round"
                  >
                    <path d="M9 18V5l12-2v13"></path>
                    <circle cx="6" cy="18" r="3"></circle>
                    <circle cx="18" cy="16" r="3"></circle>
                  </svg>
                </span>
                <span class="music-text">{{'campaigns_preview.music_text' | translate}}</span>
              </div>
              <button class="learn-more-btn">
                <span>
                  {{previewData?.call_to_action}}
                </span>
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="16"
                  height="16"
                  viewBox="0 0 24 24"
                  fill="none"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <polyline points="9 18 15 12 9 6"></polyline>
                </svg>
              </button>
            </div>
            
            <div class="right-section">
              <div class="profile-pic"></div>
              <div class="action-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="36"
                  height="36"
                  viewBox="0 0 24 24"
                  fill="#ffffff"
                  stroke="currentColor"
                  stroke-width="2"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <path
                    d="M20.84 4.61a5.5 5.5 0 0 0-7.78 0L12 5.67l-1.06-1.06a5.5 5.5 0 0 0-7.78 7.78l1.06 1.06L12 21.23l7.78-7.78 1.06-1.06a5.5 5.5 0 0 0 0-7.78z"
                  ></path>
                </svg>
                <span class="action-count">15</span>
              </div>
              <div class="action-item">
                <svg
                  width="44"
                  height="43"
                  viewBox="0 0 44 43"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_d_2495_53591)">
                    <path
                      fill-rule="evenodd"
                      clip-rule="evenodd"
                      d="M40 19.9971C40 23.6136 38.5616 26.9408 36.1507 29.5777C34.3043 32.0621 31.9284 34.1293 29.175 35.6179L24.7103 38.0317C23.5594 38.654 22.1817 37.7337 22.3158 36.4322L22.4125 35.4931C22.2754 35.4957 22.1379 35.4971 22 35.4971C12.0589 35.4971 4 28.5575 4 19.9971C4 11.4367 12.0589 4.49707 22 4.49707C31.9411 4.49707 40 11.4367 40 19.9971ZM12.5 22.4971C13.8807 22.4971 15 21.3778 15 19.9971C15 18.6164 13.8807 17.4971 12.5 17.4971C11.1193 17.4971 10 18.6164 10 19.9971C10 21.3778 11.1193 22.4971 12.5 22.4971ZM24 19.9971C24 21.3778 22.8807 22.4971 21.5 22.4971C20.1193 22.4971 19 21.3778 19 19.9971C19 18.6164 20.1193 17.4971 21.5 17.4971C22.8807 17.4971 24 18.6164 24 19.9971ZM31.5 22.4971C32.8807 22.4971 34 21.3778 34 19.9971C34 18.6164 32.8807 17.4971 31.5 17.4971C30.1193 17.4971 29 18.6164 29 19.9971C29 21.3778 30.1193 22.4971 31.5 22.4971Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d_2495_53591"
                      x="0"
                      y="0.49707"
                      width="44"
                      height="41.7334"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_2495_53591"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_2495_53591"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
              <div class="action-item">
                <svg
                  width="43"
                  height="37"
                  viewBox="0 0 43 37"
                  fill="none"
                  xmlns="http://www.w3.org/2000/svg"
                >
                  <g filter="url(#filter0_d_2495_53601)">
                    <path
                      d="M38.4866 19.7341L26.2354 31.9848C25.735 32.4852 24.9835 32.6345 24.3289 32.3638C23.6759 32.0931 23.2493 31.4549 23.2493 30.7477V25.5254C11.5921 25.9224 7.021 30.1996 6.97522 30.2454H6.97358C6.42066 30.7835 5.57899 30.8951 4.90301 30.5211C4.22702 30.1453 3.87593 29.3726 4.03999 28.6179C4.07609 28.4522 7.76439 12.6741 23.2492 11.5586V6.24638C23.2492 5.53926 23.6758 4.90107 24.3288 4.63033C24.9834 4.35963 25.7349 4.50893 26.2353 5.00932L38.4864 17.26C38.8146 17.5881 39 18.0327 39 18.4971C39 18.9614 38.8146 19.406 38.4864 19.7341L38.4866 19.7341Z"
                      fill="white"
                    />
                  </g>
                  <defs>
                    <filter
                      id="filter0_d_2495_53601"
                      x="0"
                      y="0.49707"
                      width="43"
                      height="36"
                      filterUnits="userSpaceOnUse"
                      color-interpolation-filters="sRGB"
                    >
                      <feFlood flood-opacity="0" result="BackgroundImageFix" />
                      <feColorMatrix
                        in="SourceAlpha"
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 127 0"
                        result="hardAlpha"
                      />
                      <feOffset />
                      <feGaussianBlur stdDeviation="2" />
                      <feComposite in2="hardAlpha" operator="out" />
                      <feColorMatrix
                        type="matrix"
                        values="0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0 0.25 0"
                      />
                      <feBlend
                        mode="normal"
                        in2="BackgroundImageFix"
                        result="effect1_dropShadow_2495_53601"
                      />
                      <feBlend
                        mode="normal"
                        in="SourceGraphic"
                        in2="effect1_dropShadow_2495_53601"
                        result="shape"
                      />
                    </filter>
                  </defs>
                </svg>
              </div>
              <div class="action-item">
                <svg
                  xmlns="http://www.w3.org/2000/svg"
                  width="42"
                  height="42"
                  viewBox="0 0 24 24"
                  fill="#000000"
                  stroke="#2E2E2E"
                  stroke-width="1.4"
                  stroke-linecap="round"
                  stroke-linejoin="round"
                >
                  <circle cx="12" cy="12" r="10"></circle>
                  <circle cx="12" cy="12" r="3"></circle>
                  <path d="M7 12h5m5 0h-5m0-5v5 5"></path>
                </svg>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</ng-container>

<ng-container *ngIf="platform ===adwizardPlatforms.instagram">
  <div class="instagram-post">
    <div class="post-header">
        <div class="profile-pic"></div>
        <div class="user-sponso-container">
            <span class="username">{{'campaigns_preview.username' | translate}}</span>
            <span class="sponsored">{{'campaigns_preview.sponsored' | translate}}</span>
        </div>
        <div class="options">...</div>
    </div>
    <div class="image-placeholder">
        <div class="image-text">
          <img *ngIf="previewData?.image_uri" [src]="previewData?.image_uri" alt="Ad Image">
          <video *ngIf="previewData?.video_uri" [src]="previewData?.video_uri" class="video-item" [muted]="true" autoplay loop playsinline></video>
        </div>
    </div>
    <div class="learn-more">
        <div href="#" class="learn-more-btn">
          {{'campaigns_preview.learn_more' | translate}}
          <img class="arrow" src="assets/images/ads/instagram/chevronRight.svg" />
        </div>
    </div>
    <div class="engagement">
        <div class="icons">
            <div class="left-icons">
              <img class="heart" src="assets/images/ads/instagram/insta-heart.svg" />
              <img class="comment" src="assets/images/ads/instagram/insta-comment.svg" />
              <img class="send" src="assets/images/ads/instagram/insta-send.svg" />
            </div>
            <span><img class="bookmark" src="assets/images/ads/instagram/bookmark.svg" /></span>
        </div>
        <div class="likes">15 {{'campaigns_preview.likes' | translate}}</div>
        <div class="caption">
          <span class="username-caption">{{'campaigns_preview.username' | translate}} </span>
          <span *ngIf="canRenderData(previewData, 'texts')">{{ previewData?.texts[0] }}</span>
        </div>
    </div>
  </div>
</ng-container>