import { Component, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app-service/app.service';

@Component({
    selector: 'app-demo-banner',
    templateUrl: './demo-banner.component.html',
    styleUrls: ['./demo-banner.component.scss'],
    standalone: false
})
export class DemoBannerComponent implements OnInit {
  isDemoLogin: boolean|string = false;
  public showYourDataModal = false;

  constructor(
    public appService: AppService
  ) { }

  async ngOnInit() {
    this.isDemoLogin = (await this.appService.getStorage('isDemoLogin')) || false;
  }

  createAccount(): void {
    this.showYourDataModal = false;
    this.appService.logoutUser(this.appService.appRoutes.registerPage);
  }

  signin(): void {
    this.showYourDataModal = false;
    this.appService.logoutUser(this.appService.appRoutes.loginPage);
  }

}
