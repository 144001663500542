import { Component, ElementRef, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppService } from 'src/app/services/app-service/app.service';
import { AppsflyerService } from 'src/app/services/appsflyer/appsflyer.service';

@Component({
  selector: 'app-demo-video-overlay',
  templateUrl: './demo-video-overlay.component.html',
  styleUrls: ['./demo-video-overlay.component.scss'],
})
export class DemoVideoOverlayComponent  implements OnInit, OnDestroy {
  @ViewChild('video') video: ElementRef;
  public contentVideo: HTMLVideoElement;
  public videoSkeleton = false;
  constructor(
    public appService: AppService,
    private modalCtrl: ModalController,
    public appsflyerService: AppsflyerService
  ) { }

  ngOnInit() {
    this.videoSkeleton = true;
    setTimeout(()=> {
      this.contentVideo = this.video?.nativeElement;
      if (this.contentVideo) {
        this.contentVideo.addEventListener('click', () => {
          // eslint-disable-next-line @typescript-eslint/no-unused-expressions
          this.contentVideo.paused ? this.contentVideo.play() : this.contentVideo.pause();
        })
        this.contentVideo.addEventListener("loadedmetadata", () => {
          this.videoSkeleton = false;
        });

        // Verify Video readyState and close video skeleton
        if (this.contentVideo.readyState >= 2) {
          this.videoSkeleton = false;
        }
      }
    }, 100);
  }

  dismissVideoOverlay(): void {
    this.modalCtrl.dismiss();
    this.appsflyerService.initAppsFlyer();
  }

  videoOverlayPlayPause(): void {
    if (this.contentVideo) {
      this.videoSkeleton = false;
      // eslint-disable-next-line @typescript-eslint/no-unused-expressions
      this.contentVideo.paused ? this.contentVideo.play() : this.contentVideo.pause();
    }
  }

  ngOnDestroy(): void {
    this.contentVideo.removeEventListener('click', () => {
      if (this.contentVideo) {
        this.contentVideo.pause();
      }
    });
    this.contentVideo.removeEventListener("loadedmetadata", () => {});
  }
}
