<div>
  <app-filter></app-filter>
  <!-- Dynamic Container component -->
  <div class="vertical-space-fix my-store">
    <app-dynamic-container #dynamicStatsContainer [containerData]="statsData"></app-dynamic-container>
  </div>
  <div class="book-meeting-cover-web" *ngIf="appService.checkAppFeatureEnabled(calendlyScheduleAppointment)">
    <div class="book-meeting-banner" *ngIf="statsData?.containers?.length">
      <div class="expert-image-block">
        <img [src]="appService.dynamicLinks?.['book_a_meeting_icon']" alt="">
        <div class="company-logo-smallest">
          <app-logo-smallest></app-logo-smallest>
        </div>
      </div>
      <div class="book-meet-text" (click)="appService.navigateForward(appService.appRoutes.bookMeetingPage)">
        <div class="meet-text">
          <h2>{{ 'book_meeting.book_a_meeting' | translate }}</h2>
          <h3>{{ 'book_meeting.get_help' | translate }}</h3>
        </div>
        <div class="go-next-icon">
          <app-cheveron-right></app-cheveron-right>
        </div>
      </div>
    </div>
  </div>
  <app-no-data-available
    *ngIf="showNoDataAlertStats || showInternalServerErrorAlertStats"
    [customTop]="warningDivCustomTop"
    [showInternalServerError]="showInternalServerErrorAlertStats">
  </app-no-data-available>
</div>
