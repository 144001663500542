import { Component, Input, OnInit } from '@angular/core';
import { Config } from 'src/app/services/config';
import { ApiEndpoint } from 'src/app/models/apiRequest';
import { CampaignData } from 'src/app/models/apiResponse';
import { AppService } from 'src/app/services/app-service/app.service';
import { HttpErrorResponse } from '@angular/common/http';
import { ModalController } from '@ionic/angular';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { TranslateService } from '@ngx-translate/core';

@Component({
    selector: 'app-edit-campaigns',
    templateUrl: './edit-campaigns.component.html',
    styleUrls: ['./edit-campaigns.component.scss'],
    standalone: false
})
export class EditCampaignsComponent  implements OnInit {

  @Input() campaignID: string;
  public campaignForm: FormGroup;
  public campaignData: CampaignData;
  public isCampaignFormSubmitted: boolean = false;

  constructor(
    public appService: AppService,
    public modalController: ModalController,
    public formBuilder: FormBuilder,
    private translate: TranslateService,
  ) { }

  ngOnInit() {
    this.getCampaignData();
  }

  close(): void {
    this.modalController.dismiss();
  }

  async getCampaignData(): Promise<void> {
    this.appService.showLoader();
    const apiEndpoint: ApiEndpoint = this.appService.getClone(Config.apiUrl.getCampaign);
    apiEndpoint.url = apiEndpoint.url + this.campaignID;
    this.appService.api({}, apiEndpoint).subscribe({
      next: async (results: CampaignData) => {
        this.campaignData = results;
        this.campaignForm = this.buildForm();
        this.appService.hideLoader();
      },
      error: async (e: HttpErrorResponse) => {
        console.error(e);
        this.appService.hideLoader();
        this.appService.presentToast(this.translate.instant('toast_message.general_error'));
      }
    })
  }

  buildForm(): FormGroup {
    const formControls = {};

    // Iterate through API response to build form controls dynamically
    const formFields = this.campaignData?.mutable_config;
    Object.keys(formFields).forEach(key => {
      const validations = [Validators.required];
      formControls[key] = [
        formFields[key].default || '',
        validations
      ];
    });

    return this.formBuilder.group(formControls);
  }

  getFormFieldError(form: FormGroup, fieldKey: string) {
    const formControl = form.get(fieldKey);
    const fieldName = this.campaignData.mutable_config[fieldKey].label;

    if (formControl.hasError('required')) {
      return `${fieldName} ` + this.translate.instant('adwizard.is_required').toLowerCase();
    }
    return '';
  }

  updateCampaign() {
    this.isCampaignFormSubmitted = true;
    if (this.campaignForm.valid) {
      this.appService.showLoader();
      const apiEndpoint: ApiEndpoint = this.appService.getClone(Config.apiUrl.patchCampaign);
      apiEndpoint.url = apiEndpoint.url + this.campaignID;
      this.appService.api(this.campaignForm.value, apiEndpoint).subscribe({
        next: async () => {
          this.modalController.dismiss(true)
          this.appService.hideLoader();
        },
        error: async (e: HttpErrorResponse) => {
          console.error(e);
          this.appService.hideLoader();
          this.appService.presentToast(this.translate.instant('toast_message.general_error'));
        }
      })
    }
  }

}
