<div *ngIf="selected.length; else placeholderTemplate">
  <span *ngFor="let item of selected; let last = last">
    {{item[searchField]}}{{last ? "" : ","}}
  </span>
</div>

<ng-template #placeholderTemplate><span *ngIf="showPlaceholder">{{placeholderText ? placeholderText : ('placeholders.select' | translate ) + '&nbsp;'+title}}</span></ng-template>

<ion-modal class="no-border-radius" [isOpen]="isOpen" [canDismiss]="canDismiss">
  <ng-template>
    <ion-header class="ion-no-border no-border-radius" [translucent]="true">
      <div class="pop-header notch-space title-with-button">
        <div class="close">
          <app-back-icon (click)="close()"></app-back-icon>
        </div>
        <h2>{{'placeholders.select' | translate}} {{title}}</h2>
      </div>
      <div class="search-bar">
        <ion-list>
          <ion-item lines="none">
            <ion-searchbar (ionInput)="filter($any($event))" placeholder="{{'placeholders.search' | translate}}"></ion-searchbar>
          </ion-item>
        </ion-list>
      </div>
    </ion-header>
    <ion-content class="grey-bg no-border-radius" *ngIf="filtered as filteredList">
      <div class="cards-area">
        <ion-card mode="ios" class="order-card">
          <ion-card-content>
            <ion-list mode="ios" lines="full">
              <div class="checkbox-sec">
                <ion-item *ngFor="let item of filteredList" (click)="itemSelected()">
                  <ion-checkbox labelPlacement="start" [(ngModel)]="item.selected">{{item[searchField]}}</ion-checkbox>
                </ion-item>
              </div>
            </ion-list>
          </ion-card-content>
        </ion-card>
        <div class="ion-text-center" *ngIf="!filtered.length">
          <div>
            <h3>{{'error_messages.no_data_found' | translate}}</h3>
            <p>
              {{'error_messages.check_back_later' | translate}}
            </p>
          </div>
        </div>
      </div>
    </ion-content>
    <ion-footer>
      <div class="toolbar">
        <div class="footer">
          <div class="button-section">
            <ion-button class="custom-btn-create" shape="round" expand="block" (click)="select()">
              {{ 'buttons.save' | translate }}
            </ion-button>
          </div>
        </div>
      </div>
    </ion-footer>
  </ng-template>
</ion-modal>
