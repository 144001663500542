
<svg width="12px" height="12px" viewBox="0 0 12 12" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <defs>
        <polygon id="path-7uk_xgl2wr-1" points="0 0 11.9999 0 11.9999 11.9997 0 11.9997"></polygon>
    </defs>
    <g id="EFFEKTIFY" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Store-Picked" transform="translate(-248.000000, -241.000000)">
            <g id="Providers" transform="translate(27.000000, 193.000000)">
                <g id="Group-3" transform="translate(221.000000, 48.000000)">
                    <mask id="mask-7uk_xgl2wr-2" fill="white">
                        <use xlink:href="#path-7uk_xgl2wr-1"></use>
                    </mask>
                    <g id="Clip-2"></g>
                    <path d="M9.072,4.341 L5.215,8.215 L5.212,8.215 C5.163,8.264 5.031,8.374 4.878,8.374 C4.768,8.374 4.644,8.313 4.54,8.209 L2.925,6.594 C2.879,6.548 2.879,6.476 2.925,6.429 L3.438,5.916 C3.461,5.893 3.49,5.881 3.519,5.881 C3.548,5.881 3.577,5.893 3.6,5.916 L4.881,7.197 L8.4,3.652 C8.423,3.629 8.452,3.617 8.481,3.617 C8.512,3.617 8.541,3.629 8.561,3.652 L9.066,4.174 C9.118,4.223 9.118,4.295 9.072,4.341 Z M6,0 C2.685,0 0,2.685 0,6 C0,9.314 2.685,12 6,12 C9.314,12 12,9.314 12,6 C12,2.685 9.314,0 6,0 L6,0 Z" id="Fill-1" fill="#FFFFFF" mask="url(#mask-7uk_xgl2wr-2)"></path>
                </g>
            </g>
        </g>
    </g>
</svg>
