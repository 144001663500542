<ion-content class="track-modal-content">
  <div class="header-image"></div>
  <div class="content markdown" *ngIf="scenario === appTrackingTransparencyPluginStatus.denied">
    <markdown [data]="'app_tracking_permission.guide' | translate"></markdown>
  </div>
  <div class="content" *ngIf="scenario === appTrackingTransparencyPluginStatus.notDetermined">
    <h3 class="content-title">
      {{'app_tracking_permission.ios_version_title' | translate}}
    </h3>
    <p class="description">
      {{'app_tracking_permission.description' | translate}}
    </p>
    <p class="permission-usage-title">
      {{'app_tracking_permission.permission_usage_title' | translate}}
    </p>
    <div class="permission-usage-section">
      <div class="part">
        <app-svg name="mega.svg"></app-svg>
        <p class="ion-no-padding ion-no-margin">{{'app_tracking_permission.permission_usage_1' | translate}}</p>
      </div>
      <div class="part">
        <app-svg name="like.svg"></app-svg>
        <p class="ion-no-padding ion-no-margin">{{'app_tracking_permission.permission_usage_2' | translate}}</p>
      </div>
      <div class="part">
        <app-svg name="biz.svg"></app-svg>
        <p class="ion-no-padding ion-no-margin">{{'app_tracking_permission.permission_usage_3' | translate}}</p>
      </div>
    </div>
  </div>
  <ion-button class="custom-button" (click)="dismissModal()"> {{'buttons.continue' | translate}} </ion-button>
</ion-content>
