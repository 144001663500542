import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-stat-icon',
  templateUrl: './stat-icon.component.html',
  styleUrls: ['./stat-icon.component.scss'],
})
export class StatIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
