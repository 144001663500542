import { Component, Input, Output, EventEmitter, OnChanges } from '@angular/core';
import { SearchbarCustomEvent } from '@ionic/angular';
import { AdWizardFieldOption } from 'src/app/models/apiResponse';
import { AppService } from 'src/app/services/app-service/app.service';

@Component({
    selector: 'app-searchable-select',
    templateUrl: './searchable-select.component.html',
    styleUrls: ['./searchable-select.component.scss'],
    standalone: false
})
export class SearchableSelectComponent implements OnChanges {
  @Input() title = 'Search';
  @Input() data;
  @Input() multiple = false;
  @Input() placeholderText: string;
  @Input() showPlaceholder: boolean = false;
  @Input() searchField: string;
  @Input() default: string|number|string[]|number[];
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  @Output() selectedChanged: EventEmitter<any> = new EventEmitter();
  isOpen = false;
  selected = [];
  filtered = [];
  public canDismiss = false;

  constructor(
    private appService: AppService
  ) { }

  ngOnChanges(): void {
    this.filtered = this.data;
    if (this.default) {
      this.populateDefault();
    }
  }

  open(): void {
    this.isOpen = true;
    this.filtered = this.data;
    setTimeout(() => {
      this.appService.hideLoader();
      this.canDismiss = false;
      if (this.default) {
        this.populateDefault();
      }
    }, 600);
  }

  close(): void {
    this.canDismiss = true;
    setTimeout(() => {
      this.isOpen = false;
      this.selectedChanged.emit(this.selected);
    }, 10);
  }

  populateDefault(): void {
    if (Array.isArray(this.default)) { // Handle default values when its an array
      this.selected = [];
      this.filtered.map(data => data.selected = false);
      this.default?.map((value: number|string) => {
        const itemFound = this.filtered.find((data: AdWizardFieldOption) => data?.value?.toString() === value.toString());
        if (itemFound) {
          itemFound.selected = true;
          this.selected.push(itemFound);
        }
      })
    }
  }

  select(): void {
    this.canDismiss = true;
    setTimeout(() => {
      this.selected = this.data.filter((item) => item?.selected);
      this.selectedChanged.emit(this.selected);
      this.isOpen = false;
    }, 10);
  }

  itemSelected(): void {
    if (!this.multiple) {
      if (this.selected?.length) {
        this.selected[0].selected = false;
      }
      this.selected = this.data.filter((item)=> item?.selected);
      if (!this.multiple && this.selected.length) {
        this.canDismiss = true;
        setTimeout(() => {
          this.selectedChanged.emit(this.selected);
          this.isOpen = false;
        }, 10);
      }
    }
  }

  filter(event: SearchbarCustomEvent): void {
    const filter = event.detail.value.toLowerCase();
    this.filtered = this.data.filter(item => item[this.searchField].toLowerCase().indexOf(filter) >= 0);
  }
}
