import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-close-small-icon',
    templateUrl: './close-small-icon.component.html',
    styleUrls: ['./close-small-icon.component.scss'],
    standalone: false
})
export class CloseSmallIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
