<ion-header class="custom-header-web ion-no-border" lines="none" [translucent]="true">
  <div class="header-outer">
    <ion-toolbar>
      <ion-buttons class="menu-logo" slot="start">
        <app-logo-main class="cursor-pointer" (click)="navigateRoot(appService.appRoutes.dashboardPage)"></app-logo-main>
        <div class="header-team-name">{{appService.selectedTeam.name}}</div>
      </ion-buttons>
      <ion-buttons class="contact-icon-cover" slot="end">
        <ion-button (click)="navigateRoot(appService.appRoutes.dashboardPage)">
          <div class="navigation-item" [class.active]="isTabActive([appService.appRoutes.dashboardPage])">
            <app-globe-icon></app-globe-icon>
            <span>{{'tabs.dashboard' | translate}}</span> 
          </div>
        </ion-button>
        <ion-button (click)="navigateRoot(appService.appRoutes.myStorePage)" *ngIf="appService.checkAppFeatureEnabled('page.my_store')">
          <div class="navigation-item" [class.active]="isTabActive([appService.appRoutes.myStorePage])">
            <app-stat-icon></app-stat-icon>
            <span>{{'tabs.mystore' | translate}}</span>
          </div>
        </ion-button>
        <ion-button (click)="navigateRoot(appService.appRoutes.myWebsitePage)" *ngIf="appService.checkAppFeatureEnabled('page.my_website')">
          <div class="navigation-item" [class.active]="isTabActive([appService.appRoutes.myWebsitePage])">
            <app-stat-icon></app-stat-icon>
            <span>{{'tabs.mywebsite' | translate}}</span>
          </div>
        </ion-button>
        <ion-button (click)="navigateRoot(appService.appRoutes.marketingPage)">
          <div class="navigation-item" [class.active]="isTabActive([appService.appRoutes.marketingPage, appService.appRoutes.adWizardPage])">
            <app-marketing-icon></app-marketing-icon>
            <span>{{'tabs.marketing' | translate}}</span>
          </div>
        </ion-button>
        <ion-button (click)="navigateRoot(appService.appRoutes.insightsPage)">
          <div class="navigation-item" [class.active]="isTabActive([appService.appRoutes.insightsPage])">
            <app-connections-icon></app-connections-icon>
            <span>{{'tabs.insights' | translate}}</span>
          </div>
        </ion-button>

      </ion-buttons>
      <ion-buttons class="notification-icon-cover" slot="end">
        <ion-button *ngIf="appService.checkAppFeatureEnabled(calendlyScheduleAppointment)" (click)="appService.navigateForward(appService.appRoutes.bookMeetingPage)">
          <div class="contact-icon">
            <app-svg name="contact-plus.svg"></app-svg>
          </div>
        </ion-button>
        <ion-button (click)="navigatePage(appService.appRoutes.notificationPage)">
          <div class="notification-icon">
            <app-svg name="notification-icon.svg"></app-svg>
            <div [class.ion-hide]="!appService.unreadNotificationCount" class="badge">{{(appService.unreadNotificationCount > 99) ? '99+' : appService.unreadNotificationCount}}</div>
          </div>
        </ion-button>
      </ion-buttons>
      <ion-buttons class="menu-toggle" slot="end">
        <ion-menu-button class="menu-icon-web" autoHide="false">
          <app-svg name="side-menu-icon.svg"></app-svg>
        </ion-menu-button>
      </ion-buttons>
    </ion-toolbar>
  </div>
</ion-header>
