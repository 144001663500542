import { AfterViewInit, Component, ElementRef, Input, OnInit, ViewChild } from '@angular/core';
import { Gesture, GestureController, GestureDetail, ItemReorderEventDetail, ModalController } from '@ionic/angular';
import { ApiEndpoint } from 'src/app/models/apiRequest';
import { AvailableTrendsList, TeamResponse, TrendAvailableData } from 'src/app/models/apiResponse';
import { AppService } from 'src/app/services/app-service/app.service';
import { Config } from 'src/app/services/config';
import { TranslateService } from '@ngx-translate/core';
import { FirebaseCrashlyticsService } from 'src/app/services/firebase-crashlytics/firebase-crashlytics.service';


interface ExtendedTrendsList extends TrendAvailableData {
  checked?: boolean;
}

@Component({
  selector: 'app-customize-trends',
  templateUrl: './customize-trends.component.html',
  styleUrls: ['./customize-trends.component.scss'],
})
export class CustomizeTrendsComponent implements OnInit, AfterViewInit {
  @Input() selectedTrendsArray: string[];
  @Input() periodFilter;
  @ViewChild('swipeContent', {read: ElementRef}) private swipeContentElm: ElementRef;
  public searchText: string;
  public trendsList: ExtendedTrendsList[];
  public skeletonArray = new Array(4);
  public showSkeleton = true;
  public showNoDataAlert = false;
  public showInternalServerErrorAlert = false;
  public selectedTeam: TeamResponse;
  constructor(
    private modalCtrl: ModalController,
    private gestureCtrl: GestureController,
    private appService: AppService,
    private translate: TranslateService,
    private crashlyticsService: FirebaseCrashlyticsService
  ) { }

  ngOnInit() {
    this.crashlyticsService.customizeCrashlytics({ page: 'Customize trends' });
    this.selectedTeam = this.appService.getTeam();
    this.fetchTrendsData();

    //Delete trends from payload
    if (this.periodFilter?.trends) {
      delete this.periodFilter.trends;
    }
  }

  close(): void {
    this.modalCtrl.dismiss();
  }

  // Custom swipe back gesture
  ngAfterViewInit(): void {
    const gesture: Gesture = this.gestureCtrl.create({
      el: this.swipeContentElm?.nativeElement,
      threshold: 20,
      gestureName: 'swipe-back-gesture',
      onEnd: (detail: GestureDetail) => {
        if (detail?.startX < 60 && detail?.deltaX > 0) {
          this.close();
        }
      }
    });
    gesture?.enable();
  }

  async fetchTrendsData(): Promise<void> {
    await this.appService.showLoader();
    const apiEndpoint: ApiEndpoint = this.appService.getClone(Config.apiUrl.availableTrends);
    apiEndpoint.url = apiEndpoint.urlStart + this.selectedTeam.id + apiEndpoint.urlEnd;
    this.appService.api(this.periodFilter, apiEndpoint).subscribe({
      next: async (trendsList: AvailableTrendsList) => {
        this.showNoDataAlert = false;
        this.showInternalServerErrorAlert = false;
        if (!trendsList) {
          this.showNoDataAlert = true;
        } else {
          const listSortOrder = this.appService.getTrendsSectionsBasedOnTeam()?.split(',');
          const list = trendsList?.result?.slice() || [];
          //Sorting list based on preference
          list?.sort((item1: ExtendedTrendsList, item2: ExtendedTrendsList) => {
            const index1 = listSortOrder.indexOf(item1.code);
            const index2 = listSortOrder.indexOf(item2.code);
            return ( (index1 > -1 ? index1 : Infinity) - (index2 > -1 ? index2 : Infinity));
          });
          //Assigning checked property to list
          list?.map((data: ExtendedTrendsList) => {
            const index = this.selectedTrendsArray.findIndex(item => item === data.code);
            data.checked = index > -1;
          })
          this.trendsList = list;
        }
        this.showSkeleton = false;
        await this.appService.hideLoader();
      },
      error: async () => {
        this.appService.presentToast(this.translate.instant('customize_trends.failed_fetch'));
        await this.appService.hideLoader();
      }
    })
  }

  handleReorder(ev: CustomEvent<ItemReorderEventDetail>): void {
    ev.detail.complete();
    const element = this.trendsList.splice(ev.detail.from, 1)[0];
    this.trendsList.splice(ev.detail.to, 0, element);
  }

  selectSections(code: string): void {
    const listIndex = this.trendsList.findIndex(item => item.code === code);
    this.trendsList[listIndex].checked = this.trendsList[listIndex].checked ? false : true;
  }

  saveSections(): void {
    const listStringified: string[] = [];
    this.trendsList.map((list: ExtendedTrendsList) => {
      if (list.checked) {
        listStringified.push(list.code);
      }
    });
    this.appService.setTrendsSectionsBasedOnTeam(listStringified.map((code: string) => code).join(','));
    this.modalCtrl.dismiss({ selectedTrendsArray: listStringified });
  }

  getElementIndex(el, array) {
    return array.find(elm => elm === el)
  }
}
