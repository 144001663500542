import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-open-new',
  templateUrl: './open-new.component.html',
  styleUrls: ['./open-new.component.scss'],
})
export class OpenNewComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
