<ion-content #swipeContent class="grey-bg no-border-radius" *ngIf="(highlightsList | searchBy:searchText:'name') as filteredHighlightsList">
  <div class="pop-header notch-space">
    <div class="close">
      <app-close-icon (click)="close()"></app-close-icon>
    </div>
    <h2>{{'customize_highlights.title' | translate}}</h2>
  </div>
  <div class="search-bar">
    <ion-list>
      <ion-item lines="none">
        <div class="button-icon" slot="start">
          <app-search-icon></app-search-icon>
        </div>
        <ion-input type="text" [(ngModel)]="searchText" placeholder="{{'placeholders.search' | translate}}"
          [clearInput]="true"></ion-input>
      </ion-item>
    </ion-list>
  </div>
  <div class="cards-area domain-selection">
    <ion-card mode="ios" class="order-card">
      <ion-card-content>
        <ion-list mode="ios" lines="full">
          <div class="checkbox-sec" *ngFor="let item of skeletonArray" [class.ion-hide]="!showSkeleton || showNoDataAlert">
            <ion-skeleton-text [animated]="true" class="data-skeleton"></ion-skeleton-text>
          </div>
          <div [class.ion-hide]="showSkeleton">
            <ion-reorder-group [disabled]="false" (ionItemReorder)="handleReorder($any($event))">
                <div class="checkbox-sec" *ngFor="let data of filteredHighlightsList">
                <ion-item lines="none" [class.disable]="!data?.available">
                  <ion-checkbox labelPlacement="start" [disabled]="!data?.available" (ionChange)="selectSections(data?.code)" [checked]="getElementIndex(data?.code, selectedHighlightsArray)">{{data?.name}}</ion-checkbox>
                  <ion-reorder slot="end"></ion-reorder>
                </ion-item>
              </div>
              </ion-reorder-group>
          </div>
        </ion-list>
      </ion-card-content>
    </ion-card>
    <div class="domain-listing-area ion-text-center" *ngIf="!showSkeleton && filteredHighlightsList?.length === 0">
      <div class="domain-not-available">
        <app-domain-icon></app-domain-icon>
        <h3>{{'customize_highlights.no_highlights_found' | translate}}</h3>
        <p>
          {{'customize_highlights.no_highlights_message' | translate}}
        </p>
      </div>
    </div>
  </div>
  <div class="button-section" *ngIf="filteredHighlightsList?.length">
    <ion-button class="ion-padding" expand="block" (click)="saveSections()">{{'buttons.save' | translate}}</ion-button>
  </div>
</ion-content>
