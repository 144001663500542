import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-logo-smallest',
    templateUrl: './logo-smallest.component.html',
    styleUrls: ['./logo-smallest.component.scss'],
    standalone: false
})
export class LogoSmallestComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
