import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-sources-icon',
  templateUrl: './sources-icon.component.html',
  styleUrls: ['./sources-icon.component.scss'],
})
export class SourcesIconComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
