<svg id="Group_2421" data-name="Group 2421" xmlns="http://www.w3.org/2000/svg" width="102.653" height="100.318" viewBox="0 0 102.653 100.318">
  <path id="Path_453" data-name="Path 453" d="M181.93,585.7l.11-2.471a7.981,7.981,0,0,1,3.723-.943c-1.788,1.462-1.565,4.28-2.777,6.246a4.8,4.8,0,0,1-3.521,2.219l-1.5.916a8.044,8.044,0,0,1,1.7-6.519,7.766,7.766,0,0,1,1.428-1.327c.358.945.837,1.879.837,1.879" transform="translate(-150.202 -491.789)" fill="#f2f2f2"/>
  <path id="Path_454" data-name="Path 454" d="M258.421,116.058a12.786,12.786,0,0,0,4.755-13.161q-.045-.183-.095-.366c-.8-2.924-2.778-5.7-5.662-6.636-2.421-.784-5.064-.181-7.59-.493-4.958-.612-8.8-4.624-11.507-8.825s-4.784-8.9-8.378-12.372c-5.924-5.72-15.588-6.952-22.934-3.228a22.451,22.451,0,0,0-11.825,20.216,24.816,24.816,0,0,0,12.185,20.28A13.923,13.923,0,0,0,216,113.713c2.6-.33,4.97-1.729,7.562-2.145,4.075-.653,8.124,1.189,11.649,3.336s6.929,4.692,10.934,5.687c3.889.967,8.707-1.811,12.281-4.533" transform="translate(-164.845 -58.151)" fill="#f2f2f2"/>
  <path id="Path_455" data-name="Path 455" d="M161.144,136.023H120.956a3.165,3.165,0,0,0-3.161,3.161v20a3.165,3.165,0,0,0,3.161,3.161h40.188a3.164,3.164,0,0,0,3.161-3.161v-20a3.164,3.164,0,0,0-3.161-3.161" transform="translate(-99.487 -114.881)" fill="#fff"/>
  <path id="Path_456" data-name="Path 456" d="M160.3,161.657H120.117a3.321,3.321,0,0,1-3.317-3.317v-20a3.32,3.32,0,0,1,3.317-3.317H160.3a3.321,3.321,0,0,1,3.317,3.317v20a3.321,3.321,0,0,1-3.317,3.317m-40.187-26.325a3.009,3.009,0,0,0-3.006,3.006v20a3.01,3.01,0,0,0,3.006,3.006H160.3a3.01,3.01,0,0,0,3.006-3.006v-20a3.009,3.009,0,0,0-3.006-3.006Z" transform="translate(-98.646 -114.035)" fill="#ccc"/>
  <path id="Path_457" data-name="Path 457" d="M192.5,328.331H153.991a1.255,1.255,0,0,1,0-2.51H192.5a1.255,1.255,0,1,1,0,2.51" transform="translate(-128.997 -275.181)" fill="#e6e6e6"/>
  <path id="Path_458" data-name="Path 458" d="M232.9,189.6a1.351,1.351,0,0,1-.652-.17,1.334,1.334,0,0,1-.7-1.184V177.087a1.351,1.351,0,0,1,2.076-1.141l10.589,5.581a1.351,1.351,0,0,1,0,2.281l-10.589,5.581a1.345,1.345,0,0,1-.723.214" transform="translate(-195.558 -148.422)" fill="#f2f2f2"/>
  <path id="Path_459" data-name="Path 459" d="M541.915,169.377H525.121a1.323,1.323,0,0,1-1.321-1.321V159.7a1.323,1.323,0,0,1,1.321-1.321h16.794a1.323,1.323,0,0,1,1.321,1.321v8.359a1.323,1.323,0,0,1-1.321,1.321" transform="translate(-442.389 -133.76)" fill="#fff"/>
  <path id="Path_460" data-name="Path 460" d="M541.07,168.688H524.276a1.478,1.478,0,0,1-1.477-1.477v-8.359a1.478,1.478,0,0,1,1.477-1.477H541.07a1.478,1.478,0,0,1,1.477,1.477v8.359a1.478,1.478,0,0,1-1.477,1.477m-16.794-11a1.167,1.167,0,0,0-1.166,1.166v8.359a1.167,1.167,0,0,0,1.166,1.166H541.07a1.167,1.167,0,0,0,1.166-1.166v-8.359a1.167,1.167,0,0,0-1.166-1.166Z" transform="translate(-441.544 -132.916)" fill="#ccc"/>
  <path id="Path_461" data-name="Path 461" d="M554.313,241.831H543a1.255,1.255,0,0,1,0-2.51h11.313a1.255,1.255,0,1,1,0,2.51" transform="translate(-457.546 -202.125)" fill="#e6e6e6"/>
  <path id="Path_462" data-name="Path 462" d="M571.636,181.412a.565.565,0,0,1-.272-.071.557.557,0,0,1-.293-.495v-4.665a.565.565,0,0,1,.867-.477l4.425,2.332a.565.565,0,0,1,0,.953l-4.425,2.332a.562.562,0,0,1-.3.089" transform="translate(-482.313 -148.322)" fill="#FF9999"/>
  <path id="Path_463" data-name="Path 463" d="M472.915,400.733H456.121a1.323,1.323,0,0,1-1.321-1.321v-8.359a1.323,1.323,0,0,1,1.321-1.321h16.794a1.323,1.323,0,0,1,1.321,1.321v8.359a1.323,1.323,0,0,1-1.321,1.321" transform="translate(-384.113 -329.158)" fill="#fff"/>
  <path id="Path_464" data-name="Path 464" d="M472.07,400.044H455.276a1.478,1.478,0,0,1-1.477-1.477v-8.359a1.478,1.478,0,0,1,1.477-1.477H472.07a1.478,1.478,0,0,1,1.477,1.477v8.359a1.478,1.478,0,0,1-1.477,1.477m-16.794-11a1.167,1.167,0,0,0-1.166,1.166v8.359a1.167,1.167,0,0,0,1.166,1.166H472.07a1.167,1.167,0,0,0,1.166-1.166v-8.359a1.167,1.167,0,0,0-1.166-1.166Z" transform="translate(-383.268 -328.313)" fill="#ccc"/>
  <path id="Path_465" data-name="Path 465" d="M485.313,473.331H474a1.255,1.255,0,0,1,0-2.51h11.313a1.255,1.255,0,1,1,0,2.51" transform="translate(-399.27 -397.644)" fill="#e6e6e6"/>
  <path id="Path_466" data-name="Path 466" d="M502.373,414.412a.565.565,0,0,1-.272-.071.557.557,0,0,1-.293-.495v-4.665a.565.565,0,0,1,.867-.477l4.425,2.332a.565.565,0,0,1,0,.953l-4.425,2.332a.562.562,0,0,1-.3.089" transform="translate(-423.815 -345.108)" fill="#FF9999"/>
  <path id="Path_467" data-name="Path 467" d="M114.454,267.731l-8.6.058s-4.9-1.578-7.116-1.248l-.849,3.335,6.1,2.02,10.613-1.54a1.519,1.519,0,1,0-.151-2.624Z" transform="translate(-82.679 -225.075)" fill="#a0616a"/>
  <path id="Path_468" data-name="Path 468" d="M74.465,249.883l-10.2-2.933-.928-3.459a2.793,2.793,0,0,1,.216-2.941c.871-1.179,1.551-.008,2.787.781,2.166.153,7.572,2,9.767,4.145l-.173,1.317Z" transform="translate(-53.225 -202.739)" fill="#3f3d56"/>
  <path id="Path_469" data-name="Path 469" d="M186.4,209.79a1.351,1.351,0,0,1-.652-.17,1.334,1.334,0,0,1-.7-1.184V197.273a1.351,1.351,0,0,1,2.076-1.14l10.589,5.581a1.351,1.351,0,0,1,0,2.281l-10.589,5.581a1.345,1.345,0,0,1-.723.214" transform="translate(-156.285 -165.471)" fill="#FF9999"/>
  <path id="Path_470" data-name="Path 470" d="M147.976,585.616h2.17l1.032-8.368h-3.2Z" transform="translate(-124.977 -487.529)" fill="#a0616a"/>
  <path id="Path_471" data-name="Path 471" d="M144.417,621.263h.611l2.384-.97,1.278.969h0a2.723,2.723,0,0,1,2.723,2.723v.088h-7Z" transform="translate(-121.971 -523.885)" fill="#2f2e41"/>
  <path id="Path_472" data-name="Path 472" d="M48.578,574.878l2.055.7,3.661-7.595-3.033-1.027Z" transform="translate(-41.028 -478.834)" fill="#a0616a"/>
  <path id="Path_473" data-name="Path 473" d="M41.768,612.489l.578.2,2.569-.154.9,1.328h0a2.723,2.723,0,0,1,1.706,3.452l-.028.084-6.626-2.243Z" transform="translate(-34.515 -517.293)" fill="#2f2e41"/>
  <path id="Path_474" data-name="Path 474" d="M52.333,394.363a23.028,23.028,0,0,0,.38,5.953c.633,2.913,2,12.584,2,12.584a29.8,29.8,0,0,0-4.663,13.522l3.886.311,5.564-13.2.564-13.216,4.053,8.993L63.9,410a.783.783,0,0,0,.334.888s-.379,2.47-.126,2.47a3.027,3.027,0,0,1,.887.38,22.473,22.473,0,0,0-.343,14.078l3.419-.155L70.32,408.3l-1.647-10.513-.127-2.027-.57.023-.29-.847-.533-1.457Z" transform="translate(-42.269 -332.32)" fill="#2f2e41"/>
  <path id="Path_475" data-name="Path 475" d="M51.234,244.955l-.023-.032c-1.206-1.688.608-5.744.881-6.332l-1.152-.374-1.007-8.523-.737-2.945a6.464,6.464,0,0,1,1.835-6.265l1.7-1.606,2.069-3.062h3.337l1.144,2.161a4.864,4.864,0,0,1,3.763,4.966l-.259,5.668,1.015,7.058v1.277l1.763,2.89.384,1.792a8.057,8.057,0,0,1,1.208,1.99c0,.058-.042.113-.133.175-.715.484-4.665,1.471-6.413.771-1.862-.745-9.266.372-9.34.384Z" transform="translate(-41.388 -182.274)" fill="#3f3d56"/>
  <path id="Path_476" data-name="Path 476" d="M43.934,643.253a.184.184,0,0,1-.184.185H.185a.185.185,0,0,1,0-.37H43.749a.184.184,0,0,1,.185.184Z" transform="translate(0 -543.12)" fill="#ccc"/>
  <path id="Path_477" data-name="Path 477" d="M75.81,152.585A4.42,4.42,0,1,1,71.39,157a4.42,4.42,0,0,1,4.42-4.42" transform="translate(-60.294 -128.87)" fill="#a0616a"/>
  <path id="Path_478" data-name="Path 478" d="M133.408,197.351l-6.819,5.234s-4.856,1.7-6.427,3.3l1.336,3.171,6.082-2.069,7.533-7.633a1.519,1.519,0,1,0-1.7-2" transform="translate(-101.486 -165.823)" fill="#a0616a"/>
  <path id="Path_479" data-name="Path 479" d="M90.883,242.006l-9.473,2.774-3.257-1.156a2.793,2.793,0,0,1-1.6-2.475c-.017-1.466,1.232-.942,2.693-1.06,1.819-1.185,7.246-2.973,10.289-2.589l.657,1.154Z" transform="translate(-64.653 -200.542)" fill="#3f3d56"/>
  <path id="Path_480" data-name="Path 480" d="M74.329,140.807c-.317-.009-.208-.606-.463-.8l0,.017a.622.622,0,0,1-1.189.051.945.945,0,0,0-.6-.63,1.551,1.551,0,0,1-.934,1.273,3.867,3.867,0,0,1-1.622.287,7.152,7.152,0,0,0-1.652.125,1.882,1.882,0,0,0-1.288.958,1.106,1.106,0,0,0,.436,1.432.987.987,0,0,0-.853.687,2.728,2.728,0,0,0-.084,1.153,5.916,5.916,0,0,0,1.416,3.953,4.35,4.35,0,0,0,3.021,1.049,4.847,4.847,0,0,0,1.957-.384s1-2.879.751-3.162A1.361,1.361,0,0,1,72.8,145.8a.65.65,0,0,1,.8-.571c-.059-.151-.1-.18-.132-.172a1.3,1.3,0,0,0,.046-.131,1.494,1.494,0,0,1,.933-.99,1.159,1.159,0,0,1,1.267.383,2.1,2.1,0,0,0-1.391-3.51" transform="translate(-55.783 -117.774)" fill="#2f2e41"/>
  <path id="Path_481" data-name="Path 481" d="M215.489,31.433H144.881a.281.281,0,0,1,0-.562h70.608a.281.281,0,0,1,0,.562" transform="translate(-122.131 -26.073)" fill="#cacaca"/>
  <path id="Path_482" data-name="Path 482" d="M168.357,0A1.664,1.664,0,0,1,170,1.682a1.646,1.646,0,1,1-3.291,0A1.664,1.664,0,0,1,168.357,0" transform="translate(-140.8)" fill="#3f3d56"/>
  <path id="Path_483" data-name="Path 483" d="M204.932,0a1.664,1.664,0,0,1,1.646,1.682,1.646,1.646,0,1,1-3.291,0A1.664,1.664,0,0,1,204.932,0" transform="translate(-171.69)" fill="#3f3d56"/>
  <path id="Path_484" data-name="Path 484" d="M241.507,0a1.664,1.664,0,0,1,1.646,1.682,1.646,1.646,0,1,1-3.291,0A1.664,1.664,0,0,1,241.507,0" transform="translate(-202.581)" fill="#3f3d56"/>
  <path id="Path_485" data-name="Path 485" d="M555.074,2.682h-4.038a.306.306,0,0,0,0,.612h4.038a.306.306,0,0,0,0-.612" transform="translate(-465.139 -2.265)" fill="#3f3d56"/>
  <path id="Path_486" data-name="Path 486" d="M555.074,10.067h-4.038a.306.306,0,0,0,0,.611h4.038a.306.306,0,0,0,0-.611" transform="translate(-465.139 -8.502)" fill="#3f3d56"/>
  <path id="Path_487" data-name="Path 487" d="M555.074,17.442h-4.038a.306.306,0,0,0,0,.611h4.038a.306.306,0,0,0,0-.611" transform="translate(-465.139 -14.731)" fill="#3f3d56"/>
  <path id="Path_488" data-name="Path 488" d="M346.778,136.023v4.221c0,.21-.367.385-.861.434l-.012,0c-.082.006-.169.011-.258.011a2.264,2.264,0,0,1-.8-.131l-.07-.03-.067-.036c-.123-.073-.194-.159-.194-.25v-4.221Z" transform="translate(-290.969 -114.881)" fill="#ccc"/>
  <path id="Path_489" data-name="Path 489" d="M569.651,445.413a1.923,1.923,0,1,1-1.923,1.923,1.923,1.923,0,0,1,1.923-1.923" transform="translate(-479.49 -376.185)" fill="#3f3d56"/>
  <path id="Path_490" data-name="Path 490" d="M576.059,454.133h-.012a.252.252,0,0,1-.186-.1l-.321-.413a.252.252,0,0,1,.044-.353h0l.012-.009a.251.251,0,0,1,.353.044h0a.184.184,0,0,0,.258.032.168.168,0,0,0,.02-.018l.652-.689a.252.252,0,0,1,.356-.01l.011.01a.251.251,0,0,1,.01.356h0l-1.013,1.069a.252.252,0,0,1-.183.079" transform="translate(-486.042 -382.214)" fill="#fff"/>
  <path id="Path_491" data-name="Path 491" d="M637.651,215.413a1.923,1.923,0,1,1-1.923,1.923,1.923,1.923,0,0,1,1.923-1.923" transform="translate(-536.921 -181.933)" fill="#3f3d56"/>
  <path id="Path_492" data-name="Path 492" d="M644.059,224.133h-.012a.252.252,0,0,1-.186-.1l-.321-.413a.252.252,0,0,1,.044-.353h0l.012-.009a.252.252,0,0,1,.353.044h0a.184.184,0,0,0,.258.032.177.177,0,0,0,.02-.018l.652-.689a.252.252,0,0,1,.356-.01l.011.01a.251.251,0,0,1,.01.356h0l-1.013,1.069a.252.252,0,0,1-.183.079" transform="translate(-543.473 -187.961)" fill="#fff"/>
</svg>

