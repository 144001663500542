import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-check-icon',
    templateUrl: './check-icon.component.html',
    styleUrls: ['./check-icon.component.scss'],
    standalone: false
})
export class CheckIconComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
