import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ad-publish-sucess',
  templateUrl: './ad-publish-sucess.component.html',
  styleUrls: ['./ad-publish-sucess.component.scss'],
})
export class AdPublishSucessComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
