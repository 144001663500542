<svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39">
  <g id="Group_2429" data-name="Group 2429" transform="translate(-45 -358)">
    <g id="Ellipse_6" data-name="Ellipse 6" transform="translate(45 358)" fill="#f8caca" stroke="#fa7a7a" stroke-width="1" stroke-dasharray="2">
      <circle cx="19.5" cy="19.5" r="19.5" stroke="none"/>
      <circle cx="19.5" cy="19.5" r="19" fill="none"/>
    </g>
    <path id="times" d="M12.763,89.254l5.262-5.262a1.654,1.654,0,0,0,0-2.339l-1.169-1.169a1.654,1.654,0,0,0-2.339,0L9.254,85.746,3.993,80.484a1.654,1.654,0,0,0-2.339,0L.484,81.654a1.654,1.654,0,0,0,0,2.339l5.262,5.262L.484,94.516a1.654,1.654,0,0,0,0,2.339l1.169,1.169a1.654,1.654,0,0,0,2.339,0l5.262-5.262,5.262,5.262a1.654,1.654,0,0,0,2.339,0l1.169-1.169a1.654,1.654,0,0,0,0-2.339Z" transform="translate(55 288)" fill="#fa7a7a"/>
  </g>
</svg>

