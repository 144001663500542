<div class="grey-bg no-border-radius background-fix-dark">
  <div class="store-connect">
    <div class="filter-cover">
      <div class="type-filters" *ngIf="selectedIntegration?.category">
        <ion-button *ngIf="popularTypes?.length" [class.selected]="selectedIntegration?.category === popularFilterKey" (click)="selectIntegration(groupedIntegrationTypes[popularFilterKey])" shape="round" size="small">{{'connections.popular' | translate}}</ion-button>
        <span *ngFor="let integration of groupedIntegrationTypes| keyvalue">
          <ion-button *ngIf="integration.value.category !== popularFilterKey" [class.selected]="selectedIntegration?.category === integration.value.category" (click)="selectIntegration(integration.value)" shape="round" size="small">{{integration.value?.category_name}}</ion-button>
        </span>
      </div>
      <div class="right-overlay"></div>
    </div>
    <!-- All-sources skeleton -->
    <ion-skeleton-text [animated]="true" [class.ion-hide]="!showSkeleton" *ngFor="let item of skeletonArray" class="card-skeleton"></ion-skeleton-text>
    <!-- All-sources data -->
    <div class="all-sources" [class.ion-hide]="showSkeleton">
      <ion-card *ngFor="let provider of selectedIntegration?.types">
        <ion-card-content>
          <div class="connections-main-detail">
            <div class="tech-logo">
              <img [src]="provider?.icon_uri" onload="this.style.visibility='visible'">
            </div>
            <div class="caption-content-section">
              <div class="inner-caption-content">
                <div class="tech-name">{{provider.name}}</div>
                <p>{{provider.description}}</p>
              </div>
              <ion-button class="black-custom-button" shape="round" (click)="storeIntegrationService.selectIntegrationProvider(provider)">{{'buttons.connect' | translate}}</ion-button>
            </div>
          </div>
        </ion-card-content>
      </ion-card>
    </div>
  </div>
  <app-no-data-available
    *ngIf="showNoDataAlert"
    [customTop]="warningDivCustomTop"
    [showInternalServerError]="true"
    [customErrorMessage]="'error_messages.data_missing'">
  </app-no-data-available>
</div>
