// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.store-options-skeleton {
  width: auto;
  height: 55px;
  border-radius: 10px;
  margin-top: 10px;
}

.store-connect ion-item.store-connect-btn {
  --background: var(--home-status-bar);
}

.connected-sources-header .pop-header {
  background-color: var(--ion-color-primary);
  display: flex;
  justify-content: space-between;
}
.connected-sources-header .pop-header h2 {
  font-size: 26px;
  font-weight: 600;
  color: var(--ion-text-color);
  flex: 1;
  margin-top: 10px;
}
.connected-sources-header .pop-header .close {
  width: 20px;
}

.connect-sources-info {
  background-color: var(--ion-color-primary);
  padding: 5px 15px;
}
.connect-sources-info p {
  font-size: 14px;
  font-weight: 400;
  color: var(--ion-text-color);
  margin-top: 0;
}
.connect-sources-info p span {
  font-weight: 600;
}

.wave-header {
  background-image: url('connections-bg.svg');
  background-size: cover;
  width: 100%;
  aspect-ratio: 11;
  background-repeat: no-repeat;
  background-color: var(--page-bg-grey);
  background-position: center -1px;
}`, "",{"version":3,"sources":["webpack://./src/app/components/connections/connections.component.scss"],"names":[],"mappings":"AAAA;EACE,WAAA;EACA,YAAA;EACA,mBAAA;EACA,gBAAA;AACF;;AAII;EACE,oCAAA;AADN;;AAQE;EACE,0CAAA;EACA,aAAA;EACA,8BAAA;AALJ;AAOI;EACE,eAAA;EACA,gBAAA;EACA,4BAAA;EACA,OAAA;EACA,gBAAA;AALN;AAQI;EACE,WAAA;AANN;;AAWA;EACE,0CAAA;EACA,iBAAA;AARF;AAUE;EACE,eAAA;EACA,gBAAA;EACA,4BAAA;EACA,aAAA;AARJ;AAUI;EACE,gBAAA;AARN;;AAaA;EACE,2CAAA;EACA,sBAAA;EACA,WAAA;EACA,gBAAA;EACA,4BAAA;EACA,qCAAA;EACA,gCAAA;AAVF","sourcesContent":[".store-options-skeleton {\n  width: auto;\n  height: 55px;\n  border-radius: 10px;\n  margin-top: 10px;\n}\n\n.store-connect {\n  ion-item {\n    &.store-connect-btn {\n      --background: var(--home-status-bar);\n    }\n  }\n}\n\n.connected-sources-header {\n\n  .pop-header {\n    background-color: var(--ion-color-primary);\n    display: flex;\n    justify-content: space-between;\n\n    h2 {\n      font-size: 26px;\n      font-weight: 600;\n      color: var(--ion-text-color);\n      flex: 1;\n      margin-top: 10px;\n    }\n\n    .close {\n      width: 20px;\n    }\n  }\n}\n\n.connect-sources-info {\n  background-color: var(--ion-color-primary);\n  padding: 5px 15px;\n\n  p {\n    font-size: 14px;\n    font-weight: 400;\n    color: var(--ion-text-color);\n    margin-top: 0;\n\n    span {\n      font-weight: 600;\n    }\n  }\n}\n\n.wave-header {\n  background-image: url(../../../assets/images/connections-bg.svg);\n  background-size: cover;\n  width: 100%;\n  aspect-ratio: 11;\n  background-repeat: no-repeat;\n  background-color: var(--page-bg-grey);\n  background-position: center -1px;\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
