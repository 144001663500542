<!-- Demo mode banner -->
<div class="demo-mode-banner" *ngIf="isDemoLogin">
  <div class="left-content-section">
    <p><markdown [data]="'demo_banner.click_info' | translate"></markdown></p>
  </div>
  <div class="rt-action-btn">
    <ion-button class="custom-button" shape="round" expand="block" (click)="showYourDataModal = true">{{'demo_banner.show_your_data' | translate}}</ion-button>
  </div>
</div>

<ion-modal [isOpen]="showYourDataModal" class="dashboard-modal" (willDismiss)="showYourDataModal = false">
  <ng-template>
    <ion-content>
      <div class="rt-minimise-button" (click)="showYourDataModal = false">
        <ion-icon name="chevron-down-outline"></ion-icon>
        {{'modal.minimize' | translate}}
      </div>
      <div class="main-content">
        <h2><markdown [data]="'modal.show_your_data_modal_description' | translate"></markdown></h2>
        <div class="buttons-area">
          <ion-button class="custom-button" shape="round" expand="block" (click)="createAccount()">{{'modal.create_account' | translate}}</ion-button>
          <ion-button class="custom-button outline-button" fill="outline" shape="round" expand="block" (click)="signin()"> {{'modal.signin' | translate}}</ion-button>
        </div>
        <div class="notes-section">
          <span><markdown [data]="'modal.show_your_data_modal_note' | translate"></markdown></span>
        </div>
      </div>
    </ion-content>
  </ng-template>
</ion-modal>