<svg xmlns="http://www.w3.org/2000/svg" width="23" height="29.696" viewBox="0 0 23 29.696" >
  <g id="delete">

    <path id="Path_348" data-name="Path 348"
      d="M6.893,27.845a2.072,2.072,0,0,0,1.06,1.574,5.548,5.548,0,0,0,2.34.834,7.549,7.549,0,0,0,3.784-.348,5.422,5.422,0,0,0,1.04-.531,1.972,1.972,0,0,0,1-1.529c.3.024.6.038.888.072.741.085,1.487.146,2.221.274a3.987,3.987,0,0,1,3.324,3.133,12.079,12.079,0,0,1,.42,2.806,17.881,17.881,0,0,1,0,1.85,3.083,3.083,0,0,1-2.691,3.11,14.658,14.658,0,0,1-2.079.249c-.727.045-1.46.024-2.189.031h-.188l-.021-.046c.193-.189.389-.376.579-.568a2.1,2.1,0,0,0,.171-2.763,6.261,6.261,0,0,0-.5-.529q-.476-.481-.957-.957a2.1,2.1,0,0,0-3,.007c-.192.191-.376.392-.589.615-.208-.218-.387-.414-.575-.6a2.1,2.1,0,0,0-3.039.005q-.61.612-1.222,1.222a2.1,2.1,0,0,0-.006,3.039,5.266,5.266,0,0,1,.523.577c-.057,0-.114,0-.171,0-.943-.019-1.888-.021-2.829-.064A6.907,6.907,0,0,1,2.068,38.9,2.98,2.98,0,0,1,.075,36.351a12.166,12.166,0,0,1-.013-2.558,11.132,11.132,0,0,1,.407-2.485,4.414,4.414,0,0,1,1.384-2.273,4.119,4.119,0,0,1,2.037-.865c.855-.127,1.718-.2,2.578-.292.139-.015.28-.022.426-.034"
      transform="translate(0 -13.743)" fill="#14181c" />
    <path id="Path_349" data-name="Path 349"
      d="M16.738,0a6.351,6.351,0,0,1,5.893,4.542,6.848,6.848,0,0,1,.079,3.431,11.521,11.521,0,0,1-2.329,4.937,6.028,6.028,0,0,1-2.492,1.925,3.758,3.758,0,0,1-3.584-.476,8.041,8.041,0,0,1-2.41-2.629,11.514,11.514,0,0,1-1.554-4.1,6.3,6.3,0,0,1,1.83-5.859A6.075,6.075,0,0,1,16.133.024c.13-.009.261-.017.391-.022.071,0,.142,0,.214,0"
      transform="translate(-5.047 0)" fill="#14181c" />
    <path id="Path_350" data-name="Path 350"
      d="M18.759,48.827a3.306,3.306,0,0,1-.21.267q-.683.689-1.371,1.373a.675.675,0,0,1-1.023.005q-.62-.613-1.233-1.234a.678.678,0,0,1,0-1.024q.716-.719,1.436-1.434a.83.83,0,0,1,.169-.1c0-.027-.009-.054-.013-.082a3.2,3.2,0,0,1-.249-.2q-.67-.664-1.335-1.334a.681.681,0,0,1-.009-1.037q.608-.613,1.222-1.221a.68.68,0,0,1,1.037,0c.521.519,1.04,1.041,1.568,1.57.045-.039.085-.072.121-.108q.725-.723,1.448-1.447a.687.687,0,0,1,1.063-.005q.606.6,1.208,1.208a.68.68,0,0,1-.005,1.037q-.71.713-1.424,1.422a1.118,1.118,0,0,1-.162.111c-.006.03-.012.059-.017.089a.755.755,0,0,1,.172.1q.716.707,1.424,1.421a.683.683,0,0,1,.007,1.05q-.6.607-1.208,1.209a.681.681,0,0,1-1.048,0q-.7-.7-1.4-1.4a2.412,2.412,0,0,1-.173-.231"
      transform="translate(-7.25 -21.008)" fill="#14181c" />
  </g>
</svg>