<svg id="Group_221" data-name="Group 221" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="120.409" height="101.137" viewBox="0 0 120.409 101.137">
  <g id="Group_220" data-name="Group 220">
    <path id="Path_249" data-name="Path 249" d="M142.349,207.837a6.557,6.557,0,0,1-.692-2.592,17.977,17.977,0,0,1,1.328-7.978,16.089,16.089,0,0,0-.637-12.8l.159-.092a14.135,14.135,0,0,1,1.333,3.777,16.476,16.476,0,0,1-.683,9.183c-2.618,7.043-.672,10.377-.652,10.41Z" transform="translate(-82.193 -107.02)" fill="#f2f2f2"/>
    <path id="Path_250" data-name="Path 250" d="M139.284,177.9a1.2,1.2,0,1,1-1.2,1.2,1.2,1.2,0,0,1,1.2-1.2" transform="translate(-80.154 -103.265)" fill="#f2f2f2"/>
    <path id="Path_251" data-name="Path 251" d="M148.276,188.429a1.2,1.2,0,1,1-1.2,1.2,1.2,1.2,0,0,1,1.2-1.2" transform="translate(-85.374 -109.375)" fill="#f2f2f2"/>
    <path id="Path_252" data-name="Path 252" d="M142.135,195.447a1.2,1.2,0,1,1-1.2,1.2,1.2,1.2,0,0,1,1.2-1.2" transform="translate(-81.809 -113.449)" fill="#f2f2f2"/>
    <path id="Path_253" data-name="Path 253" d="M149.592,201.369a1.2,1.2,0,1,1-1.2,1.2,1.2,1.2,0,0,1,1.2-1.2" transform="translate(-86.138 -116.886)" fill="#f2f2f2"/>
    <path id="Path_254" data-name="Path 254" d="M139.942,210.581a1.2,1.2,0,1,1-1.2,1.2,1.2,1.2,0,0,1,1.2-1.2" transform="translate(-80.536 -122.233)" fill="#f2f2f2"/>
    <path id="Path_255" data-name="Path 255" d="M144.841,233.278s-1.2-2.944,2.392-5.153Z" transform="translate(-83.963 -132.417)" fill="#f2f2f2"/>
    <path id="Path_256" data-name="Path 256" d="M135.017,235.986s-.545-3.131-4.758-3.1Z" transform="translate(-75.61 -135.178)" fill="#f2f2f2"/>
    <path id="Path_257" data-name="Path 257" d="M63.422,34.683a4.1,4.1,0,1,1,4.1-4.1A4.1,4.1,0,0,1,63.422,34.683Zm0-8.06a3.959,3.959,0,1,0,3.959,3.959A3.959,3.959,0,0,0,63.422,26.623Z" transform="translate(-34.433 -15.372)" fill="#3f3d56"/>
    <path id="Path_258" data-name="Path 258" d="M162.5,34.683a4.1,4.1,0,1,1,4.1-4.1,4.1,4.1,0,0,1-4.1,4.1m0-8.06a3.959,3.959,0,1,0,3.959,3.959A3.959,3.959,0,0,0,162.5,26.623Z" transform="translate(-91.945 -15.372)" fill="#3f3d56"/>
    <path id="Path_259" data-name="Path 259" d="M101.642,23.344H30.207a2.868,2.868,0,0,0-2.519,3.113V60.514a2.869,2.869,0,0,0,2.519,3.113h71.434a2.868,2.868,0,0,0,2.52-3.113V26.457a2.869,2.869,0,0,0-2.52-3.113Zm2.224,37.169a2.53,2.53,0,0,1-2.223,2.746H30.207a2.53,2.53,0,0,1-2.223-2.746V26.457a2.53,2.53,0,0,1,2.223-2.747h71.434a2.53,2.53,0,0,1,2.223,2.747Z" transform="translate(-16.072 -13.55)" fill="#3f3d56"/>
    <rect id="Rectangle_60" data-name="Rectangle 60" width="12.692" height="8.885" transform="translate(16.852 23.492)" fill="#f2f2f2"/>
    <rect id="Rectangle_61" data-name="Rectangle 61" width="12.692" height="8.885" transform="translate(34.622 23.492)" fill="#f2f2f2"/>
    <rect id="Rectangle_62" data-name="Rectangle 62" width="12.692" height="8.885" transform="translate(52.391 23.492)" fill="#f2f2f2"/>
    <rect id="Rectangle_63" data-name="Rectangle 63" width="12.692" height="8.885" transform="translate(70.161 23.492)" fill="#f2f2f2"/>
    <rect id="Rectangle_64" data-name="Rectangle 64" width="12.692" height="8.885" transform="translate(16.852 36.501)" fill="#f2f2f2"/>
    <rect id="Rectangle_65" data-name="Rectangle 65" width="12.692" height="8.885" transform="translate(34.622 36.501)" fill="#f2f2f2"/>
    <rect id="Rectangle_66" data-name="Rectangle 66" width="12.692" height="8.885" transform="translate(52.391 36.501)" fill="#f2f2f2"/>
    <rect id="Rectangle_67" data-name="Rectangle 67" width="12.692" height="8.885" transform="translate(70.161 36.501)" fill="#f2f2f2"/>
    <path id="Path_260" data-name="Path 260" d="M88.454,93.422l-3-4.138.816-.591,2.293,3.167,8.776-9.017.722.7Z" transform="translate(-49.605 -48.087)" fill="#FF9999"/>
    <path id="Path_261" data-name="Path 261" d="M131.187,62.412l-3-4.138.816-.591,2.293,3.167,8.776-9.017.722.7Z" transform="translate(-74.409 -30.087)" fill="#FF9999"/>
    <rect id="Rectangle_68" data-name="Rectangle 68" width="0.217" height="15.229" transform="translate(28.826)" fill="#2f2e41"/>
    <rect id="Rectangle_69" data-name="Rectangle 69" width="0.217" height="15.229" transform="translate(70.552)" fill="#2f2e41"/>
    <path id="Path_262" data-name="Path 262" d="M119.947,240.354H.461c-.239.009-.445-.1-.461-.24s.165-.264.4-.274H119.947c.239-.009.445.1.461.24s-.165.264-.4.274h-.057" transform="translate(0 -139.217)" fill="#3f3d56"/>
    <path id="Path_263" data-name="Path 263" d="M46.478,62.412l-3-4.138.816-.591,2.293,3.167,8.776-9.017.722.7Z" transform="translate(-25.239 -30.087)" fill="#FF9999"/>
    <path id="Path_264" data-name="Path 264" d="M235.75,127.523a1.974,1.974,0,0,1,.3-.065l4.1-8.326-1.251-1.875L241.483,115l3.248,3.974-6.615,9.626a1.965,1.965,0,1,1-2.367-1.076Z" transform="translate(-136.035 -66.751)" fill="#ffb8b8"/>
    <path id="Path_265" data-name="Path 265" d="M243.712,110.734a.853.853,0,0,1-.19.15l-4.192,2.43a.842.842,0,0,1-1.151-.306c-.011-.02-.022-.041-.032-.062l-2.039-4.308a.837.837,0,0,1,.287-1.056l2.6-1.768a.841.841,0,0,1,1.07.1l3.632,3.645a.842.842,0,0,1,.016,1.173Z" transform="translate(-137.002 -61.336)" fill="#ccc"/>
    <path id="Path_266" data-name="Path 266" d="M260.644,219.643l-2.214.6-3.376-8.252,3.267-.889Z" transform="translate(-148.048 -122.537)" fill="#ffb8b8"/>
    <path id="Path_267" data-name="Path 267" d="M256.082,230.8l4.268-1.161.731,2.687-6.956,1.893a2.786,2.786,0,0,1,1.957-3.419Z" transform="translate(-147.452 -133.295)" fill="#2f2e41"/>
    <path id="Path_268" data-name="Path 268" d="M218.591,223.24H216.3l-1.092-8.848h3.386Z" transform="translate(-124.918 -124.445)" fill="#ffb8b8"/>
    <path id="Path_269" data-name="Path 269" d="M210.048,233.919h4.424V236.7h-7.209A2.785,2.785,0,0,1,210.048,233.919Z" transform="translate(-120.307 -135.78)" fill="#2f2e41"/>
    <path id="Path_270" data-name="Path 270" d="M222.678,77.108a4.6,4.6,0,1,1-4.6,4.6,4.6,4.6,0,0,1,4.6-4.6" transform="translate(-126.587 -44.758)" fill="#ffb8b8"/>
    <path id="Path_271" data-name="Path 271" d="M216.831,171.518H213.7a.844.844,0,0,1-.842-.79c-.934-14.849-.388-25.5,1.719-33.521a.909.909,0,0,1,.034-.1l.169-.42a.838.838,0,0,1,.782-.529H222a.842.842,0,0,1,.639.294l.876,1.022c.017.02.033.04.049.062,3.948,5.506,7.486,16.27,11.584,30.738a.839.839,0,0,1-.553,1.032l-3.368,1.078a.846.846,0,0,1-1.015-.436l-8.742-18.132a.655.655,0,0,0-1.238.2l-2.569,18.783a.846.846,0,0,1-.834.728" transform="translate(-123.298 -79.032)" fill="#2f2e41"/>
    <path id="Path_272" data-name="Path 272" d="M225.755,115.922h-.026l-7.831-.248a.839.839,0,0,1-.81-.74c-.992-8.035,1.729-10.187,2.946-10.74a.648.648,0,0,0,.376-.514l.105-.842a.838.838,0,0,1,.167-.409,9.62,9.62,0,0,1,5.894-3.514.841.841,0,0,1,.976.679q0,.021.006.042l.158,1.209a.664.664,0,0,0,.133.316c3.978,5.2-.454,12.878-1.382,14.365a.843.843,0,0,1-.712.395" transform="translate(-125.886 -57.409)" fill="#ccc"/>
    <path id="Path_273" data-name="Path 273" d="M222.655,142.381l-5.916,1.14-.225-1.167,4.529-.873-5.264-13.875,1.111-.421Z" transform="translate(-125.25 -73.826)" fill="#FF9999"/>
    <path id="Path_274" data-name="Path 274" d="M231.113,80.863a18.3,18.3,0,0,0-3.65-3.821,10.842,10.842,0,0,0-3.875-2.05,6.01,6.01,0,0,0-4.3.383c-1.3.691-2.643,2.063-2.651,3.534,0,.473.118,1.112.583,1.03l.135.019,3.369-.68,1.435,4.98a.962.962,0,0,0,1.56.766c.052-.021.5-1.025.5-1.025a.187.187,0,0,1,.323-.054l.266.332a.374.374,0,0,0,.436.112l5.495-2.294c.253-.106.536-.243.609-.508a.912.912,0,0,0-.237-.725" transform="translate(-125.751 -43.4)" fill="#2f2e41"/>
    <path id="Path_275" data-name="Path 275" d="M231.881,138.14a2.011,2.011,0,0,0,.491-2.8,1.983,1.983,0,0,0-.228-.271l1.455-17.564-4.331.6-.082,17.064a2.021,2.021,0,0,0,2.694,2.975Z" transform="translate(-132.743 -68.207)" fill="#ffb8b8"/>
    <path id="Path_276" data-name="Path 276" d="M233.468,113.161a.842.842,0,0,1-.24-.035l-4.649-1.367a.842.842,0,0,1-.57-1.045c.007-.023.014-.044.023-.066l1.721-4.445a.837.837,0,0,1,.964-.519l3.071.667a.842.842,0,0,1,.662.846l-.143,5.144A.843.843,0,0,1,233.468,113.161Z" transform="translate(-132.33 -61.334)" fill="#ccc"/>
  </g>
</svg>

