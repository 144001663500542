import { Injectable } from '@angular/core';
import { FirebaseCrashlytics } from '@capacitor-firebase/crashlytics';
import { Capacitor } from '@capacitor/core';
import { Device } from '@capacitor/device';

interface CrashlyticsPageContent {
  page?: string;
  component?: string;
}

@Injectable({
  providedIn: 'root'
})
export class FirebaseCrashlyticsService {

  constructor() { }

  async customizeCrashlytics(content: CrashlyticsPageContent = {}, userId: string = ''): Promise<void> {
    if (await Capacitor.isNativePlatform()) {
      const deviceInfo = await Device.getInfo();
      await FirebaseCrashlytics.setCustomKey({
        key: 'device_model',
        value: deviceInfo.manufacturer + ' ' + deviceInfo.model,
        type: 'string'
      });

      await FirebaseCrashlytics.setCustomKey({
        key: 'device_os_version',
        value: deviceInfo.osVersion,
        type: 'string'
      });

      if (content?.page) {
        await FirebaseCrashlytics.setCustomKey({
          key: 'page',
          value: content?.page,
          type: 'string'
        });
      }

      await FirebaseCrashlytics.setCustomKey({
        key: 'component',
        value: content?.component || '',
        type: 'string'
      });

      if (userId) {
        await FirebaseCrashlytics.setUserId({
          userId
        });
      }
    }
  }

}
