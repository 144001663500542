import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-medium-chart-icon',
    templateUrl: './medium-chart-icon.component.html',
    styleUrls: ['./medium-chart-icon.component.scss'],
    standalone: false
})
export class MediumChartIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
