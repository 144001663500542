// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.demo-mode-banner {
  background-color: var(--ion-color-primary);
  padding: calc(var(--base-padding) * 2);
  display: flex;
  align-items: center;
}
.demo-mode-banner .left-content-section {
  flex: 1;
  padding-right: 5px;
}
.demo-mode-banner .left-content-section p {
  font-size: 12px;
  font-weight: 300;
  color: var(--ion-text-color);
  margin: 0;
}
.demo-mode-banner .left-content-section p span {
  font-weight: 500;
}
.demo-mode-banner .rt-action-btn ion-button.custom-button {
  min-height: 40px;
  --background: var(--content-black);
  --background-hover: var(--content-black);
  --background-activated: var(--content-black);
  --background-focused: var(--content-black);
  font-size: 13px;
  font-weight: 400;
  color: #fff;
}`, "",{"version":3,"sources":["webpack://./src/app/components/demo-banner/demo-banner.component.scss"],"names":[],"mappings":"AAAA;EACE,0CAAA;EACA,sCAAA;EACA,aAAA;EACA,mBAAA;AACF;AAAE;EACE,OAAA;EACA,kBAAA;AAEJ;AAAI;EACE,eAAA;EACA,gBAAA;EACA,4BAAA;EACA,SAAA;AAEN;AADM;EACE,gBAAA;AAGR;AAIM;EACE,gBAAA;EACA,kCAAA;EACA,wCAAA;EACA,4CAAA;EACA,0CAAA;EACA,eAAA;EACA,gBAAA;EACA,WAAA;AAFR","sourcesContent":[".demo-mode-banner {\n  background-color: var(--ion-color-primary);\n  padding: calc(var(--base-padding) * 2);\n  display: flex;\n  align-items: center;\n  .left-content-section {\n    flex: 1;\n    padding-right: 5px;\n\n    p {\n      font-size: 12px;\n      font-weight: 300;\n      color: var(--ion-text-color);\n      margin: 0;\n      span {\n        font-weight: 500;\n      }\n    }\n  }\n\n  .rt-action-btn {\n    ion-button {\n      &.custom-button {\n        min-height: 40px;\n        --background: var(--content-black);\n        --background-hover: var(--content-black);\n        --background-activated: var(--content-black);\n        --background-focused: var(--content-black);\n        font-size: 13px;\n        font-weight: 400;\n        color: #fff;\n      }\n    }\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
