import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';
import { RouteReuseStrategy } from '@angular/router';
import {
  IonicModule,
  IonicRouteStrategy
} from '@ionic/angular';
import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { TranslateModule } from '@ngx-translate/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';
import { Interceptor as HttpInterceptor } from './interceptors/http-interceptor';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';

// Components
import { ComponentsModule } from 'src/app/components/components.module';
import { CreateTeamComponent } from './modals/create-team/create-team.component';
import { InformationPopupComponent } from './modals/information-popup/information-popup.component';
import { CustomizeHighlightsComponent } from './modals/customize-highlights/customize-highlights.component';
import { CustomizeTrendsComponent } from './modals/customize-trends/customize-trends.component';
import { SelectTeamComponent } from './modals/select-team/select-team.component';
import { IntegrationAuthCredentialsComponent } from './modals/integration-auth-credentials/integration-auth-credentials.component';
import { UserVerificationComponent } from './modals/user-verification/user-verification.component';

@NgModule({ declarations: [
        AppComponent,
        CreateTeamComponent,
        InformationPopupComponent,
        CustomizeHighlightsComponent,
        CustomizeTrendsComponent,
        SelectTeamComponent,
        IntegrationAuthCredentialsComponent,
        UserVerificationComponent
    ],
    bootstrap: [AppComponent], imports: [BrowserModule,
        FormsModule,
        IonicModule.forRoot(),
        AppRoutingModule,
        FormsModule,
        ReactiveFormsModule,
        ComponentsModule,
        TranslateModule.forRoot()], providers: [
        ScreenOrientation,
        InAppBrowser,
        { provide: RouteReuseStrategy, useClass: IonicRouteStrategy },
        { provide: HTTP_INTERCEPTORS, useClass: HttpInterceptor, multi: true },
        provideHttpClient(withInterceptorsFromDi())
    ] })
export class AppModule {}
