import { NgModule } from '@angular/core';
import { PreloadAllModules, RouterModule, Routes } from '@angular/router';
import { AuthGuard } from './guards/auth/auth.guard';

const routes: Routes = [
  // Intial route path
  // { path: '', redirectTo: 'onboard', pathMatch: 'full' },
  {
    path: 'onboard',
    loadChildren: () => import('./pages/onboard/onboard.module').then( m => m.OnboardPageModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },
  {
    path: 'register',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },
  {
    path: 'login',
    loadChildren: () => import('./pages/login/login.module').then( m => m.LoginPageModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },
  {
    path: 'email-login',
    loadChildren: () => import('./pages/email-login/email-login.module').then( m => m.EmailLoginPageModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },
  {
    path: 'tabs',
    loadChildren: () => import('./pages/tabs/tabs.module').then( m => m.TabsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'notification',
    loadChildren: () => import('./pages/notification/notification.module').then( m => m.NotificationPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'forgot-password',
    loadChildren: () => import('./pages/forgot-password/forgot-password.module').then( m => m.ForgotPasswordPageModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },
  {
    path: 'messages',
    loadChildren: () => import('./pages/messages/messages.module').then( m => m.MessagesPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'offers',
    loadChildren: () => import('./pages/offers/offers.module').then( m => m.OffersPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'add-ecommerce',
    loadChildren: () => import('./pages/add-ecommerce/add-ecommerce.module').then( m => m.AddEcommercePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'web-shop',
    loadChildren: () => import('./pages/web-shop/web-shop.module').then( m => m.WebShopPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'conversions',
    loadChildren: () => import('./pages/conversions/conversions.module').then( m => m.ConversionsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'webshop-mail-sent',
    loadChildren: () => import('./pages/webshop-mail-sent/webshop-mail-sent.module').then( m => m.WebshopMailSentPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'domains',
    loadChildren: () => import('./pages/domains/domains.module').then( m => m.DomainsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'signup-business',
    loadChildren: () => import('./pages/signup-business/signup-business.module').then( m => m.SignupBusinessPageModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },
  {
    path: 'signup-account',
    loadChildren: () => import('./pages/signup-account/signup-account.module').then( m => m.SignupAccountPageModule),
    canLoad: [AuthGuard],
    canActivate: [AuthGuard]
  },
  {
    path: 'connect-social',
    loadChildren: () => import('./pages/connect-social/connect-social.module').then( m => m.ConnectSocialPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'add-keywords',
    loadChildren: () => import('./pages/add-keywords/add-keywords.module').then( m => m.AddKeywordsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'profile',
    loadChildren: () => import('./pages/profile/profile.module').then( m => m.ProfilePageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'change-password',
    loadChildren: () => import('./pages/change-password/change-password.module').then( m => m.ChangePasswordPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'settings',
    loadChildren: () => import('./pages/settings/settings.module').then( m => m.SettingsPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'customer-order',
    loadChildren: () => import('./pages/customer-order/customer-order.module').then( m => m.CustomerOrderPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'ad-wizard',
    loadChildren: () => import('./pages/ad-wizard/ad-wizard.module').then( m => m.AdWizardPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'ad-publish-status',
    loadChildren: () => import('./pages/ad-publish-status/ad-publish-status.module').then( m => m.AdPublishStatusPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'book-meeting',
    loadChildren: () => import('./pages/web-book-meeting/web-book-meeting.module').then( m => m.WebBookMeetingPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'store',
    loadChildren: () => import('./pages/store/store.module').then(m => m.StorePageModule),
    canActivate: [AuthGuard]
  },
  {
    path: 'social-login-success',
    loadChildren: () => import('./pages/social-login-success/social-login-success.module').then( m => m.SocialLoginSuccessPageModule)
  },
  {
    path: 'campaign-template-list',
    loadChildren: () => import('./pages/campaign-template-list/campaign-template-list.module').then( m => m.CampaignTemplateListPageModule),
    canLoad: [AuthGuard]
  },
  {
    path: 'campaign-ads-list',
    loadChildren: () => import('./pages/campaign-ads-list/campaign-ads-list.module').then( m => m.CampaignAdsListPageModule),
    canLoad: [AuthGuard]
  }
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, { preloadingStrategy: PreloadAllModules })
  ],
  exports: [RouterModule]
})
export class AppRoutingModule {}
