<svg width="59" height="6" viewBox="0 0 59 6" fill="none" xmlns="http://www.w3.org/2000/svg">
  <path d="M51.7048 0.438623H53.2298L55.263 2.50334L57.3007 0.429626H58.8436L55.8747 3.45697V5.39574L54.6557 5.40023L54.6512 5.39574C54.6557 5.40023 54.6557 3.47047 54.6557 3.47047" fill="black"/>
  <path d="M45.3582 0.425323V5.41392H46.5682V3.35371H49.2852L49.4741 2.40007C49.4741 2.39557 49.4741 2.39557 49.4741 2.39108L46.5772 2.39557V1.37446L50.6616 1.36996L50.8461 0.429822L45.3582 0.425323Z" fill="black"/>
  <path d="M43.8015 0.411804H42.5959V5.37341H43.8015V0.411804Z" fill="black"/>
  <path d="M41.2465 0.438818V0.425323H35.2683C35.2683 0.425323 35.2683 1.38346 35.2683 1.37446V1.38346H37.6479V5.41842H38.8624V1.37446H41.0666L41.2465 0.438818Z" fill="black"/>
  <path d="M27.9856 0.438623H29.1911V2.74175L32.5064 0.429626L34.1707 0.434125L31.3953 2.38188L34.4091 5.40473H32.8123L30.4731 3.02514L29.1911 3.9158V5.40473H27.9856V0.438623Z" fill="black"/>
  <path d="M26.6275 4.46029V4.45129H22.3676V3.34921L25.071 3.34021L25.2555 2.39557H22.3631V1.37446L26.4385 1.37896L26.623 0.429822V0.425323H21.144V5.40943H26.4385L26.6275 4.46029Z" fill="black"/>
  <path d="M14.3062 0.420837V5.40944H15.5162V3.34922H18.2332L18.4221 2.39559V2.39109L15.5207 2.39559V1.36998L19.6051 1.36548L19.7896 0.425336L14.3062 0.420837Z" fill="black"/>
  <path d="M7.4873 0.420837V5.40944H8.69734V3.34922H11.4143L11.5987 2.40008C11.5987 2.39559 11.5987 2.39109 11.5987 2.39109L8.70184 2.39559V1.36998L12.7863 1.36548L12.9707 0.420837H7.4873Z" fill="black"/>
  <path d="M6.12833 4.45157H1.86845V3.34499L4.57192 3.33599L4.75635 2.40035C4.75635 2.39585 4.75635 2.39585 4.75635 2.39135H1.86395V1.37474L5.9394 1.38373L6.12833 0.425598H0.649414V5.4142H5.9439L6.12833 4.45157Z" fill="black"/>
</svg>
  
