<svg id="Group_203" data-name="Group 203" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="109.968" height="100.318" viewBox="0 0 109.968 100.318">
  <g id="Group_202" data-name="Group 202">
    <path id="Path_47" data-name="Path 47" d="M234.263,175.076c5-3.353,8.6-9.31,7.769-15.276a27.753,27.753,0,0,1-17.016,8.57c-2.51.284-5.331.338-7.093,2.148a6.2,6.2,0,0,0-1.551,4.33,18.424,18.424,0,0,0,.788,4.635l-.087.382c6.015-.3,12.187-1.436,17.191-4.789" transform="translate(-132.204 -97.639)" fill="#f0f0f0"/>
    <path id="Path_48" data-name="Path 48" d="M243.213,159.659a23.72,23.72,0,0,1-9.269,11.272,10.215,10.215,0,0,1-2.909,1.335,5.859,5.859,0,0,1-3.082-.079,12.142,12.142,0,0,0-2.858-.668,3.587,3.587,0,0,0-2.694,1.161,9.319,9.319,0,0,0-1.822,3.23,9.144,9.144,0,0,1-2.026,3.589c-.125.116.063.3.188.187,1.8-1.671,1.964-4.318,3.37-6.248a3.634,3.634,0,0,1,2.736-1.653,9.417,9.417,0,0,1,2.914.622,6.25,6.25,0,0,0,3.011.189,9.437,9.437,0,0,0,2.949-1.218,23.013,23.013,0,0,0,5.489-4.647,24.139,24.139,0,0,0,4.259-7.006C243.533,159.567,243.275,159.5,243.213,159.659Z" transform="translate(-133.512 -97.497)" fill="#fff"/>
    <path id="Path_49" data-name="Path 49" d="M261.738,186.39a3.558,3.558,0,0,0,4.548.742c.146-.089.015-.32-.131-.231a3.3,3.3,0,0,1-4.229-.7C261.816,186.072,261.629,186.26,261.738,186.39Z" transform="translate(-159.903 -113.743)" fill="#fff"/>
    <path id="Path_50" data-name="Path 50" d="M243.548,185.067a6.86,6.86,0,0,1,2.15-4.513c.125-.117-.063-.3-.188-.187a7.133,7.133,0,0,0-2.227,4.7c-.012.171.254.17.265,0" transform="translate(-148.647 -110.183)" fill="#fff"/>
    <path id="Path_51" data-name="Path 51" d="M273.871,169.428a2.014,2.014,0,0,1-.772-1.749c.014-.17-.252-.169-.266,0a2.259,2.259,0,0,0,.85,1.936.137.137,0,0,0,.188,0,.133.133,0,0,0,0-.188Z" transform="translate(-166.699 -102.375)" fill="#fff"/>
    <path id="Path_52" data-name="Path 52" d="M218.121,132.255c-.012.106-.023.212-.037.32a26.553,26.553,0,0,1-.839,4.221c-.029.107-.06.215-.091.322a27.958,27.958,0,0,1-4.693,9.165,27.153,27.153,0,0,1-2.768,3.06,17.673,17.673,0,0,0-3.821,4.261,4.554,4.554,0,0,0-.237.526l4.417,8.77c.02.015.038.031.058.046l.16.358.166-.139.095-.081c.021-.018.042-.036.062-.051l.019-.017c.02-.015.037-.032.055-.046q.471-.4.937-.813s0,0,0,0a39.934,39.934,0,0,0,6.367-6.97c.054-.077.11-.156.161-.237a25.867,25.867,0,0,0,2.106-3.667,22.827,22.827,0,0,0,.873-2.148,19.05,19.05,0,0,0,1.044-5.864,15.128,15.128,0,0,0-3.83-10.794c-.069-.073-.138-.144-.209-.216" transform="translate(-125.643 -80.809)" fill="#f0f0f0"/>
    <path id="Path_53" data-name="Path 53" d="M223.621,132.161a23.719,23.719,0,0,1-.615,14.58,10.216,10.216,0,0,1-1.519,2.817,5.86,5.86,0,0,1-2.508,1.793,12.1,12.1,0,0,0-2.684,1.188,3.587,3.587,0,0,0-1.452,2.549,9.323,9.323,0,0,0,.49,3.676,9.155,9.155,0,0,1,.543,4.086c-.03.168.233.2.263.037.429-2.417-1.031-4.631-1.071-7.018a3.632,3.632,0,0,1,1.189-2.967,9.4,9.4,0,0,1,2.7-1.258,6.251,6.251,0,0,0,2.518-1.661,9.447,9.447,0,0,0,1.621-2.748,23.02,23.02,0,0,0,1.585-7.015,24.138,24.138,0,0,0-.817-8.158C223.821,131.9,223.576,132,223.621,132.161Z" transform="translate(-131.245 -80.638)" fill="#fff"/>
    <path id="Path_54" data-name="Path 54" d="M237.386,162.487a3.558,3.558,0,0,0,4.078-2.146c.063-.159-.18-.264-.244-.105a3.3,3.3,0,0,1-3.8,1.988c-.166-.038-.2.225-.037.263" transform="translate(-144.978 -97.857)" fill="#fff"/>
    <path id="Path_55" data-name="Path 55" d="M224.641,173.551a6.859,6.859,0,0,1-1-4.9c.029-.168-.234-.2-.263-.037a7.133,7.133,0,0,0,1.051,5.094c.093.144.3-.017.212-.16" transform="translate(-136.422 -102.959)" fill="#fff"/>
    <path id="Path_56" data-name="Path 56" d="M236.862,144.851a2.014,2.014,0,0,1-1.669-.931c-.092-.144-.3.016-.212.16a2.257,2.257,0,0,0,1.844,1.034.137.137,0,0,0,.15-.113.134.134,0,0,0-.113-.15" transform="translate(-143.561 -87.901)" fill="#fff"/>
    <path id="Path_57" data-name="Path 57" d="M.065,213.723c.369-3.875,2.653-7.747,6.3-9.109a17.932,17.932,0,0,0,.354,12.307c.61,1.514,1.446,3.135.958,4.692a4.01,4.01,0,0,1-1.985,2.212,11.9,11.9,0,0,1-2.877.976l-.191.167C.945,221.456-.3,217.6.065,213.723" transform="translate(0 -125.02)" fill="#f0f0f0"/>
    <path id="Path_58" data-name="Path 58" d="M10.375,204.558a15.326,15.326,0,0,0-3.561,8.732,6.594,6.594,0,0,0,.134,2.064,3.788,3.788,0,0,0,.992,1.728A7.825,7.825,0,0,1,9.2,218.5a2.318,2.318,0,0,1,.168,1.889,6.02,6.02,0,0,1-1.277,2.028,5.917,5.917,0,0,0-1.418,2.255c-.028.107-.192.058-.165-.049.4-1.536,1.852-2.443,2.517-3.835a2.347,2.347,0,0,0,.1-2.064,6.084,6.084,0,0,0-1.249-1.466,4.041,4.041,0,0,1-1.032-1.654,6.1,6.1,0,0,1-.213-2.051,14.864,14.864,0,0,1,.956-4.548,15.6,15.6,0,0,1,2.675-4.574C10.329,204.349,10.446,204.475,10.375,204.558Z" transform="translate(-3.973 -124.893)" fill="#fff"/>
    <path id="Path_59" data-name="Path 59" d="M4.485,220.745a2.3,2.3,0,0,1-1.819-2.358.086.086,0,0,1,.172,0,2.129,2.129,0,0,0,1.7,2.19C4.642,220.6,4.592,220.768,4.485,220.745Z" transform="translate(-1.627 -133.387)" fill="#fff"/>
    <path id="Path_60" data-name="Path 60" d="M9.485,232.288a4.433,4.433,0,0,0,1.905-2.609c.028-.107.193-.058.164.049a4.612,4.612,0,0,1-1.988,2.71C9.473,232.5,9.392,232.347,9.485,232.288Z" transform="translate(-5.77 -140.299)" fill="#fff"/>
    <path id="Path_61" data-name="Path 61" d="M11.136,212.182a1.3,1.3,0,0,0,1.231-.1c.093-.06.174.091.082.151a1.46,1.46,0,0,1-1.362.112.089.089,0,0,1-.058-.107A.086.086,0,0,1,11.136,212.182Z" transform="translate(-6.737 -129.576)" fill="#fff"/>
    <path id="Path_62" data-name="Path 62" d="M21.044,220.981c-.057.039-.114.079-.17.119-.761.532-.739,1.126-1.4,1.773-.052.049-.1.1-.155.151a18.072,18.072,0,0,0-3.769,5.483,17.526,17.526,0,0,0-.89,2.514,11.436,11.436,0,0,1-1.249,3.482,3.081,3.081,0,0,1-.226.3l-6.343.182-.044-.019-.253.019c.009-.046.019-.092.028-.137.005-.026.012-.052.017-.079s.007-.035.01-.051a.12.12,0,0,1,0-.016c0-.016.006-.031.009-.045q.085-.392.175-.783v0a25.832,25.832,0,0,1,2.007-5.761c.028-.054.055-.11.085-.165a16.779,16.779,0,0,1,1.438-2.324,14.8,14.8,0,0,1,.953-1.155,12.312,12.312,0,0,1,3.013-2.4c2.241-1.267,4.124-1.8,6.576-1.138.063.017.124.034.187.053" transform="translate(-3.997 -134.813)" fill="#f0f0f0"/>
    <path id="Path_63" data-name="Path 63" d="M21.072,221.449a13.421,13.421,0,0,0-7.363,4.828,6.606,6.606,0,0,0-1.135,1.729,3.785,3.785,0,0,0-.249,1.977,7.846,7.846,0,0,1,.149,1.891,2.318,2.318,0,0,1-1,1.608,6.026,6.026,0,0,1-2.241.851,5.913,5.913,0,0,0-2.49.946c-.087.069-.188-.069-.1-.138,1.242-.986,2.949-.836,4.319-1.547a2.347,2.347,0,0,0,1.322-1.587,6.082,6.082,0,0,0-.115-1.922,4.042,4.042,0,0,1,.171-1.942,6.113,6.113,0,0,1,1.065-1.766,14.873,14.873,0,0,1,3.5-3.056,11.949,11.949,0,0,1,4.152-2.041C21.162,221.254,21.179,221.424,21.072,221.449Z" transform="translate(-4.036 -135.201)" fill="#fff"/>
    <path id="Path_64" data-name="Path 64" d="M25.622,227.3a2.3,2.3,0,0,1-.033-2.978c.071-.084.206.021.135.106a2.13,2.13,0,0,0,.036,2.77c.073.083-.066.184-.138.1" transform="translate(-15.311 -137.043)" fill="#fff"/>
    <path id="Path_65" data-name="Path 65" d="M20.949,241.357a4.431,4.431,0,0,0,3.092-.936c.087-.068.188.07.1.138a4.609,4.609,0,0,1-3.219.967C20.813,241.518,20.84,241.348,20.949,241.357Z" transform="translate(-12.741 -146.888)" fill="#fff"/>
    <path id="Path_66" data-name="Path 66" d="M36.785,224.6a1.3,1.3,0,0,0,1.042.663c.11.007.084.177-.026.17a1.459,1.459,0,0,1-1.155-.731.089.089,0,0,1,.018-.12.087.087,0,0,1,.12.018" transform="translate(-22.382 -137.211)" fill="#fff"/>
    <path id="Path_67" data-name="Path 67" d="M140.47.948h-4.339a3.221,3.221,0,0,1-2.983,4.438H114.106A3.221,3.221,0,0,1,111.123.948H107.07a6.781,6.781,0,0,0-6.781,6.781V93.675a6.781,6.781,0,0,0,6.781,6.781h33.4a6.781,6.781,0,0,0,6.781-6.781V7.729A6.781,6.781,0,0,0,140.47.948" transform="translate(-61.277 -0.579)" fill="#fff"/>
    <path id="Path_68" data-name="Path 68" d="M139.891,100.246h-33.4a7.158,7.158,0,0,1-7.15-7.15V7.15A7.158,7.158,0,0,1,106.491,0h4.6l-.207.508a2.853,2.853,0,0,0,2.641,3.93h19.043a2.853,2.853,0,0,0,2.641-3.93L135,0h4.887a7.158,7.158,0,0,1,7.15,7.15V93.1a7.158,7.158,0,0,1-7.15,7.15M106.491.738a6.42,6.42,0,0,0-6.413,6.413V93.1a6.42,6.42,0,0,0,6.413,6.413h33.4A6.42,6.42,0,0,0,146.3,93.1V7.15A6.42,6.42,0,0,0,139.891.738h-3.833a3.591,3.591,0,0,1-3.489,4.438H113.526A3.592,3.592,0,0,1,110.038.738Z" transform="translate(-60.698)" fill="#e4e4e4"/>
    <path id="Path_69" data-name="Path 69" d="M187.737,33.562H177.665a.427.427,0,0,1,0-.854h10.072a.427.427,0,1,1,0,.854Z" transform="translate(-108.293 -19.985)" fill="#FF9999"/>
    <path id="Path_70" data-name="Path 70" d="M187.736,38.489H177.664a.427.427,0,1,1,0-.854h10.073a.427.427,0,1,1,0,.854Z" transform="translate(-108.292 -22.995)" fill="#FF9999"/>
    <path id="Path_71" data-name="Path 71" d="M187.736,43.417H177.664a.427.427,0,1,1,0-.854h10.073a.427.427,0,1,1,0,.854Z" transform="translate(-108.292 -26.006)" fill="#FF9999"/>
    <path id="Path_72" data-name="Path 72" d="M73.271,242.685h1.687l.8-6.509h-2.49Z" transform="translate(-44.769 -144.304)" fill="#ffb6b6"/>
    <path id="Path_73" data-name="Path 73" d="M72.164,251.493h3.324a2.118,2.118,0,0,1,2.118,2.118v.069H72.164Z" transform="translate(-44.093 -153.664)" fill="#2f2e41"/>
    <path id="Path_74" data-name="Path 74" d="M56.309,239.226l1.592.56,2.919-5.873-2.35-.827Z" transform="translate(-34.405 -142.417)" fill="#ffb6b6"/>
    <path id="Path_75" data-name="Path 75" d="M54.595,247.168l3.135,1.1h0a2.118,2.118,0,0,1,1.295,2.7l-.023.065-5.133-1.806Z" transform="translate(-32.914 -151.021)" fill="#2f2e41"/>
    <path id="Path_76" data-name="Path 76" d="M52.125,155.645,51.1,156.758l4.13,17.191,4.243,16.765h3.392l-1.951-16V158.262Z" transform="translate(-31.224 -95.1)" fill="#2f2e41"/>
    <path id="Path_77" data-name="Path 77" d="M66.452,157.234,66.9,158.9l-2.39,14.848-3.393,15.733H57.274l2.885-19.291,3.525-14.2Z" transform="translate(-34.995 -95.31)" fill="#2f2e41"/>
    <path id="Path_78" data-name="Path 78" d="M64.969,90.653l-1.494-2.681-3.543-.58a8.953,8.953,0,0,0-6.621,7.785L51.39,114.852s9.83,4.524,11.914,1.977L66.2,94.5a4.415,4.415,0,0,0-1.234-3.843" transform="translate(-31.4 -53.397)" fill="#3f3d56"/>
    <path id="Path_79" data-name="Path 79" d="M73.92,65.827a3.76,3.76,0,1,1-3.76,3.76,3.76,3.76,0,0,1,3.76-3.76" transform="translate(-42.868 -40.221)" fill="#ffb6b6"/>
    <path id="Path_80" data-name="Path 80" d="M77.108,65.037a.931.931,0,0,1-.551-.424.9.9,0,0,1,0-.918,1.361,1.361,0,0,0-1.128-.058,1.285,1.285,0,0,0,.435-1.085l-1.354.458a1,1,0,0,0,.342-1.1,1.65,1.65,0,0,1-1.762.441c-.625-.158-1.2-.487-1.84-.587a2.611,2.611,0,0,0-2.982,2.178c-.009.058-.016.115-.021.173a.977.977,0,0,0-.764.559,2.1,2.1,0,0,0-.18.964,5.165,5.165,0,0,0,2.07,4.061l.208-.072a1.664,1.664,0,0,1,1.492-1.748c.552-.034,1.2.186,1.6-.2.36-.355.231-.99.525-1.4a1.18,1.18,0,0,1,1.2-.356,11.75,11.75,0,0,1,1.242.438,1.458,1.458,0,0,0,1.269-.081,1.286,1.286,0,0,0,.481-.992,1.836,1.836,0,0,0,0-.231,1.009,1.009,0,0,1-.267-.022" transform="translate(-41.118 -37.721)" fill="#2f2e41"/>
    <rect id="Rectangle_51" data-name="Rectangle 51" width="38.716" height="11.211" transform="translate(10.891 62.422)" fill="#fff"/>
    <path id="Path_81" data-name="Path 81" d="M66.514,171.48H27.094V159.565h39.42Zm-38.716-.7H65.809V160.269H27.8Z" transform="translate(-16.555 -97.495)" fill="#e4e4e4"/>
    <path id="Path_82" data-name="Path 82" d="M70.217,168.8H46.543a.5.5,0,1,1,0-1H70.217a.5.5,0,1,1,0,1Z" transform="translate(-28.131 -102.526)" fill="#FF9999"/>
    <path id="Path_83" data-name="Path 83" d="M70.22,174.593H46.545a.5.5,0,0,1,0-1H70.22a.5.5,0,0,1,0,1" transform="translate(-28.133 -106.064)" fill="#FF9999"/>
    <path id="Path_84" data-name="Path 84" d="M60.634,180.383H46.543a.5.5,0,1,1,0-1H60.634a.5.5,0,0,1,0,1Z" transform="translate(-28.131 -109.602)" fill="#FF9999"/>
    <path id="Path_85" data-name="Path 85" d="M84.951,153.425a1.384,1.384,0,0,1-.1-1.955,1.365,1.365,0,0,1,.179-.166l-1.585-4.657,2.474.645,1.125,4.323a1.392,1.392,0,0,1-2.089,1.81" transform="translate(-50.983 -89.602)" fill="#ffb6b6"/>
    <path id="Path_86" data-name="Path 86" d="M80.127,119.541l-2.564-9.48-.225-11.55a2.2,2.2,0,1,1,4.39.057l-.413,9.088L83.349,118.5Z" transform="translate(-47.254 -58.822)" fill="#3f3d56"/>
    <path id="Path_87" data-name="Path 87" d="M61.137,154.132a1.384,1.384,0,0,1-.1-1.955,1.366,1.366,0,0,1,.179-.166l-1.585-4.657L62.1,148l1.125,4.323a1.392,1.392,0,0,1-2.089,1.81" transform="translate(-36.432 -90.034)" fill="#ffb6b6"/>
    <path id="Path_88" data-name="Path 88" d="M57.97,118.647l-1.1-9.747,1.533-11.463a2.2,2.2,0,0,1,4.354.582c-.006.047-.014.094-.023.141l-1.788,8.92.364,11.032Z" transform="translate(-34.75 -58.371)" fill="#3f3d56"/>
    <rect id="Rectangle_52" data-name="Rectangle 52" width="59.268" height="13.64" transform="translate(47.679 80.686)" fill="#fff"/>
    <path id="Path_89" data-name="Path 89" d="M143.654,219.016a.577.577,0,1,0,0,1.155h42.162a.577.577,0,0,0,0-1.155Z" transform="translate(-87.42 -133.82)" fill="#e6e6e6"/>
    <path id="Path_90" data-name="Path 90" d="M143.651,227.923a.577.577,0,1,0,0,1.155H170.3a.577.577,0,0,0,0-1.155H143.651Z" transform="translate(-87.418 -139.262)" fill="#e6e6e6"/>
    <path id="Path_91" data-name="Path 91" d="M181.839,221.062H122.571v-13.64h59.268Zm-58.293-.974h57.319V208.4H123.545Z" transform="translate(-74.892 -126.736)" fill="#e5e5e5"/>
    <path id="Path_92" data-name="Path 92" d="M30.961,133.864a2.475,2.475,0,1,1,2.475-2.475,2.475,2.475,0,0,1-2.475,2.475" transform="translate(-17.405 -78.767)" fill="#f0f0f0"/>
    <path id="Path_93" data-name="Path 93" d="M59.584,42.9a2.731,2.731,0,1,1,2.731-2.731A2.731,2.731,0,0,1,59.584,42.9Z" transform="translate(-34.737 -22.877)" fill="#f0f0f0"/>
    <path id="Path_94" data-name="Path 94" d="M241.29,94.289a2.349,2.349,0,1,1,2.349-2.349,2.349,2.349,0,0,1-2.349,2.349" transform="translate(-145.994 -54.741)" fill="#f0f0f0"/>
    <rect id="Rectangle_53" data-name="Rectangle 53" width="36.004" height="27.188" transform="translate(44.42 24.576)" fill="#fff"/>
    <path id="Path_95" data-name="Path 95" d="M149.928,90.1H112.981V61.965h36.947Zm-36-.943h35.061V62.908H113.924Z" transform="translate(-69.032 -37.861)" fill="#e4e4e4"/>
    <path id="Path_96" data-name="Path 96" d="M150.75,64.6H115.768L150.75,90.973Z" transform="translate(-70.735 -39.474)" fill="#f0f0f0"/>
    <path id="Path_97" data-name="Path 97" d="M249.424,240.494a5.3,5.3,0,1,1,5.3-5.3,5.3,5.3,0,0,1-5.3,5.3" transform="translate(-149.162 -140.468)" fill="#FF9999"/>
    <path id="Path_98" data-name="Path 98" d="M255.734,238.895h-1.568v-1.568a.523.523,0,0,0-1.045,0v1.568h-1.568a.523.523,0,0,0,0,1.045h1.568v1.568a.523.523,0,1,0,1.045,0V239.94h1.568a.523.523,0,0,0,0-1.045Z" transform="translate(-153.381 -144.69)" fill="#fff"/>
    <path id="Path_99" data-name="Path 99" d="M106.822,257.2H.372a.22.22,0,0,1,0-.439h106.45a.22.22,0,0,1,0,.439" transform="translate(-0.094 -156.882)" fill="#cacaca"/>
  </g>
</svg>

