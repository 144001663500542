/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit } from '@angular/core';
import { AdwizardPlatforms } from 'src/app/core/enum';
import { CampaignTurnkeyAds } from 'src/app/models/apiResponse';

@Component({
  selector: 'app-ad-previews',
  templateUrl: './ad-previews.component.html',
  styleUrls: ['./ad-previews.component.scss'],
  standalone: false
})
export class AdPreviewsComponent  implements OnInit {
  @Input() previewData: CampaignTurnkeyAds;
  @Input() platform: string;

  public adwizardPlatforms = AdwizardPlatforms;

  constructor() { }

  ngOnInit() {}

  canRenderData(preview: any, fieldPath: string): boolean {
    return this.getFieldValue(preview, fieldPath) != null && this.getFieldValue(preview, fieldPath).length > 0;
  }

  getFieldValue(preview: any, fieldPath: string): any {
    return fieldPath.split('.').reduce((prev, curr) => prev ? prev[curr] : null, preview);
  }

}
