<!-- <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="16.79" height="16.824" viewBox="0 0 16.79 16.824">
    <defs>
      <clipPath id="clip-path">
        <rect id="Rectangle_15" data-name="Rectangle 15" width="16.79" height="16.824" transform="translate(0 0)" fill="#d81e1e"/>
      </clipPath>
    </defs>
    <g id="Group_45" data-name="Group 45" transform="translate(-68 -14.693)">
      <g id="Home" data-name="Group 44" transform="translate(68 14.694)" clip-path="url(#clip-path)">
        <path id="Path_3" data-name="Path 3" d="M19.168,4.436c-.045.23-.077.463-.137.688a3.914,3.914,0,0,1-1.037,1.8q-1.68,1.685-3.362,3.369a4.021,4.021,0,0,1-6.247-.7.476.476,0,0,1-.031-.054,11.417,11.417,0,0,1,.826-.861.9.9,0,0,1,1.213.064,2.016,2.016,0,0,0,1.413.593,1.843,1.843,0,0,0,1.309-.516c.43-.413.847-.839,1.268-1.261.685-.685,1.373-1.366,2.052-2.057a1.95,1.95,0,0,0-.2-2.987,1.913,1.913,0,0,0-2.432.128c-.681.648-1.338,1.323-2,1.992a.236.236,0,0,1-.3.067,4.792,4.792,0,0,0-2.236-.3c-.054.005-.109.007-.163.009-.009,0-.017-.008-.039-.019.045-.049.087-.1.132-.142.985-.987,1.977-1.969,2.955-2.963A4.044,4.044,0,0,1,14.663.024.387.387,0,0,0,14.74,0h.722a.727.727,0,0,0,.107.03A4.162,4.162,0,0,1,19,2.949c.074.26.112.532.167.8v.69" transform="translate(-2.378 0)" fill="#14181c"/>
        <path id="Path_4" data-name="Path 4" d="M3.705,18.953c-.16-.031-.32-.059-.478-.094A4.119,4.119,0,0,1,.006,14.653a3.869,3.869,0,0,1,1.145-2.6c1.132-1.142,2.261-2.289,3.41-3.413a3.97,3.97,0,0,1,5.735.124c.172.182.317.39.477.584.061.074.054.124-.016.192-.217.208-.425.426-.64.636a.944.944,0,0,1-1.355.026,2.011,2.011,0,0,0-1.287-.579,1.945,1.945,0,0,0-1.532.612q-1.145,1.152-2.295,2.3c-.313.313-.631.622-.939.941a1.88,1.88,0,0,0-.493,1.936,1.85,1.85,0,0,0,1.432,1.346,1.8,1.8,0,0,0,1.731-.456C6.053,15.663,6.7,15,7.356,14.336a.255.255,0,0,1,.324-.074,4.777,4.777,0,0,0,2.2.3c.059-.006.119-.009.18-.011.009,0,.016.009.037.021-.044.049-.086.1-.131.141C8.98,15.7,7.986,16.687,7,17.684a4.02,4.02,0,0,1-2.5,1.243.369.369,0,0,0-.077.025Z" transform="translate(0 -2.129)" fill="#14181c"/>
      </g>
    </g>
  </svg> -->

  <svg width="13" height="20" viewBox="0 0 13 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="Home">
      <path d="M3.86478 14.4882C2.95395 12.2793 2.53174 11.663 1.52453 9.50342C-0.69279 4.39358 2.89118 1.30858 6.53792 1.30841C10.1847 1.30824 13.4796 4.20076 11.5514 9.55146C10.7112 11.6512 10.064 12.9558 9.33733 14.4957C9.30428 14.5657 9.23287 14.6106 9.15541 14.6106C7.1944 14.6107 6.44099 14.6134 4.05175 14.6135C3.97056 14.6135 3.89573 14.5633 3.86478 14.4882Z" stroke="black" stroke-width="1.5"/>
      <path d="M8.23145 17.0265H4.73096" stroke="black" stroke-width="1.5" stroke-linecap="round"/>
      <path d="M7.63818 19.241H5.32422" stroke="black" stroke-width="1.5" stroke-linecap="round"/>
    </g>
  </svg>
    
  
