import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-language-icon',
    templateUrl: './language-icon.component.html',
    styleUrls: ['./language-icon.component.scss'],
    standalone: false
})
export class LanguageIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
