<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="110" height="97.369" viewBox="0 0 110 97.369">
  <g id="Group_313" data-name="Group 313">
    <path id="Path_376" data-name="Path 376" d="M112.768,81.242H18.458a4.553,4.553,0,0,1-4.548-4.547V40.907a4.552,4.552,0,0,1,4.548-4.547h94.31a4.552,4.552,0,0,1,4.547,4.547V76.694a4.552,4.552,0,0,1-4.547,4.547" transform="translate(-9.059 -23.68)" fill="#fff"/>
    <path id="Path_377" data-name="Path 377" d="M112.769,81.242H18.458a4.552,4.552,0,0,1-4.547-4.547V40.907a4.552,4.552,0,0,1,4.547-4.547h94.31a4.553,4.553,0,0,1,4.548,4.547V76.694a4.553,4.553,0,0,1-4.548,4.547M18.458,36.755a4.152,4.152,0,0,0-4.152,4.152V76.694a4.152,4.152,0,0,0,4.152,4.152h94.31a4.152,4.152,0,0,0,4.152-4.152V63.2A26.445,26.445,0,0,0,90.476,36.755Z" transform="translate(-9.06 -23.68)" fill="#e6e6e6"/>
    <path id="Path_378" data-name="Path 378" d="M161.014,118.47c-1.224-1.226-2.378-2.588-2.408-3.69-.273-9.7-3.1-12.039-2.948-15.8a.117.117,0,0,0-.1-.127l0,0a.115.115,0,0,0-.124.1c-.771,6.212,2.036,11.915,1.943,16.14a9.556,9.556,0,0,0,.769,4.464,3.854,3.854,0,0,0,2.259,2.06h1.527a17.286,17.286,0,0,0-.917-3.152" transform="translate(-101.141 -64.381)" fill="#3f3d56"/>
    <path id="Path_379" data-name="Path 379" d="M179.817,97.855v0h0a.112.112,0,0,0-.158.022h0c-3.7,4.854-5.949,15.476-6.425,19.9a18.04,18.04,0,0,1,.9,3.174h1.026a1.791,1.791,0,0,0,.2-.508,7.432,7.432,0,0,0,.185-1.552,39.034,39.034,0,0,0-.305-5.494,23.038,23.038,0,0,1,4.6-15.385.114.114,0,0,0-.022-.16m-5.614,22.9a.125.125,0,0,1,.012.045C174.2,120.781,174.2,120.767,174.2,120.758Z" transform="translate(-112.821 -63.714)" fill="#3f3d56"/>
    <path id="Path_380" data-name="Path 380" d="M70.392,118.252c-.067,1.186-.165,1.892-.167,1.908a.114.114,0,0,1-.113.1l-.017,0a.114.114,0,0,1-.1-.129,1.715,1.715,0,0,1-.368.186h-.519c-.359-.143-.705-.654-.774-2.06a27.95,27.95,0,0,1,.621-6.009c.757-4.127-.556-10.279-4.318-15.213a.115.115,0,0,1,.022-.16.113.113,0,0,1,.158.019v0c3.794,4.979,5.06,11.04,5.454,15.249a41.442,41.442,0,0,1,.114,6.112" transform="translate(-42.084 -63.073)" fill="#3f3d56"/>
    <path id="Path_381" data-name="Path 381" d="M154.587,182.966h-6.766a1.253,1.253,0,1,1,0-2.506h6.769a1.253,1.253,0,0,1,0,2.506" transform="translate(-95.455 -117.529)" fill="#e6e6e6"/>
    <path id="Path_382" data-name="Path 382" d="M151.088,196.259H136.253a1.253,1.253,0,1,1,0-2.506h14.839a1.253,1.253,0,0,1,0,2.506" transform="translate(-87.921 -126.186)" fill="#e6e6e6"/>
    <path id="Path_383" data-name="Path 383" d="M119.4,43.608a21.8,21.8,0,1,1,21.8-21.8,21.8,21.8,0,0,1-21.8,21.8m0-43.266A21.461,21.461,0,1,0,140.858,21.8,21.461,21.461,0,0,0,119.4.342" transform="translate(-63.559 0.001)" fill="#f2f2f2"/>
    <path id="Path_384" data-name="Path 384" d="M156.527,61.583c-.687.041-1.373.111-2.053.213-1.468.195-2.934.406-4.4.612q-1.368.192-2.732.388a4.257,4.257,0,0,1-.115-.642,4.663,4.663,0,0,1,9.244-1.222,4.286,4.286,0,0,1,.056.651" transform="translate(-95.866 -37.109)" fill="#FF9999"/>
    <path id="Path_385" data-name="Path 385" d="M127.133,141.293c.039-.265.379-.435.76-.38s.659.316.62.581-.379.435-.76.379-.659-.315-.62-.58" transform="translate(-82.796 -91.766)" fill="#fff"/>
    <path id="Path_386" data-name="Path 386" d="M140.143,140.024c.039-.265.379-.435.76-.38s.659.316.62.581-.379.435-.76.379-.659-.315-.62-.58" transform="translate(-91.269 -90.94)" fill="#3f3d56"/>
    <path id="Path_387" data-name="Path 387" d="M123.57,130.76c.039-.265.379-.435.76-.38s.659.316.62.581-.379.435-.76.379-.659-.315-.62-.58" transform="translate(-80.476 -84.906)" fill="#ff6584"/>
    <path id="Path_388" data-name="Path 388" d="M120.123,126.918c0-.243.141-.44.31-.438s.3.2.3.443-.141.44-.31.438-.3-.2-.3-.443" transform="translate(-78.233 -82.373)" fill="#e6e6e6"/>
    <path id="Path_389" data-name="Path 389" d="M118.206,118.882c0-.243.141-.44.31-.438s.3.2.3.443-.141.44-.31.438-.3-.2-.3-.443" transform="translate(-76.984 -77.139)" fill="#3f3d56"/>
    <path id="Path_390" data-name="Path 390" d="M133.114,133.025c0-.243.141-.44.31-.438s.3.2.3.443-.141.44-.31.438-.3-.2-.3-.443" transform="translate(-86.694 -86.35)" fill="#e6e6e6"/>
    <path id="Path_391" data-name="Path 391" d="M126.827,136.164c0-.243.141-.44.31-.438s.3.2.3.443-.141.44-.31.438-.3-.2-.3-.443" transform="translate(-82.599 -88.395)" fill="#ff6584"/>
    <path id="Path_392" data-name="Path 392" d="M135.579,141.966c0-.243.141-.44.31-.438s.3.2.3.443-.141.44-.31.438-.3-.2-.3-.443" transform="translate(-88.299 -92.173)" fill="#3f3d56"/>
    <path id="Path_393" data-name="Path 393" d="M249.911,63.825c.084-.331.538-.5,1.013-.381s.794.487.71.818-.538.5-1.013.381-.794-.487-.71-.818" transform="translate(-162.751 -41.294)" fill="#fff"/>
    <path id="Path_394" data-name="Path 394" d="M233.249,63.718c.084-.331.538-.5,1.013-.381s.794.487.71.818-.538.5-1.013.381-.794-.487-.71-.818" transform="translate(-151.9 -41.225)" fill="#3f3d56"/>
    <path id="Path_395" data-name="Path 395" d="M253.038,77.649c.084-.331.538-.5,1.013-.381s.794.487.71.818-.538.5-1.013.381-.794-.487-.71-.818" transform="translate(-164.788 -50.297)" fill="#ff6584"/>
    <path id="Path_396" data-name="Path 396" d="M259.652,83.685c.035-.308.236-.539.451-.515s.361.293.326.6-.236.539-.451.515-.361-.293-.326-.6" transform="translate(-169.101 -54.165)" fill="#e6e6e6"/>
    <path id="Path_397" data-name="Path 397" d="M261.022,94.125c.035-.308.236-.539.451-.515s.361.293.326.6-.236.539-.451.515-.361-.293-.326-.6" transform="translate(-169.993 -60.965)" fill="#3f3d56"/>
    <path id="Path_398" data-name="Path 398" d="M243.987,74.228c.035-.308.236-.539.45-.515s.361.293.326.6-.236.539-.45.515-.361-.293-.326-.6" transform="translate(-158.899 -48.006)" fill="#e6e6e6"/>
    <path id="Path_399" data-name="Path 399" d="M252.373,71.077c.035-.308.236-.539.451-.515s.361.293.326.6-.236.539-.451.515-.361-.293-.326-.6" transform="translate(-164.36 -45.955)" fill="#ff6584"/>
    <path id="Path_400" data-name="Path 400" d="M242.042,62.566c.035-.308.236-.539.451-.515s.361.293.326.6-.236.539-.451.515-.361-.293-.326-.6" transform="translate(-157.632 -40.412)" fill="#3f3d56"/>
    <path id="Path_401" data-name="Path 401" d="M283.637,24.191a17.2,17.2,0,0,1,.778-10.963.84.84,0,0,1,.9-.778l8.279.588a.84.84,0,0,1,.778.9L293.59,24.9a.84.84,0,0,1-.9.778l-8.279-.588a.84.84,0,0,1-.778-.9" transform="translate(-184.37 -8.107)" fill="#f2f2f2"/>
    <path id="Path_402" data-name="Path 402" d="M286.488,22.228c-.708-1.832-.255-4.35.5-7.048a.787.787,0,0,1,.839-.728l7.116.5a.787.787,0,0,1,.728.839l-.692,9.756a.787.787,0,0,1-.839.728l-4.408-.313a3.5,3.5,0,0,1-3.244-3.739" transform="translate(-186.352 -9.41)" fill="#fff"/>
    <path id="Path_403" data-name="Path 403" d="M298.349,23.775c-1.672.823-2.993,1.586-3.687,3.308-.447-1.031-.895-1.375-1.342-2.407-.132-.3-.575-.041-.445.26l1.589,3.662c.093.215.412.131.47-.062a7.163,7.163,0,0,1,3.675-4.316c.3-.146.037-.591-.26-.445" transform="translate(-190.726 -15.465)" fill="#FF9999"/>
    <path id="Path_404" data-name="Path 404" d="M93.6,161.059a17.2,17.2,0,0,1,.778-10.963.84.84,0,0,1,.9-.778l8.279.588a.84.84,0,0,1,.778.9l-.778,10.963a.84.84,0,0,1-.9.778l-8.279-.588a.84.84,0,0,1-.778-.9" transform="translate(-60.602 -97.246)" fill="#f2f2f2"/>
    <path id="Path_405" data-name="Path 405" d="M96.447,159.1c-.708-1.832-.255-4.35.5-7.048a.787.787,0,0,1,.839-.728l7.116.5a.787.787,0,0,1,.728.839l-.692,9.756a.786.786,0,0,1-.839.728l-4.408-.313a3.5,3.5,0,0,1-3.244-3.739" transform="translate(-62.583 -98.55)" fill="#fff"/>
    <path id="Path_406" data-name="Path 406" d="M108.309,160.644c-1.672.823-2.993,1.586-3.687,3.308-.447-1.031-.895-1.375-1.342-2.407-.131-.3-.575-.041-.445.26l1.589,3.662c.093.215.412.131.47-.062a7.163,7.163,0,0,1,3.675-4.316c.3-.146.037-.591-.26-.445" transform="translate(-66.958 -104.604)" fill="#FF9999"/>
    <path id="Path_407" data-name="Path 407" d="M.545,30.591a17.2,17.2,0,0,1,.778-10.963.84.84,0,0,1,.9-.778l8.279.588a.84.84,0,0,1,.778.9L10.5,31.3a.84.84,0,0,1-.9.778l-8.279-.588a.84.84,0,0,1-.778-.9" transform="translate(0 -12.275)" fill="#f2f2f2"/>
    <path id="Path_408" data-name="Path 408" d="M3.4,28.629c-.708-1.832-.255-4.35.5-7.048a.787.787,0,0,1,.839-.728l7.116.5a.787.787,0,0,1,.728.839l-.692,9.756a.787.787,0,0,1-.839.728L6.64,32.368A3.5,3.5,0,0,1,3.4,28.629" transform="translate(-1.982 -13.579)" fill="#fff"/>
    <path id="Path_409" data-name="Path 409" d="M15.258,30.176c-1.672.823-2.993,1.586-3.687,3.308-.447-1.031-.895-1.375-1.342-2.407-.131-.3-.575-.041-.445.26L11.372,35c.093.215.412.131.47-.062a7.163,7.163,0,0,1,3.675-4.316c.3-.146.037-.591-.26-.445" transform="translate(-6.356 -19.634)" fill="#FF9999"/>
    <path id="Path_410" data-name="Path 410" d="M271.242,263.371h-1.554l-.739-5.993h2.293Z" transform="translate(-175.16 -167.623)" fill="#ffb6b6"/>
    <path id="Path_411" data-name="Path 411" d="M266.758,274.389H261.98v-.061a1.86,1.86,0,0,1,1.86-1.86l.873-.662,1.628.662h.417Z" transform="translate(-170.621 -177.021)" fill="#2f2e41"/>
    <path id="Path_412" data-name="Path 412" d="M237.764,263.371H236.21l-.739-5.993h2.293Z" transform="translate(-153.356 -167.623)" fill="#ffb6b6"/>
    <path id="Path_413" data-name="Path 413" d="M233.28,274.389H228.5v-.061a1.86,1.86,0,0,1,1.86-1.86l.873-.662,1.628.662h.417Z" transform="translate(-148.817 -177.021)" fill="#2f2e41"/>
    <path id="Path_414" data-name="Path 414" d="M274.664,129.507l-2.585,1.039c-.027,1.817,1.764,5.576,1.764,5.576l.875,6.9a1.23,1.23,0,1,0,2.122-.16l.1-8.681Z" transform="translate(-177.198 -84.344)" fill="#ffb6b6"/>
    <path id="Path_415" data-name="Path 415" d="M262.574,112.275h0a2.939,2.939,0,0,1,3.953,2.2l.324,1.729s3.714,5.023,3.368,6.852l-3.972.5s-.671-2.522-1.734-3.316a15.07,15.07,0,0,1-2.7-4.7c-.593-1.507-.745-2.681.764-3.267" transform="translate(-170.234 -72.991)" fill="#FF9999"/>
    <path id="Path_416" data-name="Path 416" d="M229.184,155.957s-2.873,2.8-2.26,3.449,2.017,1.554.715,1.7-1.843.468-.715.737.954.96.954.96-1.7,8.089-.411,13.411c0,0,.717,14.537,1.166,15.67s.621.445.45,1.132-.515-.515-.172.687a15.912,15.912,0,0,1,.444,1.913l3.359-.515s.541-1.7,0-2.152-1.026-.172-.44-1.168.758.377.586-1,.545-8.8-.586-12.728l2.09-11.016,3.318,11.407s1.76,13.931,2.332,14.422,1.43-.368.572.491-.843.711-.858.858c-.16,1.575,4.755-.148,4.755-.148a8.862,8.862,0,0,1,0-1.2c.052-.368-.567-.485,0-.855s-.277,0-.277,0-1.268-10.277-2.232-13.392l-1.116-15.167s-.071.3,0-.433.586-.22.071-.735-.859-.777-.515-1.161,1.291-1.16.731-1.631-1.661-3.536-1.661-3.536l-10.731.086" transform="translate(-147.391 -101.571)" fill="#2f2e41"/>
    <path id="Path_417" data-name="Path 417" d="M235.286,103.063l1.935-2.178,3.057.34,1.957,2.739a4.179,4.179,0,0,1,3.181,4.787,41.868,41.868,0,0,1-1.834,6.175c-.076.223.2,1.024-.217.658s-.171.547-.245.809a5.188,5.188,0,0,0-.192,3.132c.162.409,1.172,1.26.424,1.138s.216.664.286.951c.426,1.741-.109,2.408-2.435,2.063-3.1-.459-13.473-1.518-11.461-3.53a10.592,10.592,0,0,0,2.453-5.373l.081-7.691a3.418,3.418,0,0,1,2.741-3.981C235.107,103.084,235.2,103.072,235.286,103.063Z" transform="translate(-149.458 -65.703)" fill="#FF9999"/>
    <path id="Path_418" data-name="Path 418" d="M227.2,130.882l-2.657-.837c-1.173,1.388-2.173,5.43-2.173,5.43l-3.7,5.892a1.23,1.23,0,1,0,1.742,1.222l5.582-6.649Z" transform="translate(-141.946 -84.695)" fill="#ffb6b6"/>
    <path id="Path_419" data-name="Path 419" d="M235.728,109.842h0a2.939,2.939,0,0,1,1.662,4.207l-.846,1.542s-.214,6.961-1.641,8.155l-3.491-2.851s1.081-2.376.762-3.663a15.082,15.082,0,0,1,.892-5.349c.5-1.541,1.124-2.545,2.663-2.041" transform="translate(-150.712 -71.452)" fill="#FF9999"/>
    <path id="Path_420" data-name="Path 420" d="M251.56,79.152a3.7,3.7,0,1,1-3.7,3.7,3.7,3.7,0,0,1,3.7-3.7" transform="translate(-161.428 -51.55)" fill="#ffb6b6"/>
    <path id="Path_421" data-name="Path 421" d="M251.311,84.2c-1.029-.258-1.064-.552-2.093-.81-.328-.082-.707-.211-.8-.535a.779.779,0,0,1,.185-.67,6.307,6.307,0,0,0,.455-.559,1.122,1.122,0,0,0-.775-1.681c-.318-.054-.647.03-.967-.011-.439-.056-1.086-1.686-.8-2.028s.724-.531.876-.935a4.266,4.266,0,0,0,.089-.838c.065-.413.9.731.79.327-.273-.989,4.344-1.145,4.825.124.18.474.666.549,1.04.892a3.913,3.913,0,0,1,.917,1.329,2.8,2.8,0,0,1-.04,1.178c-.384,1.807-1.017,3.409-2.7,4.162" transform="translate(-160.499 -49.287)" fill="#2f2e41"/>
    <path id="Path_422" data-name="Path 422" d="M40.634,80.758a6.2,6.2,0,0,0-4.446-1.814h-.066a6.348,6.348,0,0,1-3.2-.864l-.1-.059.082-.084a6.173,6.173,0,0,0,1.09-1.545,1.879,1.879,0,0,1-1.758.441,2,2,0,0,1-1.188-.9c-.6-.966-1.537-2.343-1.277-3.339.375-1.434,5.459.893,2.077-4.7-.59-.974,1.319-1.844,1.309-2.956v-.08a8.972,8.972,0,0,1,.267-2.176l.018-.072.074,0a6.076,6.076,0,0,0,1.421-.119,4.793,4.793,0,0,1-1.275-.339l-.08-.032.027-.082c1.862-1.152,6.062-.556,8.5-6.134,1.978-4.523,8.953,4.016,8.953,8.951,0,.5-.5,1.169-.126,1.5,4.034,3.6,1.251,5.909.373,8.234-.208.551.243,1.157.243,1.754,0,.1,0,.206-.007.311l-.008.161-.135-.09a4.471,4.471,0,0,1-.735-.6,3.882,3.882,0,0,1-.123,3.351c-.422.8-.953,2.129-1.215,1.7-1.925-3.16-4.594-8.838-4.406-2.222A2.5,2.5,0,0,1,40.67,80.8l-.036-.037" transform="translate(-19.36 -35.577)" fill="#e6e6e6"/>
    <path id="Path_423" data-name="Path 423" d="M54.759,80.736c.039-.265.379-.435.76-.38s.659.316.62.581-.379.435-.76.379-.659-.315-.62-.58" transform="translate(-35.661 -52.327)" fill="#fff"/>
    <path id="Path_424" data-name="Path 424" d="M67.77,79.467c.039-.265.379-.435.76-.38s.659.316.62.581-.379.435-.76.379-.659-.315-.62-.58" transform="translate(-44.135 -51.501)" fill="#3f3d56"/>
    <path id="Path_425" data-name="Path 425" d="M51.2,70.2c.039-.265.379-.435.76-.38s.659.316.62.581-.379.435-.76.379-.659-.315-.62-.58" transform="translate(-33.341 -45.467)" fill="#ff6584"/>
    <path id="Path_426" data-name="Path 426" d="M47.75,66.361c0-.243.141-.44.31-.438s.3.2.3.443-.141.44-.31.438-.3-.2-.3-.443" transform="translate(-31.098 -42.934)" fill="#e6e6e6"/>
    <path id="Path_427" data-name="Path 427" d="M45.833,58.325c0-.243.141-.44.31-.438s.3.2.3.443-.141.44-.31.438-.3-.2-.3-.443" transform="translate(-29.85 -37.7)" fill="#3f3d56"/>
    <path id="Path_428" data-name="Path 428" d="M60.741,72.468c0-.243.141-.44.31-.438s.3.2.3.443-.141.44-.31.438-.3-.2-.3-.443" transform="translate(-39.559 -46.911)" fill="#e6e6e6"/>
    <path id="Path_429" data-name="Path 429" d="M54.454,75.607c0-.243.141-.44.31-.438s.3.2.3.443-.141.44-.31.438-.3-.2-.3-.443" transform="translate(-35.464 -48.956)" fill="#ff6584"/>
    <path id="Path_430" data-name="Path 430" d="M63.206,81.409c0-.243.141-.44.31-.438s.3.2.3.443-.141.44-.31.438-.3-.2-.3-.443" transform="translate(-41.164 -52.734)" fill="#3f3d56"/>
    <path id="Path_431" data-name="Path 431" d="M168.075,83.41c-.082-5.61-1.968-4.5-4.129-1.525-.3.409-.793-.9-1.215-1.7a3.883,3.883,0,0,1-.123-3.352,4.441,4.441,0,0,1-.735.6l-.135.089-.008-.161c-.005-.1-.008-.21-.008-.311,0-.6.451-1.2.243-1.754-.877-2.325-3.661-4.636.373-8.234.373-.333-.126-1-.126-1.5,0-4.936,9.51-13.856,8.953-8.951-.914,8.045,4.649,6.446,8.5,6.134l.027.082-.08.032a4.807,4.807,0,0,1-1.275.339,6.075,6.075,0,0,0,1.421.119l.074,0,.018.072a8.992,8.992,0,0,1,.267,2.176v.079a3.965,3.965,0,0,0,1.309,2.956,6.366,6.366,0,0,1,2.077,4.7,7.621,7.621,0,0,1-1.308,3.388,1.916,1.916,0,0,1-1.406.9,1.872,1.872,0,0,1-1.51-.488,6.192,6.192,0,0,0,1.091,1.545l.082.084-.1.059a6.349,6.349,0,0,1-3.2.864h-.066a6.2,6.2,0,0,0-4.445,1.814,6.4,6.4,0,0,1-4.567,1.942" transform="translate(-104.342 -35.943)" fill="#f2f2f2"/>
    <path id="Path_432" data-name="Path 432" d="M137.093,85.707a2.821,2.821,0,0,1-4.819,1.158c-1.628-1.767-2.441-.392-2.471,3.532,0,.505-1.138-.378-1.905-.854a3.883,3.883,0,0,1-1.794-2.833,4.424,4.424,0,0,1-.332.892l-.071.145-.088-.135c-.057-.088-.112-.177-.163-.265-.3-.516-.215-1.266-.673-1.638-1.929-1.567-5.5-2.162-3.824-7.3.155-.476-.421-1-.865-1.234-7.2-3.867-2.51-15.007,3.227-12.242,5.484,2.643,8.244.961,10.433,1.019l.064.057-.052.068a4.8,4.8,0,0,1-.931.935,6.078,6.078,0,0,0,1.288-.613l.062-.039.052.053a9,9,0,0,1,1.326,1.745l.04.069a3.966,3.966,0,0,0,2.62,1.895,6.366,6.366,0,0,1,4.161,3.013,7.491,7.491,0,0,1,.578,3.511,2,2,0,0,1-.586,1.409,1.878,1.878,0,0,1-1.727.491,6.188,6.188,0,0,0,1.721.785l.113.031-.058.1a6.35,6.35,0,0,1-2.329,2.358l-.057.033a6.2,6.2,0,0,0-2.927,3.806l-.013.05" transform="translate(-75.827 -42.003)" fill="#e6e6e6"/>
    <path id="Path_433" data-name="Path 433" d="M49.825,109.637c.021-.013.035-.033.031-.046a4.633,4.633,0,0,0-.954-1.6,5.419,5.419,0,0,0-3.943-1.737.088.088,0,0,0-.064.033c-.014.019,0,.017.011.037a2.768,2.768,0,0,0,3.5,2c1.049-.211,1.354,1.309,1.356,1.316s.028.016.053,0l.006,0" transform="translate(-29.235 -69.2)" fill="#f2f2f2"/>
    <path id="Path_434" data-name="Path 434" d="M137.271,112.1c.021-.013.035-.033.031-.046a4.633,4.633,0,0,0-.954-1.6,5.419,5.419,0,0,0-3.943-1.737.088.088,0,0,0-.064.033c-.014.019.027.023.011.037-2.513,2.169.689,2.245,3.5,2,1.066-.094,1.354,1.309,1.356,1.316s.028.016.053,0l.006,0" transform="translate(-85.606 -70.803)" fill="#f2f2f2"/>
    <path id="Path_435" data-name="Path 435" d="M72.467,67.144c.025,0,.043-.021.054-.013.514.371,1.272-.074.373-1.826-.454-.884-.287-2.484-1.745-3.939a.087.087,0,0,0-.07-.018c-.024,0-.031.02-.017.035a4.487,4.487,0,0,1,1.245,3.836,13.814,13.814,0,0,0,.116,1.887c-.007.014.01.03.037.038l.007,0" transform="translate(-46.276 -39.953)" fill="#f2f2f2"/>
    <path id="Path_436" data-name="Path 436" d="M133.517,83.67c.021-.013.035-.033.031-.046a2.308,2.308,0,0,1-.954-1.6c-.526-4.887-1.833-3.2-3.943-1.737-.017.012-.05.014-.064.033s-.009.036.011.037a4.487,4.487,0,0,1,3.5,2,13.852,13.852,0,0,0,1.356,1.316c0,.015.028.016.053,0l.006,0" transform="translate(-83.74 -51.303)" fill="#f2f2f2"/>
    <path id="Path_437" data-name="Path 437" d="M206.3,100.324c-.021-.013-.035-.033-.031-.046a2.591,2.591,0,0,0,.954-1.6c1.154-6.964,2.13-4.756,3.943-1.737.011.018.05.014.064.033s.009.036-.011.037a4.488,4.488,0,0,0-3.5,2,13.849,13.849,0,0,1-1.356,1.316c0,.015-.028.016-.053,0l-.006,0" transform="translate(-134.336 -61.261)" fill="#fff"/>
    <path id="Path_438" data-name="Path 438" d="M77.307,100.231c-.021-.013-.035-.033-.031-.046s1.3-.583.954-1.6c-1.266-3.69,1.308-2.525,3.943-1.737a.123.123,0,0,1,.064.033c.014.019.009.036-.011.037a4.488,4.488,0,0,0-3.5,2,13.85,13.85,0,0,1-1.356,1.316c0,.015-.028.016-.053,0l-.006,0" transform="translate(-50.327 -62.542)" fill="#f2f2f2"/>
    <path id="Path_439" data-name="Path 439" d="M169.373,92.774c-.021-.013-.035-.033-.031-.046a8.081,8.081,0,0,0,.954-1.6c2.419-5.33,2.977-4.5,3.943-1.737.007.02.05.014.064.033s.009.036-.011.037a4.488,4.488,0,0,0-3.5,2,13.851,13.851,0,0,1-1.356,1.316c0,.015-.028.016-.053,0l-.006,0" transform="translate(-110.287 -56.801)" fill="#f2f2f2"/>
  </g>
</svg>
