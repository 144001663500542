<!-- <div class="card-section-detail" style="background-image: url('{{ cardData.image_uri }}')" *ngFor="let cardData of campaignTemplateList" (click)="navigateTo(cardData)">
  <div class="card-info">
    <h3>{{ cardData.name }}</h3>
    <div class="open-icon">
      <ion-icon name="chevron-forward-outline"></ion-icon>
    </div>
  </div>
</div> -->


<!-- Iterate over the campaignTemplateList -->
<div *ngFor="let cardData of campaignTemplateList" class="card-section-detail" (click)="openAdDetails(cardData)">

  <!-- Conditionally display the image background if image_uri exists -->
  <div *ngIf="cardData.image_uri" class="media-background image-background" [ngStyle]="{'background-image': 'url(' + cardData.image_uri + ')'}"></div>
  
  <!-- Conditionally display the video background if video_uri exists -->
  <video *ngIf="cardData.video_uri" class="media-background video-background" [src]="cardData.video_uri" autoplay [muted]="true" loop playsinline></video>
  
  <!-- Card information -->
  <div class="card-info">
    <h3>{{ cardData.name }}</h3>
    <div class="open-icon">
      <ion-icon name="chevron-forward-outline"></ion-icon>
    </div>
  </div>
</div>
