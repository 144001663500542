import { Component, Input, OnInit } from '@angular/core';

@Component({
    selector: 'app-no-data-available',
    templateUrl: './no-data-available.component.html',
    styleUrls: ['./no-data-available.component.scss'],
    standalone: false
})
export class NoDataAvailableComponent  implements OnInit {
  
  @Input() customTop: number | undefined;
  @Input() showInternalServerError: boolean = false;

  // Optional overrides for title and message
  @Input() customErrorTitle?: string;
  @Input() customErrorMessage?: string;

  constructor() { }

  ngOnInit() {}

  get errorTitle(): string {
    return this.customErrorTitle || (this.showInternalServerError
      ? 'error_messages.internal_server_error'
      : 'error_messages.data_missing');
  }

  get errorMessage(): string {
    return this.customErrorMessage || (this.showInternalServerError
      ? 'error_messages.technical_issue'
      : 'error_messages.no_data_available');
  }

}
