import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Input } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { UserVerificationStatus } from 'src/app/core/enum';
import { ResendVerificationReponse, UserStatusResponse } from 'src/app/models/apiResponse';
import { CustomErrorResponse } from 'src/app/models/interface';
import { AppService } from 'src/app/services/app-service/app.service';
import { Config } from 'src/app/services/config';
import { TranslateService } from '@ngx-translate/core';
import { FirebaseCrashlyticsService } from 'src/app/services/firebase-crashlytics/firebase-crashlytics.service';

@Component({
    selector: 'app-user-verification',
    templateUrl: './user-verification.component.html',
    styleUrls: ['./user-verification.component.scss'],
    standalone: false
})
export class UserVerificationComponent implements OnInit {
  @Input() type: string;
  @Input() email: string;
  @Input() reset: boolean;
  public userVerificationStatus = UserVerificationStatus;
  public remainingTime: number;
  public showResend: boolean;
  public timer: ReturnType<typeof setTimeout>;
  constructor(
    private appService: AppService,
    private modalCtrl: ModalController,
    public translate: TranslateService,
    private crashlyticsService: FirebaseCrashlyticsService
  ) { }

  ngOnInit(): void {
    this.initiateTimer();
    this.crashlyticsService.customizeCrashlytics({ component: 'User verification modal' });
  }

  initiateTimer(): void {
    this.showResend = false;
    this.remainingTime = 30;
    this.startTimer();
  }

  dismiss(): void {
    this.modalCtrl.dismiss({ reset: this.reset });
  }

  startTimer() {
    this.timer = setTimeout(() => {
      this.remainingTime -= 1;
      if (this.remainingTime > 0) {
        this.showResend = false;
        this.startTimer();
      } else {
        this.showResend = true;
      }
    }, 1000);
  }

  async checkUserStatus(): Promise<void> {
    await this.appService.showLoader();
    this.appService.api({}, Config.apiUrl.userStatus).subscribe({
      next: async (response: UserStatusResponse) => {
        await this.appService.hideLoader();
        if (response.status === UserVerificationStatus.fully_authenticated) {
          await this.appService.setStorage('auth', response.jwt);
          this.modalCtrl.dismiss({ status: true });
        } else {
          this.appService.presentToast(this.translate.instant('toast_message.complete_verification'));
        }
      },
      error: async () => {
        await this.appService.hideLoader();
        this.appService.presentToast(this.translate.instant('toast_message.failed_fetch_status'));
      }
    });
  }

  async resendVerification(): Promise<void> {
    if (this.showResend) {
      await this.appService.showLoader();
      this.appService.api({email: this.email}, Config.apiUrl.resendVerification).subscribe({
        next: async (response: ResendVerificationReponse) => {
          if (response?.success) {
            this.appService.presentToast(response?.message || this.translate.instant('toast_message.verification_mail_success'));
            this.initiateTimer();
          }
          await this.appService.hideLoader();
        },
        error: async (e: HttpErrorResponse) => {
          await this.appService.hideLoader();
          const errorResponse: CustomErrorResponse = e?.error;
          this.appService.presentToast(
            errorResponse?.error?.message || errorResponse?.message || this.translate.instant('toast_message.verification_mail_error')
          );
        }
      });
    }
  }
}
