<svg width="24" height="30" viewBox="0 0 24 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g id="switch">
        <path
            d="M20 21V19C20 17.9391 19.5786 16.9217 18.8284 16.1716C18.0783 15.4214 17.0609 15 16 15H8C6.93913 15 5.92172 15.4214 5.17157 16.1716C4.42143 16.9217 4 17.9391 4 19V21"
            stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path
            d="M12 11C14.2091 11 16 9.20914 16 7C16 4.79086 14.2091 3 12 3C9.79086 3 8 4.79086 8 7C8 9.20914 9.79086 11 12 11Z"
            stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round" />
        <path
            d="M19.4177 21.8072C16.2516 24.4722 11.5751 24.4198 8.46776 21.6504L8.57448 21.4898C11.7295 24.1472 16.3788 24.116 19.4988 21.3965L19.6817 21.5692C19.5879 21.6598 19.496 21.7412 19.4177 21.8072Z"
            stroke="black" stroke-width="1.2" />
        <path d="M8.31615 21.4348L9.19491 26.0321" stroke="black" stroke-width="1.5" stroke-linecap="round" />
        <path d="M8.31462 21.3725L12.8132 20.2716" stroke="black" stroke-width="1.5" stroke-linecap="round" />
    </g>
</svg>