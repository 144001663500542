<svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39">
  <g id="Group_2429" data-name="Group 2429" transform="translate(-45 -358)">
    <g id="Ellipse_6" data-name="Ellipse 6" transform="translate(45 358)" fill="#f8dfc1" stroke="#edab5d" stroke-width="1" stroke-dasharray="2">
      <circle cx="19.5" cy="19.5" r="19.5" stroke="none"/>
      <circle cx="19.5" cy="19.5" r="19" fill="none"/>
    </g>
    <path id="minus" d="M19.687,208H1.514A1.515,1.515,0,0,0,0,209.514v1.514a1.515,1.515,0,0,0,1.514,1.514H19.687a1.515,1.515,0,0,0,1.514-1.514v-1.514A1.515,1.515,0,0,0,19.687,208Z" transform="translate(54 167)" fill="#edab5d"/>
  </g>
</svg>

