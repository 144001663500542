import { Component, OnInit } from '@angular/core';
import { ApiEndpoint } from 'src/app/models/apiRequest';
import { NewsResponse } from 'src/app/models/apiResponse';
import { AppService } from 'src/app/services/app-service/app.service';
import { Config } from 'src/app/services/config';

@Component({
  selector: 'app-effektify-news',
  templateUrl: './effektify-news.component.html',
  styleUrls: ['./effektify-news.component.scss'],
})
export class EffektifyNewsComponent  implements OnInit {

  public config = Config;
  public effektifyNews: NewsResponse[];
  public newsSeletonArray = new Array(3);
  public showSkeleton: boolean = true;
  constructor(
    public appService: AppService,
  ) { }

  ngOnInit() {
    this.fetchEffektifyNews();
  }

  fetchEffektifyNews() {
    this.showSkeleton = true;
    const apiEndpoint: ApiEndpoint = this.appService.getClone(Config.apiUrl.getEffektifyNews);
    this.appService.api({}, apiEndpoint).subscribe({
      next: async (news: NewsResponse[]) => {
        this.showSkeleton = false;
        this.effektifyNews = news;
        await this.appService.hideLoader();
      },
      error: async () => {
        this.effektifyNews = [];
        this.showSkeleton = false;
        await this.appService.hideLoader();
      }
    })
  }

}
