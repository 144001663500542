<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <g id="EFFEKTIFY"  stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Home-Menu" transform="translate(-25.000000, -222.000000)" fill="#14181C">
          <g id="Insight" transform="translate(25.000000, 222.000000)">
              <path d="M10,0 C4.47714543,0 0,4.47730966 0,9.99949994 C0,15.5216902 4.47714543,19.9989999 10,19.9989999 C15.5231046,19.9989999 20,15.5216902 20,9.99949994 C20,4.47730966 15.5231046,0 10,0 Z M11.2502501,17.3924241 L11.2502501,9.99949994 L8.74974995,9.99949994 L8.74974995,17.3924241 C5.20779156,16.7953494 2.5005001,13.7084636 2.5005001,9.99949994 C2.5005001,5.86448306 5.86492298,2.50031254 10,2.50031254 C14.135077,2.50031254 17.4994999,5.86448306 17.4994999,9.99949994 C17.4994999,13.7084636 14.7922084,16.7953494 11.2502501,17.3924241 Z" id="Shape" fill-rule="nonzero"></path>
              <path d="M14.0363395,13.5555556 L12.2802324,11.9110996 C12.8144185,11.3298215 13.1088198,10.572274 13.1088198,9.77845711 C13.1088198,8.0481635 11.7144262,6.64018423 10,6.64018423 C8.28581333,6.64018423 6.89118019,8.0479217 6.89118019,9.77845711 C6.89118019,10.572274 7.18558145,11.3298215 7.71976763,11.9110996 L5.96366052,13.5555556 C5.01697722,12.5255037 4.4957265,11.1840184 4.4957265,9.77845711 C4.4957265,6.71489925 6.96496017,4.22222222 10,4.22222222 C13.0350398,4.22222222 15.5042735,6.71489925 15.5042735,9.77845711 C15.5042735,11.1840184 14.9827832,12.5255037 14.0363395,13.5555556 Z" id="Path" fill-rule="nonzero"></path>
              <circle id="Oval" cx="10" cy="9.77777778" r="2"></circle>
          </g>
      </g>
  </g>
</svg>
