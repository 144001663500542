import { HttpErrorResponse, HttpResponse } from '@angular/common/http';
import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output
} from '@angular/core';
import { InfiniteScrollCustomEvent } from '@ionic/angular';
import { ApiEndpoint, OrdersPayload } from 'src/app/models/apiRequest';
import {
  OrderResponse,
  TeamResponse
} from 'src/app/models/apiResponse';
import { AppService } from 'src/app/services/app-service/app.service';
import { Config } from 'src/app/services/config';
import { OrderStatus } from 'src/app/core/enum';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { FirebaseCrashlyticsService } from 'src/app/services/firebase-crashlytics/firebase-crashlytics.service';
import {
  unreadNotificationMaxText
} from 'src/app/constants/app-constants';

enum Filters {
  all = 'all',
  new = 'paid',
  fulfilled = 'done'
}

const searchTimeoutDelay = 1000;
const defaultHeadertemplateHeight = 50;

@Component({
    selector: 'app-orders',
    templateUrl: './orders.component.html',
    styleUrls: ['./orders.component.scss'],
    standalone: false
})
export class OrdersComponent  implements OnInit, OnDestroy {
  public filter = Filters.all;
  public selectedTeam: TeamResponse = null;
  public showNoDataAlert = false;
  public showInternalServerErrorAlert = false;
  public showSkeleton = true;
  public searchText = '';
  public orders: OrderResponse[];
  public filters = Filters;
  public orderStatus = OrderStatus;
  public searchTimeout: ReturnType<typeof setTimeout>;
  public page = 1;
  public perPage: number;
  public stopInfiniteLoad = false;
  public skeletonArray = new Array(5);
  public warningDivCustomTop: number = defaultHeadertemplateHeight;
  unreadNotificationMaxText = unreadNotificationMaxText;
  config = Config;
  private infiniteLoadEvent: InfiniteScrollCustomEvent;
  private obs = new Subject();
  @Output() orderEvent = new EventEmitter<OrderResponse>();
  constructor(
    public appService: AppService,
    private crashlyticsService: FirebaseCrashlyticsService
  ) { }

  async ngOnInit(): Promise<void> {
    await this.appService.showLoader();
    this.fetchOrders(1, true);
    this.crashlyticsService.customizeCrashlytics({ page: 'Orders' });
  }

  async changeFilter(filter: Filters): Promise<void> {
    if (this.filter !== filter) {
      this.filter = filter;
      await this.appService.showLoader();
      this.fetchOrders(1, true);
    }
  }

  searchOrder(): void {
    clearTimeout(this.searchTimeout);
    this.searchTimeout = setTimeout(async () => {
      await this.appService.showLoader();
      this.fetchOrders(1, true);
    }, searchTimeoutDelay);
  }

  fetchOrders(page = 1, refresh = true): void {
    this.selectedTeam = this.appService.getTeam();
    const apiEndpoint: ApiEndpoint = this.appService.getClone(Config.apiUrl.orders);
    apiEndpoint.url = apiEndpoint.urlStart + this.selectedTeam.id + apiEndpoint.urlEnd;
    const payload: OrdersPayload = {
      status: this.filter,
      search: this.searchText,
      page: page.toString()
    }
    if (this.filter === Filters.all) {
      delete payload.status;
    }
    if (!this.searchText) {
      delete payload.search;
    }
    if (this.filter === Filters.new) {
      payload.period_start = new Date().toISOString().slice(0, 10) + 'T00:00:00';
      payload.status = OrderStatus.paid + ',' + OrderStatus.pending + ',' + OrderStatus.processing;
    }

    this.appService.api(payload, apiEndpoint, true).pipe(takeUntil(this.obs)).subscribe({
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      next: async (response: HttpResponse<any>) => {
        const lastPage = response?.headers?.get('X-pagination-last-page') ? Number(response?.headers?.get('X-pagination-last-page')) : null;
        this.page = response?.headers?.get('X-Pagination-Current-Page') ? Number(response?.headers?.get('X-Pagination-Current-Page')) : null;
        this.perPage = response?.headers?.get('X-Pagination-Per-Page') ? Number(response?.headers?.get('X-Pagination-Per-Page')) : null;
        this.stopInfiniteLoad = this.page >= lastPage;
        const orders = response?.body;
        this.showNoDataAlert = false;
        this.showInternalServerErrorAlert = false;
        this.showSkeleton = false;
        if (refresh) {
          this.orders = undefined;
          this.page = 1;
        }
        if (!orders?.length && !this.searchText?.length && !payload.status) {
          this.showNoDataAlert = true;
        } else {
          if (this.orders?.length > 0) {
            this.orders = this.orders.concat(orders);
          } else {
            this.orders = orders;
          }
          this.page++;
        }
        await this.appService.hideLoader();
        this.completeInfiniteLoad();
      },
      error: async (e: HttpErrorResponse) => {
        if (!e.status || e.status === 500 || e.status === 404) {
          this.showInternalServerErrorAlert = true;
        }
        this.showSkeleton = false;
        await this.appService.hideLoader();
        this.completeInfiniteLoad();
      }
    })
  }

  async doRefresh(): Promise<void> {
    let refresh = true;
    let page = 1;
    if (this.orders?.length) {
      const clearDataThreshold = Math.round(this.orders.length / this.perPage);
      if (clearDataThreshold && clearDataThreshold > 5) {
        this.orders = this.orders.slice(0, this.perPage * 2);
        refresh = false;
        page = 3;
      }
    }
    this.fetchOrders(page, refresh);
  }

  openOrderDetail(order: OrderResponse): void {
    this.orderEvent.emit(order)
  }

  completeInfiniteLoad(): void {
    if (this.infiniteLoadEvent) {
      this.infiniteLoadEvent.target.complete();
      this.infiniteLoadEvent = null;
    }
  }

  loadMoreOrders(event): void {
    this.infiniteLoadEvent = event;
    this.fetchOrders(this.page, false);
  }

  ngOnDestroy(): void {
    this.obs.next(void 0);
    this.obs.complete();
  }

}
