import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-edit-icon',
    templateUrl: './edit-icon.component.html',
    styleUrls: ['./edit-icon.component.scss'],
    standalone: false
})
export class EditIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
