import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-offers-icon',
  templateUrl: './offers-icon.component.html',
  styleUrls: ['./offers-icon.component.scss'],
})
export class OffersIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
