import { Component, Input, OnInit, ViewChild } from '@angular/core';
import { ApiEndpoint } from 'src/app/models/apiRequest';
import { DynamicContainerData, TeamResponse } from 'src/app/models/apiResponse';
import { AppService } from 'src/app/services/app-service/app.service';
import { Config } from 'src/app/services/config';
import { FilterService } from 'src/app/services/filter-service/filter.service';
import { DynamicContainerComponent } from '../dynamic-container/dynamic-container.component';
import { HttpErrorResponse } from '@angular/common/http';
import { calendlyScheduleAppointment, defaultNoDataErrorTemplateOffsetTopDemoUser } from 'src/app/constants/app-constants';

@Component({
    selector: 'app-mystore-stats',
    templateUrl: './mystore-stats.component.html',
    styleUrls: ['./mystore-stats.component.scss'],
    standalone: false
})


export class MystoreStatsComponent  implements OnInit {
  @ViewChild('dynamicStatsContainer') dynamicStatsContainer: DynamicContainerComponent;
  @Input() isTemplateActive = false;
  public currentLanguage: string;
  public selectedTeam: TeamResponse = null;
  public statsData: DynamicContainerData = {};
  public showNoDataAlertStats = false;
  public showInternalServerErrorAlertStats = false;
  public showBookMeetingModal = false;
  public warningDivCustomTop: number = defaultNoDataErrorTemplateOffsetTopDemoUser;
  public config = Config;
  public calendlyScheduleAppointment = calendlyScheduleAppointment;
  constructor(
    public appService: AppService,
    private filterService: FilterService
  ) { }

  ngOnInit() {
    this.fetchStatsData();
  }

  async fetchStatsData(): Promise<void> {
    this.selectedTeam = this.appService.getTeam();
    this.currentLanguage = this.appService.getLanguage();
    const apiEndpoint: ApiEndpoint = this.appService.getClone(Config.apiUrl.dashboardStoreStats);
    apiEndpoint.url = apiEndpoint.urlStart + this.selectedTeam.id + apiEndpoint.urlEnd;
    this.appService.api(this.filterService.getRequestPayload(), apiEndpoint).subscribe({
      next: async (statsData: DynamicContainerData) => {
        this.statsData = {};
        this.showNoDataAlertStats = false;
        this.showInternalServerErrorAlertStats = false;
        if (!statsData?.containers?.length) {
          this.showNoDataAlertStats = true;
          this.dynamicStatsContainer.showSkeleton = false;
        } else {
          this.statsData = statsData;
        }
        await this.appService.hideLoader();
      },
      error: async (e: HttpErrorResponse) => {
        this.statsData = {};
        if (!e.status || e.status === 500 || e.status === 404) {
          this.showInternalServerErrorAlertStats = true;
        }
        this.dynamicStatsContainer.showSkeleton = false;
        await this.appService.hideLoader();
      }
    })
  }
}
