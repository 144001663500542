import { Component, Input, OnInit } from '@angular/core';
import { ModalController, SearchbarCustomEvent } from '@ionic/angular';

@Component({
    selector: 'app-custom-select',
    templateUrl: './custom-select.component.html',
    styleUrls: ['./custom-select.component.scss'],
    standalone: false
})
export class CustomSelectComponent implements OnInit {
  @Input() title;
  @Input() data;
  filtered = [];

  constructor(
    private modalController: ModalController
  ) { }

  ngOnInit(): void {
    this.filtered = this.data;
  }

  close(): void {
    this.modalController.dismiss();
  }

  itemSelected(item) {
    this.modalController.dismiss(item);
  }

  filter(event: SearchbarCustomEvent): void {
    const filter = event.detail.value.toLowerCase();
    this.filtered = this.data.filter(item => item.name.toLowerCase().indexOf(filter) >= 0);
  }
}
