export const AppRoutes = {
  onboardPage: '/onboard',
  loginPage: '/login',
  registerPage: '/register',
  signupAccountPage: '/signup-account',
  signupBusinessPage: '/signup-business',
  forgotPasswordPage: '/forgot-password',
  changePasswordPage: '/change-password',
  profilePage: '/profile',
  settingsPage: '/settings',
  messagesPage: '/messages',
  storePage: '/store',
  offersPage: '/offers',
  notificationPage: '/notification',
  webShopPage: '/web-shop',
  connectSocialPage: '/connect-social',
  customerOrderPage: '/customer-order',
  adWizardPage: '/ad-wizard',
  adPublishStatusPage: '/ad-publish-status',
  addKeywordsPage: '/add-keywords',
  bookMeetingPage: '/book-meeting',
  emailLoginPage: '/email-login',
  dashboardPage: '/tabs/dashboard',
  marketingPage: '/tabs/marketing',
  myStorePage: '/tabs/mystore',
  myWebsitePage: '/tabs/mywebsite',
  insightsPage: '/tabs/insights',
  addEcommercePage: '/add-ecommerce',
  campaignTemplateListPage: '/campaign-template-list',
  campaignAdsListPage: '/campaign-ads-list',
  webshopMailSentPage: '/webshop-mail-sent',
};