import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-cheveron-icon',
    templateUrl: './cheveron-icon.component.html',
    styleUrls: ['./cheveron-icon.component.scss'],
    standalone: false
})
export class CheveronIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
