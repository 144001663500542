import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-notification-icon',
    templateUrl: './notification-icon.component.html',
    styleUrls: ['./notification-icon.component.scss'],
    standalone: false
})
export class NotificationIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
