<!-- <svg width="22px" height="18px" viewBox="0 0 22 18" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="EFFEKTIFY" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Home" transform="translate(-339.000000, -763.000000)" fill="#14181C" fill-rule="nonzero">
            <g id="chart-bar-2" transform="translate(339.000000, 763.000000)">
                <path d="M2.2,12.375 L2.2,10.125 C2.2,9.50367966 2.69248678,9 3.3,9 L5.5,9 C6.10751322,9 6.6,9.50367966 6.6,10.125 L6.6,12.375 C6.6,12.9963203 6.10751322,13.5 5.5,13.5 L3.3,13.5 C2.69248678,13.5 2.2,12.9963203 2.2,12.375 Z M9.9,13.5 L12.1,13.5 C12.7075132,13.5 13.2,12.9963203 13.2,12.375 L13.2,1.125 C13.2,0.503679656 12.7075132,0 12.1,0 L9.9,0 C9.29248678,0 8.8,0.503679656 8.8,1.125 L8.8,12.375 C8.8,12.9963203 9.29248678,13.5 9.9,13.5 Z M16.5,13.5 L18.7,13.5 C19.3075132,13.5 19.8,12.9963203 19.8,12.375 L19.8,5.625 C19.8,5.00367966 19.3075132,4.5 18.7,4.5 L16.5,4.5 C15.8924868,4.5 15.4,5.00367966 15.4,5.625 L15.4,12.375 C15.4,12.9963203 15.8924868,13.5 16.5,13.5 Z M21.45,15.75 L0.55,15.75 C0.246243388,15.75 0,16.0018398 0,16.3125 L0,17.4375 C0,17.7481602 0.246243388,18 0.55,18 L21.45,18 C21.7537566,18 22,17.7481602 22,17.4375 L22,16.3125 C22,16.0018398 21.7537566,15.75 21.45,15.75 Z" id="Shape"></path>
            </g>
        </g>
    </g>
</svg> -->
<svg width="20" height="20" viewBox="0 0 20 20" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#clip0_0_1467)">
    <path d="M5.46436 2.43228L3.08936 5.59895V16.6823C3.08936 17.1022 3.25617 17.5049 3.5531 17.8019C3.85004 18.0988 4.25276 18.2656 4.67269 18.2656H15.756C16.1759 18.2656 16.5787 18.0988 16.8756 17.8019C17.1725 17.5049 17.3394 17.1022 17.3394 16.6823V5.59895L14.9644 2.43228H5.46436Z" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M3.08936 5.59894H17.3394" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    <path d="M13.3812 8.76559C13.3812 9.60545 13.0476 10.4109 12.4537 11.0048C11.8598 11.5986 11.0544 11.9323 10.2145 11.9323C9.37467 11.9323 8.56921 11.5986 7.97535 11.0048C7.38148 10.4109 7.04785 9.60545 7.04785 8.76559" stroke="black" stroke-width="1.5" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
    <clipPath id="clip0_0_1467">
    <rect x="0.714355" y="0.848938" width="19" height="19" rx="2" fill="white"/>
    </clipPath>
    </defs>
</svg>
    