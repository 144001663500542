<ion-app class="left-menu" [class.web]="appService.isWebLayout">
  <ion-split-pane contentId="main-content" when="true">
    <ion-menu side="end" contentId="main-content" type="overlay" [swipeGesture]="hasMenuSwipeGesture()" [disabled]="hideSideMenu()" (ionWillOpen)="intercomService.hideIntercomLauncher()" max-edge-start="20">
      <ion-content>
        <ion-list class="menu-section overflow-scroll" id="menu-list" lines="none">
          <div class="menu-header ios-padding-top">
            <ion-menu-toggle class="cursor-pointer close-icon-container" auto-hide="false">
              <div class="close">
                <ion-icon slot="start" src="assets/svg/close.svg"></ion-icon>
              </div>
            </ion-menu-toggle>
            <div class="user-section">
              <ion-avatar class="user-image" [style.background-image]="'url('+user?.avatar_url+')'">
              </ion-avatar>
              <div class="user-details">
                <ion-menu-toggle (click)="navigatePage(appService.appRoutes.profilePage)" class="cursor-pointer">
                  <ion-list-header>{{'sidemenu.hello' | translate}}{{user?.first_name ? ',': ''}} {{user?.first_name}}</ion-list-header>
                </ion-menu-toggle>
              </div>
            </div>
          </div>
          <div class="message-item" *ngIf="env.development">
            <ion-menu-toggle auto-hide="false" class="cursor-pointer">
              <ion-item detail="true" (click)="navigatePage(appService.appRoutes.messagesPage)" routerLinkActive="selected">
                <ion-label>{{'sidemenu.messages' | translate}} <sup><span class="dot"></span></sup></ion-label>
              </ion-item>
            </ion-menu-toggle>
          </div>
          <!-- Commenting for future use -->
          <!-- Customer & Order Accordion group -->
          <div class="accordion-gp">
            <ion-accordion-group (ionChange)="appService.expandCustomer($event)" #customerAccordionGroup>
              <ion-accordion [value]="index" *ngFor="let customer of appService.customers; let index = index">
                <ion-item slot="header" lines="none">
                  <ion-label>{{customer.name}}</ion-label>
                </ion-item>
                <div class="item-list-section" slot="content">
                  <ion-list>
                    <ion-item *ngIf="appService.customerOrders[index] && appService.customerOrders[index]?.length <= 0" class="no-padding">
                      {{'sidemenu.order_unavailable' | translate}}
                    </ion-item>
                    <ion-menu-toggle auto-hide="false" *ngFor="let order of appService.customerOrders[index]">
                      <ion-item detail="true">
                        <ion-label class="cursor-pointer" (click)="selectCustomerOrder(order)">{{order.foreign_id}} {{order.date}}</ion-label>
                      </ion-item>
                    </ion-menu-toggle>
                  </ion-list>
                </div>
              </ion-accordion>
            </ion-accordion-group>
          </div>
          <!-- Ion-menu-Items -->
          <ion-menu-toggle class="menu-items cursor-pointer" auto-hide="false">
            <ion-item (click)="navigatePage(appService.appRoutes.profilePage)" lines="none" detail="false">
              <span class="menu-icon"><app-user-icon></app-user-icon></span>
              <ion-label>{{'sidemenu.view_profile' | translate}}</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle class="menu-items cursor-pointer" auto-hide="false">
            <ion-item (click)="navigatePage(appService.appRoutes.storePage)" lines="none" detail="false">
              <span class="menu-icon"><app-sources-icon></app-sources-icon></span>
              <ion-label>{{'sidemenu.connect_edit_sources' | translate}}</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle class="menu-items cursor-pointer" auto-hide="false" *ngIf="appService.teams?.length > 1">
            <ion-item (click)="openModal('switch-account')" lines="none" detail="false" routerLinkActive="selected">
              <span class="menu-icon"><app-switch-account></app-switch-account></span>
              <ion-label>{{'sidemenu.switch_account' | translate}}</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <!-- Commenting for future use -->
          <!-- <ion-menu-toggle class="menu-items cursor-pointer" auto-hide="false">
            <ion-item lines="none" detail="false">
              <span class="menu-icon"><app-new-account></app-new-account></span>
              <ion-label>{{'sidemenu.add_account' | translate}}</ion-label>
            </ion-item>
          </ion-menu-toggle> -->
          <ion-menu-toggle *ngIf="appService.checkAppFeatureEnabled(calendlyScheduleAppointment)" class="menu-items cursor-pointer" auto-hide="false">
            <ion-item (click)="navigatePage(appService.appRoutes.bookMeetingPage)" lines="none" detail="false">
              <span class="menu-icon"><app-experts></app-experts></span>
              <ion-label>{{'sidemenu.meet_expert' | translate}}</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle class="menu-items cursor-pointer" auto-hide="false" *ngIf="env.development">
            <ion-item (click)="navigatePage(appService.appRoutes.offersPage)" lines="none" detail="false" routerLinkActive="selected">
              <span class="menu-icon"><app-offers-icon></app-offers-icon></span>
              <ion-label>{{'sidemenu.offers' | translate}}</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle class="menu-items cursor-pointer" auto-hide="false">
            <ion-item (click)="navigatePage(appService.appRoutes.notificationPage)" lines="none" detail="false" routerLinkActive="selected">
              <span class="menu-icon"><app-notification-icon></app-notification-icon></span>
              <ion-label>{{'sidemenu.notifications' | translate}}</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle class="menu-items cursor-pointer" auto-hide="false" *ngIf="env.development">
            <ion-item (click)="navigatePage('/#')" lines="none" detail="false" routerLinkActive="selected">
              <span class="menu-icon"><app-help-icon></app-help-icon></span>
              <ion-label>{{'sidemenu.help' | translate}}</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle class="menu-items cursor-pointer" auto-hide="false">
            <ion-item (click)="navigatePage(appService.appRoutes.settingsPage)" lines="none" detail="false" routerLinkActive="selected">
              <span class="menu-icon"><app-settings-icon></app-settings-icon></span>
              <ion-label>{{'sidemenu.settings' | translate}}</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle class="menu-items cursor-pointer" auto-hide="false" *ngIf="env.development">
            <ion-item (click)="navigatePage(appService.appRoutes.addEcommercePage)" lines="none" detail="false" routerLinkActive="selected">
              <span class="menu-icon"><app-insights-icon></app-insights-icon></span>
              <ion-label>{{'sidemenu.add_ecommerce' | translate}}</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle class="menu-items cursor-pointer" auto-hide="false" *ngIf="env.development">
            <ion-item (click)="navigatePage(appService.appRoutes.webShopPage)" lines="none" detail="false" routerLinkActive="selected">
              <span class="menu-icon"><app-help-icon></app-help-icon></span>
              <ion-label>{{'sidemenu.web_shop' | translate}}</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle class="menu-items cursor-pointer" auto-hide="false" *ngIf="env.development">
            <ion-item (click)="navigatePage(appService.appRoutes.connectSocialPage)" lines="none" detail="false" routerLinkActive="selected">
              <span class="menu-icon"><app-insights-icon></app-insights-icon></span>
              <ion-label>{{'sidemenu.connect_social' | translate}}</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <ion-menu-toggle class="menu-items cursor-pointer" auto-hide="false">
            <ion-item (click)="logout()" lines="none" detail="false" routerLinkActive="selected">
              <span class="menu-icon"><app-logout-icon></app-logout-icon></span>
              <ion-label>{{'sidemenu.logout' | translate}}</ion-label>
            </ion-item>
          </ion-menu-toggle>
          <!-- Ion-menu-Items -->
          <div class="legal-message">
            <div class="legal-information">
                {{'google_legal_message.message_part_1' | translate}} <span class="cursor-pointer" (click)="appService.openIabUrl(appService.dynamicLinks?.['google_terms_and_conditions'])">{{'google_legal_message.clickable_link_1' | translate}}</span> {{'google_legal_message.message_part_2' | translate}} <span class="cursor-pointer" (click)="appService.openIabUrl(appService.dynamicLinks?.['privacy_policy'])">{{'google_legal_message.clickable_link_2' | translate}}</span>
            </div>
            <div class="legal-text">
              <ion-note class="label cursor-pointer" (click)="appService.showTermsAndConditions()">{{'sidemenu.legal' | translate}}</ion-note>
              <ion-note>v{{env.appVersion}}</ion-note>
            </div>
          </div>
        </ion-list>
      </ion-content>
    </ion-menu>
    <ion-router-outlet id="main-content"></ion-router-outlet>
  </ion-split-pane>
</ion-app>

<!-- Limit mobile usage on web app -->
<ion-modal *ngIf="!appService.isNativePlatform && appService.appBaseUrl.includes(env.webUrlString)"  #modal class="limit-mobile-modal" [isOpen]="!appService.isWebLayout">
  <ng-template>
    <div class="modal-content">
      <div class="logo-icon">
        <app-e-icon color="#000000"></app-e-icon>
      </div>
      <p>{{'modal.app_download_message' | translate}}</p>
      <ion-icon (click)="appService.openEffektifyAppleStore()" slot="start" src="assets/svg/app-store-dwn.svg" *ngIf="appService.getOperatingSystem() === 'iOS'"></ion-icon>
      <img (click)="appService.openEffektifyPlayStore()" *ngIf="appService.getOperatingSystem() === 'Android'" src="assets/svg/play-store-dwn.png"/>
    </div>
  </ng-template>
</ion-modal>
