import { InsightsActionTypes, InsightsActionURITypes } from "../core/enum";

/* eslint-disable @typescript-eslint/no-explicit-any */
export interface LoginResponse {
  jwt: string;
  success: boolean;
  status?: string;
}

export interface MeResponse {
  notifications: {
    unread_count: number;
  };
  user: UserResponse;
}

export interface UserResponse {
  id: string;
  email: string;
  name: string;
  first_name: string;
  last_name: string;
  created_at: string;
  avatar_url: string;
  show_tutorial?: boolean;
  locale?: string;
}

export interface TeamResponse {
  id: string;
  name?: string;
  created_at?: string;
  avatar_url?: string;
  api_endpoint?: string;
  business_type?: string;
  city?: string;
  country_code?: string;
  postal_code?: string;
  is_enterprise_enrolled?: boolean;
  enterprise_endpoint?: string;
}

export interface DynamicContainerData {
  containers?: DynamicContainer[];
}

export interface DynamicContainer {
  id: string;
  spacing: 'compact'|'normal';
  type: 'side_scroller'|'container';
  cards: ContainerCard[];
}

export interface ContainerCard {
  id: string;
  title: string;
  image: {
    image_uri?: string;
    image_text?: string;
  };
  value: number;
  format: ContainerCardFormat;
  formatted_value: string|number;
  trend: {
    value: string;
    direction: 'none'|'up'|'down';
  }
  chart?: ContainerChart;
  list?: ContainerList;
  actions?: ContainerActions[]
  description?: string;
  rows?: ContainerRow[];
  skeleton: boolean;
  skeleton_configuration: ContainerCardSkeleton;
}

export interface ContainerCardSkeleton {
  description: string;
  title: string;
  button: boolean;
  button_text: string;
}

export interface ContainerRow {
  title: string;
  value: string|number;
  description?: string;
}

export interface ContainerActions {
  type: string;
  uri: string;
}

export interface ContainerCardFormat {
  height: 'small'|'medium'|'large';
  xs?: number;
  sm?: number;
  md?: number;
  lg?: number;
  xl?: number;
}

export interface ContainerChart {
  type: 'line_chart';
  data: ContainerChartData[];
  description?: string;
  configuration: ChartConfiguration;
}

export interface ChartConfiguration {
  trend_label?: string;
  value_label?: string;
  xaxis?: {
    show?: boolean;
  };
  yaxis?: {
    show?: boolean;
  };
  draggable?: boolean;
}

export interface ContainerList {
  type: 'list'|'percentage_list';
  data: ContainerListData[];
  description?: string;
  configuration: ChartConfiguration;
}

// eslint-disable-next-line @typescript-eslint/no-empty-object-type
export interface ContainerListData extends ContainerChartData {}

export interface ContainerChartData {
  name: string;
  value: number;
  period_start: string;
  period_end: string;
  formatted_value: string|number;
  label?: string;
  trend?: {
    value: string;
    direction: 'none'|'up'|'down';
  }
}

export interface KeywordData {
  kw: string;
  id: string;
  editable: boolean;
  start: number;
  grank: number;
  life: number;
  search_volume: number;
  favorited: boolean,
  managed_by_effektify?: boolean,
  domain: string;
}

export interface KeywordsResponse {
  data: KeywordData[]
  available: number;
  used: number;
}

export type IntegrationStatus = 'active'|'pending_authorization'|'pending_reauthorization'|'pending_account_configuration'|'pending_account_identifier'|'paused'|'stopped';

export interface Integration {
  account_display_name: string|null;
  account_identifier: string;
  id: string;
  integration_type: string;
  status: IntegrationStatus;
  verified_at: string;
  icon_url?: string;
  display_name?: string;
  authentication_configuration?: AuthenticationConfiguration;
  color?: string;
  configurable?: boolean;
}

export type IntegrationAuthenticationMethod = 'oauth2' | 'user_redirect' | 'credentials' | 'facebook_sdk';

export interface AuthenticationConfiguration {
  allow_automatic_creation: boolean;
  allow_creation_new_team: boolean;
  allow_creation: boolean;
  expect_error_return: boolean;
  expect_success_return: boolean;
  method: IntegrationAuthenticationMethod;
  require_client_id: boolean;
  require_client_secret: boolean;
  require_id: boolean;
  require_url: boolean;
  require_key: boolean;
  require_username: boolean;
  require_password: boolean;
  scopes: string[];
  open_in_external_browser?: boolean;
}

export interface IntegrationTypeResponse {
  integration_types: IntegrationType[];
  recommendations: IntegrationRecommendationResponse;
}

export interface IntegrationRecommendationResponse {
  [key: string]: IntegrationRecommendation
}

export interface IntegrationRecommendation {
  highlight: boolean;
  icon_uri?: string;
}

export interface IntegrationType {
  authentication_configuration?: AuthenticationConfiguration;
  category: string;
  category_name: string;
  name: string;
  type: string;
  icon_uri?: string;
  guide_uri?: string|null;
  popular: boolean;
  description: string;
}

export interface CreateIntegrationResponse {
  id: string;
  status: IntegrationStatus;
  verified_at: null|string;
  integration_type: string;
  account_identifier: null|string;
  account_display_name: null|string;
  authorization_url?: string;
  icon_url?: string;
  authentication_configuration?: AuthenticationConfiguration;
}

export interface ReauthorizeIntegrationResponse {
  success: boolean;
  url: string;
}

export interface AccountIdentifier {
  name: string;
  id: string;
}

export interface SuccessResponse {
  success?: boolean;
  message?: string;
}

export interface InitializeAppResponse {
  current_terms_and_conditions_uri: string;
  update_available: boolean;
  force_update: boolean;
  session_token: string;
  features?: AppFeatureList[];
  available_locales?: LanguageList[];
  translations?: TranslationKey;
  links?: Record<string, string | object>;
  sign_up?: InitializeAccountSignup;
  available_countries?: Country[];
  intro: OnboardingIntroConfig;
  authentication: UserAuthenticationConfig;
}

export interface UserAuthenticationConfig {
  methods?: {
    [key: string]: {
      enabled?: boolean;
      configuration: SDKMethodConfig
    }
  }
}

export interface SDKMethodConfig {
  method: string;
  scopes: string[];
  open_in_external_browser?: boolean;
}

export interface OnboardingIntroConfig {
  default_team?: string;
  demo_video_uri?: string;
  slides?: IntroSlide[];
  mode?: 'demo'|'normal';
}

export interface IntroSlide {
  description?: string;
  image_uri?: string;
  title?: string;
}

export interface LanguageList {
  name: string;
  value: string;
}
export interface AppFeatureList {
  enabled: boolean;
  key: string;
  calendly_url?: string;
}

export interface LogoutResponse {
  success: boolean;
  message: string;
}

export interface NotificationResponse {
  id: string;
  subject?: string;
  message: string;
  sent_at: string;
  read_at: string;
}

export interface OrderResponse {
  order_id: string;
  currency?: string;
  customer_name?: string;
  integration_id: string;
  order_time?: string;
  status?: string;
  total_amount?: string;
  flag_url?: string;
}

export interface OrderDetailResponse {
  order_id: string;
  currency: string;
  flag_url: string;
  customer_name: string;
  integration_id: string;
  order_time: string;
  status: string;
  total_amount: string;
  customer: OrderCustomer;
  shipment: OrderShipment;
  products: OrderProduct[];
}

export interface OrderCustomer {
  email: string;
  full_name: string;
  first_name: string;
  last_name: string;
  phone_number: string;
}

export interface OrderShipment {
  address: string;
  city: string;
  country: string;
  delivery_method: string;
  first_name: string;
  full_name: string;
  last_name: string;
  phone_number: string;
  zipcode: string;
  delivery_cost: string;
}

export interface OrderProduct {
  image_url: string;
  title: string;
  price: string;
  quantity: number;
}

export interface ConversionsResponse {
  conversion_date: string;
  conversions: number;
  goal: string;
}

export interface AdsCampaign {
  campaign?: string;
  clicks?: number;
  icon_url?: string;
  conversions?: number;
  cost?: string;
  cost_per_conversion?: string;
  impressions?: string|number;
  reach?: string|number;
  domain?: string;
  source?: string|null;
  source_type?: string|null;
  name?: string;
  uid: string;
  expandable_url: string|null;
  expandable_count: number;
  insight_status: string;
  adSets?: AdsCampaign[];
  status: string;
  mutable?: boolean;
  type?: string;
  stale?: boolean;
  start_date?: string;
  end_date?: string;
  daily_budget?: string;
  error?: null|string;
}

export interface CampaignsAdsData {
  data: AdsCampaign[];
  meta: {
    color: string;
    display_name: string;
    icon_url: string;
  };
  summary: DynamicContainer;
}

export interface CampaignData {
  id: string;
  identifier: string;
  mutable: boolean;
  mutable_config: MutableConfigFields[];
  name: string;
  status: string;
}

export interface MutableConfigFields {
  default: string;
  label: string;
  validation: string;
  options: ActionOption[]
}

export interface CampaignAdsResponse {
  [key: string]: CampaignsAdsData;
}

export interface MyCampaignsResponse {
  ads: CampaignAdsResponse;
  summary: DynamicContainer;
}

export interface Country {
  name: string;
  code: string;
}

export interface InitializeAccountSignup {
  content_url?: string;
  content_type?: string;
  redirect_url?: string;
}

export interface SignupResponse {
  success: boolean;
  jwt: string;
  status: string;
}

export interface UserStatusResponse {
  jwt: string;
  success: boolean;
  status: string;
}

export interface ResendVerificationReponse {
  success: boolean;
  message: string;
}

export interface GetCityByCountryAndPostalResponse {
  result: string;
  success: boolean;
}

export interface HighlightAvailableData {
  available: boolean;
  name: string;
  code: string;
}

export interface TrendAvailableData {
  available: boolean;
  name: string;
  code: string;
}

export interface AvailableHighlightsList {
  success: boolean;
  result: HighlightAvailableData[];
}

export interface AvailableTrendsList {
  success: boolean;
  result: TrendAvailableData[];
}

export interface ActionOption {
  name: string;
  value: string;
}

export interface ActionConfiguration {
  [key: string]: {
    validation?: string;
    max?: number;
    min?: number;
    options?: ActionOption[];
  };
}

export interface ActionProposition {
  available: boolean;
  identifier: string;
  configuration: ActionConfiguration;
}

export interface KeywordActionResponse {
  identifier?: string;
  result?: null;
  status?: string;
}

export interface InsightResponse {
  action?: InsightsAction;
  body: string;
  icon_url: string|null;
  title: string|null;
  value: string|null;
}

export interface InsightsAction {
  type: InsightsActionTypes.click | InsightsActionTypes.button;
  uri: string;
  uri_type: InsightsActionURITypes.internal | InsightsActionURITypes.external;
  text?: string;
}

export interface TranslationKey {
  [key: string]: object;
}

export interface ConfigResponse {
  language: string;
  translations: TranslationKey;
  marketing_seo_content: DynamicMarketingContent;
  features?: AppFeatureList[];
  create_campaign?: CreateCampaignConfig
}

export interface CreateCampaignConfig {
  image_uri: string;
}

export interface Customer {
  address_line_1: null|string;
  address_line_2: null|string;
  business_registration_number: string;
  city: null|string;
  country_code: null|string;
  created_at: string;
  id: string;
  name: string;
  postal_code: null|string;
  vatin: null|string|number;
}

export interface CustomerOrder {
  amount: number;
  amount_formatted: string;
  currency: string;
  date: string;
  foreign_id: string;
  id: string;
  type: string;
}

export interface CustomerOrderDetails extends CustomerOrder {
  items: CustomerOrderItem[];
}

export interface CustomerOrderItem {
  currency: string;
  description: string;
  invoice_start_date: string;
  name: string;
  period_end: string;
  period_start: string;
  price: number;
  quantity: number;
  price_formatted: string;
  product_group: string;
}

export interface AdWizardResponse {
  available_levels: number;
  is_complete: boolean;
  is_valid: boolean;
  request_uid?: string;
  validation: {
    [key: string]: {
      valid: boolean;
    };
  };
  fields: AdWizardField[];
  groups: AdWizardFieldGroup[];
  preview_formats: AdWizardFieldOption[];
}

export interface AdWizardFieldGroup {
  key: string;
  label_translation: string;
  description_translation: string;
  fields: string[];
  info_popup?: boolean
}

export interface AdWizardField {
  autocomplete?: boolean;
  autocomplete_include_fields?: string[];
  field: string;
  type: string;
  freesolo?: boolean;
  group?: string;
  context?: string | string[];
  currency?: string;
  default?: any;
  placeholder?: boolean;
  placeholder_translation? : string;
  data_type?: string;
  description_translation?: string;
  label_translation?: string;
  info_popup?: boolean;
  level?: number;
  mandatory?: boolean;
  step?: number;
  min?: number;
  options?: AdWizardFieldOption[];
  regex?: string;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  [key: string]: any; // Update MinMax validation object structure
  readonly?: boolean;
  dependant_on?: string[];
}

export interface AdWizardFieldOption {
  label_translation: string;
  description_translation: string;
  placeholder_translation?: string;
  available_for?: string[];
  active?: boolean;
  icon_uri?: string;
  currency?: number | string;
  value?: number|string;
  prerequisites?: AdWizardPrerequisiteObject;
  is_valid?: boolean;
}

export interface AdWizardPrerequisiteObject {
  [key: string]: AdWizardPrerequisite;
}

export interface AdWizardPrerequisite {
  description_translation?: string;
  placeholder_translation?: string;
  label_translation?: string;
  name?: string;
  uri?: string|null;
  valid?: boolean|null;
  status?: string;
  integration_type?: string;
  integration_id?: string;
  objectives_requiring_pixel?: string[];
}

export interface MinMaxValidation {
  min: number;
  max: number;
}

export interface AdWizardPreviewResponse {
  [key: string]: AdWizardPreivewObject;
}

interface AdWizardPreivewObject {
  format: 'desktop'|'mobile';
  label_translation: string;
  description_translation: string;
  preview: AdWizardPreivew;
}

// eslint-disable-next-line @typescript-eslint/no-empty-interface, @typescript-eslint/no-empty-object-type
export interface DynamicMarketingContent extends AdWizardPreivew {};

interface AdWizardPreivew {
  html?: string;
  iframe?: AdWizardPreviewIFrame;
  button?: AdWizardPreviewButton;
  refreshable?: boolean;
  mode?: string;
  media?: any;
  positions?: string[];
  content?: any;
}

interface AdWizardPreviewButton {
  uri: string;
  text: string;
}

interface AdWizardPreviewIFrame {
  uri: string;
  width: string;
  height: string;
}

export interface AdWizardPreUpload {
  id: string;
  upload_url?: string;
  uri: string;
}

export interface AdPublishPlatformStatus {
  success: boolean;
  message?: string;
  title?: string;
  status?: string;
  campaigns?: CampaignIDs[]
}

export interface CampaignIDs {
  id: string;
}

interface AdPublishPlatformStatusResults {
  [key: string]: AdPublishPlatformStatus;
}

export interface AdPublishStatusResponse {
  uid: string;
  status: string;
  results: AdPublishPlatformStatusResults;
}

export interface NewsResponse {
  title: string;
  description: string;
  link: string;
  image_uri: string;
  published_at: string;
}

export interface OAuthSessionResponse {
  token: string;
  provider: string;
  action: string;
  status: string;
  error: string | null;
  auth_user: OAuthSessionAuthUser | null;
  error_message: string | null;
  authorization_uri: string | null
}

export interface OAuthSessionAuthUser {
  id: string;
  name: string;
  email: string;
  username: string;
  last_name: string;
  first_name: string;
  locale?: string;
  picture?: string;
  hosted_domain?: string;
}

export interface CampaignTemplateCard {
  id: string;
  name: string;
  created_at?: string;
  status?: string;
  image_uri?: string;
  video_uri?: string;
  link?: string;
  title?: string;
  text?: string;
  platforms?: string[];
  tags?: string[];
  url?: string;
  type?: string;
  texts?: string | string[];
  headlines?: string | string[];
  call_to_action?: string;
  sender?: string;
  turnkey_ads?: CampaignTurnkeyAds[];
}

export interface CampaignTurnkeyAds {
  id: string;
  name: string;
  image_uri: string;
  platforms: string[];
  headlines: string | string[];
  texts: string | string[];
  link: string;
  video_uri?: string;
  call_to_action?: string;
  sender?: string;
  type?: string;
}
