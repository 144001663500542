import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, Input, Output, EventEmitter, OnDestroy } from '@angular/core';
import { IntegrationType, IntegrationTypeResponse, TeamResponse } from 'src/app/models/apiResponse';
import { AppService } from 'src/app/services/app-service/app.service';
import { Config } from 'src/app/services/config';
import { EventsService } from 'src/app/services/events/events.service';
import { StoreIntegrationService } from 'src/app/services/store-integration/store-integration.service';
import { GroupedIntegrationTypes, IntegrationGroup } from 'src/app/models/interface';
import { environment } from 'src/environments/environment';
import { TranslateService } from '@ngx-translate/core';
import { ApiEndpoint } from 'src/app/models/apiRequest';
import { ModalController } from '@ionic/angular';
import { FirebaseCrashlyticsService } from 'src/app/services/firebase-crashlytics/firebase-crashlytics.service';
import { popularFilterKey } from 'src/app/constants/app-constants';

const defaultHeaderSectionHeight = 230;

@Component({
  selector: 'app-connect-store',
  templateUrl: './connect-store.component.html',
  styleUrls: ['./connect-store.component.scss'],
})
export class ConnectStoreComponent implements OnInit, OnDestroy {
  @Input() componentType: string;
  @Output() notifyParent: EventEmitter<string> = new EventEmitter();
  public groupedIntegrationTypes: GroupedIntegrationTypes;
  public integrationTypes: IntegrationType[];
  public popularTypes: IntegrationType[] = [];
  public selectedIntegration: IntegrationGroup;
  public showSkeleton = true;
  public showNoDataAlert = false;
  public selectedTeam: TeamResponse = null;
  public skeletonArray = new Array(4);
  public popularFilterKey = popularFilterKey;
  public warningDivCustomTop = defaultHeaderSectionHeight;
  constructor(
    public appService: AppService,
    private events: EventsService,
    public storeIntegrationService: StoreIntegrationService,
    private translate: TranslateService,
    private modalCtrl: ModalController,
    private crashlyticsService: FirebaseCrashlyticsService
  ) { }

  ngOnInit() {
    this.appService.getSelectedTeam().then((team: TeamResponse) => {
      this.selectedTeam = team;
      this.fetchIntegrationTypes();
    }).catch(async () => {
      await this.appService.hideLoader();
      this.appService.presentToast(this.translate.instant('toast_message.failed_fetch_team'));
    })

    this.subscribeEvents();
    this.crashlyticsService.customizeCrashlytics({ component: 'Connect store' });
  }

  ngOnDestroy() {
    this.events.destroy('integration:listRefresh');
  }

  selectIntegration(option): void {
    this.selectedIntegration = option;
  }

  fetchIntegrationTypes(): void {
    this.popularTypes = [];
    const apiEndpoint: ApiEndpoint = this.appService.getClone(Config.apiUrl.integrationTypes);
    apiEndpoint.url = apiEndpoint.urlStart
        + this.selectedTeam.id + '/' + apiEndpoint.urlEnd;
    this.appService.api({}, apiEndpoint).subscribe({
      next: async (response: IntegrationTypeResponse) => {
        this.showNoDataAlert = false;
        this.showSkeleton = false;
        if (!response?.integration_types || response?.integration_types.length <= 0) {
          this.showNoDataAlert = true;
        } else {
          this.integrationTypes = response.integration_types;
          this.groupedIntegrationTypes =  this.storeIntegrationService.groupIntegrationTypes(response.integration_types, 'category', true);
          this.popularTypes = this.integrationTypes.filter(type => {return !!type?.popular}) || [];
          if (this.popularTypes.length) {
            this.groupedIntegrationTypes[popularFilterKey] = {
              category: popularFilterKey,
              category_name: this.translate.instant('connections.popular'),
              types: this.popularTypes
            }
            this.selectedIntegration = this.groupedIntegrationTypes[popularFilterKey];
          } else {
            this.selectedIntegration = this.groupedIntegrationTypes[Object.keys(this.groupedIntegrationTypes)[0]];
          }
        }
        await this.appService.hideLoader();
      },
      error: async (e: HttpErrorResponse) => {
        if (!e.status || e.status === 500 || e.status === 404) {
          this.showNoDataAlert = true;
        }
        this.showSkeleton = false;
        await this.appService.hideLoader();
      }
    })
  }

  subscribeEvents(): void {
    this.events.subscribe('integration:listRefresh', () => {
      this.notifyParent.emit(); // Notify parent to dismiss connections modal
    });
  }

  openHomeWebsite(): void {
    if (!environment.modifyFeatureOnAppStore) {
      this.appService.openIabUrl(Config.websiteURL);
    }
  }
}
