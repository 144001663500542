import { HttpErrorResponse } from '@angular/common/http';
import { Component, Input, ViewChild } from '@angular/core';
import { ElementRef, OnInit } from '@angular/core';
import { ModalController, SearchbarCustomEvent } from '@ionic/angular';
import { ApiEndpoint } from 'src/app/models/apiRequest';
import { AdWizardField } from 'src/app/models/apiResponse';
import { AppService } from 'src/app/services/app-service/app.service';
import { Config } from 'src/app/services/config';

const searchTimeoutDelay = 1000;
@Component({
    selector: 'app-adwizard-autocomplete',
    templateUrl: './adwizard-autocomplete.component.html',
    styleUrls: ['./adwizard-autocomplete.component.scss'],
    standalone: false
})

export class AdwizardAutocompleteComponent implements OnInit {

  @Input() title;
  @Input() field: AdWizardField;
  @Input() searchValue;
  @Input() adwizardPayload;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  filtered: any = [];
  private selectedTeam;
  public skeletonArray = new Array(15);
  public showSkeleton = false;
  public searchTimeout: ReturnType<typeof setTimeout>;
  @ViewChild('autocompleteInput', { read: ElementRef }) private autocompleteInput: ElementRef;

  constructor(
    private appService: AppService,
    public modalController: ModalController
  ) { }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  getURLParamValues(value: any): string {
    if (Array.isArray(value)) {
      if (value.some(item => typeof item === 'object')) {
        // Array of objects
        return JSON.stringify(value);
      } else {
        // Array of non-object values
        return value.join(',');
      }
    } else if (typeof value === 'object' && !Array.isArray(value)) {
      // Single object
      return JSON.stringify(value);
    } else {
      // Primitive value
      return value ? value.toString() : '';
    }
  }

  ngOnInit() {
    this.selectedTeam = this.appService.getTeam();
    this.focusInput();
    this.getResults(this.searchValue);
  }

  focusInput() {
    setTimeout(() => {
      this.autocompleteInput.nativeElement.setFocus();
    }, 0);
  }

  async getResults(searchKey: string) {
      const searchParams = new URLSearchParams();
      searchParams.append('field', this.field.field)
      searchParams.append('search', searchKey)

      if (this.field.autocomplete_include_fields) {
        this.field.autocomplete_include_fields.forEach(include_field => {
          searchParams.append(include_field, this.getURLParamValues(this.adwizardPayload.state[include_field]));
        });
      }

      const apiEndpoint: ApiEndpoint = this.appService.getClone(Config.apiUrl.adWizardAutoComplete);
      apiEndpoint.url = apiEndpoint.urlStart + this.selectedTeam.id + apiEndpoint.urlEnd + '?' + searchParams.toString();

      this.showSkeleton = true;

      this.appService.api({}, apiEndpoint).subscribe({
        next: async (results: unknown) => {
          this.filtered = results;
          this.focusInput();
          this.showSkeleton = false;
        },
        error: async (e: HttpErrorResponse) => {
          this.filtered = [];
          this.focusInput();
          this.showSkeleton = false;
          if (e.error.message) { this.appService.presentToast(e.error.message) };
        }
      })
  }

  close(): void {
    this.modalController.dismiss();
  }

  itemSelected(item): void {
    this.modalController.dismiss({ item });
  }

  filter(event: SearchbarCustomEvent): void {
    const filter = event.detail.value.toLowerCase();
    clearTimeout(this.searchTimeout);
    this.showSkeleton = true
    this.searchTimeout = setTimeout(() => {
      this.getResults(filter);
    }, searchTimeoutDelay);
  }

  shouldShowUserInputOption(): boolean {
    const isFreesolo = this.field.freesolo;
    const hasSearchValue = !!this.searchValue;
    const noFilteredResults = this.filtered.length === 0;
    const noExactMatch = this.filtered.length > 0 &&
                         !this.filtered.some(f => f.value.toLowerCase() === this.searchValue.toLowerCase());

    if (isFreesolo && hasSearchValue) {
      if (noFilteredResults || noExactMatch) {
        return true;
      }
    }
    return false;
  }
}
