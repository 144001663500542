import { Injectable } from '@angular/core';
import { DateFilters, PeriodFilterParams } from 'src/app/core/enum';
import { OverviewPayload, sortOptions } from 'src/app/models/apiRequest';
import { AppService } from '../app-service/app.service';
import { Subject } from 'rxjs';
import { Router } from '@angular/router';
import { sortAsc, sortDesc } from 'src/app/constants/app-constants';

@Injectable({
  providedIn: 'root'
})
export class FilterService {
  public filter = DateFilters.month;
  public dateFilters = DateFilters;
  // TODO - Need to update the logic when custom filter added
  public dateFilterKeys = Object.keys(DateFilters).filter((key, index, array) => index < array.length - 1);
  public selectedFilterIndex: number;
  public period_start;
  public period_end;
  
  private triggerFunctionSubject = new Subject<string>();
  triggerFunction$ = this.triggerFunctionSubject.asObservable();

  constructor(
    private router: Router,
    public appService: AppService
  ) {
  }
  
  triggerFunction(): void {
    const currentPath = this.router.url;
    this.triggerFunctionSubject.next(currentPath);
  }

  async changeFilter(filter: DateFilters): Promise<void> {
    if (this.filter !== filter) {
      this.period_start = undefined;
      this.period_end = undefined;
      this.filter = filter;
      await this.appService.showLoader();
      this.triggerFunction();
    }
  }
  
  getRequestPayload(): OverviewPayload {
    if (this.filter === DateFilters.day) {
      this.period_start = this.period_start ? this.period_start : this.getPeriodDate(PeriodFilterParams.yesterday);
      this.period_end = this.period_end ? this.period_end : this.getPeriodDate(PeriodFilterParams.yesterday);
    } else if (this.filter === DateFilters.week) {
      this.period_start = this.period_start ? this.period_start : this.getPeriodDate(PeriodFilterParams.weekStart);
      this.period_end = this.period_end ? this.period_end : this.getPeriodDate(PeriodFilterParams.weekEnd);
    } else if (this.filter === DateFilters.month) {
      this.period_start = this.period_start ? this.period_start : this.getPeriodDate(PeriodFilterParams.monthStart);
      this.period_end = this.period_end ? this.period_end : this.getPeriodDate(PeriodFilterParams.monthEnd);
    } else if (this.filter === DateFilters.year) {
      this.period_start = this.period_start ? this.period_start : this.getPeriodDate(PeriodFilterParams.yearStart);
      this.period_end = this.period_end ? this.period_end : this.getPeriodDate(PeriodFilterParams.yearEnd);
    } 

    return {
      period_start: this.period_start,
      period_end: this.period_end,
    }
  }

  canSeekFilterNext() {
    if (this.period_end) {
      const inputDate = new Date(this.period_end?.replace(/-/g, "/"));

      const today = new Date();

      // Set the time of today to 00:00:00 to ignore the time part in comparison
      today.setHours(0, 0, 0, 0);

      return inputDate < today;
    }
  }

  seekFilterArrow(seekBack = false): void {
    if (!seekBack && !this.canSeekFilterNext()) {
      return;
    }
    this.period_start = this.seekFilter(this.period_start, seekBack, true);
    this.period_end = this.seekFilter(this.period_end, seekBack, false);
    this.appService.showLoader();
    this.triggerFunction();
  }

  seekFilter(customDate: string, seekBack: boolean, isStartDate: boolean): string {
    let date = new Date(customDate.replace(/-/g, "/"));
    switch (this.filter) {
      case DateFilters.day:
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        seekBack ? date.setDate(date.getDate() - 1) : date.setDate(date.getDate() + 1);
        break;
      case DateFilters.week:
        // eslint-disable-next-line @typescript-eslint/no-unused-expressions
        seekBack ? date.setDate(date.getDate() - 7) : date.setDate(date.getDate() + 7);
        break;
      case DateFilters.month:
        if (isStartDate) {
          date = seekBack ? new Date(date.getFullYear(), date.getMonth() - 1, 1) : new Date(date.getFullYear(), date.getMonth() + 1, 1);
        } else {
          date = seekBack ? new Date(date.getFullYear(), date.getMonth() + 0, 0) : new Date(date.getFullYear(), date.getMonth() + 2, 0);
        }
        break;
      case DateFilters.year:
        if (isStartDate) {
          date = seekBack ? new Date(date.getFullYear() - 1, 0, 1) : new Date(date.getFullYear() + 1, 0, 1);
        } else {
          date = seekBack ? new Date(date.getFullYear() - 1, 11, 31) : new Date(date.getFullYear() + 1, 11, 31);
        }
        break;
      default:
        break;
    }

    return date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate();
  }


  // Sorting
  get sortAscending() {
    return sortAsc;
  }

  get sortDescending() {
    return sortDesc;
  }

  sortItems(sortOptions: sortOptions) {
    // Sort the keywords based on the current column and direction
    sortOptions.items.sort((firstItem, secondItem) => {
      let firstValue = firstItem[sortOptions.column];
      let secondValue = secondItem[sortOptions.column];
      
       // customSort - Assume -1 as a high value
      if (sortOptions.customSort) {
        firstValue = firstValue === -1 ? 101 : firstValue;
        secondValue = secondValue === -1 ? 101 : secondValue;
      }
  
      return sortOptions.order === sortAsc ? firstValue - secondValue : secondValue - firstValue;
    });
    
    return sortOptions.items;
  }

    getPeriodDate(type: string): string {
      let date = new Date();
      switch (type) {
        case PeriodFilterParams.today:
          date.setDate(date.getDate());
          break;
        case PeriodFilterParams.yesterday:
          date.setDate(date.getDate()-1);
          break;
        case PeriodFilterParams.weekStart:
          // eslint-disable-next-line no-var
          var day = date.getDay(),
            diff = date.getDate() - day + (day == 0 ? -6 : 1);
          date.setDate(diff);
          break;
        case PeriodFilterParams.weekEnd:
          // eslint-disable-next-line no-var
          var day = date.getDay(),
            diff = (date.getDate() - day + (day == 0 ? -6 : 1)) + 6;
          date.setDate(diff);
          break;
        case PeriodFilterParams.monthStart:
          date = new Date(date.getFullYear(), date.getMonth(), 1);
          break;
        case PeriodFilterParams.monthEnd:
          date = new Date(date.getFullYear(), date.getMonth() + 1, 0);
          break;
        case PeriodFilterParams.yearStart:
          date = new Date(new Date().getFullYear(), 0, 1);
          break;
        case PeriodFilterParams.yearEnd:
          date = new Date(new Date().getFullYear(), 11, 31);
          break;
        default:
          break;
      }
  
      return date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate();
    }
}
