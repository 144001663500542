import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-minus-circle',
  templateUrl: './minus-circle.component.html',
  styleUrls: ['./minus-circle.component.scss'],
})
export class MinusCircleComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
