<ng-container *ngIf="title; else menuTemplate">
  <div class="pop-header notch-space menu-bar-available">
    <ng-container *ngTemplateOutlet="menuTemplate"></ng-container>
  </div>
</ng-container>

<ng-template #menuTemplate>
    <h2 *ngIf="title">{{ title }}</h2>
    <ion-menu-button mode="ios" autoHide="false">
      <div class="user-menu-section">
        <div class="menu-icon">
          <app-menu-toggle></app-menu-toggle>
        </div>
        <ion-avatar class="user-image" [style.background-image]="'url('+appService.getUserDetail()?.avatar_url+')'"></ion-avatar>
      </div>
    </ion-menu-button>
</ng-template>
