import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-social-icons',
    templateUrl: './social-icons.component.html',
    styleUrls: ['./social-icons.component.scss'],
    standalone: false
})
export class SocialIconsComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
