import { Component, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { Observable } from 'rxjs';
import { TranslateService } from '@ngx-translate/core';
import { AppService } from 'src/app/services/app-service/app.service';
import { IntercomService } from 'src/app/services/intercom/intercom.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-terms-conditions',
  templateUrl: './terms-conditions.component.html',
  styleUrls: ['./terms-conditions.component.scss'],
})
export class TermsConditionsComponent implements OnInit {

  public termsAndConditions = '';
  constructor(
    private modalController: ModalController,
    public appService: AppService,
    public intercomService: IntercomService,
    private translate: TranslateService,
    private httpClient: HttpClient,
    ) { }

  ngOnInit() {
    this.showTermsAndConditions();
  }

  close() {
    this.modalController.dismiss();
  }

  async showTermsAndConditions(): Promise<void> {
    await this.appService.showLoader();
    this.intercomService.hideIntercomLauncher();
    this.getTermsAndConditions(this.appService.getTermsAndConditionsURL()).subscribe (async (text: string) => {
      await this.appService.hideLoader();
      if (text) {
        this.termsAndConditions = text;
      }
    }, async () => {
      await this.appService.hideLoader();
      this.appService.presentToast(this.translate.instant('error_messages.failed_load_terms'));
    })
  }

  getTermsAndConditions(url): Observable<string> {
    return this.httpClient.get(url, {responseType: 'text'});
  }

}
