import {
  Component,
  OnInit,
  ElementRef,
  ViewChild,
  Input,
  Output,
  EventEmitter
} from '@angular/core';
import {
  OrderDetailResponse,
  OrderResponse
} from 'src/app/models/apiResponse';
import { AppService } from 'src/app/services/app-service/app.service';
import { ApiEndpoint } from 'src/app/models/apiRequest';
import { Config } from 'src/app/services/config';
import { HttpErrorResponse } from '@angular/common/http';
import { OrderStatus } from 'src/app/core/enum';
import { FirebaseCrashlyticsService } from 'src/app/services/firebase-crashlytics/firebase-crashlytics.service';

const defaultSectionTemplateHeight = 228;

@Component({
  selector: 'app-order-detail',
  templateUrl: './order-detail.component.html',
  styleUrls: ['./order-detail.component.scss'],
})
export class OrderDetailComponent  implements OnInit {

  @ViewChild('sectionHeader') private sectionHeaderElement: ElementRef;
  public showNoDataAlert = false;
  public showInternalServerErrorAlert = false;
  public showSkeleton = true;
  public orderDetails: OrderDetailResponse;
  public orderStatus = OrderStatus;
  public warningDivCustomTop: number;
  public productsSeletonArray = new Array(2);
  public detailsSeletonArray = new Array(4);
  config = Config;
  @Input() order: OrderResponse;
  @Output() orderEvent = new EventEmitter();
  @Output() orderScrollEvent = new EventEmitter();
  constructor(
    public appService: AppService,
    private crashlyticsService: FirebaseCrashlyticsService
  ) { }

  ngOnInit(): void {
    // eslint-disable-next-line @typescript-eslint/no-unused-expressions
    this.order ? this.fetchOrderDetails(false) : this.close();
    this.crashlyticsService.customizeCrashlytics({ page: 'Order detail' });
    this.orderScrollEvent.emit();
  }

  close(): void {
    this.orderEvent.emit();
  }

  async fetchOrderDetails(loader: boolean = true): Promise<void> {
    this.warningDivCustomTop = (this.sectionHeaderElement?.nativeElement?.offsetHeight || defaultSectionTemplateHeight);
    const apiEndpoint: ApiEndpoint = this.appService.getClone(Config.apiUrl.orderDetail);
    apiEndpoint.url = apiEndpoint.urlStart
      + this.order.integration_id + apiEndpoint.urlEnd + '/' + this.order.order_id;
    if (loader) {
      await this.appService.showLoader();
    }
    this.appService.api({}, apiEndpoint).subscribe({
      next: async (orderDetails: OrderDetailResponse) => {
        this.showNoDataAlert = false;
        this.showInternalServerErrorAlert = false;
        this.showSkeleton = false;
        if (Object.entries(orderDetails).length === 0) {
          this.showNoDataAlert = true;
        } else {
          this.orderDetails = orderDetails;
        }
        await this.appService.hideLoader();
      },
      error:async (e: HttpErrorResponse) => {
        if (!e.status || e.status === 500 || e.status === 404) {
          this.showInternalServerErrorAlert = true;
        }
        this.showSkeleton = false;
        await this.appService.hideLoader();
      }
    })
  }

  async doRefresh(): Promise<void> {
    this.fetchOrderDetails(false);
  }
}
