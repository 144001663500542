<ion-content #swipeContent class="grey-bg no-border-radius connected-sources-header">
  <div class="pop-header notch-space title-with-button">
    <div class="close" (click)="dismiss()">
      <app-back-icon></app-back-icon>
    </div>
    <h2>{{ 'connections.all_sources' | translate}}</h2>
  </div>
  <div class="connect-sources-info">
    <p><markdown [data]="'connections.all_sources_title_description' | translate"></markdown></p>
  </div>
  <div class="wave-header"></div>
  <app-connect-store (notifyParent)="dismiss()"></app-connect-store>
</ion-content>
