<!-- <svg width="22px" height="20px" viewBox="0 0 22 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
    <g id="EFFEKTIFY" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
        <g id="Home" transform="translate(-56.000000, -762.000000)" fill="#000000">
            <polygon id="Fill-1" points="66.8333333 762 56 772.001333 59.2493333 772.001333 59.2493333 782 64.332 782 64.332 775.333333 69.3333333 775.333333 69.3333333 782 74.416 782 74.416 772.001333 77.6666667 772.001333"></polygon>
        </g>
    </g>
</svg> -->

<svg width="18" height="18" viewBox="0 0 18 18" fill="none" xmlns="http://www.w3.org/2000/svg">
    <rect x="1.40625" y="1.39648" width="5.8033" height="5.8033" rx="0.75" stroke="black" stroke-width="1.5"/>
    <rect x="1.40625" y="10.7319" width="5.8033" height="5.8033" rx="0.75" stroke="black" stroke-width="1.5"/>
    <rect x="10.7622" y="1.39648" width="5.8033" height="5.8033" rx="0.75" stroke="black" stroke-width="1.5"/>
    <rect x="10.7622" y="10.7319" width="5.8033" height="5.8033" rx="0.75" stroke="black" stroke-width="1.5"/>
    </svg>
    