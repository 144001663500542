// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `svg circle.fill {
  fill: var(--ion-text-color, none);
}
svg circle.stroke {
  stroke: var(--ion-text-color, none);
}
svg path {
  stroke: var(--ion-text-color, none);
}`, "",{"version":3,"sources":["webpack://./src/app/components/svg/info-icon/info-icon.component.scss"],"names":[],"mappings":"AAOI;EACE,iCAAA;AANN;AAQI;EACI,mCAAA;AANR;AAQI;EACE,mCAAA;AANN","sourcesContent":["// svg {\n//     width: 15px;\n//     height: auto;\n//     fill: var(--ion-color-primary);\n// }\n\nsvg {\n    circle.fill{\n      fill: var(--ion-text-color, none); // Use 'none' as fallback\n    }\n    circle.stroke{\n        stroke: var(--ion-text-color, none); // Use 'none' as fallback\n    }\n    path {\n      stroke: var(--ion-text-color, none); // Use 'none' as fallback\n    }\n  }"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
