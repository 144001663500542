/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { ModalController } from '@ionic/angular';
import { AdwizardPlatforms } from 'src/app/core/enum';
import { CampaignTurnkeyAds } from 'src/app/models/apiResponse';
import { AppService } from 'src/app/services/app-service/app.service';

@Component({
    selector: 'app-campaigns-preview',
    templateUrl: './campaigns-preview.component.html',
    styleUrls: ['./campaigns-preview.component.scss'],
    standalone: false
})
export class CampaignsPreviewComponent  implements OnInit {

  @Input() selectedAd: CampaignTurnkeyAds | null;

  public adwizardPlatforms = AdwizardPlatforms
  constructor(
    public modalController: ModalController,
    private router: Router,
    public appService: AppService
  ) { }

  ngOnInit() {}

  close(): void {
    this.modalController.dismiss();
  }

  openAdwizard(): void {
    this.close();
    this.router.navigate([this.appService.appRoutes.adWizardPage], { state: { adId: this.selectedAd?.id } });
  }

}
