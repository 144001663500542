<ion-header class="custom-header ion-no-border" [translucent]="true" #sectionHeader>
  <div class="heading-bar pop-header notch-space title-with-button">
    <div class="close">
      <app-back-icon (click)="close()"></app-back-icon>
    </div>
    <h2>{{'integration_configurations.title' | translate}}</h2>
  </div>
</ion-header>

<ion-content class="grey-bg no-border-radius" #content>
  <div class="web-content ad-wizard-page-web configuration-section-header">
    <div class="questions-section">
      <div class="form-area" *ngIf="configFields && configFields.length > 0">
        <form [formGroup]="configFieldsForm">

          <ng-container *ngFor="let field of configFields">

            <div class="fields-section">
              <ng-container *ngTemplateOutlet="formTemplate; context: { form: configFieldsForm, field: field, isFormSubmitted: configFieldsFormSubmitted }"></ng-container>
            </div>
          </ng-container>

        </form>
      </div>
    </div>
  </div>
</ion-content>

<ion-footer>
  <div class="toolbar">
    <div class="footer">
      <div class="button-section">
        <ion-button class="custom-btn-create" shape="round" expand="block" (click)="submitFields()">
          {{ 'buttons.save' | translate }}
        </ion-button>
      </div>
    </div>
  </div>
</ion-footer>

<ng-template #fieldLabelTemplate let-field="field">
  <div class="label">
    <div>
      {{field.label_translation}}
      <span *ngIf="field.mandatory"> * </span>
      <span class="info-icon-section" *ngIf="field.info_popup" (click)="appService.showInformationPopup(field.description_translation, true)"><app-info-icon></app-info-icon></span>
    </div>
  </div>
  
</ng-template>
<ng-template #formTemplate let-field="field" let-form="form" let-isFormSubmitted="isFormSubmitted">

  <div class="form-lable-sec" *ngIf="field.type === 'input' && !field.autocomplete">
    <ng-container *ngTemplateOutlet="fieldLabelTemplate; context: { field: field }"></ng-container>
    <div class="form-cover-single">
      <ion-list>
        <ion-item lines="none">
          <ion-input *ngIf="!field.default" [formControl]="form.get(field.field)" [placeholder]="getPlaceholder(field)"></ion-input>
          
          <ion-input *ngIf="field.default" [readonly]="field.readonly">
            {{ getControlValue(form, field.field, -1) }}
          </ion-input>
        </ion-item>
      </ion-list>
    </div>
    <ion-note color="danger" class="error" slot="error" *ngIf="isFormSubmitted && form.get(field.field).invalid">
      {{ getFormFieldError(form, field) }}
    </ion-note>
  </div>

  <div class="form-lable-sec web-options-align" *ngIf="field.type === 'combo_box' && !field.autocomplete">
    <ng-container *ngTemplateOutlet="fieldLabelTemplate; context: { field: field }"></ng-container>
    <div class="form-cover-single">
      <ion-list>
        <ion-item lines="none">
          <ion-select class="custom-select" interface="action-sheet" justify="space-between" [formControl]="form.get(field.field)" [interfaceOptions]="{cssClass: 'custom-action-sheet', header: field.label_translation}" mode="md" [placeholder]="getPlaceholder(field)">
            <ion-select-option *ngFor="let option of field.options | keyvalue" [value]="option.key">
              {{ getOptionValueLabel(option.value) }}
            </ion-select-option>
          </ion-select>
        </ion-item>
      </ion-list>
    </div>
    <ion-note color="danger" class="error" slot="error" *ngIf="isFormSubmitted && form.get(field.field).invalid">
      {{ field.label_translation }} {{'adwizard.is_required' | translate | lowercase}}
    </ion-note>
  </div>
  
  <div class="form-lable-sec web-options-align" *ngIf="field.type === 'multiselect' && !field.autocomplete">
    <ng-container *ngTemplateOutlet="fieldLabelTemplate; context: { field: field }"></ng-container>
    <div class="form-cover-single">
      <ion-list>
        <ion-item lines="none" (click)="this.appService.showLoader(); searchableSelect.open();">
          <app-searchable-select #searchableSelect
            [title]="field.label_translation"
            [data]="field.options"
            searchField="label_translation"
            [showPlaceholder]="true"
            [placeholderText]="getPlaceholder(field)"
            [multiple]="true"
            [default]="form.get(field.field).value"
            (selectedChanged)="selectSearchable(field.field, $event)"
          >
          </app-searchable-select>
        </ion-item>
      </ion-list>
    </div>
    <ion-note color="danger" class="error" slot="error" *ngIf="isFormSubmitted && form.get(field.field).invalid">
      {{ field.label_translation }} {{'adwizard.is_required' | translate | lowercase}}
    </ion-note>
  </div>

  <div class="form-lable-sec" *ngIf="field.type === 'multiinput' && !field.autocomplete">
    <div class="label">
      <div>
        {{field.label_translation}}
        <span *ngIf="field.mandatory"> * </span>
        <span class="info-icon-section" *ngIf="field.info_popup" (click)="appService.showInformationPopup(field.description_translation, true)"><app-info-icon></app-info-icon></span>
      </div>
      <div>
        <span class="add-button button-icon" slot="end" aria-hidden="true" *ngIf="canAddMultiInputs(form.get(field.field).length, field.field)" (click)="addInput(form, field.field, $event)">
          <app-plus-circle></app-plus-circle>
        </span>
      </div>
    </div>
    <div class="form-cover-single">
      <ion-list>
        <ion-item lines="none" *ngFor="let input of getInputs(form, field.field).controls; let i = index" [formGroup]="input"
        [class.error-border]="isFormSubmitted && input.get('value').hasError('pattern')">
          <ion-input *ngIf="field.default && i === 0" [readonly]="field.readonly && i === 0" [placeholder]="getPlaceholder(field)">
            {{ getControlValue(form, field.field, -1, i) }}
          </ion-input>
          <ion-input *ngIf="!field.default || (field.default && i > 0)" formControlName="value" [placeholder]="getPlaceholder(field)">
          </ion-input>
          <div class="button-icon" slot="end" aria-hidden="true" *ngIf="canRemoveMultiInputs(form.get(field.field).length) && !(field.readonly && i === 0)" (click)="removeInput(form, field.field, i, $event)">
            <app-minus-circle></app-minus-circle>
          </div>
          </ion-item>
      </ion-list>
    </div>
    <ion-note color="danger" class="error" slot="error" *ngIf="isFormSubmitted && form.get(field.field).invalid">
      {{ field.label_translation }} {{'adwizard.is_required' | translate | lowercase}}
    </ion-note>
  </div>
  
  <!-- <div class="form-lable-sec" *ngIf="field.type === 'multiselect' && !field.autocomplete">
    <div class="label">
      <div>
        {{field.label_translation}}
        <span *ngIf="field.mandatory"> * </span>
        <span class="info-icon-section" *ngIf="field.info_popup" (click)="appService.showInformationPopup(field.description_translation, true)"><app-info-icon></app-info-icon></span>
      </div>
      <div>
        <span class="add-button button-icon" slot="end" aria-hidden="true" *ngIf="canAddMultiInputs(form.get(field.field).length, field.field)" (click)="addInput(form, field.field, $event)">
          <app-plus-circle></app-plus-circle>
        </span>
      </div>
    </div>
    <div class="form-cover-single">
      <ion-list>
        <ion-item lines="none" *ngFor="let input of getInputs(form, field.field).controls; let i = index" [formGroup]="input"
        [class.error-border]="isFormSubmitted && input.get('value').hasError('pattern')">
          <ion-select class="custom-select" interface="action-sheet" justify="space-between" formControlName="value" [interfaceOptions]="{cssClass: 'custom-action-sheet', header: field.label_translation}" mode="md" [placeholder]="getPlaceholder(field)">
            <ion-select-option *ngFor="let option of field.options | keyvalue" [value]="option.key">
              {{ getOptionValueLabel(option.value) }}
            </ion-select-option>
          </ion-select>
          <div class="button-icon" slot="end" aria-hidden="true" *ngIf="canRemoveMultiInputs(form.get(field.field).length) && !(field.readonly && i === 0)" (click)="removeInput(form, field.field, i, $event)">
            <app-minus-circle></app-minus-circle>
          </div>
          </ion-item>
      </ion-list>
    </div>
    <ion-note color="danger" class="error" slot="error" *ngIf="isFormSubmitted && form.get(field.field).invalid">
      {{ field.label_translation }} {{'adwizard.is_required' | translate | lowercase}}
    </ion-note>
  </div> -->

</ng-template>