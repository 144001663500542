import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-cheveron-right',
  templateUrl: './cheveron-right.component.html',
  styleUrls: ['./cheveron-right.component.scss'],
})
export class CheveronRightComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
