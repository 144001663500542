import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'app-connection-plug-icon',
  templateUrl: './connection-plug-icon.component.html',
  styleUrls: ['./connection-plug-icon.component.scss'],
})
export class ConnectionPlugIconComponent  implements OnInit {

  @Input() iconSize: string = "26";

  constructor() { }

  ngOnInit() {}

}
