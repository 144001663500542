import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-app-icon',
    templateUrl: './app-icon.component.html',
    styleUrls: ['./app-icon.component.scss'],
    standalone: false
})
export class AppIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
