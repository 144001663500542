import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-online-icon',
  templateUrl: './online-icon.component.html',
  styleUrls: ['./online-icon.component.scss'],
})
export class OnlineIconComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
