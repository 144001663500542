import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-back-icon-ad',
  templateUrl: './back-icon-ad.component.html',
  styleUrls: ['./back-icon-ad.component.scss'],
})
export class BackIconAdComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
