<svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39">
  <g id="Group_2429" data-name="Group 2429" transform="translate(-45 -358)">
    <g id="Ellipse_6" data-name="Ellipse 6" transform="translate(45 358)" fill="#e0f9f5" stroke="#79d9ca" stroke-width="1" stroke-dasharray="2">
      <circle cx="19.5" cy="19.5" r="19.5" stroke="none"/>
      <circle cx="19.5" cy="19.5" r="19" fill="none"/>
    </g>
    <path id="check" d="M7.228,80.657.312,73.74a1.064,1.064,0,0,1,0-1.5l1.5-1.5a1.064,1.064,0,0,1,1.5,0L7.981,75.39l9.98-9.98a1.064,1.064,0,0,1,1.5,0l1.5,1.5a1.064,1.064,0,0,1,0,1.5L8.733,80.657a1.064,1.064,0,0,1-1.5,0Z" transform="translate(54 304.902)" fill="#79d9ca"/>
  </g>
</svg>

