import { Injectable, NgZone } from '@angular/core';
import {
  ActionPerformed,
  PushNotifications,
  Token,
} from '@capacitor/push-notifications';
import { AppService } from '../app-service/app.service';
import { FcmTokenPayload } from 'src/app/models/apiRequest';
import { Config } from '../config';
import { HttpErrorResponse } from '@angular/common/http';
import { NavController } from '@ionic/angular';
import { EventsService } from '../events/events.service';
import { TranslateService } from '@ngx-translate/core';

@Injectable({
  providedIn: 'root'
})
export class FcmService {

  constructor(
    private appService: AppService,
    private navCtrl: NavController,
    private events: EventsService,
    private translate: TranslateService,
    private zone: NgZone
  ) { }

  initPush() {
    if (this.appService.isNativePlatform) {
      this.registerPushNotification();
    }
  }

  async registerPushNotification() {
    let permissionStatus = await PushNotifications.checkPermissions();

    if (permissionStatus.receive === 'prompt') {
      permissionStatus = await PushNotifications.requestPermissions();
    }

    if (permissionStatus.receive === 'granted') {
      PushNotifications.register();
    }

    PushNotifications.addListener('registration',
      (token: Token) => {
        const payload: FcmTokenPayload = {token: token.value}
        this.appService.setStorage('fcm_token', token.value);
        this.appService.api(payload, Config.apiUrl.initializeFcmToken).subscribe({
          error: async (e: HttpErrorResponse) => {
            console.error(e);
          }
        })
      }
    );

    PushNotifications.addListener('registrationError',
      () => {
        this.appService.presentToast(this.translate.instant('toast_message.push_notification_issue'));
      }
    );

    PushNotifications.addListener('pushNotificationReceived',
      () => {
        this.events.publish('notifications:update:notificationPage');
        setTimeout(() => {
          PushNotifications.removeAllDeliveredNotifications(); // Clear notifications if app is open
        }, 5000)
        /*
          @Todo
          Code section to handle when notification received
        */
      }
    );

    PushNotifications.addListener('pushNotificationActionPerformed',
      (notification: ActionPerformed) => {
        this.zone.run(() => {
          // Added navigation only of the users are already logged in the app
          if (notification.notification.data.type === 'click' && this.appService.isLoggedIn()) {
            setTimeout(async () => {  
              this.appService.hideLoader();            
              this.navCtrl.navigateForward(notification.notification.data.uri);
            }, 1000)
          }
        })
      }
    );
  }

  clearPushNotifications(): void {
    if (this.appService.isNativePlatform) {
      PushNotifications.removeAllDeliveredNotifications();
    }
  }

  /*
    Todo
    Handle page navigation on notification action click
  */
  private async navigatePage(url: string) {
    setTimeout(async () => {
      await this.appService.hideLoader();
      this.navCtrl.navigateRoot(url);
    }, 300);
  }
}
