import { Component, Input, OnInit } from '@angular/core';
import { defaultChartSegmentColor, defaultDashedTooltipColor, dynamicChartBackgroundColor, dynamicChartBorderColor, gridLineColor, lineChartBorderColor } from 'src/app/constants/app-constants';
import { ContainerCard, ContainerChartData } from 'src/app/models/apiResponse';
import { Chart, registerables } from 'chart.js';
import { ChartTypes } from 'src/app/core/enum';

Chart.register(...registerables);

@Component({
    selector: 'app-graph',
    templateUrl: './graph.component.html',
    styleUrls: ['./graph.component.scss'],
    standalone: false
})
export class GraphComponent  implements OnInit {
  @Input() containerCard: ContainerCard;
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  tooltipLine: any;
  activeChartPoint: ContainerChartData;
  activeChartPointIndex: number|null;
  public chart = undefined;
  constructor() { }

  ngOnInit() {
    //Custom tooltip line plugin for draggable feature
    this.tooltipLine = {
      id: 'tooltipLine',
      beforeDraw: (chart) => {
        if (chart.tooltip?._active && chart.tooltip?._active?.length) {
          const {ctx} = chart;
          ctx.save();
          const activePoint = chart.tooltip._active[0];

          //CTX dashed tooltip line
          ctx.beginPath();
          ctx.setLineDash([5, 7]);
          ctx.moveTo(activePoint.element.x, chart.chartArea.top);
          ctx.lineTo(activePoint.element.x, chart.chartArea.bottom);
          ctx.lineWidth = 1;
          ctx.strokeStyle = defaultDashedTooltipColor;
          ctx.stroke();
          ctx.restore();

          //Update chart segment based on active point
          if (activePoint) {
            this.activeChartPointIndex = activePoint.index;
            this.activeChartPoint = this.containerCard.chart.data[activePoint.index];
            this.updateChartSegmentData();
          }
        }
      }
    }
    this.renderContainerChart();
  }

  renderContainerChart(): void {
    this.chart = undefined;
    setTimeout(() => {
      const containerChartData = this.containerCard?.chart?.data;
      const labels = containerChartData.map((data: ContainerChartData) => data.name);
      const plotData = containerChartData.map((data: ContainerChartData) => data.value);
      const chartData = {
        labels,
        datasets: [{
          borderWidth: 2,
          data: plotData,
          lineTension: 0.5,
          pointRadius: 0,
          pointHoverRadius: 0,
          pointBorderColor: dynamicChartBorderColor,
          pointBackgroundColor: dynamicChartBackgroundColor,
          borderColor: dynamicChartBorderColor,
          segment: {
            borderColor: (ctx) => this.getChartSegementColor(ctx, dynamicChartBorderColor),
            backgroundColor: (ctx) => this.getChartSegementColor(ctx, dynamicChartBackgroundColor)
          },
        }]
      }
      const chartCanvas: HTMLElement = document?.getElementById(this.containerCard.id);
      if (chartCanvas) {
        this.chart = new Chart(chartCanvas as HTMLCanvasElement, {
          type: ChartTypes[this.containerCard.chart.type],
          plugins: this.containerCard.chart?.configuration?.draggable ? [this.tooltipLine] : [],
          data: chartData,
          options: {
            interaction: {
              mode: 'nearest',
              axis: 'x',
              intersect: false,
            },
            onClick: (e, activeEls) => {
              if (this.containerCard.chart?.configuration?.draggable) {
                const dataIndex = activeEls[0]?.index;
                if (dataIndex >= 0) {
                  this.activeChartPointIndex = dataIndex;
                  this.activeChartPointIndex = dataIndex;
                  this.activeChartPoint = this.chart.data[dataIndex];
                  this.updateChartSegmentData();
                } else {
                  this.resetActiveChartPoint();
                }
              }
            },
            maintainAspectRatio: false,
            plugins: {
              legend: {
                display: false,
              },
              tooltip: {
                enabled: false,
              }
            },
            scales: {
              y: {
                beginAtZero: true,
                position: 'right',
                ticks: {
                  padding: 10,
                  stepSize: this.getStepSize(containerChartData),
                  display: this.containerCard?.chart.configuration?.yaxis?.show || false,
                  font: {
                    size: 10
                  }
                },
                grid: {
                  display: true,
                  drawTicks: false,
                  color: gridLineColor
                },
                border: {
                  color: lineChartBorderColor,
                  dash: [3, 3]
                }
              },
              x: {
                ticks: {
                  font: {
                    size: 10
                  },
                  display: this.containerCard?.chart.configuration?.xaxis?.show || false
                },
                grid: {
                  display: false,
                },
                border: {
                  color: lineChartBorderColor
                }
              }
            }
          }
        });
      }
    }, 50);
  }

  getStepSize(containerChartData: ContainerChartData[]): number {
    // Find max value from the chart data
    const maxChartValue =  containerChartData.reduce((max, currentValue) => Math.max(max, currentValue.value), containerChartData[0]?.value);
    // Divide value to display maximum of 5 grid lines
    const stepValue = maxChartValue / 5;
    const chartStepValue = (stepValue < 100) ? Math.round(stepValue / 10) * 10 : Math.round(stepValue / 100) * 100;
    return chartStepValue;
  }

  getChartSegementColor(ctx, value: string): string {
    if (this.activeChartPointIndex !== null && this.activeChartPointIndex >= 0) {
      return ctx.p0DataIndex < this.activeChartPointIndex ? value : defaultChartSegmentColor;
    } else {
      return value;
    }
  }

  resetActiveChartPoint(): void {
    this.activeChartPoint = null;
    this.activeChartPointIndex = null;
    if (this.chart && this.chart?.data && this.chart?.data?.segment) {
      this.updateChartSegmentData();
    }
  }

  updateChartSegmentData(): void {
    this.chart.data.segment = {
      borderColor: (ctx) => this.getChartSegementColor(ctx, dynamicChartBorderColor),
      backgroundColor: (ctx) => this.getChartSegementColor(ctx, dynamicChartBackgroundColor)
    };
    this.chart.update();
  }
}
