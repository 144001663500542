// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `svg circle {
  fill: var(--circle-fill-color, none);
}
svg path {
  stroke: var(--path-stroke-color, none);
}`, "",{"version":3,"sources":["webpack://./src/app/components/svg/arrow-control/arrow-control.component.scss"],"names":[],"mappings":"AACE;EACE,oCAAA;AAAJ;AAEE;EACE,sCAAA;AAAJ","sourcesContent":["svg {\n  circle {\n    fill: var(--circle-fill-color, none); // Use 'none' as fallback\n  }\n  path {\n    stroke: var(--path-stroke-color, none); // Use 'none' as fallback\n  }\n}"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
