<ion-header class="ion-no-border" lines="none">
  <div class="pop-header notch-space title-with-button">
    <div class="close">
      <app-back-icon (click)="close()"></app-back-icon>
    </div>
    <h2>{{'signup_account.terms_conditions' | translate}}</h2>
  </div>
</ion-header>
<ion-content class="terms-and-conditions grey-bg">
  <div class="content"><p [innerText]="termsAndConditions"></p></div>
</ion-content>
