import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-plus-circle',
    templateUrl: './plus-circle.component.html',
    styleUrls: ['./plus-circle.component.scss'],
    standalone: false
})
export class PlusCircleComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
