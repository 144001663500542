<div class="content-section-outer-level">
  <div class="h-100 create-content-section">
    <div class="cards-area-inner content-section-outer-level">
      <ion-card class="create-card" (click)="openAdwizard()">
        <ion-card-content>
          <div class="info-icon-section"><app-info-icon></app-info-icon></div>
          <div class="create-icon-area">
            <app-create-copy></app-create-copy>
          </div>
          <div class="create-label">
            Create Ads
          </div>
        </ion-card-content>
      </ion-card>
      <ion-card class="create-card">
        <ion-card-content>
          <div class="info-icon-section"><app-info-icon></app-info-icon></div>
          <div class="create-icon-area">
            <app-offline-icon></app-offline-icon>
          </div>
          <div class="create-label">
            View Ads
          </div>
        </ion-card-content>
      </ion-card>
    </div>
  </div>
  <!-- <div class="create-campaign">
    <div class="create-campaign-form-area">
      <div class="form-area">
        <form>
          <div class="fields-section">
            <div class="title-block">
              <ion-list>
                <ion-item>
                  <ion-input [clearOnEdit]="true" placeholder="Title of campaign*"></ion-input>
                </ion-item>
                <ion-item class="text-area-cover" lines="none">
                  <ion-textarea placeholder="Descriptions*" [autoGrow]="true"></ion-textarea>
                  <div class="small-text">1000</div>
                </ion-item>
              </ion-list>
            </div>
            <div class="form-cover-single">
              <ion-list>
                <ion-item lines="none">
                  <ion-icon src="assets/svg/image-upload-icon.svg"></ion-icon>
                  <ion-input [clearOnEdit]="true" placeholder="Add image or video*"></ion-input>
                </ion-item>
              </ion-list>
            </div>
            <div class="cta-buttons-section">
              <div class="label">CTA Button*</div>
              <div class="cta-btn-container">
                <ion-button>Shop now</ion-button>
                <ion-button>Get offer</ion-button>
                <ion-button>Get offer</ion-button>
                <ion-button>Get offer</ion-button>
                <ion-button>Learn more</ion-button>
                <ion-button>
                  <app-more-big-icon></app-more-big-icon>
                </ion-button>
              </div>
            </div>
            <div class="form-cover-single">
              <ion-list>
                <ion-item lines="none">
                  <ion-icon src="assets/svg/gender-icon.svg"></ion-icon>
                  <ion-select interface="action-sheet" placeholder="Preferred gender" [interfaceOptions]="{cssClass: 'custom-action-sheet', header: 'Preferred gender'}" mode="md">
                    <ion-select-option value="male">Male</ion-select-option>
                    <ion-select-option value="female">Female</ion-select-option>
                  </ion-select>
                </ion-item>
              </ion-list>
            </div>
            <div class="range-section">
              <div class="range-label">
                <div class="title">Age range*</div>
                <div class="range-value">16 - 32</div>
              </div>
              <div class="range-area">
                <ion-range [dualKnobs]="true" [value]="{ lower: 20, upper: 80 }"></ion-range>
              </div>
            </div>
            <div class="form-lable-sec">
              <div class="label">Location*</div>
              <div class="form-cover-single">
                <ion-list>
                  <ion-item lines="none">
                    <ion-icon src="assets/svg/location-icon.svg"></ion-icon>
                    <ion-input [clearOnEdit]="true" placeholder="Search location…"></ion-input>
                  </ion-item>
                </ion-list>
              </div>
            </div>
            <div class="form-lable-sec">
              <div class="label">Target interests*</div>
              <div class="form-cover-single">
                <ion-list>
                  <ion-item lines="none">
                    <ion-icon src="assets/svg/interest-icon.svg"></ion-icon>
                    <ion-input [clearOnEdit]="true" placeholder="Search interests…"></ion-input>
                  </ion-item>
                </ion-list>
              </div>
            </div>
            <div class="publish-btns-area">
              <div class="label">Publish on*</div>
              <div class="publish-btn-container">
                <ion-button class="fb active">
                  <ion-icon slot="start" src="assets/svg/f-icon.svg"></ion-icon>
                  Facebook
                </ion-button>
                <ion-button class="twitter active">
                  <ion-icon slot="start" src="assets/svg/twitt-bird-icon.svg"></ion-icon>
                  Twitter
                </ion-button>
                <ion-button class="insta">
                  <ion-icon slot="start" src="assets/svg/insta-icon.svg"></ion-icon>
                  Instagram
                </ion-button>
                <ion-button class="pinterest">
                  <ion-icon slot="start" src="assets/svg/pint-icon.svg"></ion-icon>
                  Pinterest
                </ion-button>
                <ion-button class="in active">
                  <ion-icon slot="start" src="assets/svg/in-icon.svg"></ion-icon>
                  LinkedIn
                </ion-button>
                <ion-button class="tiktok active">
                  <ion-icon slot="start" src="assets/svg/tiktok-icon.svg"></ion-icon>
                  TikTok
                </ion-button>
              </div>
            </div>
          </div>
        </form>
      </div>
    </div>
    <div class="button-section">
      <ion-button class="ion-padding" expand="block">Publish</ion-button>
    </div>
    <div class="overlay-create-content">
      <div class="inner-text-block">
        <h3>{{'feature.create_campaign.coming_soon' | translate}}</h3>
      </div>
    </div>
  </div> -->
</div>
