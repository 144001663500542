import { Component, Input, OnInit } from '@angular/core';
import { CampaignTurnkeyAds } from 'src/app/models/apiResponse';
import { ModalController } from '@ionic/angular';
import { CampaignsPreviewComponent } from '../campaigns-preview/campaigns-preview.component';
@Component({
    selector: 'app-campaign-template-card',
    templateUrl: './campaign-template-card.component.html',
    styleUrls: ['./campaign-template-card.component.scss'],
    standalone: false
})
export class CampaignTemplateCardComponent implements OnInit {
  @Input() campaignTemplateList: CampaignTurnkeyAds[];
  constructor(
    public modalCtrl: ModalController
  ) { }
  
  ngOnInit() {
  }
  
  async openAdDetails(ad: CampaignTurnkeyAds): Promise<void> {
    const previewModal = await this.modalCtrl.create({
      component: CampaignsPreviewComponent,
      cssClass: 'no-border-radius campaigns-preview-modal',
      componentProps: {
        selectedAd: ad
      }
    });

    return await previewModal.present();
  }
}