<svg width="93px" height="97px" viewBox="0 0 93 97" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <defs>
      <polygon id="path-vh15qcknue-1" points="0 0 64.489011 0 64.489011 74.0244451 45.8200722 92.7362637 0 92.7362637"></polygon>
  </defs>
  <g id="EFFEKTIFY" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Store---POS" transform="translate(-149.000000, -219.000000)">
          <g id="Group-2" transform="translate(149.000000, 219.000000)">
              <g id="coin1" transform="translate(52.714286, 26.944444)">
                  <ellipse id="Oval-11" fill-opacity="0.5" fill="#000000" style="mix-blend-mode: overlay;" cx="21.4857143" cy="24.25" rx="18.8" ry="18.8611111"></ellipse>
                  <ellipse id="Oval-11" fill="#F09A28" cx="19.6952381" cy="20.6574074" rx="18.8" ry="18.8611111"></ellipse>
                  <ellipse id="Oval-11" fill="#FDD659" cx="18.8" cy="18.8611111" rx="18.8" ry="18.8611111"></ellipse>
                  <ellipse id="Oval-11" stroke="#F09A28" cx="18.8" cy="18.8611111" rx="13.4285714" ry="13.4722222"></ellipse>
                  <path d="M21.2566592,21.8590314 C21.2566592,21.3210754 21.0686961,20.8679127 20.6927641,20.4995298 C20.3168322,20.1311469 19.683003,19.7978531 18.7912574,19.4996383 C17.5498078,19.1195607 16.6449619,18.6240054 16.0766927,18.0129575 C15.5084235,17.4019097 15.2242932,16.6256859 15.2242932,15.6842629 C15.2242932,14.7252978 15.5025952,13.9388413 16.0592076,13.3248698 C16.61582,12.7108983 17.3807863,12.3454445 18.3541295,12.2284975 L18.3541295,10.3076534 L19.6567708,10.3076534 L19.6567708,12.2372685 C20.6359424,12.3717575 21.3979945,12.7839894 21.9429501,13.4739764 C22.4879058,14.1639635 22.7603795,15.108296 22.7603795,16.3070023 L21.1517485,16.3070023 C21.1517485,15.4825263 20.9565,14.8276331 20.565997,14.3423032 C20.1754941,13.8569734 19.6480316,13.6143121 18.9835938,13.6143121 C18.2900139,13.6143121 17.7596373,13.7941153 17.3924479,14.1537272 C17.0252586,14.5133391 16.8416667,15.0147417 16.8416667,15.6579499 C16.8416667,16.2543794 17.0354581,16.7294693 17.4230469,17.0832339 C17.8106356,17.4369984 18.4488361,17.7585977 19.3376674,18.0480414 C20.2264987,18.3374852 20.9200682,18.6561609 21.4183966,19.0040781 C21.916725,19.3519953 22.2839088,19.756918 22.5199591,20.2188585 C22.7560094,20.680799 22.8740327,21.2216706 22.8740327,21.8414894 C22.8740327,22.8296912 22.5782458,23.6249186 21.9866629,24.2271955 C21.3950801,24.8294723 20.5660024,25.1861552 19.4994048,25.2972548 L19.4994048,26.9725116 L18.205506,26.9725116 L18.205506,25.2972548 C17.1214232,25.1978499 16.2719463,24.8104689 15.6570499,24.1351002 C15.0421534,23.4597315 14.7347098,22.5431735 14.7347098,21.3853986 L16.3520833,21.3853986 C16.3520833,22.1981799 16.5648168,22.8238368 16.9902902,23.2623879 C17.4157635,23.700939 18.0189941,23.9202112 18.8,23.9202112 C19.5635207,23.9202112 20.1638371,23.7360225 20.6009673,23.3676396 C21.0380974,22.9992567 21.2566592,22.4963923 21.2566592,21.8590314 Z" id="$" fill="#F09A28"></path>
              </g>
              <g id="checklist" transform="translate(0.000000, 0.000000)">
                  <polygon id="Mask" fill-opacity="0.5" fill="#000000" style="mix-blend-mode: overlay;" points="2.66483516 2.66483516 64.4795038 0.452760121 68.2712651 78.7643239 49.6344189 96.7341686 0.240599876 92.6963201"></polygon>
                  <mask id="mask-vh15qcknue-2" fill="white">
                      <use xlink:href="#path-vh15qcknue-1"></use>
                  </mask>
                  <use id="Mask" fill="#D2EBFF" xlink:href="#path-vh15qcknue-1"></use>
                  <g id="Group" mask="url(#mask-vh15qcknue-2)" stroke="#BFE3FF" stroke-width="4">
                      <g transform="translate(6.395604, 6.395604)">
                          <rect id="Rectangle-3" x="2" y="2" width="48.7637363" height="75.9450549"></rect>
                          <line x1="12.3399061" y1="1.06309913" x2="12.3399061" y2="78.6122272" id="Line" stroke-linecap="square"></line>
                          <line x1="1.48929901" y1="12.0282074" x2="51.3906814" y2="12.0282074" id="Line-2" stroke-linecap="square"></line>
                          <line x1="1.48929901" y1="23.2059354" x2="51.3906814" y2="23.2059354" id="Line-2" stroke-linecap="square"></line>
                          <line x1="1.48929901" y1="34.3836634" x2="51.3906814" y2="34.3836634" id="Line-2" stroke-linecap="square"></line>
                          <line x1="1.48929901" y1="45.5613915" x2="51.3906814" y2="45.5613915" id="Line-2" stroke-linecap="square"></line>
                          <line x1="1.48929901" y1="56.7391195" x2="51.3906814" y2="56.7391195" id="Line-2" stroke-linecap="square"></line>
                          <line x1="1.48929901" y1="67.9168476" x2="51.3906814" y2="67.9168476" id="Line-2" stroke-linecap="square"></line>
                      </g>
                  </g>
                  <path d="M23.717033,11.1923077 L43.9697802,11.1923077 C45.0000037,11.1923077 45.8351648,12.0274688 45.8351648,13.0576923 C45.8351648,14.0879158 45.0000037,14.9230769 43.9697802,14.9230769 L23.717033,14.9230769 C22.6868095,14.9230769 21.8516484,14.0879158 21.8516484,13.0576923 C21.8516484,12.0274688 22.6868095,11.1923077 23.717033,11.1923077 Z" id="Rectangle" fill="#BFE3FF" mask="url(#mask-vh15qcknue-2)"></path>
                  <path d="M23.717033,22.3846154 L49.2994505,22.3846154 C50.329674,22.3846154 51.1648352,23.2197765 51.1648352,24.25 C51.1648352,25.2802235 50.329674,26.1153846 49.2994505,26.1153846 L23.717033,26.1153846 C22.6868095,26.1153846 21.8516484,25.2802235 21.8516484,24.25 C21.8516484,23.2197765 22.6868095,22.3846154 23.717033,22.3846154 Z" id="Rectangle-Copy" fill="#BFE3FF" mask="url(#mask-vh15qcknue-2)"></path>
                  <path d="M23.717033,33.043956 L41.8379121,33.043956 C42.8681356,33.043956 43.7032967,33.8791172 43.7032967,34.9093407 C43.7032967,35.9395641 42.8681356,36.7747253 41.8379121,36.7747253 L23.717033,36.7747253 C22.6868095,36.7747253 21.8516484,35.9395641 21.8516484,34.9093407 C21.8516484,33.8791172 22.6868095,33.043956 23.717033,33.043956 Z" id="Rectangle-Copy-2" fill="#BFE3FF" mask="url(#mask-vh15qcknue-2)"></path>
                  <path d="M23.717033,44.7692308 L29.5796703,44.7692308 C30.6098938,44.7692308 31.4450549,45.6043919 31.4450549,46.6346154 C31.4450549,47.6648389 30.6098938,48.5 29.5796703,48.5 L23.717033,48.5 C22.6868095,48.5 21.8516484,47.6648389 21.8516484,46.6346154 C21.8516484,45.6043919 22.6868095,44.7692308 23.717033,44.7692308 Z" id="Rectangle-Copy-3" fill="#BFE3FF" mask="url(#mask-vh15qcknue-2)"></path>
                  <path d="M35.9752747,44.7692308 L50.3653846,44.7692308 C51.3956081,44.7692308 52.2307692,45.6043919 52.2307692,46.6346154 C52.2307692,47.6648389 51.3956081,48.5 50.3653846,48.5 L35.9752747,48.5 C34.9450512,48.5 34.1098901,47.6648389 34.1098901,46.6346154 C34.1098901,45.6043919 34.9450512,44.7692308 35.9752747,44.7692308 Z" id="Rectangle-Copy-5" fill="#BFE3FF" mask="url(#mask-vh15qcknue-2)"></path>
                  <path d="M23.717033,55.9615385 L38.1071429,55.9615385 C39.1373663,55.9615385 39.9725275,56.7966996 39.9725275,57.8269231 C39.9725275,58.8571466 39.1373663,59.6923077 38.1071429,59.6923077 L23.717033,59.6923077 C22.6868095,59.6923077 21.8516484,58.8571466 21.8516484,57.8269231 C21.8516484,56.7966996 22.6868095,55.9615385 23.717033,55.9615385 Z" id="Rectangle-Copy-4" fill="#BFE3FF" mask="url(#mask-vh15qcknue-2)"></path>
                  <polyline id="Path-2" stroke="#1982FF" stroke-width="4" mask="url(#mask-vh15qcknue-2)" points="10.1263736 11.4981487 12.1219449 14.3901099 17.5879121 5.32967033"></polyline>
                  <polyline id="Path-2" stroke="#1982FF" stroke-width="4" mask="url(#mask-vh15qcknue-2)" points="10.1263736 22.1574894 12.1219449 25.0494505 17.5879121 15.989011"></polyline>
                  <line x1="22.3846154" y1="12.2582418" x2="49.5659341" y2="13.8571429" id="Path-3" stroke="#1982FF" stroke-width="4" mask="url(#mask-vh15qcknue-2)"></line>
                  <line x1="22.3846154" y1="24.5164835" x2="50.0989011" y2="22.3846154" id="Path-3" stroke="#1982FF" stroke-width="4" mask="url(#mask-vh15qcknue-2)"></line>
                  <polygon id="Rectangle-2" fill="#7BC5FF" mask="url(#mask-vh15qcknue-2)" points="45.8351648 74.0824176 64.489011 74.0824176 45.8351648 92.7362637"></polygon>
              </g>
          </g>
      </g>
  </g>
</svg>
