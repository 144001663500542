<svg width="29px" height="24px" viewBox="0 0 29 24" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <g id="EFFEKTIFY" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Insights" transform="translate(-35.000000, -342.000000)" fill="#14181C" fill-rule="nonzero">
          <g id="Content" transform="translate(0.000000, 162.000000)">
              <g id="Low-chart" transform="translate(35.000000, 180.000000)">
                  <path d="M28.3409091,24 L0.659090909,24 C0.295272727,24 0,23.7013333 0,23.3333333 C0,22.9653333 0.295272727,22.6666667 0.659090909,22.6666667 L28.3409091,22.6666667 C28.7047273,22.6666667 29,22.9653333 29,23.3333333 C29,23.7013333 28.7047273,24 28.3409091,24 Z M27.6818182,17.3333333 C27.6811591,17.3333333 27.6805,17.3333333 27.6791818,17.3333333 C27.666,17.3333333 26.2871818,17.3333333 25.7045455,17.3333333 C21.0909091,17.3333333 16.3843409,12.6666667 13.1818182,12.6666667 C10.1097955,12.6666667 6.10318182,15.2326667 3.95454545,17.3333333 C2.60406818,18.7313333 1.94827273,17.3513333 2.64888636,16.6806667 C4.74875,14.7373333 9.02163636,11.3333333 13.1818182,11.3333333 C17.2128182,11.3333333 21.8580909,16 25.7045455,16 L27.6818182,16 L27.6818182,16 L27.6818182,16 C28.0456364,16 28.3409091,16.298 28.3409091,16.6666667 C28.3409091,17.0353333 28.0456364,17.3333333 27.6818182,17.3333333 Z M27.6818182,12 C27.6811591,12 27.6805,12 27.6798409,11.9993333 C27.6692955,11.9993333 26.2878409,11.9993333 25.7045455,11.9993333 C19.9375,12 17.5898182,1.33333333 13.1818182,1.33333333 C8.74943182,1.33333333 4.06527273,9.33333333 2.48609091,13.3186667 C1.99506818,14.4833333 0.645909091,13.9426667 1.61740909,11.954 C3.55513636,8.06733333 8.17865909,0 13.1818182,0 C18.4545455,0 21.0909091,10.6666667 25.7045455,10.6666667 L27.6818182,10.6666667 L27.6818182,10.6666667 L27.6818182,10.6666667 C28.0456364,10.6666667 28.3409091,10.9653333 28.3409091,11.3333333 C28.3409091,11.7013333 28.0456364,12 27.6818182,12 Z" id="Shape"></path>
              </g>
          </g>
      </g>
  </g>
</svg>
