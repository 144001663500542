import { Injectable } from '@angular/core';
import { AccountIdentifier, CreateIntegrationResponse, Integration, IntegrationType, IntegrationTypeResponse, TeamResponse } from 'src/app/models/apiResponse';
import { InAppBrowser, InAppBrowserOptions } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { environment as env } from 'src/environments/environment';
import { AccountIdentifierPayload, ApiEndpoint, CreateIntegrationPayload, PatchIntegrationPayload } from 'src/app/models/apiRequest';
import { Config } from '../config';
import { AppService } from '../app-service/app.service';
import { HttpErrorResponse } from '@angular/common/http';
import { AlertController, ModalController } from '@ionic/angular';
import { EventsService } from '../events/events.service';
import { GroupedIntegrationTypes, CustomErrorResponse, CustomRecommendedIntegrationType, GroupedIntegrations, IntegrationAuthParameters } from 'src/app/models/interface';
import { IntegrationAuthMethods, IntegrationStatus } from 'src/app/core/enum';
import { Device, DeviceInfo } from '@capacitor/device';
import { TranslateService } from '@ngx-translate/core';
import { Browser } from '@capacitor/browser';
import { IntegrationAuthCredentialsComponent } from 'src/app/modals/integration-auth-credentials/integration-auth-credentials.component';
import { CreateTeamComponent } from 'src/app/modals/create-team/create-team.component';
import { FacebookLogin, FacebookLoginResponse  } from '@capacitor-community/facebook-login';
import { IntegrationConfigurationsComponent } from 'src/app/components/integration-configurations/integration-configurations.component';
import { CustomSelectComponent } from 'src/app/components/custom-select/custom-select.component';

@Injectable({
  providedIn: 'root'
})
export class StoreIntegrationService {
  private type: string = undefined;
  private integrationAuthCredentialsModal: HTMLIonModalElement;
  private selectedTeam: TeamResponse;
  private deviceInfo: DeviceInfo;
  private createTeamModal: HTMLIonModalElement;
  IntegrationReference: CreateIntegrationResponse;
  constructor(
    private iab: InAppBrowser,
    private appService: AppService,
    private alertController: AlertController,
    private events: EventsService,
    private modalCtrl: ModalController,
    private translate: TranslateService
  ) { 
    this.events.subscribe('integrationoOauth2:success', () => {
      if (this.IntegrationReference) {
        this.handleIABAuthRedirection(this.IntegrationReference);
      }
    });
  }

  async openAuthURL(url: string, createdIntegration: CreateIntegrationResponse, type: string): Promise<void> {
    this.IntegrationReference = createdIntegration;
    this.deviceInfo = await Device.getInfo();
    this.type = type;
    if (this.appService.isNativePlatform) {
      const IABOptions: InAppBrowserOptions = {
        location: this.deviceInfo.platform === 'android' ? 'yes' : 'no',
        fullscreen: 'no',
        zoom: 'no',
        hardwareback: 'yes',
        hideurlbar: 'yes',
        hidenavigationbuttons: 'yes',
        toolbarcolor: '#f1f1f1',
        toolbartranslucent: 'no',
        lefttoright: this.deviceInfo.platform === 'ios' ? 'yes' : 'no',
        toolbarposition: 'top',
        closebuttoncolor: '#14181C',
        closebuttoncaption: 'X  '
      }
      const browserTarget = createdIntegration?.authentication_configuration?.open_in_external_browser ? '_system' : '_blank';
      const browser = this.iab.create(url, browserTarget, IABOptions);
      browser.on('loadstart').subscribe(event => {
        const navUrl = event.url;
        if (navUrl.includes(env.integrationAuthSuccessRedirect) || navUrl.includes(env.integrationAuthFailureRedirect)) {
          browser.close();
        }
      });
      browser.on('exit').subscribe(() => {
        this.handleIABAuthRedirection(createdIntegration);
      });
    } else {
      Browser.open({ url });
      const alert = await this.alertController.create({
        header: this.translate.instant('modal.complete_auth_head'),
        message: this.translate.instant('modal.complete_auth_message'),
        mode: 'ios',
        cssClass: 'custom-alert message-text-on',
        backdropDismiss: false,
        buttons: [
          {
            text: this.translate.instant('buttons.cancel'),
            role: 'cancel',
            cssClass: 'secondary ion-text-capitalize',
            handler: () => {
              this.events.publish('integration:listRefresh'); // Emit event to dismiss connections modal and refresh store page on cancel event
              this.type = undefined;
            }
          }, {
            text: this.translate.instant('buttons.ok'),
            cssClass: 'ion-text-capitalize',
            handler: async () => {
              await this.appService.showLoader();
              const integrationByTokenEndpoint: ApiEndpoint = this.appService.getClone(Config.apiUrl.integrationByToken);
              integrationByTokenEndpoint.url = integrationByTokenEndpoint.url + '/' + createdIntegration.id;
              this.appService.api({}, integrationByTokenEndpoint).subscribe({
                next: async (integrationData: Integration) => {
                  if (
                    (integrationData.status !== IntegrationStatus.pending_authorization) &&
                    (integrationData.status !== IntegrationStatus.pending_reauthorization)) 
                  {
                    alert.dismiss();
                    await this.appService.hideLoader();
                    this.handleIABAuthRedirection(integrationData);
                  } else {
                    await this.appService.hideLoader();
                    this.appService.presentToast(this.translate.instant('toast_message.integration_auth_not_complete'));
                    return false;
                  }
                },
                error: async () => {
                  await this.appService.hideLoader();
                  this.appService.presentToast(this.translate.instant('toast_message.failed_fetch_integration'));
                  alert.dismiss();
                }
              })
              return false;
            }
          }
        ]
      });
      await alert.present();
    }
  }

  async handleIABAuthRedirection(createdIntegration: CreateIntegrationResponse): Promise<void> {
    const integrationByTokenEndpoint: ApiEndpoint = this.appService.getClone(Config.apiUrl.integrationByToken);
    integrationByTokenEndpoint.url = integrationByTokenEndpoint.url + '/' + createdIntegration.id;
    await this.appService.showLoader();
    await this.appService.api({}, integrationByTokenEndpoint).subscribe({
      next: async (integrationData: Integration) => {
        await this.appService.hideLoader();
        if (integrationData.status === IntegrationStatus.active) {
          this.handleSuccess(integrationData);
        } else if (integrationData.status === IntegrationStatus.pending_account_identifier) {
          this.fetchAccountIdentifiers(createdIntegration);
        } else if (integrationData.status === IntegrationStatus.pending_account_configuration) {
          this.fetchAccountConfigurations(createdIntegration);
        } else if (this.hasAccountIdentifier(createdIntegration)) {
          const accountIdentifier = {
            id: createdIntegration.account_identifier,
            name: createdIntegration.account_display_name
          }
          this.patchIntegration(accountIdentifier, createdIntegration);
        } else if (
          (integrationData.status === IntegrationStatus.pending_authorization) ||
          (integrationData.status === IntegrationStatus.pending_reauthorization)
        ) {
          this.handleAuthPending(integrationData);
        } else {
          this.handleError(integrationData);
          this.appService.presentToast(this.translate.instant('toast_message.failed_integration_connection'));
        }
      },
      error: async () => {
        await this.appService.hideLoader();
        this.appService.presentToast(this.translate.instant('toast_message.failed_fetch_integration'));
      }
    });
  }

  async fetchAccountIdentifiers(createdIntegration: CreateIntegrationResponse, type: string = ''): Promise<void> {
    if (type) {
      this.type = type;
    }
    const apiEndpoint: ApiEndpoint = this.appService.getClone(Config.apiUrl.accountIdentifiers);
    apiEndpoint.url = apiEndpoint.urlStart + createdIntegration.id + apiEndpoint.urlEnd;
    const payload: AccountIdentifierPayload = {
      integrationToken: createdIntegration.id
    }
    await this.appService.showLoader();
    await this.appService.api(payload, apiEndpoint).subscribe({
      next: async (accountIdentifiers: AccountIdentifier[]) => {
        await this.appService.hideLoader();
        if (accountIdentifiers.length) {
          this.showAccountIdentifiers(accountIdentifiers, createdIntegration)
        } else {
          this.handleError(createdIntegration);
          this.appService.presentToast(this.translate.instant('toast_message.failed_fetch_identifier'));
        }
      },
      error: async () => {
        await this.appService.hideLoader();
      }
    });
  }

  async fetchAccountConfigurations(createdIntegration: CreateIntegrationResponse, type: string = ''): Promise<void> {
    if (type) {
      this.type = type;
    }
    const modal = await this.modalCtrl.create({
      component: IntegrationConfigurationsComponent,
      cssClass: 'no-border-radius',
      componentProps: {
        id: createdIntegration.id
      }
    });
    modal.onDidDismiss().then((result) => {
      if (result?.data) {
        this.patchIntegration(null, createdIntegration, { configuration: result?.data?.configuration }, 'reauth');
      }
    });
    return await modal.present();
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  createAIAlertInputs(inputArray: AccountIdentifier[]): any[] {
    const newInputs = [];
    // eslint-disable-next-line @typescript-eslint/prefer-for-of
    for (let i = 0; i < inputArray.length; i++) {
      newInputs.push(
        {
          type: 'radio',
          label: inputArray[i].name,
          value: inputArray[i],
          checked: false
        }
      );
    }
    return newInputs;
  }

  async showAccountIdentifiers(accountIdentifiers: AccountIdentifier[], createdIntegration: CreateIntegrationResponse): Promise<void> {    
    const modal = await this.modalCtrl.create({
      component: CustomSelectComponent,
      cssClass: 'no-border-radius',
      componentProps: {
        data: accountIdentifiers,
        title: this.translate.instant('buttons.select_account_identifier')
      }
    });
    modal.onDidDismiss().then((result) => {
      if (result?.data) {
        this.patchIntegration(result?.data, createdIntegration, {}, 'reauth');
      }
    });
    return await modal.present();
  }

  async patchIntegration(
    selectedAccountIdentifier: AccountIdentifier|null,
    integration: CreateIntegrationResponse|Integration,
    apiPayload: PatchIntegrationPayload = {},
    action: string = ''
  ): Promise<void> {
    const apiEndpoint: ApiEndpoint = this.appService.getClone(Config.apiUrl.patchIntegration);
    apiEndpoint.url = apiEndpoint.urlStart + integration.id + apiEndpoint.urlEnd;
    const payload: PatchIntegrationPayload = apiPayload;
    if (selectedAccountIdentifier) {
      payload.account_identifier = selectedAccountIdentifier.id;
      payload.account_display_name = selectedAccountIdentifier.name;
    }
    await this.appService.showLoader();
    await this.appService.api(payload, apiEndpoint).subscribe({
      next: async (patchedIntegration: CreateIntegrationResponse) => {
        await this.appService.hideLoader();
        await this.integrationAuthCredentialsModal?.dismiss(); // Dismissing auth input modal
        if (action === 'reauth') {
          if (patchedIntegration.status === IntegrationStatus.pending_account_identifier) {
            this.fetchAccountIdentifiers(patchedIntegration);
          } else if (patchedIntegration.status === IntegrationStatus.pending_account_configuration) {
            this.fetchAccountConfigurations(patchedIntegration);
          } else if (patchedIntegration.status === IntegrationStatus.active) {
            this.handleSuccess(patchedIntegration);
          } else if (
            (patchedIntegration.status === IntegrationStatus.pending_authorization) ||
            (patchedIntegration.status === IntegrationStatus.pending_reauthorization)
          ) {
            this.handleAuthPending(patchedIntegration);
          } else {
            this.handleError(patchedIntegration);
            this.appService.presentToast(this.translate.instant('toast_message.failed_integration_connection'));
          }
        } else {
          this.handleSuccess(patchedIntegration);
        }
      },
      error: async (e: HttpErrorResponse) => {
        await this.appService.hideLoader();
        this.appService.showApiErrorMessage(e);
      }
    });
  }

  handleSuccess(patchedIntegration: CreateIntegrationResponse, type: string = ''): void {
    if (type) {
      this.type = type;
    }
    this.appService.presentToast(this.translate.instant('toast_message.integration_success'));
    const eventName = (this.type && this.type === 'create') ? 'integration:listRefresh' : 'integration:reauthorize';
    this.events.publish(eventName, patchedIntegration);
    this.events.publish('appsFlyer:createIntegrationEvent', patchedIntegration.account_display_name);
    this.type = undefined;
  }

  handleAuthPending(patchedIntegration: CreateIntegrationResponse, type: string = ''): void {
    if (type) {
      this.type = type;
    }
    this.appService.presentToast(this.translate.instant('toast_message.failed_integration_authorization'));

    const eventName = (this.type && this.type === 'create') ? 'integration:listRefresh' : 'integration:reauthorize';
    this.events.publish(eventName, patchedIntegration);
    this.type = undefined;
  }

  handleError(patchedIntegration: CreateIntegrationResponse): void {
    const eventName = (this.type && this.type === 'create') ? 'integration:listRefresh' : 'integration:reauthorize';
    this.events.publish(eventName, patchedIntegration);
    this.type = undefined;
  }

  groupIntegrationTypes(objectArray: IntegrationType[], property: string, checkAllowCreationFlag = false): GroupedIntegrationTypes {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    return objectArray.reduce((acc: Record<string, any>, obj) => {
      const key: string = obj[property];
      if (checkAllowCreationFlag) { // Grouping for connect store component - Integration connection
        if (obj?.authentication_configuration?.allow_creation || obj?.authentication_configuration.allow_creation_new_team) {
          acc = this.groupIntegration(acc, key, obj);
        }
      } else { // Grouping for store list - Connected Integrations
        acc = this.groupIntegration(acc, key, obj);
      }
      return acc;
    }, {});
  }

  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  groupIntegration(acc: Record<string, any>, key: string, obj: IntegrationType): Record<string, any> {
    if (!acc[key]) {
      acc[key] = {};
      acc[key].category = obj.category;
      acc[key].category_name = obj.category_name;
      acc[key].types = [];
    }
    acc[key].types.push(obj);
    return acc;
  }

  // Used for grouping Integration based on Category - will be removed on future versions
  groupIntegrationsByCategory(objectArray: Integration[], groupTypes: GroupedIntegrationTypes): GroupedIntegrations {
    const res = {};
    objectArray.forEach(integration => {
      const integrationTypes = Object.values(groupTypes);
      integrationTypes.forEach((obj) => {
        obj.types.forEach(integrationType => {
          if (
            integration.integration_type === integrationType.type
          ) {
            if (!res[integrationType.category]) {
              res[integrationType.category] = [];
            }
            res[integrationType.category].push(integration);
          }
        })
      })
    })
    return res;
  }

  getRecommendedIntegrationTypes(data: IntegrationTypeResponse): CustomRecommendedIntegrationType[] {
    const recommededIntegrations: CustomRecommendedIntegrationType[] = [];
    const recommendedKeys = Object.keys(data.recommendations);
    recommendedKeys.forEach((key) => {
      data.integration_types.forEach((integration: CustomRecommendedIntegrationType) => {
        if (integration.type === key) {
          integration['highlight'] = data.recommendations[key];
          recommededIntegrations.push(integration)
        }
      })
    })
    return recommededIntegrations;
  }

  hasAccountIdentifier(integration: Integration): boolean {
    return (integration?.account_display_name != null && integration?.account_identifier != null)
  }

  setRequestType(type: string|undefined): void {
    this.type = type;
  }

  // Open credential input modal
  async openAuthenticationModal(provider: Integration|IntegrationType, action: string = '') {
    this.integrationAuthCredentialsModal = await this.modalCtrl.create({
      component: IntegrationAuthCredentialsComponent,
      cssClass: 'connection-provider-modal',
      initialBreakpoint: 0.94,
      componentProps: {
        provider: JSON.parse(JSON.stringify(provider)),
        action
      }
    });
    await this.integrationAuthCredentialsModal.present();
  }

  // Handle auth & re-auth for "credentials" method
  async handleCredentialsMethod(provider: Integration|IntegrationType, authParams: IntegrationAuthParameters, action: string = '' ) {
    this.selectedTeam = this.appService.getTeam();
    let apiEndpoint: ApiEndpoint;
    let payload: CreateIntegrationPayload;
    if (action === 'reauth') {
      this.patchIntegration(null, provider as Integration, authParams, action);
    } else {
      const integration = provider as IntegrationType;
      apiEndpoint = this.appService.getClone(Config.apiUrl.createIntegration);
      apiEndpoint.url = apiEndpoint.urlStart + this.selectedTeam.id + apiEndpoint.urlEnd;
      payload = {
        integration_type: integration.type
      }
      // Add authParams to payload
      if (authParams && Object.keys(authParams)?.length) {
        Object.entries(authParams).forEach(
          ([key, value]) => {
            payload[key] = value;
          }
        );
      }
      await this.appService.showLoader();
      await this.appService.api(payload, apiEndpoint).subscribe({
        next: async (createdIntegration: CreateIntegrationResponse) => {
          await this.appService.hideLoader();
          await this.integrationAuthCredentialsModal?.dismiss(); // Dismissing auth input modal
          if (createdIntegration.status === IntegrationStatus.active) {
            this.handleSuccess(createdIntegration, 'create');
          } else if (createdIntegration.status === IntegrationStatus.pending_account_configuration) {
            this.fetchAccountConfigurations(createdIntegration, 'create');
          } else if (createdIntegration.status === IntegrationStatus.pending_account_identifier) {
            this.fetchAccountIdentifiers(createdIntegration, 'create');
          } else if (
            (createdIntegration.status === IntegrationStatus.pending_authorization) ||
            (createdIntegration.status === IntegrationStatus.pending_reauthorization)
          ) {
            this.handleAuthPending(createdIntegration, 'create');
          } else {
            this.handleError(createdIntegration);
            this.appService.presentToast(this.translate.instant('toast_message.failed_integration_connection'));
          }
        },
        error: async (e: HttpErrorResponse) => {
          await this.appService.hideLoader();
          this.appService.showApiErrorMessage(e);
        }
      });
    }
  }

  // Open credential input modal for "oauth2" and "user_redirect" methods
  async openUserInputAndRedirect(provider: IntegrationType): Promise<void> {
    if (provider?.authentication_configuration?.allow_automatic_creation) {
      this.createIntegrationAndRedirectToAuth(provider, {});
    } else {
      this.integrationAuthCredentialsModal = await this.modalCtrl.create({
        component: IntegrationAuthCredentialsComponent,
        cssClass: 'connection-provider-modal',
        initialBreakpoint: 0.94,
        componentProps: {
          provider: JSON.parse(JSON.stringify(provider)),
        }
      });
      await this.integrationAuthCredentialsModal.present();
    }
  }

  // Create integration and redirect for "oauth2" and "user_redirect" methods
  // For "facebook_sdk", new integration will be created based on FB SDK data and jumps to existing IABAuthHandler
  async createIntegrationAndRedirectToAuth(provider: IntegrationType, authParams: IntegrationAuthParameters): Promise<void> {
    this.selectedTeam = this.appService.getTeam();
    const apiEndpoint: ApiEndpoint = this.appService.getClone(Config.apiUrl.createIntegration);
    apiEndpoint.url = apiEndpoint.urlStart + this.selectedTeam.id + apiEndpoint.urlEnd;
    const payload: CreateIntegrationPayload = {
      integration_type: provider.type,
    }
    if (provider?.authentication_configuration?.expect_success_return) {
      payload.success_redirect_uri = this.appService.isNativePlatform ? env.integrationAuthSuccessRedirect : env.webIntegrationAuthSuccessRedirect;
      if (provider?.authentication_configuration?.open_in_external_browser) {
        if (this.appService.isNativePlatform) {
          payload.success_redirect_uri = 'https://' + env.appWebsite + env.externalBrowserIntegrationSuccessPath;
        } else {
          payload.success_redirect_uri = this.appService.getHostname() + '/' + env.externalBrowserIntegrationSuccessPath
        }
      }
    }
    if (provider?.authentication_configuration?.expect_error_return) {
      payload.failure_redirect_uri = this.appService.isNativePlatform ? env.integrationAuthFailureRedirect : env.webIntegrationAuthFailureRedirect;
      if (provider?.authentication_configuration?.open_in_external_browser) {
        if (this.appService.isNativePlatform) {
          payload.failure_redirect_uri = 'https://' + env.appWebsite + env.externalBrowserIntegrationFailPath;
        } else {
          payload.failure_redirect_uri = this.appService.getHostname() + '/' + env.externalBrowserIntegrationFailPath
        }
      }
    }
    // Add authParams to payload
    if (authParams && Object.keys(authParams)?.length) {
      Object.entries(authParams).forEach(
        ([key, value]) => {
          payload[key] = value;
        }
      );
    }
    await this.appService.showLoader();
    await this.appService.api(payload, apiEndpoint).subscribe({
      next: async (createdIntegration: CreateIntegrationResponse) => {
        await this.appService.hideLoader();
        if (provider.authentication_configuration.method === IntegrationAuthMethods.facebook_sdk) {
          this.handleIABAuthRedirection(createdIntegration); // Jumps to IAB handler for integration connection
        } else {
          await this.integrationAuthCredentialsModal?.dismiss();
          this.openAuthURL(createdIntegration.authorization_url, createdIntegration, 'create');
        }
      },
      error: async (e: HttpErrorResponse) => {
        await this.appService.hideLoader();
        const errorResponse: CustomErrorResponse = e?.error;
        let errorMsg: string;
        if (errorResponse?.validation_errors) {
          const validationErrors = errorResponse?.validation_errors;
          const firstErrorKey = Object.keys(validationErrors)?.[0];
          if (validationErrors?.[firstErrorKey] && validationErrors?.[firstErrorKey]?.[0]) {
            errorMsg = validationErrors?.[firstErrorKey]?.[0];
          }
        }
        if (e.status && e.status !== 500 && errorMsg) {
          this.appService.presentToast(errorMsg);
        }
      }
    });
  }

  // Connect to Integration from Recommmended and Connect store component
  async selectIntegrationProvider(provider: IntegrationType): Promise<void> {
    if (provider?.authentication_configuration) {
      if (!provider?.authentication_configuration.allow_creation && provider.authentication_configuration.allow_creation_new_team) {
        this.showIntegrationLimitDialog();
      } else {
        switch (provider.authentication_configuration.method) {
          case IntegrationAuthMethods.oauth2:
            this.openUserInputAndRedirect(provider);
          break;
          case IntegrationAuthMethods.user_redirect:
            this.openUserInputAndRedirect(provider);
            break;
          case IntegrationAuthMethods.credentials:
            this.openAuthenticationModal(provider);
            break;
          case IntegrationAuthMethods.facebook_sdk:
            this.connectFbIntegrationUsingSDK(provider?.authentication_configuration.scopes, provider, 'create');
            break;
        }
      }
    } else {
      this.appService.presentToast(this.translate.instant('toast_message.invalid_authentication'));
    }
  }

  // Handle "facebook_sdk" integrate create and reauth scenarios
  async connectFbIntegrationUsingSDK(scope: string[], provider: IntegrationType|Integration, type: string) {
    this.type = type;
    
    try {
      const result: FacebookLoginResponse = await FacebookLogin.login({ permissions: scope });
      if (result?.accessToken) {
        if (type === 'create') {
          this.createIntegrationAndRedirectToAuth(provider as IntegrationType, {credentials: result?.accessToken});
        } else {
          this.patchIntegration(null, provider as Integration, {credentials: result?.accessToken}, 'reauth');
        }
      } else {
        console.error('Facebook login failed', result); // Log the failure reason
        this.appService.presentToast(this.translate.instant('toast_message.general_error'));
      }
    } catch (error) {
      console.error('Facebook login error:', error); // Log the error
      this.appService.presentToast(this.translate.instant('toast_message.general_error'));
    }
  }

  async showIntegrationLimitDialog(): Promise<void> {
    const alert = await this.alertController.create({
      mode: 'ios',
      header: this.translate.instant('modal.limit_reached'),
      message: this.translate.instant('modal.create_team_message'),
      buttons: [
        {
          text: this.translate.instant('buttons.cancel'),
          role: 'cancel',
          cssClass: 'secondary ion-text-capitalize',
        }, {
          text: this.translate.instant('buttons.ok'),
          cssClass: 'ion-text-capitalize',
          handler: () => {
            this.openCreateTeamModal();
          }
        }
      ]
    });
    await alert.present();
  }

  async openCreateTeamModal(): Promise<void> {
    this.createTeamModal = await this.modalCtrl.create({
      component: CreateTeamComponent,
      cssClass: 'no-border-radius',
    });
    await this.createTeamModal.present(); // Enabled swipe back gesture
    const { data } = await this.createTeamModal.onDidDismiss();
    if (data?.closeAndRefreshIntegration) {
      this.events.publish('integration:listRefresh'); // To close connections modal and refresh Integration list
    }
  }

}
