<svg [attr.width]="iconSize" [attr.height]="iconSize" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" id="connected">
  <circle cx="12.8908" cy="12.6025" r="12.4728" fill="#6FD75E" />
  <path
    d="M8.27002 12.6497C8.27002 9.95313 10.4617 7.7701 13.1583 7.78076L16.2355 7.79292V17.531L13.1198 17.5187C10.4382 17.5081 8.27002 15.3313 8.27002 12.6497Z"
    fill="white" />
  <path d="M8.98786 12.631L5.962 12.6309" stroke="white" stroke-width="2.3" stroke-linecap="round" />
  <path d="M18.9232 10.4485L16.4891 10.4485" stroke="white" stroke-width="2" stroke-linecap="round" />
  <path d="M18.8355 15.008L15.6179 15.0236" stroke="white" stroke-width="2" stroke-linecap="round" />
</svg>

<svg [attr.width]="iconSize" [attr.height]="iconSize" viewBox="0 0 26 26" fill="none" xmlns="http://www.w3.org/2000/svg" id="not-connected">
  <ellipse cx="13.4748" cy="13.0061" rx="12.4728" ry="12.3087" fill="#BBBBBB" />
  <path
    d="M8.78552 13.1946C8.78552 10.5335 10.9772 8.37921 13.6738 8.38973L16.751 8.40172V18.0117L13.6353 17.9995C10.9537 17.9891 8.78552 15.8409 8.78552 13.1946Z"
    fill="white" />
  <path d="M9.50336 13.1762L6.4775 13.176" stroke="white" stroke-width="2.3" stroke-linecap="round" />
  <path d="M19.4386 11.0224L17.0045 11.0224" stroke="white" stroke-width="2" stroke-linecap="round" />
  <path d="M19.351 15.5219L16.1334 15.5373" stroke="white" stroke-width="2" stroke-linecap="round" />
  <mask id="mask0_41_1869" style="mask-type:alpha" maskUnits="userSpaceOnUse" x="0" y="0" width="26" height="26">
    <ellipse cx="13.4074" cy="13.1184" rx="12.4728" ry="12.3087" fill="#BBBBBB" />
  </mask>
  <g mask="url(#mask0_41_1869)">
    <line opacity="0.5" y1="-1.5" x2="29.924" y2="-1.5"
      transform="matrix(0.685379 0.728187 -0.737176 0.675701 2.79346 3.99448)" stroke="#6C6C6C" stroke-width="3" />
  </g>
</svg>