import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-conversion-rate-icon',
  templateUrl: './conversion-rate-icon.component.html',
  styleUrls: ['./conversion-rate-icon.component.scss'],
})
export class ConversionRateIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
