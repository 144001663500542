<svg width="26px" height="26px" viewBox="0 0 26 26" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <g id="EFFEKTIFY" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Website-Provider" transform="translate(-348.000000, -581.000000)">
          <g id="Close" transform="translate(348.000000, 581.000000)">
              <circle id="Oval" fill="#E8E8E8" cx="13" cy="13" r="13"></circle>
              <path d="M16.4183673,8 L17.5006736,9.0823063 L13.832,12.75 L17.5006736,16.4183673 L16.4183673,17.5006736 L12.75,13.832 L9.0823063,17.5006736 L8,16.4183673 L11.668,12.75 L8,9.0823063 L9.0823063,8 L12.75,11.668 L16.4183673,8 Z" fill="#7C7A79"></path>
          </g>
      </g>
  </g>
</svg>
