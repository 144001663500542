import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-external-icon',
    templateUrl: './external-icon.component.html',
    styleUrls: ['./external-icon.component.scss'],
    standalone: false
})
export class ExternalIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
