import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-add-keywords',
    templateUrl: './add-keywords.component.html',
    styleUrls: ['./add-keywords.component.scss'],
    standalone: false
})
export class AddKeywordsComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
