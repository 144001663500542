import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-more-big-icon',
    templateUrl: './more-big-icon.component.html',
    styleUrls: ['./more-big-icon.component.scss'],
    standalone: false
})
export class MoreBigIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
