import { Injectable } from '@angular/core';

import { AppService } from '../app-service/app.service';
import { EventsService } from '../events/events.service';
import { Intercom } from '@capacitor-community/intercom';
import { Capacitor } from '@capacitor/core';
import { environment as env } from 'src/environments/environment';

@Injectable({
  providedIn: 'root'
})
export class IntercomService {

  constructor(
    public appService: AppService,
    private events: EventsService
  ) { }

  initIntercom(): void {
    this.subscribeIntercomEvents()
  }

  subscribeIntercomEvents(): void {
    this.events.subscribe('intercom:initialize', async () => {
      if (this.appService.checkAppFeatureEnabled('intercom')) {
        this.initializeIntercom();
      }
    });

    this.events.subscribe('intercom:logout', async () => {
      this.logoutIntercom();
    });
  }

  async initializeIntercom(): Promise<void> {
    if (Capacitor.isNativePlatform()) {
      const user = this.appService.currentUser;
      await this.appService.getStorage('fcm_token').then(async (fcm_token) => {
        if (fcm_token && Capacitor.getPlatform() === 'android') {
          await Intercom.sendPushTokenToIntercom( {value: fcm_token});
        }
      });
      if (user) {
        await Intercom.registerIdentifiedUser({ userId: user.id, email: user.email });
        await Intercom.updateUser({ userId: user.id, email: user.email, name: user.first_name, customAttributes: {} });
        Intercom.hideLauncher();
      }
    }
    else if (this.appService.currentUser) {
      const intercomSettings = {
        api_base: env.intercomAPIBase,
        app_id: env.intercomAppID,
        name: this.appService.currentUser.first_name,
        email: this.appService.currentUser.email,
        created_at: this.appService.currentUser.created_at,
        user_id: this.appService.currentUser.id,
        custom_launcher_selector: '#custom-intercom-icon',
        hide_default_launcher: true
      };
      window.Intercom('boot', intercomSettings);
    }
  }

  showIntercomMessenger(): void {
    if (Capacitor.isNativePlatform() && this.appService.checkAppFeatureEnabled('intercom')) {
      Intercom.displayMessenger();
    }
  }

  hideIntercomLauncher(): void {
    if (Capacitor.getPlatform() === 'web' && this.appService.checkAppFeatureEnabled('intercom')) {
      window.Intercom('hide');
    }
  }

  logoutIntercom(): void {
    if (Capacitor.getPlatform() === 'web') {
      window.Intercom('shutdown');
    }
    else {
      Intercom.logout();
    }
  }
}
