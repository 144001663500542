import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-switch-account',
    templateUrl: './switch-account.component.html',
    styleUrls: ['./switch-account.component.scss'],
    standalone: false
})
export class SwitchAccountComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
