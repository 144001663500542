<ion-grid class="news-section news-skeleton" *ngIf="showSkeleton">
  <h3 *ngFor="let item of newsSeletonArray"> <ion-skeleton-text [animated]="true" class="description-skeleton"></ion-skeleton-text></h3>
</ion-grid>

<div class="news-section" *ngIf="!showSkeleton && effektifyNews && effektifyNews.length">
  <h3>{{ 'news.title' | translate }}</h3>
  <div class="news-section-detail" style="background-image: url('{{ news.image_uri }}')"  *ngFor="let news of effektifyNews" (click)="appService.openIabUrl(news.link)">
    <div class="news-info">
        <h3> {{ news.title }}</h3>
        <span class="ex-link-icon"><ion-icon name="open-outline"></ion-icon></span>
    </div>
  </div>
</div>