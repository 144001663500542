import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-lock-icon',
  templateUrl: './lock-icon.component.html',
  styleUrls: ['./lock-icon.component.scss'],
})
export class LockIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
