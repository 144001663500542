import { Component, Input } from '@angular/core';
import { OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app-service/app.service';
import { calendlyScheduleAppointment, unreadNotificationMaxText } from 'src/app/constants/app-constants';
import { TeamResponse } from 'src/app/models/apiResponse';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';

@Component({
    selector: 'app-web-header',
    templateUrl: './web-header.component.html',
    styleUrls: ['./web-header.component.scss'],
    standalone: false
})
export class WebHeaderComponent implements OnInit {

  @Input() title: string; // Title to handle in future
  public selectedTeam: TeamResponse = null;
  public unreadNotificationMaxText = unreadNotificationMaxText
  public showBookMeetingModal = false;
  public calendlyScheduleAppointment = calendlyScheduleAppointment;
  constructor(
    public appService: AppService,
    public router: Router,
    private navCtrl: NavController
  ) { }

  ngOnInit() {
    this.selectedTeam = this.appService.getTeam();
  }

  navigatePage(url: string) {
    this.navCtrl.navigateForward(url);
  }

  navigateRoot(url: string): void {
    this.navCtrl.navigateRoot(url);
  }

  isTabActive(urls: string[]): boolean {
    const route: string[] = [this.router.url];
    let flag = false;
    urls.forEach(url => {
      if (route.some(str => str.includes(url))) {
        flag = true;
      }
    })
    return flag;
  }
}
