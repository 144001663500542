import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-video',
  templateUrl: './create-video.component.html',
  styleUrls: ['./create-video.component.scss'],
})
export class CreateVideoComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
