<ion-header class="ion-no-border" lines="none">
  <ion-toolbar>
    <ion-buttons>
      <ion-button class="ion-text-capitalize">
       <app-close-modal-icon (click)="dismiss()"></app-close-modal-icon>
      </ion-button>
    </ion-buttons>
    <h2>{{'integration_auth_credentials.title' | translate}}</h2>
    <p>{{'integration_auth_credentials.subtitle' | translate}} <span *ngIf="provider?.guide_uri" (click)="openGuideUri()" class="infoicon"><ion-icon name="information-circle"></ion-icon></span></p>
  </ion-toolbar>
</ion-header>
<ion-content #swipeContent class="grey-bg">
  <div class="add-ecommerce-section">
    <ion-list>
      <ion-item lines="none" *ngIf="provider.authentication_configuration.require_username">
        <ion-label position="stacked">{{'placeholders.username' | translate}} *</ion-label>
        <ion-input [(ngModel)]="authParams.username" aria-label="require_username" placeholder="{{'placeholders.username_integration' | translate}}"></ion-input>
        <ion-note color="danger" class="error" *ngIf="isCredentialsSubmitted && showUsernameError">
          {{'validations.provide_username' | translate}}
        </ion-note>
      </ion-item>
      <ion-item lines="none" *ngIf="provider.authentication_configuration.require_password">
        <ion-label position="stacked">{{'placeholders.password' | translate}} *</ion-label>
        <ion-input [(ngModel)]="authParams.password" aria-label="require_password" placeholder="{{'placeholders.password_integration' | translate}}"></ion-input>
        <ion-note color="danger" class="error" *ngIf="isCredentialsSubmitted && showPasswordError">
          {{'validations.provide_password' | translate}}
        </ion-note>
      </ion-item>
      <ion-item lines="none" *ngIf="provider.authentication_configuration.require_key">
        <ion-label position="stacked">{{'placeholders.key' | translate}} *</ion-label>
        <ion-input [(ngModel)]="authParams.key" aria-label="require_key" placeholder="{{'placeholders.key_integration' | translate}}"></ion-input>
        <ion-note color="danger" class="error" *ngIf="isCredentialsSubmitted && showKeyError">
          {{'validations.provide_key' | translate}}
        </ion-note>
      </ion-item>
      <ion-item lines="none" *ngIf="provider.authentication_configuration.require_id">
        <ion-label position="stacked">{{idLabel}} *</ion-label>
        <ion-input [(ngModel)]="authParams.id" aria-label="require_id" [placeholder]="idPlaceholder"></ion-input>
        <ion-note color="danger" class="error" *ngIf="isCredentialsSubmitted && showIdError">
          {{'validations.provide_valid' | translate}} {{(provider.type === SHOPIFY_TYPE ? 'integration_auth_credentials.data': 'integration_auth_credentials.id') | translate}}.
        </ion-note>
      </ion-item>
      <ion-item lines="none" *ngIf="provider.authentication_configuration.require_url">
        <ion-label position="stacked">{{'placeholders.url' | translate}} *</ion-label>
        <ion-input [(ngModel)]="authParams.url" aria-label="require_url" placeholder="{{'placeholders.url_integration' | translate}}"></ion-input>
        <ion-note color="danger" class="error" *ngIf="isCredentialsSubmitted && showUrlError">
          {{'validations.provide_provide_url' | translate}}
        </ion-note>
      </ion-item>
      <ion-item lines="none" *ngIf="provider.authentication_configuration.require_client_id">
        <ion-label position="stacked">{{'placeholders.client_id' | translate}} *</ion-label>
        <ion-input [(ngModel)]="authParams.client_id" aria-label="require_client_id" placeholder="{{'placeholders.client_id_integration' | translate}}"></ion-input>
        <ion-note color="danger" class="error" *ngIf="isCredentialsSubmitted && showClientIdError">
          {{'validations.provide_client_id' | translate}}
        </ion-note>
      </ion-item>
      <ion-item lines="none" *ngIf="provider.authentication_configuration.require_client_secret">
        <ion-label position="stacked">{{'placeholders.client_secret' | translate}} *</ion-label>
        <ion-input [(ngModel)]="authParams.client_secret" aria-label="require_client_secret" placeholder="{{'placeholders.client_secret_integration' | translate}}"></ion-input>
        <ion-note color="danger" class="error" *ngIf="isCredentialsSubmitted && showClientSecretError">
          {{'validations.provide_client_secret' | translate}}
        </ion-note>
      </ion-item>
    </ion-list>
  </div>
  <div class="button-section">
    <ion-button class="ion-padding" expand="block" (click)="saveAuthCredentials()">{{'buttons.save' | translate}}</ion-button>
  </div>
</ion-content>
