import { Component, Input, OnChanges } from '@angular/core';
import SwiperCore, { Pagination, Navigation } from 'swiper';
import { minListCollapseCount} from 'src/app/constants/app-constants';
import { ContainerActions, DynamicContainerData } from 'src/app/models/apiResponse';
import { IonicSlides, NavController } from '@ionic/angular';
import { AppService } from 'src/app/services/app-service/app.service';
SwiperCore.use([Pagination, Navigation, IonicSlides]);

enum ContainerTypes {
  container = 'container',
  sideScroller = 'side_scroller'
}

@Component({
    selector: 'app-dynamic-container',
    templateUrl: './dynamic-container.component.html',
    styleUrls: ['./dynamic-container.component.scss'],
    standalone: false
})
export class DynamicContainerComponent  implements OnChanges {
  @Input() containerData: DynamicContainerData;
  showSkeleton: boolean = true;
  showAllFlag = {};
  minListCollapseCount = minListCollapseCount;
  containerTypes = ContainerTypes;
  constructor(
    public appService: AppService,
    private navCtrl: NavController
  ) { }

  ngOnChanges(): void {
    if (this.containerData?.containers?.length) {
      this.showSkeleton = false;
    }
  }

  navigateCardPage (actions: ContainerActions[]): void {
    if (actions && actions[0]?.type === 'click') {
      this.navCtrl.navigateForward(actions[0]?.uri);
    }
  }
}
