import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { Gesture, GestureController, GestureDetail, ModalController } from '@ionic/angular';
import { IntegrationAuthMethods } from 'src/app/core/enum';
import { IntegrationType } from 'src/app/models/apiResponse';
import { IntegrationAuthParameters } from 'src/app/models/interface';
import { AppService } from 'src/app/services/app-service/app.service';
import { Config } from 'src/app/services/config';
import { StoreIntegrationService } from 'src/app/services/store-integration/store-integration.service';
import { TranslateService } from '@ngx-translate/core';
import { FirebaseCrashlyticsService } from 'src/app/services/firebase-crashlytics/firebase-crashlytics.service';

const SHOPIFY_TYPE = 'shopify';

@Component({
    selector: 'app-integration-auth-credentials',
    templateUrl: './integration-auth-credentials.component.html',
    styleUrls: ['./integration-auth-credentials.component.scss'],
    standalone: false
})
export class IntegrationAuthCredentialsComponent implements OnInit, AfterViewInit {
  @Input() provider: IntegrationType;
  @Input() action: string;
  @ViewChild('swipeContent', {read: ElementRef}) private swipeContentElm: ElementRef;
  public authParams: IntegrationAuthParameters = {};
  public showUsernameError = false;
  public showPasswordError = false;
  public showKeyError = false;
  public showIdError = false;
  public showUrlError = false;
  public showClientIdError = false;
  public showClientSecretError = false;
  public isCredentialsSubmitted = false;
  public integrationAuthMethods = IntegrationAuthMethods;
  public SHOPIFY_TYPE = SHOPIFY_TYPE;
  public idLabel: string;
  public idPlaceholder: string;
  constructor(
    private modalCtrl: ModalController,
    private appService: AppService,
    private gestureCtrl: GestureController,
    private storeIntegrationService: StoreIntegrationService,
    public translate: TranslateService,
    private crashlyticsService: FirebaseCrashlyticsService
  ) { }

  ngOnInit() {
    this.crashlyticsService.customizeCrashlytics({ component: 'Integration details modal' });
    this.idLabel = this.getIdLabel();
    this.idPlaceholder = this.getIdPlaceholder();
  }

  // Custom swipe back gesture
  ngAfterViewInit(): void {
    const gesture: Gesture = this.gestureCtrl.create({
      el: this.swipeContentElm?.nativeElement,
      threshold: 20,
      gestureName: 'swipe-back-gesture',
      onEnd: (detail: GestureDetail) => {
        if (detail?.startX < 60 && detail?.deltaX > 0) {
          this.dismiss();
        }
      }
    });
    gesture?.enable();
  }

  dismiss(): void {
    this.modalCtrl.dismiss();
  }

  getIdLabel(): string {
    switch (this.provider.type) {
      case SHOPIFY_TYPE:
        return this.translate.instant('integration_auth_credentials.shopify_address');
      default:
        return this.translate.instant('integration_auth_credentials.id');
    }
  }

  getIdPlaceholder(): string {
    switch (this.provider.type) {
      case SHOPIFY_TYPE:
        return this.translate.instant('integration_auth_credentials.shopify_placeholder');
      default:
        return this.translate.instant('integration_auth_credentials.shopify_id');
    }
  }

  saveAuthCredentials(): void {
    let flag = false;
    this.isCredentialsSubmitted = true;
    this.resetValidationErrors();
    if (this.provider.authentication_configuration.require_username && !this.authParams.username) {
      this.showUsernameError = true;
      flag = true;
    }
    if (this.provider.authentication_configuration.require_password && !this.authParams.password) {
      this.showPasswordError = true;
      flag = true;
    }
    if (this.provider.authentication_configuration.require_key && !this.authParams.key) {
      this.showKeyError = true;
      flag = true;
    }
    if (this.provider.authentication_configuration.require_id && !this.authParams.id) {
      this.showIdError = true;
      flag = true;
    }
    if (this.provider.authentication_configuration.require_url && !this.authParams.url) {
      this.showUrlError = true;
      flag = true;
    }
    if (this.provider.authentication_configuration.require_client_id && !this.authParams.client_id) {
      this.showClientIdError = true;
      flag = true;
    }
    if (this.provider.authentication_configuration.require_client_secret && !this.authParams.client_secret) {
      this.showClientSecretError = true;
      flag = true;
    }
    if (this.authParams.url) {
      const reg = new RegExp(Config.validation.url);
      const hasValidUrl = reg.test(this.authParams.url);
      if (!hasValidUrl) {
        this.showUrlError = true;
        flag = true;
      }
    }
    if (!flag) {
      if (this.provider.authentication_configuration.method === IntegrationAuthMethods.credentials) {
        this.storeIntegrationService.handleCredentialsMethod(this.provider, this.authParams, this.action);
      } else {
        this.storeIntegrationService.createIntegrationAndRedirectToAuth(this.provider, this.authParams);
      }
    }
  }

  resetValidationErrors(): void {
    this.showUsernameError = false;
    this.showPasswordError = false;
    this.showKeyError = false;
    this.showIdError = false;
    this.showUrlError = false;
    this.showClientIdError = false;
    this.showClientSecretError = false;
  }

  openGuideUri(): void {
    if (this.provider?.guide_uri) {
      this.appService.openIabUrl(this.provider?.guide_uri);
    }
  }
}
