import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-ad-publish-nil',
  templateUrl: './ad-publish-nil.component.html',
  styleUrls: ['./ad-publish-nil.component.scss'],
})
export class AdPublishNilComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
