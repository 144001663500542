
<ion-header class="ion-no-border no-border-radius" [translucent]="true">
  <div class="pop-header notch-space title-with-button">
    <div class="close">
      <app-back-icon (click)="close()"></app-back-icon>
    </div>
    <h2>{{'placeholders.select' | translate}} {{ title | translate}}</h2>
  </div>
  <div class="search-bar">
    <ion-list>
      <ion-item lines="none">
        <ion-searchbar #autocompleteInput [(ngModel)]="searchValue" (ionInput)="filter($any($event))" placeholder="{{'placeholders.search' | translate}}"></ion-searchbar>
      </ion-item>
    </ion-list>
  </div>
</ion-header>
<ion-content class="grey-bg no-border-radius">
  <div class="cards-area">
    <ion-card mode="ios" class="order-card" [class.ion-hide]="!showSkeleton">
      <ion-card-content>
        <ion-list mode="ios" lines="full">
          <div class="checkbox-sec ion-margin" *ngFor="let item of skeletonArray">
            <ion-skeleton-text [animated]="true" class="data-skeleton"></ion-skeleton-text>
          </div>
        </ion-list>
      </ion-card-content>
    </ion-card>
    <ion-card mode="ios" class="order-card" *ngIf="(filtered.length || field.freesolo) && !showSkeleton">
      <ion-card-content>
        <ion-list mode="ios" lines="full">
          <div class="checkbox-sec">
            <!-- Option to select user input -->
            <ion-item *ngIf="shouldShowUserInputOption()" class="user-option" (click)="itemSelected({ value: searchValue })">
              <ion-label>{{ searchValue }}</ion-label>
            </ion-item>
            <ion-item *ngFor="let item of filtered" (click)="itemSelected(item)">
              <ion-label>{{item.value}}</ion-label>
            </ion-item>
          </div>
        </ion-list>
      </ion-card-content>
    </ion-card>
    <div class="ion-text-center" *ngIf="!filtered.length && !showSkeleton">
      <div>
        <h3 *ngIf="!searchValue">{{'placeholders.enter_search_value' | translate}}</h3>
        <h3 *ngIf="searchValue && !field.freesolo">{{'error_messages.no_data_found' | translate}}</h3>
      </div>
    </div>
  </div>
</ion-content>
