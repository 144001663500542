<ion-header class="custom-header ion-no-border" [translucent]="true">
  <div class="heading-bar pop-header notch-space title-with-button">
    <div class="close">
      <app-back-icon (click)="close()"></app-back-icon>
    </div>
    <h2>{{'campaigns_preview.title' | translate}}</h2>
  </div>
</ion-header>

<ion-content class="grey-bg no-border-radius" #content>
  <div class="web-content campaigns-preview" [class.web-preview]="appService.isWebLayout">
    <ng-container *ngIf="selectedAd">
      <ng-container *ngIf="selectedAd?.platforms.includes(adwizardPlatforms.facebook)">
        <ng-container *ngIf="selectedAd?.type === 'story'">
          <div class="story-container">
            <video *ngIf="selectedAd?.video_uri" class="video-background" autoplay loop [muted]="true" playsinline>
              <source [src]="selectedAd?.video_uri" type="video/mp4">
            </video>
            <img class="image-background" *ngIf="selectedAd?.image_uri" [src]="selectedAd?.image_uri" />
            <div class="content">
                <div class="header">
                    <div class="user-info">
                        <div class="profile-pic"></div>
                        <div>
                            <div class="username">{{'campaigns_preview.username' | translate}}</div>
                            <div class="sponsored">{{'campaigns_preview.sponsored' | translate}}</div>
                        </div>
                    </div>
                    <div class="close">×</div>
                </div>
                <div class="aspect-ratio">
                </div>
                <div class="footer">
                  <div class="footer-container">
                    <div class="arrow-up">⌃</div>
                    <div href="#" class="learn-more">{{'campaigns_preview.learn_more' | translate}}</div>
                  </div>
                    <div class="arrow-right"><img class="send" src="assets/images/ads/instagram/send-white.svg" /></div>
                </div>
            </div>
        </div>
        </ng-container>
        
        <ng-container *ngIf="selectedAd?.type === 'post'">
          <app-ad-previews
            [previewData]="selectedAd"
            [platform]="adwizardPlatforms.facebook">
          </app-ad-previews>
        </ng-container>
        
        <ng-container *ngIf="selectedAd?.type !== 'story' && selectedAd?.type !== 'post'">
          <div class="post">
            <h3>{{'campaigns_preview.not_supported_type' | translate}}</h3>
          </div>
        
        </ng-container>
      </ng-container>
      <ng-container *ngIf="selectedAd?.platforms.includes(adwizardPlatforms.instagram)">
        <ng-container *ngIf="selectedAd?.type === 'story'">
          <div class="story-container">
            <video *ngIf="selectedAd?.video_uri" class="video-background" autoplay loop [muted]="true" playsinline>
                <source [src]="selectedAd?.video_uri" type="video/mp4">
            </video>
            <img class="image-background" *ngIf="selectedAd?.image_uri" [src]="selectedAd?.image_uri" />
            <div class="content">
                <div class="header">
                    <div class="user-info">
                        <div class="profile-pic"></div>
                        <div>
                            <div class="username">{{'campaigns_preview.username' | translate}}</div>
                            <div class="sponsored">{{'campaigns_preview.sponsored' | translate}}</div>
                        </div>
                    </div>
                    <div class="close">×</div>
                </div>
                <div class="aspect-ratio">
                </div>
                <div class="footer">
                  <div class="footer-container">
                    <div class="arrow-up">⌃</div>
                    <div href="#" class="learn-more">{{'campaigns_preview.learn_more' | translate}}</div>
                  </div>
                    <div class="arrow-right"><img class="send" src="assets/images/ads/instagram/send-white.svg" /></div>
                </div>
            </div>
        </div>
      </ng-container>

        <ng-container *ngIf="selectedAd?.type === 'post'">
          <app-ad-previews
            [previewData]="selectedAd"
            [platform]="adwizardPlatforms.instagram">
          </app-ad-previews>
        </ng-container>
        
        <ng-container *ngIf="selectedAd?.type !== 'story' && selectedAd?.type !== 'post'">
          <div class="post">
            <h3>{{'campaigns_preview.not_supported_type' | translate}}</h3>
          </div>

        </ng-container>
      </ng-container>
      <ng-container *ngIf="selectedAd?.platforms.includes(adwizardPlatforms.google)">
        <app-ad-previews
          [previewData]="selectedAd"
          [platform]="adwizardPlatforms.google">
        </app-ad-previews>
      </ng-container>
      <ng-container *ngIf="selectedAd?.platforms.includes(adwizardPlatforms.tiktok)">
        <app-ad-previews
          [previewData]="selectedAd"
          [platform]="adwizardPlatforms.tiktok">
        </app-ad-previews>
      </ng-container>
      <ng-container>
        <button (click)="openAdwizard()" class="button-choose">{{'buttons.choose' | translate}}</button>
      </ng-container>
    </ng-container>
    <app-no-data-available
      *ngIf="!selectedAd"
      [showInternalServerError]="false">
    </app-no-data-available>
  </div>
</ion-content>