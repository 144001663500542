<svg id="Filter" xmlns="http://www.w3.org/2000/svg" width="12" height="12" viewBox="0 0 12 12">
  <g id="Oval" transform="translate(0 6)" fill="none" stroke="#14181c" stroke-miterlimit="10" stroke-width="2">
    <circle cx="3" cy="3" r="3" stroke="none"/>
    <circle cx="3" cy="3" r="2" fill="none"/>
  </g>
  <rect id="Rectangle" width="7" height="2" transform="translate(5 8)" fill="#14181c"/>
  <g id="Oval-2" data-name="Oval" transform="translate(6)" fill="none" stroke="#14181c" stroke-miterlimit="10" stroke-width="2">
    <circle cx="3" cy="3" r="3" stroke="none"/>
    <circle cx="3" cy="3" r="2" fill="none"/>
  </g>
  <rect id="Rectangle-2" data-name="Rectangle" width="7" height="2" transform="translate(0 2)" fill="#14181c"/>
</svg>

