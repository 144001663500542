<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="121.939" height="99.908" viewBox="0 0 121.939 99.908">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_57" data-name="Rectangle 57" width="121.939" height="99.908" fill="none"/>
    </clipPath>
  </defs>
  <g id="Group_208" data-name="Group 208" clip-path="url(#clip-path)">
    <path id="Path_137" data-name="Path 137" d="M76.564,211.693l.122-2.755a8.9,8.9,0,0,1,4.151-1.051c-1.993,1.63-1.744,4.771-3.1,6.963a5.353,5.353,0,0,1-3.925,2.474l-1.668,1.021a8.967,8.967,0,0,1,1.89-7.267,8.671,8.671,0,0,1,1.592-1.479c.4,1.054.933,2.1.933,2.1" transform="translate(-41.192 -118.923)" fill="#f2f2f2"/>
    <path id="Path_138" data-name="Path 138" d="M149.53,52.627a14.255,14.255,0,0,0,5.3-14.673c-.033-.137-.069-.273-.106-.408-.892-3.259-3.1-6.357-6.312-7.4-2.7-.874-5.645-.2-8.461-.549-5.528-.683-9.813-5.155-12.829-9.838s-5.333-9.924-9.34-13.794c-6.6-6.377-17.378-7.75-25.567-3.6s-13.4,13.36-13.182,22.538A27.665,27.665,0,0,0,92.618,47.514c2.907,1.71,6.269,2.922,9.615,2.5,2.9-.368,5.541-1.927,8.431-2.391,4.543-.728,9.057,1.326,12.986,3.72s7.725,5.231,12.19,6.34c4.335,1.077,9.707-2.019,13.691-5.054" transform="translate(-45.208 0)" fill="#f2f2f2"/>
    <path id="Path_139" data-name="Path 139" d="M96.021,27.2h-44.8a3.529,3.529,0,0,0-3.525,3.525v22.3a3.529,3.529,0,0,0,3.525,3.525h44.8a3.528,3.528,0,0,0,3.525-3.525v-22.3A3.528,3.528,0,0,0,96.021,27.2" transform="translate(-27.284 -15.558)" fill="#fff"/>
    <path id="Path_140" data-name="Path 140" d="M95.791,56.486h-44.8a3.7,3.7,0,0,1-3.7-3.7v-22.3a3.7,3.7,0,0,1,3.7-3.7h44.8a3.7,3.7,0,0,1,3.7,3.7v22.3a3.7,3.7,0,0,1-3.7,3.7m-44.8-29.348a3.355,3.355,0,0,0-3.351,3.351v22.3a3.355,3.355,0,0,0,3.351,3.351h44.8a3.355,3.355,0,0,0,3.351-3.351v-22.3a3.355,3.355,0,0,0-3.351-3.351Z" transform="translate(-27.053 -15.326)" fill="#ccc"/>
    <path id="Path_141" data-name="Path 141" d="M106.175,106.843H63.24a1.4,1.4,0,1,1,0-2.8h42.935a1.4,1.4,0,0,1,0,2.8" transform="translate(-35.376 -59.519)" fill="#e6e6e6"/>
    <path id="Path_142" data-name="Path 142" d="M95.26,58.737a1.509,1.509,0,0,1-.727-.189,1.487,1.487,0,0,1-.781-1.321V44.782a1.506,1.506,0,0,1,2.314-1.271l11.805,6.222a1.506,1.506,0,0,1,0,2.543L96.066,58.5a1.5,1.5,0,0,1-.806.238" transform="translate(-53.631 -24.756)" fill="#f2f2f2"/>
    <path id="Path_143" data-name="Path 143" d="M232.278,48.512H213.556a1.475,1.475,0,0,1-1.473-1.473V37.721a1.474,1.474,0,0,1,1.473-1.473h18.723a1.475,1.475,0,0,1,1.473,1.473v9.318a1.475,1.475,0,0,1-1.473,1.473" transform="translate(-121.323 -20.736)" fill="#fff"/>
    <path id="Path_144" data-name="Path 144" d="M232.046,48.453H213.324a1.648,1.648,0,0,1-1.646-1.646V37.488a1.648,1.648,0,0,1,1.646-1.646h18.723a1.648,1.648,0,0,1,1.646,1.646v9.318a1.648,1.648,0,0,1-1.646,1.646M213.324,36.189a1.3,1.3,0,0,0-1.3,1.3v9.318a1.3,1.3,0,0,0,1.3,1.3h18.723a1.3,1.3,0,0,0,1.3-1.3V37.488a1.3,1.3,0,0,0-1.3-1.3Z" transform="translate(-121.092 -20.504)" fill="#ccc"/>
    <path id="Path_145" data-name="Path 145" d="M233.359,71.82H220.746a1.4,1.4,0,0,1,0-2.8h12.612a1.4,1.4,0,1,1,0,2.8" transform="translate(-125.48 -39.484)" fill="#e6e6e6"/>
    <path id="Path_146" data-name="Path 146" d="M231.853,49.689a.631.631,0,0,1-.3-.079.619.619,0,0,1-.326-.552v-5.2a.629.629,0,0,1,.966-.532l4.933,2.6a.629.629,0,0,1,0,1.063l-4.933,2.6a.626.626,0,0,1-.336.1" transform="translate(-132.272 -24.729)" fill="#FF9999"/>
    <path id="Path_147" data-name="Path 147" d="M204.34,142.186H185.618a1.475,1.475,0,0,1-1.473-1.473v-9.318a1.474,1.474,0,0,1,1.473-1.473H204.34a1.474,1.474,0,0,1,1.473,1.473v9.318a1.475,1.475,0,0,1-1.473,1.473" transform="translate(-105.341 -74.323)" fill="#fff"/>
    <path id="Path_148" data-name="Path 148" d="M204.109,142.128H185.387a1.648,1.648,0,0,1-1.646-1.646v-9.318a1.648,1.648,0,0,1,1.646-1.646h18.723a1.648,1.648,0,0,1,1.646,1.646v9.318a1.648,1.648,0,0,1-1.646,1.646m-18.723-12.264a1.3,1.3,0,0,0-1.3,1.3v9.318a1.3,1.3,0,0,0,1.3,1.3h18.723a1.3,1.3,0,0,0,1.3-1.3v-9.318a1.3,1.3,0,0,0-1.3-1.3Z" transform="translate(-105.11 -74.091)" fill="#ccc"/>
    <path id="Path_149" data-name="Path 149" d="M205.422,165.552H192.809a1.4,1.4,0,0,1,0-2.8h12.612a1.4,1.4,0,1,1,0,2.8" transform="translate(-109.498 -93.104)" fill="#e6e6e6"/>
    <path id="Path_150" data-name="Path 150" d="M203.809,144.028a.633.633,0,0,1-.63-.631v-5.2a.628.628,0,0,1,.967-.531l4.933,2.6a.63.63,0,0,1,.194.87.619.619,0,0,1-.194.193l-4.933,2.6a.626.626,0,0,1-.337.1" transform="translate(-116.23 -78.696)" fill="#FF9999"/>
    <path id="Path_151" data-name="Path 151" d="M58.1,81.4l-9.583.064s-5.458-1.759-7.933-1.392l-.946,3.718,6.8,2.252,11.831-1.716A1.694,1.694,0,1,0,58.1,81.4Z" transform="translate(-22.675 -45.778)" fill="#a0616a"/>
    <path id="Path_152" data-name="Path 152" d="M38.276,80.278l-11.369-3.27-1.035-3.857a3.115,3.115,0,0,1,.241-3.278c.971-1.314,1.729-.009,3.107.87,2.414.171,8.442,2.233,10.888,4.621l-.193,1.467Z" transform="translate(-14.597 -39.652)" fill="#3f3d56"/>
    <path id="Path_153" data-name="Path 153" d="M76.432,66.91a1.509,1.509,0,0,1-.727-.189,1.487,1.487,0,0,1-.781-1.321V52.955a1.506,1.506,0,0,1,2.314-1.271l11.805,6.222a1.506,1.506,0,0,1,0,2.543L77.238,66.672a1.5,1.5,0,0,1-.806.238" transform="translate(-42.861 -29.432)" fill="#FF9999"/>
    <path id="Path_154" data-name="Path 154" d="M59.915,215.174h2.419l1.15-9.329H59.914Z" transform="translate(-34.274 -117.755)" fill="#a0616a"/>
    <path id="Path_155" data-name="Path 155" d="M58.474,224.355h.681l2.658-1.081,1.425,1.081h0a3.036,3.036,0,0,1,3.035,3.035v.1h-7.8Z" transform="translate(-33.45 -127.725)" fill="#2f2e41"/>
    <path id="Path_156" data-name="Path 156" d="M19.669,210.513l2.291.775,4.082-8.467-3.381-1.145Z" transform="translate(-11.252 -115.37)" fill="#a0616a"/>
    <path id="Path_157" data-name="Path 157" d="M17.551,220.114l.645.218,2.864-.171,1,1.481h0a3.036,3.036,0,0,1,1.9,3.849l-.032.093-7.387-2.5Z" transform="translate(-9.465 -125.917)" fill="#2f2e41"/>
    <path id="Path_158" data-name="Path 158" d="M22.813,132.426a25.67,25.67,0,0,0,.424,6.637c.706,3.248,2.225,14.029,2.225,14.029a33.222,33.222,0,0,0-5.2,15.075l4.332.347L30.8,153.8l.628-14.734,4.519,10.026-.232.775a.873.873,0,0,0,.373.991s-.423,2.753-.141,2.753a3.374,3.374,0,0,1,.989.424,25.054,25.054,0,0,0-.383,15.695l3.812-.173,2.5-21.593-1.835-11.721-.141-2.259-.635.026-.324-.944-.594-1.624Z" transform="translate(-11.592 -75.189)" fill="#2f2e41"/>
    <path id="Path_159" data-name="Path 159" d="M22.328,91.988l-.025-.035c-1.344-1.882.677-6.4.983-7.059L22,84.477l-1.123-9.5-.821-3.283A7.207,7.207,0,0,1,22.1,64.708l1.9-1.79,2.307-3.413h3.72L31.3,61.914a5.423,5.423,0,0,1,4.2,5.536L35.21,73.77l1.132,7.868v1.424l1.965,3.222.428,2A8.976,8.976,0,0,1,40.081,90.5c0,.064-.047.126-.148.194-.8.54-5.2,1.639-7.15.86-2.076-.83-10.329.415-10.413.428Z" transform="translate(-11.351 -34.04)" fill="#3f3d56"/>
    <path id="Path_160" data-name="Path 160" d="M121.939,232.7c0,.113-.228.206-.51.206H.513c-.283,0-.513-.092-.513-.206s.23-.206.513-.206H121.426c.283,0,.513.092.514.2Z" transform="translate(0 -133)" fill="#ccc"/>
    <path id="Path_161" data-name="Path 161" d="M33.832,33.9a4.927,4.927,0,1,1-4.927,4.927A4.927,4.927,0,0,1,33.832,33.9" transform="translate(-16.535 -19.394)" fill="#a0616a"/>
    <path id="Path_162" data-name="Path 162" d="M63.419,52.747l-7.6,5.835s-5.413,1.891-7.165,3.678l1.489,3.535,6.78-2.307,8.4-8.509a1.693,1.693,0,1,0-1.9-2.231" transform="translate(-27.832 -29.528)" fill="#a0616a"/>
    <path id="Path_163" data-name="Path 163" d="M46.973,73.345l-10.56,3.092-3.631-1.289A3.113,3.113,0,0,1,31,72.389c-.02-1.634,1.373-1.051,3-1.181,2.028-1.321,8.079-3.314,11.471-2.887l.732,1.287Z" transform="translate(-17.731 -39.05)" fill="#3f3d56"/>
    <path id="Path_164" data-name="Path 164" d="M35.974,30.1c-.353-.01-.232-.676-.517-.888,0,.006,0,.013,0,.019a.693.693,0,0,1-1.325.056,1.054,1.054,0,0,0-.669-.7A1.727,1.727,0,0,1,32.418,30a4.3,4.3,0,0,1-1.808.32,8,8,0,0,0-1.842.14,2.1,2.1,0,0,0-1.436,1.067,1.233,1.233,0,0,0,.486,1.6,1.1,1.1,0,0,0-.95.766,3.05,3.05,0,0,0-.094,1.286,6.6,6.6,0,0,0,1.579,4.407,4.85,4.85,0,0,0,3.368,1.17,5.4,5.4,0,0,0,2.181-.427s1.119-3.21.837-3.525a1.519,1.519,0,0,1-.465-1.14.725.725,0,0,1,.894-.637c-.066-.168-.116-.2-.147-.192a1.48,1.48,0,0,0,.052-.146,1.667,1.667,0,0,1,1.041-1.1,1.293,1.293,0,0,1,1.412.428A2.338,2.338,0,0,0,35.974,30.1" transform="translate(-15.299 -16.352)" fill="#2f2e41"/>
    <path id="Path_165" data-name="Path 165" d="M142.015,27.2V31.9c0,.234-.409.43-.96.484a.03.03,0,0,0-.014,0c-.092.007-.189.012-.288.012a2.525,2.525,0,0,1-.892-.146l-.078-.033-.074-.04c-.137-.082-.217-.177-.217-.279V27.2Z" transform="translate(-79.797 -15.558)" fill="#ccc"/>
    <path id="Path_166" data-name="Path 166" d="M232.013,152.466a2.144,2.144,0,1,1-2.144,2.144,2.143,2.143,0,0,1,2.144-2.144" transform="translate(-131.498 -87.219)" fill="#3f3d56"/>
    <path id="Path_167" data-name="Path 167" d="M233.649,157.12h-.014a.281.281,0,0,1-.208-.108l-.358-.46a.281.281,0,0,1,.049-.394l.013-.01a.281.281,0,0,1,.394.049.2.2,0,0,0,.31.015l.728-.768a.281.281,0,0,1,.4-.011l.012.011a.281.281,0,0,1,.011.4h0l-1.129,1.191a.28.28,0,0,1-.2.088" transform="translate(-133.295 -88.873)" fill="#fff"/>
    <path id="Path_168" data-name="Path 168" d="M259.546,59.341a2.144,2.144,0,1,1-2.144,2.144,2.144,2.144,0,0,1,2.144-2.144" transform="translate(-147.248 -33.946)" fill="#3f3d56"/>
    <path id="Path_169" data-name="Path 169" d="M261.182,63.995h-.014a.282.282,0,0,1-.208-.108l-.358-.461a.28.28,0,0,1,.049-.393l.013-.011a.281.281,0,0,1,.394.05.2.2,0,0,0,.287.036.215.215,0,0,0,.023-.021l.728-.768a.281.281,0,0,1,.4-.011l.012.011a.28.28,0,0,1,.011.4h0l-1.129,1.192a.28.28,0,0,1-.2.087" transform="translate(-149.045 -35.6)" fill="#fff"/>
  </g>
</svg>

