import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-domain-icon',
    templateUrl: './domain-icon.component.html',
    styleUrls: ['./domain-icon.component.scss'],
    standalone: false
})
export class DomainIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
