import { HttpRequest, HttpHandler, HttpEvent, HttpInterceptor, HttpErrorResponse } from '@angular/common/http';
import { Observable, throwError, from } from 'rxjs';
import { catchError, switchMap } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { Preferences } from '@capacitor/preferences';
import { Config } from '../services/config';
import { LanguageService } from '../services/language/language.service';
import { AppService } from '../services/app-service/app.service';
import { environment as env } from 'src/environments/environment';
import { Capacitor } from '@capacitor/core';

// Part of S3 URL used to exclude the Authorization header from the request
const S3UploadBaseURL = 'amazonaws.com';

@Injectable()
export class Interceptor implements HttpInterceptor {
  constructor(private languageService: LanguageService, private appService: AppService) {
  }
  // eslint-disable-next-line @typescript-eslint/no-explicit-any
  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
    const requestUrl = request.url;
    const termsConditionsUrl = this.appService.getTermsAndConditionsURL();
    const authExcludedEndpoints = [
      Config.staticApiUrl + Config.apiUrl.ping.url,
      Config.staticApiUrl + Config.apiUrl.login.url,
      Config.staticApiUrl + Config.apiUrl.initializeApp.url,
      Config.staticApiUrl + Config.apiUrl.signup.url,
      Config.staticApiUrl + Config.apiUrl.resendVerification.url,
      termsConditionsUrl
    ];
    const excludeAuth = authExcludedEndpoints.some(url => (requestUrl === url || requestUrl.includes(S3UploadBaseURL)));
    request = request.clone({
      setHeaders: {
        // eslint-disable-next-line @typescript-eslint/naming-convention
        'Accept-Language': this.languageService.currentLanguage
      }
    });

    if (requestUrl !== termsConditionsUrl) {
      request = request.clone({
        headers: request.headers
        .set('X-App-Theme', this.appService.darkThemeToggle ? 'dark' : 'light')
        .set('X-App-Version', env.appVersion + '-' + Capacitor.getPlatform())
      });
    }

    return from((async () => {
      if (requestUrl !== termsConditionsUrl) {
        const appTrackingStatus = await this.appService.getAppTrackingStatus();
        request = request.clone({
          headers: request.headers.set('X-App-Tracking-Enabled', appTrackingStatus.toString())
        });
      }

      if (env.apiDomain.includes('ngrok-free.app')) {
        request = request.clone({
          headers: request.headers.set('ngrok-skip-browser-warning', '42')
        });
      }

      // Handle Authorization if required
      if (!excludeAuth) {
        const tokenObject = await Preferences.get({ key: 'auth' });
        const token = tokenObject.value;
        if (token) {
          request = request.clone({
            headers: request.headers.set('Authorization', `Bearer ${token}`)
          });
        }
      }

      return request;
    })()).pipe(
      switchMap(updatedRequest =>
        next.handle(updatedRequest).pipe(
          catchError((error: HttpErrorResponse) => {
            // Handle specific HTTP errors
            if (error.status === 401 && error.url !== Config.staticApiUrl + Config.apiUrl.login.url) {
              this.appService.logout();
            }
            return throwError(() => error);
          })
        )
      )
    );
  }
}
