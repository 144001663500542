/* eslint-disable @typescript-eslint/naming-convention */
/* eslint-disable @typescript-eslint/no-explicit-any */
import { Component, Input, OnInit } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { AdWizardField, AdWizardFieldOption, AdWizardResponse } from 'src/app/models/apiResponse';
import { AppService } from 'src/app/services/app-service/app.service';
import { TranslateService } from '@ngx-translate/core';
import { ApiEndpoint } from 'src/app/models/apiRequest';
import { Config } from 'src/app/services/config';
import { ModalController } from '@ionic/angular';

const defaultMinValidation = 1;
const defaultMaxValidation = 100;

@Component({
    selector: 'app-integration-configurations',
    templateUrl: './integration-configurations.component.html',
    styleUrls: ['./integration-configurations.component.scss'],
    standalone: false
})
export class IntegrationConfigurationsComponent  implements OnInit {

  @Input() id;

  public configFields: AdWizardField[];
  public configFieldsForm: FormGroup;
  public configFieldsFormSubmitted = false;
  minInputLimits: { [fieldName: string]: number } = {};
  maxInputLimits: { [fieldName: string]: number } = {};

  constructor(
    public appService: AppService,
    public formBuilder: FormBuilder,
    private translate: TranslateService,
    public modalController: ModalController
  ) { }

  ngOnInit() {
    this.getIntegrationConfigurations();
  }

  close(): void {
    this.modalController.dismiss();
  }

  async getIntegrationConfigurations(): Promise<void> {
    await this.appService.showLoader();
    const integrationConfiguration: ApiEndpoint = this.appService.getClone(Config.apiUrl.integrationConfiguration);
    integrationConfiguration.url = integrationConfiguration.urlStart + this.id + integrationConfiguration.urlEnd;
    this.appService.api({}, integrationConfiguration).subscribe({
      next: async (configurationFields: AdWizardResponse) => {
        this.configFields = configurationFields?.fields;
        this.configFieldsForm = this.buildForm(this.configFields);
        await this.appService.hideLoader();
      },
      error: async () => {
        await this.appService.hideLoader();
        this.appService.presentToast(this.translate.instant('toast_message.general_error'));
        this.modalController.dismiss();
      }
    })
  }

  getFormFieldError(form: FormGroup, field: AdWizardField) {
    const formControl = form.get(field.field);
    const fieldName = field.label_translation;

    if (formControl.hasError('required')) {
      return `${fieldName} ` + this.translate.instant('adwizard.is_required').toLowerCase();
    }
    if (formControl.hasError('pattern')) {
      return `${fieldName} ` + this.translate.instant('adwizard.is_invalid').toLowerCase();
    }
    if (formControl.hasError('min')) {
      return this.translate.instant('adwizard.value_greater_equal') + ` ${formControl.errors?.min.min}.`;
    }
    if (formControl.hasError('max')) {
      return this.translate.instant('adwizard.value_less_equal') + ` ${formControl.errors?.max.max}.`;
    }
    return '';
  }

  buildForm(formStructure: AdWizardField[]): FormGroup {
    const formControls = {};

    // Iterate through API response to build form controls dynamically
    formStructure.forEach((field) => {
      if (field.type === 'input' || field.type === 'combo_box' || field.type === 'multiselect') {
        const validations = [];
        if (field.mandatory) { validations.push(Validators.required) }
        if (field.regex) { validations.push(Validators.pattern(field.regex)) }
        formControls[field.field] = [
          field.default || '',
          validations
        ];
      }
      else if (field.type === 'multiinput') {

        this.minInputLimits[field.field] = defaultMinValidation;
        this.maxInputLimits[field.field] = defaultMaxValidation;

        formControls[field.field] = this.formBuilder.array(
          [this.createInput(field, true)],
          [Validators.minLength(this.minInputLimits[field.field]), Validators.maxLength(this.maxInputLimits[field.field])]
        )
      }
    });
    return this.formBuilder.group(formControls);
  }

  getPlaceholder(field: AdWizardField): string {
    return field.placeholder ? field.placeholder_translation : '';
  }

  getOptionValueLabel(option: any): string {
    return option.label_translation;
  }

  canAddMultiInputs(fieldLength: number, fieldName: string): boolean {
    if (this.minInputLimits[fieldName] >= 1 && fieldLength < this.maxInputLimits[fieldName]) {
      return true;
    }
    return false;
  }

  canRemoveMultiInputs(fieldLength: number): boolean {
    if (fieldLength > 1) {
      return true;
    }
    return false;
  }

  removeInput(form: FormGroup, fieldName: string, index: number, event: Event): void {
    event.stopPropagation();
    const inputs = form.get(fieldName) as FormArray;
    inputs.removeAt(index);
  }

  addInput(form: FormGroup, fieldName: string, event: Event): void {
    event.stopPropagation();
    let currentField = {};
    currentField = this.configFields.filter(field => field.field === fieldName)[0];

    const inputs = form.get(fieldName) as FormArray;

    if (inputs.length < this.maxInputLimits[fieldName]) {
      inputs.push(this.createInput(currentField));
    }
  }

  createInput(field: any, addDefault: boolean = false): FormGroup {
    const validations = [];
    if (this.minInputLimits[field.field] > 1 || field.mandatory) { validations.push(Validators.required) }
    if (field.regex) { validations.push(Validators.pattern(field.regex)) }

    return this.formBuilder.group({
        value: [
          ((field.default && addDefault) ? field.default[0] : ''),
          validations
        ],
      });
  }

  getInputs(form: FormGroup, fieldName: string): any {
    return (form.get(fieldName) as any) as FormGroup;
  }

  getControlValue(form: FormGroup, fieldName: string, index: number, multiInputIndex: number = -1): string {
    const fieldArray = form.get(fieldName) as FormArray;
    const control = (index >= 0) ? fieldArray.at(index).get('value') as FormControl : fieldArray;
    if (multiInputIndex >= 0) {
      return control ? (control.value[multiInputIndex].value.value) ? control.value[multiInputIndex].value.value :  control.value[multiInputIndex].value : '';
    }
    return control ? (control.value.value) ? control.value.value : control.value : '';
  }

  async submitFields(): Promise<void> {
    this.configFieldsFormSubmitted = true;
    if (this.configFieldsForm.valid) {
      const configuration = this.getFormData(this.configFieldsForm);
      this.modalController.dismiss({ configuration });
    }
  }

  getFormData(form: FormGroup): Record<string,any> {
    const formData = {};
      Object.keys(form.controls).forEach(fieldName => {
        const fieldArray = form.get(fieldName) as any;

        if (Array.isArray(fieldArray.value)) {
          const formDataArray = fieldArray.value.map((input: { value: string }) => input.value ? input.value : input);
          formData[fieldName] = formDataArray.filter(item => item !== '');
        }
        else if (typeof fieldArray.value === 'object') {
          const rangeValue = form.get(fieldName).value;
          if (rangeValue && (rangeValue.lower || rangeValue.lower === 0) && rangeValue.upper) {
            formData[fieldName] = [rangeValue.lower, rangeValue.upper];
          } else {
            formData[fieldName] = rangeValue;
          }
        }
        else {
          formData[fieldName] = form.controls[fieldName].value;
        }
      });

      return formData;
  }

  selectSearchable(field: string, selectedData: AdWizardFieldOption[]): void {
    const formValue: string[] = [];
    selectedData.map((data: AdWizardFieldOption) => {
      formValue.push(data?.value?.toString());
    })
    this.configFieldsForm.controls[field]?.setValue(formValue);
  }
}
