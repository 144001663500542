import { Component, OnInit } from '@angular/core';
import { DateFilters } from 'src/app/core/enum';
import { FilterService } from 'src/app/services/filter-service/filter.service';

@Component({
  selector: 'app-filter',
  templateUrl: './filter.component.html',
  styleUrls: ['./filter.component.scss'],
})
export class FilterComponent  implements OnInit {
  public dateFilters = DateFilters;
  constructor(
    public filterService: FilterService
  ) { }

  ngOnInit() {}

}
