<svg width="15px" height="3px" viewBox="0 0 13 3" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <g id="EFFEKTIFY" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Statistics---Advsertising" transform="translate(-345.000000, -265.000000)" fill="#AAAAAA">
          <g id="Group-2" transform="translate(0.000000, 232.000000)">
              <g id="more-vertical" transform="translate(351.500000, 34.500000) rotate(90.000000) translate(-351.500000, -34.500000) translate(350.000000, 28.000000)">
                  <circle id="Oval" cx="1.5" cy="6.5" r="1.5"></circle>
                  <circle id="Oval" cx="1.5" cy="1.5" r="1.5"></circle>
                  <circle id="Oval" cx="1.5" cy="11.5" r="1.5"></circle>
              </g>
          </g>
      </g>
  </g>
</svg>
