import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-search-big',
  templateUrl: './search-big.component.html',
  styleUrls: ['./search-big.component.scss'],
})
export class SearchBigComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
