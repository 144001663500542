import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-earnings-icon',
  templateUrl: './earnings-icon.component.html',
  styleUrls: ['./earnings-icon.component.scss'],
})
export class EarningsIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
