import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-create-copy',
  templateUrl: './create-copy.component.html',
  styleUrls: ['./create-copy.component.scss'],
})
export class CreateCopyComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
