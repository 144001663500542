import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { GestureDetail, ItemReorderEventDetail, ModalController } from '@ionic/angular';
import { ApiEndpoint, OverviewPayload } from 'src/app/models/apiRequest';
import { AvailableHighlightsList, HighlightAvailableData, TeamResponse } from 'src/app/models/apiResponse';
import { AppService } from 'src/app/services/app-service/app.service';
import { Config } from 'src/app/services/config';
import { Gesture, GestureController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { FirebaseCrashlyticsService } from 'src/app/services/firebase-crashlytics/firebase-crashlytics.service';

interface ExtendedHighlightsList extends HighlightAvailableData {
  checked?: boolean;
 }

@Component({
    selector: 'app-customize-highlights',
    templateUrl: './customize-highlights.component.html',
    styleUrls: ['./customize-highlights.component.scss'],
    standalone: false
})
export class CustomizeHighlightsComponent implements OnInit, AfterViewInit {
  @Input() selectedHighlightsArray: string[];
  @Input() periodFilter: OverviewPayload;
  @ViewChild('swipeContent', {read: ElementRef}) private swipeContentElm: ElementRef;
  public searchText: string;
  public highlightsList: ExtendedHighlightsList[];
  public skeletonArray = new Array(4);
  public showSkeleton = true;
  public showNoDataAlert = false;
  public showInternalServerErrorAlert = false;
  public selectedTeam: TeamResponse;
  constructor(
    private modalCtrl: ModalController,
    private appService: AppService,
    private gestureCtrl: GestureController,
    private translate: TranslateService,
    private crashlyticsService: FirebaseCrashlyticsService
  ) { }

  ngOnInit() {
    this.crashlyticsService.customizeCrashlytics({ page: 'Customize highlights' });
    this.selectedTeam = this.appService.getTeam();
    this.fetchHighlightsData();

    //Delete highlights from overview payload
    if (this.periodFilter?.highlights) {
      delete this.periodFilter.highlights;
    }
  }

  // Custom swipe back gesture
  ngAfterViewInit(): void {
    const gesture: Gesture = this.gestureCtrl.create({
      el: this.swipeContentElm?.nativeElement,
      threshold: 20,
      gestureName: 'swipe-back-gesture',
      onEnd: (detail: GestureDetail) => {
        if (detail?.startX < 60 && detail?.deltaX > 0) {
          this.close();
        }
      }
    });
    gesture?.enable();
  }

  close() {
    this.modalCtrl.dismiss();
  }

  async fetchHighlightsData() {
    await this.appService.showLoader();
    const apiEndpoint: ApiEndpoint = this.appService.getClone(Config.apiUrl.availableHighlights);
    apiEndpoint.url = apiEndpoint.urlStart + this.selectedTeam.id + apiEndpoint.urlEnd;
    this.appService.api(this.periodFilter, apiEndpoint).subscribe({
      next: async (highlightsList: AvailableHighlightsList) => {
        this.showNoDataAlert = false;
        this.showInternalServerErrorAlert = false;
        if (!highlightsList) {
          this.showNoDataAlert = true;
        } else {
          const listSortOrder = this.appService.getHighlightsSectionsBasedOnTeam()?.split(',');
          const list = highlightsList?.result?.slice() || [];
          //Sorting list based on preference
          list?.sort((item1: ExtendedHighlightsList, item2: ExtendedHighlightsList) => {
            const index1 = listSortOrder.indexOf(item1.code);
            const index2 = listSortOrder.indexOf(item2.code);
            return ( (index1 > -1 ? index1 : Infinity) - (index2 > -1 ? index2 : Infinity));
          });
          //Assigning checked property to list
          list?.map((data: ExtendedHighlightsList) => {
            const index = this.selectedHighlightsArray.findIndex(item => item === data.code);
            data.checked = index > -1;
          })
          this.highlightsList = list;
        }
        this.showSkeleton = false;
        await this.appService.hideLoader();
      },
      error: async () => {
        this.appService.presentToast(this.translate.instant('customize_highlights.failed_fetch'));
        await this.appService.hideLoader();
      }
    })
  }

  selectSections(code: string): void {
    const listIndex = this.highlightsList.findIndex(item => item.code === code);
    this.highlightsList[listIndex].checked = this.highlightsList[listIndex].checked ? false : true;
  }

  saveSections(): void {
    const listStringified: string[] = [];
    this.highlightsList.map((list: ExtendedHighlightsList) => {
      if (list.checked) {
        listStringified.push(list.code);
      }
    });
    this.appService.setHighlightsSectionsBasedOnTeam(listStringified.map((code: string) => code).join(','));
    this.modalCtrl.dismiss({ selectedHighlightsArray: listStringified });
  }

  handleReorder(ev: CustomEvent<ItemReorderEventDetail>): void {
    ev.detail.complete();
    const element = this.highlightsList.splice(ev.detail.from, 1)[0];
    this.highlightsList.splice(ev.detail.to, 0, element);
  }

  getElementIndex(el, array) {
    return array.find(elm => elm === el)
  }
}
