// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.booking-wrapper {
  background: var(--grey-bg);
}

.remaining-time {
  position: absolute;
  bottom: 25px;
  background-color: #fff;
  padding: 10px;
  border-left: 4px solid var(--ion-color-primary);
  color: #000;
  margin: 4px;
  z-index: 999;
}

.calendly-wrapper {
  min-width: 320px;
  height: 85vh;
}`, "",{"version":3,"sources":["webpack://./src/app/components/book-meeting/book-meeting.component.scss"],"names":[],"mappings":"AAAA;EACE,0BAAA;AACF;;AACA;EACE,kBAAA;EACA,YAAA;EACA,sBAAA;EACA,aAAA;EACA,+CAAA;EACA,WAAA;EACA,WAAA;EACA,YAAA;AAEF;;AAAA;EACE,gBAAA;EACA,YAAA;AAGF","sourcesContent":[".booking-wrapper {\n  background: var(--grey-bg);\n}\n.remaining-time {\n  position: absolute;\n  bottom: 25px;\n  background-color: #fff;\n  padding: 10px;\n  border-left: 4px solid var(--ion-color-primary);\n  color: #000;\n  margin: 4px;\n  z-index: 999;\n}\n.calendly-wrapper {\n  min-width: 320px;\n  height: 85vh;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
