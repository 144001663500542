<ion-content #swipeContent class="no-border-radius signup-acc-cover">
  <div class="pop-header signup-account notch-space title-with-button">
    <div class="close">
      <app-back-icon (click)="dismiss()"></app-back-icon>
    </div>
  </div>
  <div class="set-account-section">
    <h2>{{'create_team.setup_team_title' | translate}}</h2>
    <div class="form-area">
      <form [formGroup]="teamForm">
        <div class="fields-section">
          <ion-list>
            <ion-item>
              <ion-input class="ion-text-left" formControlName="name" placeholder="{{'placeholders.name' | translate}}*"></ion-input>
              <!-- Name error message -->
              <ion-note color="danger" class="error" slot="error" *ngIf="isTeamFormSubmitted && (errorControl.name.errors?.required || errorControl.name.errors?.whitespace)">
                {{'validations.name_required' | translate}}
              </ion-note>
            </ion-item>
            <ion-item lines="none">
              <ion-label class="btn-text-toggle">{{ 'create_team.use_business_info' | translate }}</ion-label>
              <div class="custom-toggle">
                <ion-toggle mode="ios" (ionChange)="populateCurrentTeam($event)"></ion-toggle>
              </div>
            </ion-item>
            <ion-item>
              <ion-input class="ion-text-left" formControlName="postal_code" (ionInput)='onChangePostalAndCountry()' placeholder="{{'placeholders.zipcode' | translate}}*"></ion-input>
              <!-- Zip code error message -->
              <ion-note color="danger" class="error" slot="error" *ngIf="isTeamFormSubmitted && errorControl.postal_code.errors?.required">
                {{'validations.zipcode_required' | translate}}
              </ion-note>
              <ion-note color="danger" class="error" slot="error" *ngIf="errorControl.postal_code.errors?.invalidPostalCode">
                {{'validations.invalid_zipcode' | translate}}
              </ion-note>
            </ion-item>
            <ion-item>
              <ion-select interface="action-sheet" formControlName="country_code" (ionChange)='onChangePostalAndCountry()' [interfaceOptions]="{cssClass: 'custom-action-sheet', header: 'placeholders.country_region' | translate}" placeholder="{{'placeholders.country_region' | translate}}*" mode="md">
                <ion-select-option [value]="country?.code" *ngFor="let country of countries">{{country?.name}}</ion-select-option>
              </ion-select>
              <!-- Country error message -->
              <ion-note color="danger" class="error" slot="error" *ngIf="isTeamFormSubmitted && errorControl.country_code.errors?.required">
                {{'validations.country_required' | translate}}
              </ion-note>
            </ion-item>
            <ion-item>
              <ion-input class="ion-text-left" formControlName="city" placeholder="{{'placeholders.city' | translate}}*"></ion-input>
              <!-- City error message -->
              <ion-note color="danger" class="error" slot="error" *ngIf="isTeamFormSubmitted && errorControl.city.errors?.required">
                {{'validations.city_required' | translate}}
              </ion-note>
              <ion-note color="danger" class="error" slot="error" *ngIf="errorControl.city.errors?.noCityFound">
                {{'validations.city_not_found' | translate}}
              </ion-note>
            </ion-item>
          </ion-list>
        </div>
        <div class="business-type-selection">
          <div class="inner-label">{{'placeholders.business_type' | translate}}*</div>
          <!-- Business type error message -->
          <ion-note color="danger" class="error" slot="error" *ngIf="isTeamFormSubmitted && errorControl.business_type.errors?.required">
            {{'validations.business_type_required' | translate}}
          </ion-note>
          <ion-list>
            <ion-item lines="none" [class.active]="teamForm.get('business_type').value === businessTypes.ecommerce">
              <input type="radio" id="e-commerce" [value]="businessTypes.ecommerce" formControlName="business_type">
              <label for="e-commerce">{{'signup_business.ecommerce' | translate}}</label>
            </ion-item>
            <ion-item lines="none" [class.active]="teamForm.get('business_type').value === businessTypes.retail">
              <input type="radio" id="retail" [value]="businessTypes.retail" formControlName="business_type">
              <label for="retail">{{'signup_business.retail' | translate}}</label>
            </ion-item>
            <ion-item lines="none" [class.active]="teamForm.get('business_type').value === businessTypes.food">
              <input type="radio" id="food" [value]="businessTypes.food" formControlName="business_type">
              <label for="food">{{'signup_business.food' | translate}}</label>
            </ion-item>
            <ion-item lines="none" [class.active]="teamForm.get('business_type').value === businessTypes.finance">
              <input type="radio" id="finance" [value]="businessTypes.finance" formControlName="business_type">
              <label for="finance">{{'signup_business.finance' | translate}}</label>
            </ion-item>
            <ion-item lines="none" [class.active]="teamForm.get('business_type').value === businessTypes.it">
              <input type="radio" id="it" [value]="businessTypes.it" formControlName="business_type">
              <label for="it">{{'signup_business.it' | translate}}</label>
            </ion-item>
            <ion-item lines="none" [class.active]="teamForm.get('business_type').value === businessTypes.development">
              <input type="radio" id="development" [value]="businessTypes.development" formControlName="business_type">
              <label for="development">{{'signup_business.development' | translate}}</label>
            </ion-item>
            <ion-item lines="none" [class.active]="teamForm.get('business_type').value === businessTypes.sales">
              <input type="radio" id="sales" [value]="businessTypes.sales" formControlName="business_type">
              <label for="sales">{{'signup_business.sales' | translate}}</label>
            </ion-item>
            <ion-item lines="none" [class.active]="teamForm.get('business_type').value === businessTypes.unknown">
              <input type="radio" id="other" [value]="businessTypes.unknown" formControlName="business_type">
              <label for="other">{{'signup_business.other' | translate}}</label>
            </ion-item>
          </ion-list>
        </div>
      </form>
    </div>
    <div class="button-section">
      <ion-button class="ion-padding" shape="round" expand="block" (click)="submitForm()">{{'buttons.create_team' | translate}}</ion-button>
    </div>
  </div>
</ion-content>
