import { Injectable } from '@angular/core';
import { FormControl } from '@angular/forms';
import { HttpClient, HttpHeaders, HttpErrorResponse } from '@angular/common/http';
import {
  Observable,
  throwError,
  BehaviorSubject,
  lastValueFrom,
  timer
} from 'rxjs';
import {
  catchError,
  delay,
  map,
  retry
} from 'rxjs/operators';
import { Config } from 'src/app/services/config';
import {
  AlertController,
  LoadingController,
  ModalController,
  NavController,
  ToastController
} from '@ionic/angular';
import {
  ApiEndpoint,
  ApiData,
  LogoutPayload,
  InitializeAppPayload
} from 'src/app/models/apiRequest';
import {
  AdsCampaign,
  AppFeatureList,
  ConfigResponse,
  Country,
  CreateCampaignConfig,
  Customer,
  CustomerOrder,
  DynamicMarketingContent,
  InitializeAccountSignup,
  InitializeAppResponse,
  LogoutResponse,
  MeResponse,
  OnboardingIntroConfig,
  SuccessResponse,
  TeamResponse,
  UserAuthenticationConfig,
  UserResponse
} from 'src/app/models/apiResponse';
import { Preferences } from '@capacitor/preferences';
import { Network } from '@capacitor/network';
import { Device } from '@capacitor/device';
import { EventsService } from '../events/events.service';
import { environment as env } from 'src/environments/environment';
import { InAppBrowser } from '@awesome-cordova-plugins/in-app-browser/ngx';
import { ApiAppMessageContent, CustomErrorResponse, HighlightsPreferences, TrendsPreferences } from 'src/app/models/interface';
import { Capacitor } from '@capacitor/core';
import cloneDeep from 'lodash-es/cloneDeep'
import { StatusBar, Style } from '@capacitor/status-bar';
import { LanguageService } from '../language/language.service';
import { TranslateService } from '@ngx-translate/core';
import { Router } from '@angular/router';
import { TermsConditionsComponent } from 'src/app/components/terms-conditions/terms-conditions.component';
import { InformationPopupComponent } from 'src/app/modals/information-popup/information-popup.component';
import { SelectTeamComponent } from 'src/app/modals/select-team/select-team.component';
import { AppRoutes } from 'src/app/constants/app-routes';

const defaultHighlightsPreferences = 'returning_visitors,engagement_rate,average_session_duration,ctr,conversion_rate,roas';
const defaultTrendsPreferences = 'conversions,visitors,roas,roi,bounce_rate';
const limitMobileResolutionWidth = 768;
const DefaultDelayTime = 2000;

interface StorageObject {
  value: string;
}

@Injectable({
  providedIn: 'root'
})

export class AppService {
  public appRoutes = AppRoutes;
  public currentUser: UserResponse = null;
  public selectedTeam: TeamResponse = null;
  public highlightsPreferences: HighlightsPreferences = {};
  public trendsPreferences: TrendsPreferences = {};
  public teams: TeamResponse[];
  public appFeatureList = {};
  private isLoaderActive = false;
  private sessionToken = '';
  private authApiEndpoint = '';
  private selectDomainModal: HTMLIonModalElement;
  private calendlyURL = '';
  private termsAndConditionsURL = '';
  public unreadNotificationCount = 0;
  public darkThemeToggle = false;
  public availableLanguages = [];
  private appUpdateAlert: HTMLIonAlertElement|undefined;
  public customers: Customer[] = [];
  public customerOrders = {};
  public dynamicLinks = {};
  initializeAPILoaded$ = new BehaviorSubject<boolean>(false);
  public isNativePlatform = Capacitor.isNativePlatform();
  public availableCountries: Country[] = [];
  public signupInitialValues: InitializeAccountSignup;
  public onboardingIntroConfig: OnboardingIntroConfig;
  public userAuthenticationConfig: UserAuthenticationConfig;
  public isWebLayout = false;
  public dynamicMarketingContent: DynamicMarketingContent = undefined;
  public createCampaign: CreateCampaignConfig = undefined;
  public appBaseUrl = '';
  constructor(
    private router: Router,
    private http: HttpClient,
    private loadingCtrl: LoadingController,
    private navController: NavController,
    private events: EventsService,
    private toastController: ToastController,
    private iab: InAppBrowser,
    private modalCtrl: ModalController,
    private alertController: AlertController,
    private language: LanguageService,
    private translate: TranslateService
  ) {
    this.initService();
  }

  getHostname(): string {
    const currentPort = window.location.port;
    return `${window.location.protocol}//${window.location.hostname}${currentPort ? ':' + currentPort : ''}`;
  }

  addDelay(callback: () => void, delayTime: number = DefaultDelayTime) {
    // Emit a value after the specified delay
    timer(delayTime).pipe(
      delay(0) // Added a small delay to ensure execution is deferred
    ).subscribe(() => {
      // Execute the callback function after the delay
      callback();
    });
  }

  async initService(): Promise<void> {
    await this.getStorage('selectedTeam').then((selectedTeam) => {
      this.selectedTeam = selectedTeam ? JSON.parse(selectedTeam) : null;
    })

    await this.getStorage('appThemeMode').then((appThemeMode) => {
      if(appThemeMode) {
        this.initializeAppTheme((appThemeMode === 'dark'));
      } else {
        const prefersDark = window.matchMedia('(prefers-color-scheme: dark)');
        this.initializeAppTheme(prefersDark.matches, false);
      }
    })

    Preferences.get({ key: 'user' }).then((data) => {
      this.currentUser = JSON.parse(data.value);
    })

    Preferences.get({ key: 'highlightsPreferences' }).then((data) => {
      this.highlightsPreferences = JSON.parse(data.value) || {};
    })

    Preferences.get({ key: 'trendsPreferences' }).then((data) => {
      this.trendsPreferences = JSON.parse(data.value) || {};
    })

    Network.addListener('networkStatusChange', status => {
      if (status.connected) {
        setTimeout(() => {
          this.reInitializeApp();
        }, 1000);
      }
    });

    // Web app
    if (!Capacitor.isNativePlatform()) {
      this.isWebLayout = (window.innerWidth > limitMobileResolutionWidth && !this.isNativePlatform);
      window.addEventListener('resize', () => {
        this.isWebLayout = (window.innerWidth > limitMobileResolutionWidth && !this.isNativePlatform);
      });
    }

    const parsedUrl = new URL(window.location.href);
    this.appBaseUrl = parsedUrl.origin;
  }

  checkInitializeAPILoaded(): void {
    this.initializeAPILoaded$.subscribe(async (isLoaded) => {
      if (isLoaded) {
        if(!this.checkAppFeatureEnabled('signup')) {
          this.presentToast(this.translate.instant('toast_message.general_error'));
          this.navController.navigateRoot(this.appRoutes.onboardPage);
        }
      }
    });
  }

  runDemoFeaturesAfterInitializeAPILoaded(): void {
    this.initializeAPILoaded$.subscribe(async (isLoaded) => {
      if (isLoaded) {
        this.events.publish('runDemoFeatures');
      }
    });
  }

  // Check/uncheck the toggle and update the theme based on isDark
  initializeAppTheme(isDark: boolean, setStorage: boolean = true): void {
    this.darkThemeToggle = isDark;
    this.toggleAppTheme(isDark, setStorage);
  }

  // Listen for the toggle check/uncheck to toggle the dark theme
  toggleAppThemeMode(ev): void {
    this.toggleAppTheme(ev.detail.checked);
  }

  // Add or remove the "dark" class on the document body
  async toggleAppTheme(isDark: boolean, setStorage: boolean = true): Promise<void> {
    document.body.classList.toggle('dark', isDark);
    if (setStorage && isDark) {
      this.setStorage('appThemeMode', 'dark');
    } else if(setStorage) {
      this.setStorage('appThemeMode', 'light');
    }
    if (Capacitor.getPlatform() === 'ios') {
      await StatusBar.setStyle({ style: isDark ? Style.Dark : Style.Light });
    }
  }

  setSessionToken(token: string = ''): Promise<void> {
    return new Promise(async (resolve) => {
      this.sessionToken  = token;
      resolve();
    })
  }

  async fetchUserNotifications(): Promise<void> {
    return await lastValueFrom(this.api({}, Config.apiUrl.me).pipe(map(async (response: MeResponse) => {
      this.unreadNotificationCount = (response?.notifications?.unread_count) || 0;
    })));
  }

  async fetchAppConfigurations(): Promise<void> {
    const apiEndpoint: ApiEndpoint = this.getClone(Config.apiUrl.config);
    apiEndpoint.url = apiEndpoint.urlStart + this.selectedTeam.id + apiEndpoint.urlEnd;
    return await lastValueFrom(this.api({}, apiEndpoint).pipe(map(async (response: ConfigResponse) => {
      this.language.updateTranslations(response.translations);
      this.setDynamicMarketingContent(response?.marketing_seo_content);
      this.setCreateCampaign(response?.create_campaign);
      this.setConfigFeatureControl(response?.features);
    })));
  }

  setDynamicMarketingContent(content: DynamicMarketingContent): void {
    this.dynamicMarketingContent = content || undefined;
  }

  setCreateCampaign(content: CreateCampaignConfig): void {
    this.createCampaign = content || undefined;
  }

  async showInformationPopup(key: string, isDirectMessage = false): Promise<void> {
    let information = this.translate.instant(key);
    if (isDirectMessage) {
      information = key;
    }
    if (information !== key || isDirectMessage) {
      const modal = await this.modalCtrl.create({
        component: InformationPopupComponent,
        cssClass: 'information-popup',
        componentProps: {
          message: information
        }
      });
      modal.present();
    } else {
      this.presentToast(this.translate.instant('toast_message.no_information_available'));
    }
  }

  async showLoader(message: string = undefined): Promise<void> {
    this.isLoaderActive = true;
    return await this.loadingCtrl.create({
      showBackdrop: true,
      message,
      cssClass: 'loading-spinner',
      spinner: Config.loadingSpinner as 'crescent',
    }).then((loader: HTMLIonLoadingElement) => {
      loader.present().then(() => {
        if (!this.isLoaderActive) {
          loader.dismiss();
        }
      });
    });
  }

  async hideLoader(): Promise<boolean> {
    if (this.isLoaderActive) {
      this.isLoaderActive = false;
      return await this.loadingCtrl.dismiss();
    }
    return;
  }

  api(data: ApiData, endpoint: ApiEndpoint, observeResponse: boolean = false): Observable<object> {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const headerObj: any = { Accept: 'application/json' };
    if (this.sessionToken) {
      headerObj.session_token = this.sessionToken;
    }
    const header = new HttpHeaders(headerObj);
    const URL = endpoint.dynamicApiUrl ? this.authApiEndpoint + endpoint.url : Config.staticApiUrl + endpoint.url;
    if (endpoint.dynamicApiUrl && !this.authApiEndpoint) {
      this.presentToast(this.translate.instant('toast_message.dynamic_server_error'));
    }
    const observeType = observeResponse ? 'response' as 'body' : 'body';

    if (endpoint.method === 'get') {
      return this.http.get(URL, { headers: header, params: data, observe: observeType })
        .pipe(
          retry(0),
          catchError((e: HttpErrorResponse) => {
            this.handleError(e);
            return throwError(() => e);
          })
        )
    }

    if (endpoint.method === 'post') {
      return this.http.post(URL, data, { headers: header, observe: observeType })
        .pipe(
          retry(0),
          catchError((e: HttpErrorResponse) => {
            this.handleError(e);
            return throwError(() => e);
          })
        )
    }

    if (endpoint.method === 'delete') {
      return this.http.delete(URL)
        .pipe(
          retry(0),
          catchError((e: HttpErrorResponse) => {
            this.handleError(e);
            return throwError(() => e);
          })
        )
    }

    if (endpoint.method === 'patch') {
      return this.http.patch(URL, data, { headers: header, observe: observeType })
        .pipe(
          retry(0),
          catchError((e: HttpErrorResponse) => {
            this.handleError(e);
            return throwError(() => e);
          })
        )
    }
  }

  // Section for handling common network error
  handleError(error: HttpErrorResponse) {
    if (!error?.status) {
      this.presentToast(this.translate.instant('toast_message.connectivity_error'));
    } else if(error?.status === 500) {
      this.presentToast(this.translate.instant('toast_message.server_error'));
    }
    console.error(error?.status, error?.statusText);

    //App-Message-Toast
    const appMessageHeader = error?.headers?.get('X-App-Message') ? error?.headers?.get('X-App-Message') : null;
    if (appMessageHeader) {
      const content: ApiAppMessageContent = JSON.parse(appMessageHeader);
      if (content?.message) {
        this.presentToast(content?.message, content?.type);
      }
    }
  };

  showApiErrorMessage(e: HttpErrorResponse): void {
    const errorResponse: CustomErrorResponse = e?.error;
    let errorMsg = errorResponse?.error?.message || errorResponse?.message;
    if (errorResponse?.validation_errors) {
      const validationErrors = errorResponse?.validation_errors;
      const firstErrorKey = Object.keys(validationErrors)?.[0];
      if (validationErrors?.[firstErrorKey] && validationErrors?.[firstErrorKey]?.[0]) {
        errorMsg = validationErrors?.[firstErrorKey]?.[0];
      }
    }
    if (e.status && e.status !== 500 && errorMsg) {
      this.presentToast(errorMsg);
    }
  }

  fetchUserDetails(): Promise<void> {
    return new Promise(async (resolve, reject) => {
      this.api({}, Config.apiUrl.me).subscribe({
        next: async (response: MeResponse) => {
          const userData = response.user;
          await this.setStorage('user', userData);
          this.currentUser = userData;
          this.events.publish('user:update', this.currentUser);
          if (response?.user.locale) {
            this.language.setLanguage(response?.user.locale);
          }
          else {
            const storageLanguage = (await Preferences.get({key: 'lang'})).value;
            this.updateUserLanguage(storageLanguage);
          }
          resolve()
        },
        error: (e: HttpErrorResponse) => {
          this.presentToast(this.translate.instant('toast_message.failed_fetch_user_data'));
          reject(e);
        }
      });
    })
  }

  getLanguage(): string {
    return this.language.currentLanguage;
  }

  getTeam(): TeamResponse {
    return this.selectedTeam;
  }

  getSelectedTeam(): Promise<TeamResponse> {
    return new Promise(async (resolve, reject) => {
      this.getStorage('selectedTeam').then((selectedTeam) => {
        this.selectedTeam = selectedTeam ? JSON.parse(selectedTeam) : null;
        if (this.selectedTeam) {
          if (this.selectedTeam?.api_endpoint) {
            this.authApiEndpoint = this.selectedTeam?.api_endpoint + '/';
          }
          resolve(this.selectedTeam);
        } else {
          reject();
        }
      })
    })
  }

  selectTeam(): Promise<TeamResponse> {
    return new Promise(async (resolve, reject) => {
      this.api({}, Config.apiUrl.teams).subscribe({
        next: async (teams: TeamResponse[]) => {
          this.setTeams(teams);
          if (teams?.length === 1 && teams[0]) {
            this.fetchTeam(teams[0]).then((team: TeamResponse) => {
              resolve(team);
            }).catch(() => reject())
          } else if (teams?.length > 1) {
            this.hideLoader();
            this.selectDomainModal = await this.modalCtrl.create({
              component: SelectTeamComponent,
              cssClass: 'no-border-radius',
              componentProps: { teams }
            });
            this.selectDomainModal.present(); // Enabled swipe back gesture

            const { data } = await this.selectDomainModal.onWillDismiss();
            if (data?.team) {
              this.showLoader();
              this.fetchTeam(data?.team).then((team: TeamResponse) => {
                resolve(team);
              }).catch(() => {
                reject();
                this.presentToast(this.translate.instant('toast_message.failed_fetch_accounts'));
              })
            } else {
              reject();
            }
          } else {
            reject();
            this.presentToast(this.translate.instant('toast_message.invalid_account'));
          }
        },
        error: (e: HttpErrorResponse) => {
          reject(e);
          this.presentToast(this.translate.instant('toast_message.failed_fetch_accounts'));
        }
      })
    })
  }

  getClone(object) {
    return cloneDeep(object);
  }

  fetchTeam(selectedTeam: TeamResponse): Promise<TeamResponse> {
    return new Promise(async (resolve, reject) => {
      const teamApiEndpoint: ApiEndpoint = this.getClone(Config.apiUrl.team);
      teamApiEndpoint.url = teamApiEndpoint.url + selectedTeam?.id;
      this.api({}, teamApiEndpoint).subscribe({
        next: async (team: TeamResponse) => {
          if (team) {
            this.selectedTeam = team;
            await this.setStorage('selectedTeam', this.selectedTeam);
            await this.setStorage('apiEndpoint', this.selectedTeam?.api_endpoint);
            if (this.selectedTeam?.api_endpoint) {
              this.authApiEndpoint = this.selectedTeam?.api_endpoint + '/';
            }
            this.reInitializeApp(true);
            resolve(this.selectedTeam);
          } else {
            reject();
          }
        },
        error: (e: HttpErrorResponse) => {
          reject(e);
        }
      })
    })
  }

  // Configuring team after creation
  setTeam(selectedTeam: TeamResponse): Promise<TeamResponse> {
    return new Promise(async (resolve, reject) => {
      if (selectedTeam) {
        this.selectedTeam = selectedTeam;
        await this.setStorage('selectedTeam', this.selectedTeam);
        await this.setStorage('apiEndpoint', this.selectedTeam?.api_endpoint);
        this.authApiEndpoint = this.selectedTeam?.api_endpoint + '/';
        this.reInitializeApp();
        this.fetchTeams();
        resolve(this.selectedTeam);
      } else {
        reject();
      }
    })
  }

  /***
   * Displays user account deletion confirmation modal
   * @returns Promise<void>
  */
  async confirmUserAccountDeletion(): Promise<void> {
    const alert = await this.alertController.create({
      mode: 'ios',
      header: this.translate.instant('sidemenu.confirm_deletion'),
      message: this.translate.instant('sidemenu.confirm_deletion_message'),
      cssClass: 'custom-alert message-text-on',
      buttons: [
        {
          text: this.translate.instant('buttons.cancel'),
          role: 'cancel',
          cssClass: 'secondary ion-text-capitalize',
        }, {
          text: this.translate.instant('buttons.ok'),
          cssClass: 'ion-text-capitalize',
          handler: () => {
            this.deleteUserAccount();
          }
        }
      ]
    });
    await alert.present();
  }

  async deleteUserAccount(): Promise<void> {
    await this.showLoader();
    this.api({}, Config.apiUrl.deleteUserAccount).subscribe({
      next: async (response: SuccessResponse) => {
        await this.hideLoader();
        if (response.success) {
          this.presentToast(this.translate.instant('toast_message.account_deleted'));
          this.events.publish('appsFlyer:deleteAccountEvent', this.currentUser);
          this.logout(); // Direct user logout without API call
        } else {
          this.presentToast((response && response.message) ? response.message : this.translate.instant('toast_message.general_error'));
        }
      },
      error: async () => {
        await this.hideLoader();
        this.presentToast(this.translate.instant('toast_message.failed_account_delete'));
      }
    })
  }

  async logoutUser(route: string = this.appRoutes.onboardPage): Promise<void> {
    await this.showLoader();
    const fcm_token = (await this.getStorage('fcm_token')) || '';
    const payload: LogoutPayload = {fcm_token};
    this.api(payload, Config.apiUrl.logout).subscribe({
      next: async (response: LogoutResponse) => {
        if (response.success) {
          this.logout(route);
        } else {
          this.presentToast((response && response.message) ? response.message : this.translate.instant('toast_message.general_error'));
        }
        await this.hideLoader();
      },
      error: async (e: HttpErrorResponse) => {
        await this.hideLoader();
        if (e.status === 401) {
          // Forced logout when JWT token issue
          this.logout();
        }
      }
    })
  }

  async logout(route: string = this.appRoutes.onboardPage): Promise<void> {
    const firstUser = await this.getStorage('firstUser');
    await Preferences.clear();
    this.currentUser = null;
    this.events.publish('user:update', this.currentUser);
    this.events.publish('intercom:logout');
    this.language.initLanguage();
    this.router.navigate([route], { replaceUrl: true }); // replaceUrl clears the navigation stack
    if (firstUser) {
      this.setStorage('firstUser', firstUser);
    }
  }

  async setStorage(key: string, data: string|object|boolean): Promise<void> {
    const value = (!(typeof data === 'string')) ? JSON.stringify(data) : data;
    await Preferences.set({key, value});
  }

  async getStorage(key: string): Promise<string> {
    const storageObj: StorageObject  = await Preferences.get({key});
    return storageObj.value ? storageObj.value : null;
  }

  isLoggedIn(): boolean {
    return !!this.currentUser;
  }

  getUserDetail(): UserResponse|null {
    return this.currentUser;
  }

  async presentToast(message: string, css: string = ''): Promise<void> {
    const toast: HTMLIonToastElement = await this.toastController.create({
      message,
      duration: 3000,
      position: 'top',
      cssClass: 'custom-toast ' + css
    });

    await toast.present();
  }

  getFormattedNumber(n: number): string {
    return n ? n?.toLocaleString('en-US') : '0';
  }

  getFormattedHeaderValue(n: number|string): number|string {
    return (n && Number.isInteger(n)) ? this.getFormattedNumber(n as number) : n;
  }

  // Move method to filter service after removing Home and Statistics pages
  getPeriodDate(type: string): string {
    let date = new Date();
    switch (type) {
      case 'today':
        date.setDate(date.getDate());
        break;
      case 'yesterday':
        date.setDate(date.getDate()-1);
        break;
      case 'weekStart':
        // eslint-disable-next-line no-var
        var day = date.getDay(),
          diff = date.getDate() - day + (day == 0 ? -6 : 1);
        date.setDate(diff);
        break;
      case 'weekEnd':
        // eslint-disable-next-line no-var
        var day = date.getDay(),
          diff = (date.getDate() - day + (day == 0 ? -6 : 1)) + 6;
        date.setDate(diff);
        break;
      case 'monthStart':
        date = new Date(date.getFullYear(), date.getMonth(), 1);
        break;
      case 'monthEnd':
        date = new Date(date.getFullYear(), date.getMonth() + 1, 0);
        break;
      case 'yearStart':
        date = new Date(new Date().getFullYear(), 0, 1);
        break;
      case 'yearEnd':
        date = new Date(new Date().getFullYear(), 11, 31);
        break;
      default:
        break;
    }

    return date.getFullYear() + '-' + (date.getMonth()+1) + '-' + date.getDate();
  }

  getAbsoluteValue(num: number): number {
    return num ? Math.abs(num) : 0;
  }

  async appInitializationPayload(): Promise<InitializeAppPayload> {
    const deviceUUID = await Device.getId();
    const deviceInfo = await Device.getInfo();
    return {
      app_id: env.appId,
      app_version: env.appVersion,
      api_version: env.apiVersion,
      app_environment: deviceInfo.platform,
      device_model: deviceInfo.manufacturer + ' ' + deviceInfo.model,
      device_os_version: deviceInfo.osVersion,
      device_uuid: deviceUUID.identifier,
      user_id: this.currentUser?.id || '',
      team_id: this.selectedTeam?.id || ''
    }
  }

  openIabUrl(url: string): void {
    if (url) {
      this.iab.create(url, '_system');
    }
  }

  noWhitespaceValidator(control: FormControl) {
    const isWhitespace = (control.value || '').trim().length === 0;
    const isValid = !isWhitespace;
    return isValid ? null : { whitespace: true };
  }

  setTeams(teams): void {
    this.teams = teams;
  }

  getTeams(): TeamResponse[]|[] {
    return this.teams || [];
  }

  async fetchTeams(): Promise<void> {
    const userData = await Preferences.get({ key: 'user' });
    if (userData?.value) {
      this.api({}, Config.apiUrl.teams).subscribe({
        next: async (teams: TeamResponse[]) => {
          this.setTeams(teams);
        }
      })
    }
  }

  setHighlightsSectionsBasedOnTeam(sections: string): void {
    this.highlightsPreferences[this.selectedTeam.id] = sections;
    this.setStorage('highlightsPreferences', JSON.stringify(this.highlightsPreferences));
  }

  getHighlightsSectionsBasedOnTeam(): string {
    return this.highlightsPreferences.hasOwnProperty(this.selectedTeam.id) ?
      this.highlightsPreferences?.[this.selectedTeam.id] : defaultHighlightsPreferences;
  }

  setTrendsSectionsBasedOnTeam(sections: string): void {
    this.trendsPreferences[this.selectedTeam.id] = sections;
    this.setStorage('trendsPreferences', JSON.stringify(this.trendsPreferences));
  }

  getTrendsSectionsBasedOnTeam(): string {
    return this.trendsPreferences.hasOwnProperty(this.selectedTeam.id) ?
      this.trendsPreferences?.[this.selectedTeam.id] : defaultTrendsPreferences;
  }

  setAppFeatureControl(featureList: AppFeatureList[]): void {
    this.appFeatureList = featureList.reduce((acc, obj) => {
      const key = obj.key;
      if (!acc[key] && obj?.enabled) {
        acc[key] = true;
      }
      return acc;
    }, {});
  }

  setConfigFeatureControl(featureList: AppFeatureList[]): void {
    const features = featureList.reduce((acc, obj) => {
      const key = obj.key;
      if (!acc[key] && obj?.enabled) {
        acc[key] = true;
      }
      return acc;
    }, {});
    this.appFeatureList = {...this.appFeatureList, ...features}

    // Dynamically check MyStore availability and switch tabs 
    if (this.router.url == this.appRoutes.myStorePage) {
      if (!this.appFeatureList['page.my_store']) {
        const route = !!this.appFeatureList['page.my_website'] ? this.appRoutes.myWebsitePage : this.appRoutes.dashboardPage;
        this.navController.navigateRoot(route);
      }
    }

    // Dynamically check MyWebsite availability and switch tabs 
    if (this.router.url == this.appRoutes.myWebsitePage) {
      if (!this.appFeatureList['page.my_website']) {
        const route = !!this.appFeatureList['page.my_store'] ? this.appRoutes.myStorePage : this.appRoutes.dashboardPage;
        this.navController.navigateRoot(route);
      }
    }
  }

  checkAppFeatureEnabled(feature: string) {
    return !!this.appFeatureList[feature];
  }

  setDynamicLinks(links: Record<string, string | object>) {
    this.dynamicLinks = links;
  }

  setOnboardingIntroConfig(introConfig: OnboardingIntroConfig): void {
    this.onboardingIntroConfig = introConfig || {};
  }

  setUserAuthentications(authentication: UserAuthenticationConfig): void {
    this.userAuthenticationConfig = authentication || {};
  }

  // ReInitialize app after login, user registration & domain change
  reInitializeApp(fetchConfig: boolean = false): void {
    this.appInitializationPayload().then(async (payload: InitializeAppPayload) => {
      this.api(payload, Config.apiUrl.initializeApp).subscribe({
        next: async (response: InitializeAppResponse) => {
          this.setCalendlyURL(response?.features);
          this.setTermsAndConditionsURL(response?.current_terms_and_conditions_uri);
          this.setAppFeatureControl(response?.features);
          this.language.setAvailableLocales(response?.available_locales);
          this.setDynamicLinks(response?.links);
          this.setOnboardingIntroConfig(response?.intro);
          this.setUserAuthentications(response?.authentication);
          this.setAvailableCountries(response?.available_countries);
          this.setSignupInitialValues(response?.sign_up);
          this.language.updateTranslations(response?.translations);
          await this.setSessionToken(response?.session_token);
          if (Capacitor.isNativePlatform()) {
            this.checkForAppUpdate(response.update_available, response.force_update);
          }
          const userData =  await Preferences.get({ key: 'user' });
          if (userData.value || fetchConfig) {
            this.events.publish('intercom:initialize');
            await this.fetchAppConfigurations();
          }
        },
        error: async () => {
          this.presentToast(this.translate.instant('toast_message.failed_reinitialization'));
        }
      });
    })
  }

  setCalendlyURL(features: AppFeatureList[]): void {
    const calendlyObj = features.find((feature: AppFeatureList) => feature.key === 'calendly_schedule_appointment')
    if (calendlyObj?.calendly_url) {
      this.calendlyURL = calendlyObj?.calendly_url;
    }
  }

  getCalendlyURL(): string {
    return this.calendlyURL || '';
  }

  setTermsAndConditionsURL(url: string): void {
    if (url) {
      this.termsAndConditionsURL = url;
    }
  }

  getTermsAndConditionsURL(): string {
    return this.termsAndConditionsURL || '';
  }

  async checkForAppUpdate(updateAvailable: boolean, forceUpdate: boolean): Promise<void> {
    if (!this.appUpdateAlert && updateAvailable) {
      this.appUpdateAlert = await this.alertController.create({
        header: forceUpdate ? this.translate.instant('app_update.important_update_title') :
          this.translate.instant('app_update.update_title'),
        message: forceUpdate? this.translate.instant('app_update.force_update_message') :
          this.translate.instant('app_update.update_message'),
        backdropDismiss: !forceUpdate,
        mode: 'ios',
        cssClass: 'custom-alert message-text-on',
        buttons: [
          {
            text: this.translate.instant('buttons.cancel'),
            role: 'cancel',
            cssClass: forceUpdate ? 'ion-hide' : ' ' + 'secondary ion-text-capitalize',
          }, {
            text: this.translate.instant('buttons.download'),
            cssClass: 'ion-text-capitalize',
            handler: () => {
              this.openEffektifyAppStore();
              return false;
            }
          }
        ]
      });
      await this.appUpdateAlert.present();
      await this.appUpdateAlert.onWillDismiss().then(()=> {this.appUpdateAlert = undefined;});
    }
  }

  openEffektifyAppStore(): void {
    if (Capacitor.getPlatform() === 'android') {
      this.iab.create(env.appPlayStoreOpenUrl, '_system');
      // eslint-disable-next-line @typescript-eslint/no-explicit-any
      (navigator as any).app.exitApp();
    } else if (Capacitor.getPlatform() === 'ios') {
      this.iab.create(env.appAppStoreOpenUrl, '_blank');
    } else {
      console.error('Oops, requires native device for this action');
    }
  }

  openEffektifyAppleStore(): void {
    this.iab.create(env.appAppStoreOpenUrl, '_blank');
  }

  openEffektifyPlayStore(): void {
    this.iab.create(env.appPlayStoreOpenUrl, '_blank');
  }

  async chooseLanguage(): Promise<void> {
    const alert = await this.alertController.create({
      header: this.translate.instant('placeholders.select_language'),
      mode: 'ios',
      cssClass: 'custom-alert',
      buttons: [
        {
          text: this.translate.instant('buttons.cancel'),
          role: 'cancel',
          cssClass: 'secondary ion-text-capitalize',
        }, {
          text: this.translate.instant('buttons.ok'),
          cssClass: 'ion-text-capitalize',
          handler: (lan: string) => {
            if (!lan.includes(this.language.currentLanguage.toLowerCase())) {
              this.language.setLanguage(lan, true);
              this.updateUserLanguage(lan, true);
              const route: string = this.router.url;
              this.events.publish('appsFlyer:changeLanguageEvent', lan);
              this.events.publish('update:tabsData:' + route);
            }
          },
        }
      ],
      inputs: this.language.getChooseLangInputs()
    });
    await alert.present();
  }

  async updateUserLanguage(selectedLanguage, showLoader = false): Promise<void> {
    if (showLoader) {
      await this.showLoader();
    }
    const apiEndpoint: ApiEndpoint = this.getClone(Config.apiUrl.updateUserDetails);
    apiEndpoint.url = apiEndpoint.url + this.currentUser.id;
    this.api({ locale: selectedLanguage }, apiEndpoint).subscribe({
      next: async () => {
        if (showLoader) {
          await this.hideLoader();
          this.presentToast(this.translate.instant('toast_message.language_update_success'));
        }
      },
      error: async () => {
        if (showLoader) {
          await this.hideLoader();
          this.presentToast(this.translate.instant('toast_message.language_update_failed'));
        }
      }
    })
  }

  // Commenting customer fetch code for future use
  fetchCustomers(): void {
    this.customerOrders = {}; // Clearing existing customer orders if any
    this.api({}, Config.apiUrl.getCustomers).subscribe({
      next: async (customers: Customer[]) => {
        this.customers = [];
        if (customers.length) {
          this.customers = customers;
        }
      },
      error: () => {
        this.presentToast(this.translate.instant('toast_message.customer_fetch_failed'));
      }
    });
  }

  async expandCustomer(ev): Promise<void> {
    if (ev.detail.value) { // Accordion expansion
      await this.showLoader();
      this.fetchOrdersForCustomers(ev.detail.value);
    }
  }

  fetchOrdersForCustomers(i: number): void {
    const selectedCustomer = this.customers[i];
    const apiEndpoint: ApiEndpoint = this.getClone(Config.apiUrl.getCustomerOrders);
    apiEndpoint.url = apiEndpoint.urlStart + selectedCustomer.id + apiEndpoint.urlEnd;
    this.api({}, apiEndpoint).subscribe({
      next: async (orders: CustomerOrder[]) => {
        this.customerOrders[i] = orders;
        await this.hideLoader();
      },
      error: async () => {
        await this.hideLoader();
        this.presentToast(this.translate.instant('toast_message.customer_order_fetch_failed'));
      }
    });
  }

  async showTermsAndConditions() {
    const termsConditionsModal = await this.modalCtrl.create({
      component: TermsConditionsComponent,
      cssClass: 'no-border-radius'
    });
    termsConditionsModal.present();
  }

  getOperatingSystem(): string {
    const userAgent = navigator?.userAgent;
    if (/windows phone/i.test(userAgent)) {
      return 'Windows Phone';
    }
    if (/android/i.test(userAgent)) {
      return 'Android';
    }
    if (/iPad|iPhone|iPod/.test(userAgent)) {
      return 'iOS';
    }
    return 'unknown';
  }
  
  setAvailableCountries(countries: Country[]): void {
    this.availableCountries = countries;
  }

  setSignupInitialValues(signup: InitializeAccountSignup): void {
    this.signupInitialValues = signup;
  }

  canShowImpressionOrReach(campaign: AdsCampaign, type: string): boolean {
    let flag = 0;

    if (campaign?.clicks) {
      flag+=1;
    }
    if (campaign?.conversions) {
      flag+=1;
    }
    if (campaign?.cost_per_conversion) {
      flag+=1;
    }
    if (campaign?.cost) {
      flag+=1;
    }
    if (type == 'reach') {
      if (campaign?.impressions) {
        flag+=1;
      }
    }
    return flag < 4;
  }

  hasDemoIntroFlag(): boolean {
    return (this.onboardingIntroConfig?.mode === 'demo');
  }

  // Check isWebAppLimitModalDisplayed for triggering demo video overlay
  isWebAppLimitModalDisplayed(): boolean {
    return !this.isNativePlatform && this.appBaseUrl.includes(env.webUrlString) && !this.isWebLayout;
  }
}
