import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';
import { AppTrackingTransparencyPluginStatus } from 'src/app/core/enum';

@Component({
    selector: 'app-app-tracking-info',
    templateUrl: './app-tracking-info.component.html',
    styleUrls: ['./app-tracking-info.component.scss'],
    standalone: false
})
export class AppTrackingInfoComponent  implements OnInit {

  @Input() infoData: string;
  @Input() scenario: string;
  public appTrackingTransparencyPluginStatus = AppTrackingTransparencyPluginStatus;
  constructor(
    private modalController: ModalController,
  ) {}

  dismissModal() {
    this.modalController.dismiss();
  }

  ngOnInit() {}

}
