<ion-content>
  <div class="rt-minimise-button" (click)="dismissVideoOverlay()">
    <ion-icon name="chevron-down-outline"></ion-icon>
    {{'modal.minimize' | translate}}
  </div>
  <div class="main-content">
    <h3><markdown [data]="'modal.video_overlay_title' | translate"></markdown></h3>
    <div class="video-cover">
      <ion-skeleton-text *ngIf="videoSkeleton" [animated]="true" class="video-skeleton" style="height: 500px; width: 80vw;"></ion-skeleton-text>
      <video #video [class.ion-hide]="videoSkeleton" playsinline preload="metadata" 
        [src]="appService.onboardingIntroConfig?.demo_video_uri+'#t=0.001'" width="100%" height="100%"></video>
      <div class="button-cover" (click)="videoOverlayPlayPause()" *ngIf="this.contentVideo?.paused">
        <div class="play-button">
          <span class="play-icon"><ion-icon name="play"></ion-icon></span>
          <!-- <span class="video-time">30sec</span> Commenting for future use -->
        </div>
      </div>
    </div>
    <div class="speedy-explanation">
      <markdown [data]="'modal.video_overlay_description' | translate"></markdown>
    </div>
  </div>
</ion-content>
