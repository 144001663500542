import { Component, OnInit, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { GestureDetail, ModalController } from '@ionic/angular';
import { Gesture, GestureController } from '@ionic/angular';

@Component({
  selector: 'app-connections',
  templateUrl: './connections.component.html',
  styleUrls: ['./connections.component.scss'],
})
export class ConnectionsComponent implements OnInit, AfterViewInit {
  @ViewChild('swipeContent', {read: ElementRef}) private swipeContentElm: ElementRef;
  constructor(
    private modalCtrl: ModalController,
    private gestureCtrl: GestureController
  ) { }

  ngOnInit(): void {
  }

  // Custom swipe back gesture
  ngAfterViewInit(): void {
    const gesture: Gesture = this.gestureCtrl.create({
      el: this.swipeContentElm?.nativeElement,
      threshold: 20,
      gestureName: 'swipe-back-gesture',
      onEnd: (detail: GestureDetail) => {
        if (detail?.startX < 60 && detail?.deltaX > 0) {
          this.dismiss();
        }
      }
    });
    gesture?.enable();
  }

  dismiss(): void {
    this.modalCtrl.dismiss();
  }

}
