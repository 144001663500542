<ion-header class="custom-header ion-no-border" [translucent]="true" #sectionHeader>
  <div class="heading-bar notch-space title-with-button">
    <div class="close" (click)="dismiss()">
      <app-back-icon></app-back-icon>
    </div>
    <h2>{{'select_domain.title' | translate}}</h2>
  </div>
</ion-header>

<ion-content #swipeContent class="grey-bg no-border-radius" *ngIf="(teams | searchBy:searchText:'name') as filteredTeams">
  <div class="search-bar">
    <ion-list>
      <ion-item lines="none">
        <div class="button-icon" slot="start">
          <app-search-icon></app-search-icon>
        </div>
        <ion-input type="text" [(ngModel)]="searchText" placeholder="{{'placeholders.search_accounts' | translate}}" [clearInput]="true"></ion-input>
      </ion-item>
    </ion-list>
  </div>
  <div class="cards-area domain-selection">
    <ion-card mode="ios" class="order-card">
      <ion-card-content>
        <ion-list mode="ios" lines="full" *ngIf="teams">
          <ion-radio-group value="team" [(ngModel)]="selectedTeamId" (ngModelChange)="handleSelection()">
            <ion-grid class="ion-no-padding ion-no-margin" *ngIf="filteredTeams.length">
              <ion-item *ngFor="let team of filteredTeams">
                <ion-radio labelPlacement="start" [value]="team?.id">{{team?.name}}</ion-radio>
              </ion-item>
            </ion-grid>
          </ion-radio-group>
        </ion-list>
      </ion-card-content>
    </ion-card>
    <div class="domain-listing-area" *ngIf="filteredTeams?.length === 0">
      <div class="domain-not-available">
        <app-domain-icon></app-domain-icon>
        <h3>{{'select_domain.no_accounts_found' | translate}}</h3>
        <p>
          {{'select_domain.dont_have_accounts' | translate}}
        </p>
      </div>
    </div>
  </div>
</ion-content>
