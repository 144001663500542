import { Component, OnInit, Input, ViewChild, ElementRef, AfterViewInit } from '@angular/core';
import { GestureDetail, ModalController } from '@ionic/angular';
import { TeamResponse } from 'src/app/models/apiResponse';
import { AppService } from 'src/app/services/app-service/app.service';
import { Gesture, GestureController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { FirebaseCrashlyticsService } from 'src/app/services/firebase-crashlytics/firebase-crashlytics.service';

@Component({
    selector: 'app-select-team',
    templateUrl: './select-team.component.html',
    styleUrls: ['./select-team.component.scss'],
    standalone: false
})
export class SelectTeamComponent implements OnInit, AfterViewInit {
  @Input() teams: TeamResponse[];
  @ViewChild('swipeContent', {read: ElementRef}) private swipeContentElm: ElementRef;
  public selectedTeamId: string;
  public savedTeam: TeamResponse;
  public searchText: string;
  constructor(
    private modalCtrl: ModalController,
    private appService: AppService,
    private gestureCtrl: GestureController,
    private translate: TranslateService,
    private crashlyticsService: FirebaseCrashlyticsService
  ) {}

  async ngOnInit(): Promise<void> {
    const savedTeamObj = await this.appService.getStorage('selectedTeam') || '';
    this.savedTeam = savedTeamObj ? JSON.parse(savedTeamObj) : null;
    if (this.savedTeam && this.savedTeam?.id) {
      this.selectedTeamId = this.savedTeam.id;
    }
    this.crashlyticsService.customizeCrashlytics({ component: 'Select account' });
  }

  // Custom swipe back gesture
  ngAfterViewInit(): void {
    const gesture: Gesture = this.gestureCtrl.create({
      el: this.swipeContentElm?.nativeElement,
      threshold: 20,
      gestureName: 'swipe-back-gesture',
      onEnd: (detail: GestureDetail) => {
        if (detail?.startX < 60 && detail?.deltaX > 0) {
          this.dismiss();
        }
      }
    });
    gesture?.enable();
  }

  dismiss(): void {
    this.modalCtrl.dismiss();
  }

  handleSelection(): void {
    if (this.selectedTeamId) {
      this.modalCtrl.dismiss({ team: this.teams.find(team => team?.id === this.selectedTeamId) });
    } else {
      this.appService.presentToast(this.translate.instant('select_domain.select_account'));
    }
  }
}
