<svg width="20px" height="20px" viewBox="0 0 20 20" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <g id="EFFEKTIFY" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd">
      <g id="Connect-Store" transform="translate(-22.000000, -68.000000)" stroke="#14181C" stroke-width="3">
          <g id="close" transform="translate(24.000000, 70.000000)">
              <line x1="0" y1="16" x2="16" y2="0" id="Path"></line>
              <line x1="16" y1="16" x2="0" y2="0" id="Path"></line>
          </g>
      </g>
  </g>
</svg>
