<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="110.919" height="96.954" viewBox="0 0 110.919 96.954">
  <g id="Group_216" data-name="Group 216">
    <path id="Path_170" data-name="Path 170" d="M32.144,49.887,23.537,47.54q3.448-3.253,3-6.129h5.607Z" transform="translate(-14.279 -25.122)" fill="#9f616a"/>
    <path id="Path_171" data-name="Path 171" d="M25.616,171.378l-2.78.391-3.349-30.775L14.14,171.638l-2.869.13v-38.86H29.006Z" transform="translate(-6.837 -80.628)" fill="#2f2e41"/>
    <path id="Path_172" data-name="Path 172" d="M12.012,235.27h0a1.955,1.955,0,0,0,1.939-1.429l.2-3.03-.409-2.149a2.365,2.365,0,0,0-3.275,0l-.352,4.5a1.955,1.955,0,0,0,1.8,2.1l.1.005" transform="translate(-6.135 -138.316)" fill="#2f2e41"/>
    <path id="Path_173" data-name="Path 173" d="M41.185,235.27h0a1.955,1.955,0,0,0,1.939-1.429l.2-3.03-.409-2.149a2.365,2.365,0,0,0-3.275,0l-.352,4.5a1.955,1.955,0,0,0,1.8,2.1l.1.005" transform="translate(-23.832 -138.316)" fill="#2f2e41"/>
    <path id="Path_174" data-name="Path 174" d="M34.792,26.493a4.955,4.955,0,1,1-4.956,4.956,4.956,4.956,0,0,1,4.956-4.956" transform="translate(-18.1 -16.072)" fill="#9f616a"/>
    <path id="Path_175" data-name="Path 175" d="M29.185,84.892H10.276c2.451-11.358,2.444-20.505.492-27.9a1.977,1.977,0,0,1,1.2-2.35l5.087-1.957c1.527,1.972,3.683,2.834,7.042,1.565l2.666.547a2.739,2.739,0,0,1,2.186,2.864c-.2,2.894-.119,5.809-.288,8.326-.523,7.793-.749,13.869.522,18.908" transform="translate(-6.234 -31.959)" fill="#e6e6e6"/>
    <path id="Path_176" data-name="Path 176" d="M30.9,20.22a4.224,4.224,0,0,1,6.259-.131l.913.261-.651.522s3.39,1.565,3.129,4.825a1.491,1.491,0,0,0-.782,0c-.261.13-.391,1.565-.391,1.565a8.222,8.222,0,0,0-.522-1.174c-.131-.13-1.956,1.435-4.955-.913,0,0-.913,2.347-1.957,2.086a2.462,2.462,0,0,0-3.129,1.565S25.3,21.263,30.9,20.22" transform="translate(-16.82 -11.363)" fill="#2f2e41"/>
    <path id="Path_177" data-name="Path 177" d="M57.412,98.715l-1.674,2.244a2.2,2.2,0,0,0,.448,3.077l.027.02a2.2,2.2,0,0,0,3.459-1.435l.479-2.993.913-12.91-.652-6.52-4.173,1.565,1.174,5.607Z" transform="translate(-33.548 -48.651)" fill="#9f616a"/>
    <path id="Path_178" data-name="Path 178" d="M53.944,69.853l5.086-2.347-.261-1.848a7.585,7.585,0,0,0-4.843-7.072l-1.547-.6Z" transform="translate(-31.775 -35.177)" fill="#e6e6e6"/>
    <path id="Path_179" data-name="Path 179" d="M3.651,97.389l1.674,2.244a2.2,2.2,0,0,1-.448,3.077l-.027.02A2.2,2.2,0,0,1,1.392,101.3L.913,98.3,0,85.392l.652-6.52,4.173,1.565L3.651,86.044Z" transform="translate(0 -47.847)" fill="#9f616a"/>
    <path id="Path_180" data-name="Path 180" d="M5.749,68.527.663,66.18l.261-1.848A7.585,7.585,0,0,1,5.767,57.26l1.547-.6Z" transform="translate(-0.402 -34.372)" fill="#e6e6e6"/>
    <path id="Path_181" data-name="Path 181" d="M160.874,119.387H92.106a1.085,1.085,0,0,1-1.084-1.083V88.4a1.085,1.085,0,0,1,1.084-1.084h68.769a1.085,1.085,0,0,1,1.084,1.084V118.3a1.085,1.085,0,0,1-1.084,1.083M92.106,87.747a.651.651,0,0,0-.65.65V118.3a.651.651,0,0,0,.65.65h68.769a.651.651,0,0,0,.65-.65V88.4a.651.651,0,0,0-.65-.65Z" transform="translate(-55.218 -52.968)" fill="#e6e6e6"/>
    <path id="Path_182" data-name="Path 182" d="M99.936,96.68a.65.65,0,0,0,0,1.3h9.1a.65.65,0,0,0,0-1.3Z" transform="translate(-60.231 -58.65)" fill="#e6e6e6"/>
    <path id="Path_183" data-name="Path 183" d="M164.688,107.774V119.83H103.312v-6.348c.425-.273.852-.544,1.284-.807a17.766,17.766,0,0,1,5.964-2.573,9,9,0,0,1,3.079-.039,17.6,17.6,0,0,1,3.133.922,21.366,21.366,0,0,0,3.073.964,8.985,8.985,0,0,0,3.116.061,26.9,26.9,0,0,0,5.674-1.807,18.418,18.418,0,0,1,5.783-1.493,17.263,17.263,0,0,1,5.83.961c3.84,1.185,7.762,2.943,11.828,1.737,4.129-1.223,7.928-4.751,12.5-3.7a.174.174,0,0,1,.108.068" transform="translate(-62.673 -65.223)" fill="#e6e6e6"/>
    <path id="Path_184" data-name="Path 184" d="M164.621,107.984a.186.186,0,0,1-.21.092,6.836,6.836,0,0,0-1.663-.172c-4.5.079-7.962,3.7-12.364,4.245-3.968.488-7.7-1.442-11.435-2.45a13.541,13.541,0,0,0-5.933-.446,31.419,31.419,0,0,0-5.7,1.881A16.557,16.557,0,0,1,121.7,112.5c-2.139.036-4.065-.846-6.048-1.517a10.767,10.767,0,0,0-3.183-.653,9.621,9.621,0,0,0-3.137.485,25.281,25.281,0,0,0-5.856,3.051.163.163,0,0,1-.109.03.2.2,0,0,1-.085-.361l.085-.054c.424-.273.851-.545,1.281-.807a17.711,17.711,0,0,1,5.952-2.573,8.966,8.966,0,0,1,3.073-.039,17.508,17.508,0,0,1,3.127.922,21.316,21.316,0,0,0,3.066.964,8.968,8.968,0,0,0,3.11.061,26.828,26.828,0,0,0,5.662-1.807,18.354,18.354,0,0,1,5.771-1.493,17.2,17.2,0,0,1,5.817.961c3.832,1.185,7.746,2.943,11.8,1.737,4.121-1.222,7.911-4.751,12.478-3.7a.173.173,0,0,1,.108.068.2.2,0,0,1,0,.21" transform="translate(-62.606 -65.223)" fill="#FF9999"/>
    <path id="Path_185" data-name="Path 185" d="M123.428,112.112a.9.9,0,1,1-.9.9.9.9,0,0,1,.9-.9" transform="translate(-74.332 -68.012)" fill="#FF9999"/>
    <path id="Path_186" data-name="Path 186" d="M181.085,109.181a.9.9,0,1,1-.9.9.9.9,0,0,1,.9-.9" transform="translate(-109.31 -66.234)" fill="#FF9999"/>
    <path id="Path_187" data-name="Path 187" d="M231.446,106.249a.9.9,0,1,1-.9.9.9.9,0,0,1,.9-.9" transform="translate(-139.861 -64.455)" fill="#FF9999"/>
    <path id="Path_188" data-name="Path 188" d="M122.524,151.772a.65.65,0,0,0,0,1.3h9.1a.65.65,0,0,0,0-1.3Z" transform="translate(-73.934 -92.071)" fill="#e6e6e6"/>
    <path id="Path_189" data-name="Path 189" d="M104.864,147a2.456,2.456,0,1,1-2.456,2.456A2.456,2.456,0,0,1,104.864,147" transform="translate(-62.125 -89.175)" fill="#FF9999"/>
    <path id="Path_190" data-name="Path 190" d="M178.351,151.772a.65.65,0,1,0,0,1.3h9.1a.65.65,0,1,0,0-1.3Z" transform="translate(-107.8 -92.071)" fill="#e6e6e6"/>
    <path id="Path_191" data-name="Path 191" d="M160.691,147a2.456,2.456,0,1,1-2.456,2.456A2.456,2.456,0,0,1,160.691,147" transform="translate(-95.992 -89.175)" fill="#FF9999"/>
    <path id="Path_192" data-name="Path 192" d="M234.177,151.772a.65.65,0,1,0,0,1.3h9.1a.65.65,0,0,0,0-1.3Z" transform="translate(-141.667 -92.071)" fill="#e6e6e6"/>
    <path id="Path_193" data-name="Path 193" d="M216.517,147a2.456,2.456,0,1,1-2.456,2.456A2.456,2.456,0,0,1,216.517,147" transform="translate(-129.858 -89.175)" fill="#FF9999"/>
    <path id="Path_194" data-name="Path 194" d="M202.465,52.785H168.537c-.661,0-1.2-.382-1.2-.852V44.558c0-.47.537-.852,1.2-.852h33.927c.662,0,1.2.382,1.2.852v7.374c0,.47-.537.852-1.2.853m-33.927-8.738c-.4,0-.718.229-.719.511v7.374c0,.282.322.511.719.511h33.927c.4,0,.719-.229.719-.511V44.558c0-.282-.322-.511-.719-.511Z" transform="translate(-101.515 -26.514)" fill="#e6e6e6"/>
    <path id="Path_195" data-name="Path 195" d="M188.773,54.36a.723.723,0,0,0-.722.722v5.057a.723.723,0,0,0,.722.723h25.861a.724.724,0,0,0,.723-.723V55.082a.724.724,0,0,0-.723-.722Z" transform="translate(-114.08 -32.977)" fill="#FF9999"/>
    <path id="Path_196" data-name="Path 196" d="M196.618,58.767a1.589,1.589,0,1,1-1.589,1.589,1.589,1.589,0,0,1,1.589-1.589" transform="translate(-118.313 -35.651)" fill="#fff"/>
    <path id="Path_197" data-name="Path 197" d="M216.478,61.054a.65.65,0,0,0,0,1.3h9.1a.65.65,0,0,0,0-1.3Z" transform="translate(-130.93 -37.038)" fill="#fff"/>
    <path id="Path_198" data-name="Path 198" d="M137.824,9.079H103.9c-.661,0-1.2-.382-1.2-.852V.852c0-.47.537-.852,1.2-.852h33.928c.662,0,1.2.382,1.2.852V8.226c0,.47-.537.852-1.2.853M103.9.341c-.4,0-.718.229-.719.511V8.226c0,.282.322.511.719.511h33.928c.4,0,.719-.229.719-.511V.852c0-.282-.322-.511-.719-.511Z" transform="translate(-62.301)" fill="#e6e6e6"/>
    <path id="Path_199" data-name="Path 199" d="M124.131,10.654a.723.723,0,0,0-.722.722v5.057a.723.723,0,0,0,.722.723h25.861a.724.724,0,0,0,.723-.723V11.376a.724.724,0,0,0-.723-.722Z" transform="translate(-74.865 -6.463)" fill="#FF9999"/>
    <path id="Path_200" data-name="Path 200" d="M131.976,15.061a1.589,1.589,0,1,1-1.589,1.589,1.589,1.589,0,0,1,1.589-1.589" transform="translate(-79.098 -9.137)" fill="#fff"/>
    <path id="Path_201" data-name="Path 201" d="M151.837,17.347a.65.65,0,1,0,0,1.3h9.1a.65.65,0,0,0,0-1.3Z" transform="translate(-91.716 -10.523)" fill="#fff"/>
    <path id="Path_202" data-name="Path 202" d="M113.763,245.7c0,.1-.2.189-.456.19H5.146c-.254,0-.459-.085-.459-.19s.206-.19.459-.19H113.3c.253,0,.458.084.459.188Z" transform="translate(-2.843 -148.94)" fill="#ccc"/>
  </g>
</svg>

