import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-options-icon',
    templateUrl: './options-icon.component.html',
    styleUrls: ['./options-icon.component.scss'],
    standalone: false
})
export class OptionsIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
