<!-- <svg class="header-logo" id="Group_201" data-name="Group 201" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="140.988" height="33.809" viewBox="0 0 140.988 33.809">
  <defs>
    <clipPath id="clip-path">
      <rect id="Rectangle_49" data-name="Rectangle 49" width="140.988" height="33.809" fill="none"/>
    </clipPath>
  </defs>
  <g id="Group_200" data-name="Group 200" clip-path="url(#clip-path)">
    <path id="Path_35" data-name="Path 35" d="M1340.462,44.982V40.329c.116.059.216.107.314.158a6.289,6.289,0,0,0,1.436.557,3.411,3.411,0,0,0,2.043-.122,2.416,2.416,0,0,0,1.008-.712,4.856,4.856,0,0,0,.8-1.449.3.3,0,0,0-.033-.244l-1.049-2.437-4.923-11.437c-.031-.072-.064-.143-.1-.217h-4.016c-.011.463,0,.917-.005,1.371s0,.911,0,1.367V38.108h-5.314c-.021-.5-.006-1.008-.009-1.513s0-1.017,0-1.526V24.413c-.249-.021-.478-.008-.706-.011s-.463,0-.694,0h-1.379c-.043-.163-.057-3.744-.017-4.058.9,0,1.813,0,2.746,0,.006-.095.016-.178.016-.26a12.133,12.133,0,0,1,.081-1.72,6.019,6.019,0,0,1,5.02-5.465,10.575,10.575,0,0,1,2.167-.192,8.76,8.76,0,0,1,3.1.585,2.745,2.745,0,0,1,.757.434L1340.33,17.5l-.212-.1a5.435,5.435,0,0,0-1.578-.5,2.072,2.072,0,0,0-1.909.583,2.526,2.526,0,0,0-.527.9,5.105,5.105,0,0,0-.273,1.791.951.951,0,0,0,.018.114h.236c1.163,0,2.326,0,3.489,0q.565,0,1.13.02c.323.011.649.014.969.057a3.75,3.75,0,0,1,2.017.982,4,4,0,0,1,1.038,1.479q1.926,4.655,3.858,9.307c.063.151.135.3.2.44a.332.332,0,0,0,.039-.005.03.03,0,0,0,.016-.01.762.762,0,0,0,.047-.086l5.049-12.092a.192.192,0,0,1,.019-.025h5.08a.8.8,0,0,1-.031.127q-2.438,5.718-4.878,11.434-1.806,4.233-3.61,8.467a9.934,9.934,0,0,1-1.907,2.968,6.438,6.438,0,0,1-3.468,1.9,9.892,9.892,0,0,1-2.782.129,7.347,7.347,0,0,1-1.706-.331c-.062-.02-.122-.045-.19-.071" transform="translate(-1218.056 -11.655)" fill="#fff"/>
    <path id="Path_36" data-name="Path 36" d="M827.992,23.711c-2.429,2.484-4.906,4.867-7.315,7.332l8.238,10.468c-.079,0-.122.01-.165.01q-3.053,0-6.107,0a.323.323,0,0,1-.279-.143q-2.535-3.148-5.074-6.293c-.124-.154-.249-.309-.374-.462-.032-.04-.07-.074-.119-.125l-.406.418c-.673.691-1.343,1.384-2.021,2.07a.541.541,0,0,0-.167.418c.006,1.281,0,2.562,0,3.843,0,.084-.007.168-.011.263h-5.22c-.008-.048-.02-.1-.023-.147s0-.119,0-.178q0-12.222,0-24.445v-.289c.156-.045,4.93-.061,5.26-.02.023.463.007.93.01,1.4s0,.938,0,1.407v11.3a.471.471,0,0,0,.233-.166q1.294-1.2,2.586-2.4,2.1-1.944,4.208-3.884c.092-.085.189-.166.273-.259a.4.4,0,0,1,.331-.136q2.964.006,5.928,0c.057,0,.114.005.214.01" transform="translate(-742.09 -15.055)" fill="#fff"/>
    <path id="Path_37" data-name="Path 37" d="M568.841,107.4H555.135c.016.13.022.239.043.346a3.063,3.063,0,0,0,.561,1.212,4.77,4.77,0,0,0,3.055,1.93,7.5,7.5,0,0,0,3.473-.114,5.957,5.957,0,0,0,2.424-1.376c.048-.043.1-.085.161-.14l2.817,3.045c-.051.065-.1.127-.146.185a6.4,6.4,0,0,1-1.083.984,9.337,9.337,0,0,1-3.681,1.583,12.584,12.584,0,0,1-3.055.232,12.067,12.067,0,0,1-5.269-1.348,8.531,8.531,0,0,1-4.43-5.583,10.079,10.079,0,0,1-.271-3.084,9.277,9.277,0,0,1,.734-3.225,8.856,8.856,0,0,1,5.472-5,10.609,10.609,0,0,1,2.379-.49A13.069,13.069,0,0,1,560,96.483a8.915,8.915,0,0,1,6.945,3.335,8.566,8.566,0,0,1,1.9,4.37,12.637,12.637,0,0,1,.026,3.041c-.005.051-.02.1-.034.171m-4.938-3.161a1.79,1.79,0,0,0-.015-.186,4.047,4.047,0,0,0-2.138-2.982,4.713,4.713,0,0,0-4.664.059,3.864,3.864,0,0,0-1.9,2.383,2.974,2.974,0,0,0-.138.726Z" transform="translate(-504.281 -88.506)" fill="#fff"/>
    <path id="Path_38" data-name="Path 38" d="M19.149,107.395H5.42c.016.089.025.151.039.213a4.058,4.058,0,0,0,2.027,2.662A5.8,5.8,0,0,0,9.729,111a6.885,6.885,0,0,0,3.839-.611,6.073,6.073,0,0,0,1.337-.88c.09-.076.184-.147.287-.229l2.8,3.036c-.042.055-.079.107-.12.155A7.436,7.436,0,0,1,15.957,114a9.545,9.545,0,0,1-3.077,1.107,12.3,12.3,0,0,1-2.8.193,12.034,12.034,0,0,1-5.587-1.505A8.468,8.468,0,0,1,.2,107.726c-.027-.149-.049-.3-.071-.449-.011-.077,0-.156-.091-.2-.028-.016-.039-.08-.039-.122,0-.641,0-1.281,0-1.922,0-.026-.006-.062.008-.077.165-.171.115-.4.142-.6a9.068,9.068,0,0,1,6.8-7.511,10.174,10.174,0,0,1,1.952-.312,16,16,0,0,1,1.7-.025,9.474,9.474,0,0,1,3.8,1.026,8.758,8.758,0,0,1,2.886,2.28,8.589,8.589,0,0,1,1.659,3.408,9.941,9.941,0,0,1,.315,3.041c-.022.342-.056.684-.085,1.026a.727.727,0,0,1-.031.108m-4.934-3.14c-.005-.083,0-.149-.014-.213a3.9,3.9,0,0,0-2.121-2.956,5.09,5.09,0,0,0-1.992-.54,4.435,4.435,0,0,0-2.7.582,3.86,3.86,0,0,0-1.936,2.714,1.252,1.252,0,0,0-.029.413Z" transform="translate(0 -88.518)" fill="#fff"/>
    <path id="Path_39" data-name="Path 39" d="M382.752,24.537h-3.907l-.016-.039c.053-.055.1-.111.159-.165q1.789-1.785,3.58-3.568a.484.484,0,0,0,.154-.363c.015-.581.014-1.164.071-1.741a6.012,6.012,0,0,1,3.214-5.028,6.59,6.59,0,0,1,2.358-.7,11.718,11.718,0,0,1,2.2-.072,8.152,8.152,0,0,1,2.615.589,2.591,2.591,0,0,1,.66.395l-1.366,3.8c-.073-.035-.136-.063-.2-.094a5.368,5.368,0,0,0-1.3-.481,2.774,2.774,0,0,0-1.976.27A1.9,1.9,0,0,0,388,18.649a8.18,8.18,0,0,0-.121,1.714c0,.011.012.022.03.057h4.608V24.5c-1.478,0-2.948,0-4.445,0V38.239h-5.325c0-.763,0-1.52,0-2.277s0-1.519,0-2.278V24.537Z" transform="translate(-347.519 -11.775)" fill="#fff"/>
    <path id="Path_40" data-name="Path 40" d="M245.007,24.584H242.26c-.049-.171-.06-3.825-.014-4.082.882,0,1.771,0,2.674,0a2.578,2.578,0,0,0,.053-.306c.01-.191.01-.383.008-.574a7.425,7.425,0,0,1,.526-2.981,5.716,5.716,0,0,1,3.361-3.223,8.076,8.076,0,0,1,2.2-.485,11.4,11.4,0,0,1,1.742-.03,8,8,0,0,1,2.793.678,1.191,1.191,0,0,1,.458.333L254.7,17.67c-.08-.036-.14-.062-.2-.09a5.4,5.4,0,0,0-1.512-.514,2.6,2.6,0,0,0-2.132.569,1.9,1.9,0,0,0-.652,1.148,5.934,5.934,0,0,0-.046,1.618c0,.012.011.023.03.059h3.938l.02.042c-.067.068-.133.137-.2.2q-1.781,1.75-3.564,3.5a.458.458,0,0,0-.148.363q0,6.705,0,13.41v.265l-.024.026s-.01.011-.015.012c-.059.005-.118.013-.177.013h-5c-.012-.518,0-1.023-.006-1.528s0-1,0-1.505V24.584" transform="translate(-222.198 -11.817)" fill="#fff"/>
    <path id="Path_41" data-name="Path 41" d="M1035.768,16.414v7.279c.313.02.608.007.9.009s.595,0,.892,0h2.692c.051.152.072,3.566.026,4.068-1.465.034-2.933-.057-4.415-.03-.005.085-.012.149-.013.213q-.027,3.585-.049,7.17a3.971,3.971,0,0,0,.079.864,1.967,1.967,0,0,0,1.648,1.587,3.1,3.1,0,0,0,1.507-.095l.474-.132c.05.146.067,3.891.02,4.206a3.358,3.358,0,0,1-.72.169,10.368,10.368,0,0,1-2.647.048,7.337,7.337,0,0,1-2.332-.585,5.312,5.312,0,0,1-3.164-3.88,7.386,7.386,0,0,1-.168-1.651q0-3.784,0-7.567v-.265h-5.648a1.4,1.4,0,0,1,.1-.122q1.983-1.925,3.966-3.852a.44.44,0,0,1,.348-.14c.33.01.661,0,.991,0,.065,0,.129-.006.219-.01v-.241c0-.806,0-1.611,0-2.417a.334.334,0,0,1,.133-.286q2.5-2.108,5-4.221c.043-.036.091-.066.166-.121" transform="translate(-940.152 -15.057)" fill="#fff"/>
    <path id="Path_42" data-name="Path 42" d="M1239.273,104.793h5.139l.016.015s.01.01.011.015a.933.933,0,0,1,.012.117q0,8.786,0,17.573a.317.317,0,0,1-.019.069h-5.156Z" transform="translate(-1136.849 -96.132)" fill="#fff"/>
    <path id="Path_43" data-name="Path 43" d="M1239.525,2.7a2.714,2.714,0,0,1-1.938,2.654,3.549,3.549,0,0,1-2.825-.206,2.548,2.548,0,0,1-1.416-2.252,2.726,2.726,0,0,1,2.4-2.849A3.987,3.987,0,0,1,1237.9.3a2.482,2.482,0,0,1,1.594,2.011c.02.13.019.263.028.394" transform="translate(-1131.407 0)" fill="#fff"/>
    <path id="Path_44" data-name="Path 44" d="M1637.264,344.825a3.263,3.263,0,0,1,1.841.513,2.365,2.365,0,0,1,1.1,2.034,2.665,2.665,0,0,1-2.395,2.717,3.4,3.4,0,0,1-2.152-.337,2.454,2.454,0,0,1-1.353-1.993,2.578,2.578,0,0,1,1.98-2.81,3.374,3.374,0,0,1,.979-.124" transform="translate(-1499.217 -316.325)" fill="#ffa3af"/>
  </g>
</svg> -->

<svg class="header-logo"  xmlns="http://www.w3.org/2000/svg" width="217.883" height="18.724" viewBox="0 0 217.883 18.724">
  <g id="Group_2435" data-name="Group 2435" transform="translate(0)">
    <path id="Path_493" data-name="Path 493" d="M236.874.117h5.7l7.617,7.733h0L257.832.092h5.774v0L252.485,11.436V18.69l-4.567.011-.01-.01c.01.01.02-7.216.02-7.216" transform="translate(-45.723 -0.018)" fill="#fff"/>
    <path id="Path_494" data-name="Path 494" d="M207.421.065V18.736h4.535v-7.72l10.166,0,.7-3.562V7.426l-10.854.019V3.61l15.29-.022.69-3.523V.058Z" transform="translate(-40.038 -0.011)" fill="#fff"/>
    <rect id="Rectangle_2265" data-name="Rectangle 2265" width="4.508" height="18.573" transform="translate(157.052)" fill="#fff"/>
    <path id="Path_495" data-name="Path 495" d="M182.99.108V.052L160.6.046s.006,3.58.006,3.55v.03h8.911v15.1h4.547V3.606h8.245l.679-3.5Z" transform="translate(-31 -0.009)" fill="#fff"/>
    <path id="Path_496" data-name="Path 496" d="M126.832.118h4.506l.006,8.624L143.758.09l6.229,0-10.4,7.3,11.291,11.3H144.9L136.144,9.79l-4.8,3.336V18.7h-4.517Z" transform="translate(-24.482 -0.018)" fill="#fff"/>
    <path id="Path_497" data-name="Path 497" d="M115.619,15.158v-.025H99.666V11.005l10.121-.032.692-3.53L99.652,7.437V3.616l15.257.017.7-3.556V.052L95.1.057V18.725l19.822,0,.7-3.57Z" transform="translate(-18.356 -0.01)" fill="#fff"/>
    <path id="Path_498" data-name="Path 498" d="M63.378.042V18.713h4.535v-7.72l10.163,0,.7-3.577V7.4l-10.854.019V3.587l15.291-.022L83.906.044V.036L63.379.042Z" transform="translate(-12.233 -0.007)" fill="#fff"/>
    <path id="Path_499" data-name="Path 499" d="M31.743.044V18.715h4.535V11l10.166,0,.7-3.559V7.406l-10.854.019V3.589l15.284-.022.692-3.53Z" transform="translate(-6.127 -0.007)" fill="#fff"/>
    <path id="Path_500" data-name="Path 500" d="M20.52,15.12H4.569V10.993L14.7,10.961l.685-3.5V7.431L4.554,7.424V3.6l15.25.031.7-3.593L0,.047V18.714l19.816.006.705-3.6Z" transform="translate(0 -0.008)" fill="#fff"/>
  </g>
</svg>

