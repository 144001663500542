import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-ad-publish-error',
    templateUrl: './ad-publish-error.component.html',
    styleUrls: ['./ad-publish-error.component.scss'],
    standalone: false
})
export class AdPublishErrorComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
