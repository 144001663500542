import { Component, Input, OnInit } from '@angular/core';
import { ModalController } from '@ionic/angular';

@Component({
  selector: 'app-information-popup',
  templateUrl: './information-popup.component.html',
  styleUrls: ['./information-popup.component.scss'],
})
export class InformationPopupComponent implements OnInit {
  @Input() message: string;
  constructor(
    private modalCtrl: ModalController) { }

  ngOnInit() {}
  
  close(): void {
    this.modalCtrl.dismiss();
  }
}
