import { Component, ElementRef, OnInit, ViewChild, AfterViewInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Gesture, GestureController, GestureDetail, ModalController, NavController } from '@ionic/angular';
import { TranslateService } from '@ngx-translate/core';
import { BusinessTypes } from 'src/app/core/enum';
import { ApiEndpoint } from 'src/app/models/apiRequest';
import { Country, GetCityByCountryAndPostalResponse, TeamResponse } from 'src/app/models/apiResponse';
import { AppService } from 'src/app/services/app-service/app.service';
import { Config } from 'src/app/services/config';
import { FirebaseCrashlyticsService } from 'src/app/services/firebase-crashlytics/firebase-crashlytics.service';

const searchTimeoutDelay = 1200;

@Component({
  selector: 'app-create-team',
  templateUrl: './create-team.component.html',
  styleUrls: ['./create-team.component.scss'],
})
export class CreateTeamComponent implements OnInit, AfterViewInit {
  public teamForm: FormGroup;
  public isTeamFormSubmitted = false;
  public businessTypes = BusinessTypes;
  public searchPostalTimeout: ReturnType<typeof setTimeout>;
  public countries: Country[];
  public selectedTeam: TeamResponse = null;

  @ViewChild('swipeContent', {read: ElementRef}) private swipeContentElm: ElementRef;
  constructor(
    private gestureCtrl: GestureController,
    private modalCtrl: ModalController,
    public appService: AppService,
    public formBuilder: FormBuilder,
    private translate: TranslateService,
    private navCtrl: NavController,
    private crashlyticsService: FirebaseCrashlyticsService
  ) { }

  async ngOnInit() {
    this.selectedTeam = this.appService.getTeam();
    this.teamForm = this.formBuilder.group({
      name: ['', [Validators.required, this.appService.noWhitespaceValidator]],
      country_code: ['', [Validators.required]],
      city: ['', [Validators.required]],
      postal_code: ['', [Validators.required, this.appService.noWhitespaceValidator]],
      business_type: ['', [Validators.required]],
    })

    this.countries = this.appService.availableCountries;
    if (this.countries?.length === 1) {
      this.teamForm.controls.country_code.setValue(this.countries[0]?.code);
    } else if (!this.countries?.length) {
      this.appService.presentToast(this.translate.instant('toast_message.invalid_country_list'))
    }
    this.crashlyticsService.customizeCrashlytics({ page: 'Create team' });
  }

  get errorControl() {
    return this.teamForm.controls;
  }

  // Custom swipe back gesture
  ngAfterViewInit(): void {
    const gesture: Gesture = this.gestureCtrl.create({
      el: this.swipeContentElm?.nativeElement,
      threshold: 20,
      gestureName: 'swipe-back-gesture',
      onEnd: (detail: GestureDetail) => {
        if (detail?.startX < 60 && detail?.deltaX > 0) {
          this.dismiss();
        }
      }
    });
    gesture?.enable();
  }

  dismiss(): void {
    this.modalCtrl.dismiss();
  }

  onChangePostalAndCountry(): void {
    clearTimeout(this.searchPostalTimeout);
    this.searchPostalTimeout = setTimeout(() => {
      const postal_code: string = this.teamForm.get('postal_code')?.value;
      const country_code: string = this.teamForm.get('country_code')?.value;
      if (postal_code && country_code) {
        this.getCityName(postal_code, country_code);
      }
    }, searchTimeoutDelay);
  }

  async getCityName(postal_code: string, country_code: string): Promise<void> {
    const apiEndpoint: ApiEndpoint = this.appService.getClone(Config.apiUrl.getCityNameByCountryAndPostal);
    apiEndpoint.url = apiEndpoint.urlStart
      + country_code + '/' + postal_code + apiEndpoint.urlEnd;
    this.appService.api({} , apiEndpoint).subscribe({
      next: async (response: GetCityByCountryAndPostalResponse) => {
        if (response?.result) {
          this.teamForm.controls.city.setValue(response?.result);
          // TODO - Will remove the below logic after the confirmation from the client side
          // this.teamForm.controls.postal_code.setErrors({ invalidPostalCode: null });
          // this.teamForm.controls.postal_code.updateValueAndValidity();
        }
      },
      // TODO - Will remove the below logic after the confirmation from the client side
      // error: async (e: HttpErrorResponse) => {
      //   this.teamForm.controls.city.reset();
      //   this.teamForm.controls.city.setErrors({ required: true })
      //   if (e.status === 400) {
      //     this.teamForm.controls.postal_code.setErrors({ invalidPostalCode: true })
      //   } else if (e.status === 404) {
      //     this.teamForm.controls.city.setErrors({ noCityFound: true })
      //   }
      // }
    });
  }

  submitForm(): boolean {
    this.isTeamFormSubmitted = true;
    if (this.teamForm.valid) {
      this.createTeam(this.teamForm.value);
    } else {
      return false;
    }
  }

  async populateCurrentTeam(ev): Promise<void> {
    if (ev.detail.checked) {
      const teamApiEndpoint: ApiEndpoint = this.appService.getClone(Config.apiUrl.team);
      teamApiEndpoint.url = teamApiEndpoint.url + this.selectedTeam?.id;
      await this.appService.showLoader();
      this.appService.api({}, teamApiEndpoint).subscribe({
        next: async (team: TeamResponse) => {
          this.teamForm.controls.country_code.setValue(team.country_code);
          this.teamForm.controls.postal_code.setValue(team.postal_code);
          this.teamForm.controls.city.setValue(team.city);
          this.teamForm.controls.business_type.setValue(team.business_type.toUpperCase());
          await this.appService.hideLoader();
        },
        error: async () => {
          await this.appService.hideLoader();
          this.appService.presentToast(this.translate.instant('toast_message.failed_fetch_business'));
        }
      })
    } 
  }

  async createTeam(team): Promise<void> {
    await this.appService.showLoader();
    await this.appService.api(team, Config.apiUrl.createTeam).subscribe({
      next: async (team: TeamResponse) => {
        await this.appService.hideLoader();
        this.appService.setTeam(team).then(() => {
          this.modalCtrl.dismiss({ closeAndRefreshIntegration: true});
          this.navCtrl.navigateRoot(this.appService.appRoutes.storePage);
        })
      },
      error: async () => {
        await this.appService.hideLoader();
        this.appService.presentToast(this.translate.instant('toast_message.failed_team_create'));
      }
    });
  }
  
}
