import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-process-info-icon',
    templateUrl: './process-info-icon.component.html',
    styleUrls: ['./process-info-icon.component.scss'],
    standalone: false
})
export class ProcessInfoIconComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
