import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-connections-icon',
    templateUrl: './connections-icon.component.html',
    styleUrls: ['./connections-icon.component.scss'],
    standalone: false
})
export class ConnectionsIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
