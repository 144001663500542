<ion-content>
  <div class="pop-header user-verification notch-space absolute title-with-button">
    <div class="close">
      <app-back-icon (click)="dismiss()"></app-back-icon>
    </div>
  </div>
  <div class="mail-sent-webshop">
    <div class="inner-main-content">
      <div class="check-icon-big">
        <app-check-icon-big></app-check-icon-big>
      </div>
      <div class="mail-sent-message">
        <h3>{{'user_verification.check_email' | translate}}</h3>
        <p class="email-text" *ngIf="type !== userVerificationStatus.pending_email_verification">{{'user_verification.action_required' | translate}}</p>
        <p class="email-text" *ngIf="type === userVerificationStatus.pending_email_verification">{{'user_verification.continue_verify_email' | translate}} <b>{{email}}</b>.</p>
        <p [class.disable-resend]="!showResend" *ngIf="type === userVerificationStatus.pending_email_verification">
          <a (click)="resendVerification()">{{'user_verification.didnt_receive_email' | translate}}</a>
        </p>
        <p class="ion-no-margin" *ngIf="type === userVerificationStatus.pending_email_verification" [class.hidden]="!(remainingTime > 0)"><a>({{remainingTime}})</a></p>
      </div>
    </div>
    <div class="action-button-area">
      <ion-button expand="block" shape="round" class="custom-button" (click)="checkUserStatus()">{{'buttons.continue' | translate}}</ion-button>
    </div>
  </div>
</ion-content>
