import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-search-icon',
  templateUrl: './search-icon.component.html',
  styleUrls: ['./search-icon.component.scss'],
})
export class SearchIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
