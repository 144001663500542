import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-failed-icon',
    templateUrl: './failed-icon.component.html',
    styleUrls: ['./failed-icon.component.scss'],
    standalone: false
})
export class FailedIconComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
