<ion-header class="ion-no-border no-border-radius" [translucent]="true">
  <div class="pop-header notch-space title-with-button">
    <div class="close">
      <app-back-icon (click)="close()"></app-back-icon>
    </div>
    <h2>{{ title | translate}}</h2>
  </div>
  <div class="search-bar">
    <ion-list>
      <ion-item lines="none">
        <ion-searchbar #autocompleteInput (ionInput)="filter($any($event))" placeholder="{{'placeholders.search' | translate}}"></ion-searchbar>
      </ion-item>
    </ion-list>
  </div>
</ion-header>

<ion-content class="grey-bg no-border-radius">
  <div class="cards-area">
    <ion-card mode="ios" class="select-card" *ngIf="filtered.length">
      <ion-card-content>
        <ion-list mode="ios" lines="full">
          <div class="checkbox-sec">
            <ion-item *ngFor="let item of filtered" (click)="itemSelected(item)">
              <ion-label>{{item.name}}</ion-label>
            </ion-item>
          </div>
        </ion-list>
      </ion-card-content>
    </ion-card>
    <div class="ion-text-center" *ngIf="!filtered.length">
      <div>
        <h3>{{'error_messages.no_data_found' | translate}}</h3>
      </div>
    </div>
  </div>
</ion-content>