import { Component, Input, OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app-service/app.service';

@Component({
  selector: 'app-header-menu',
  templateUrl: './header-menu.component.html',
  styleUrls: ['./header-menu.component.scss'],
})
export class HeaderMenuComponent  implements OnInit {

  @Input() title: string;

  constructor(
    public appService: AppService
  ) { }

  ngOnInit() {}

}
