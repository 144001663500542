import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-arrow-control',
    templateUrl: './arrow-control.component.html',
    styleUrls: ['./arrow-control.component.scss'],
    standalone: false
})
export class ArrowControlComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
