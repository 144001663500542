<svg width="14px" height="14px" viewBox="0 0 14 14" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
  <g id="EFFEKTIFY" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" stroke-linecap="round" stroke-linejoin="round">
      <g id="Insights" transform="translate(-47.000000, -542.000000)" stroke="#FF9999" stroke-width="1.5">
          <g id="Content" transform="translate(0.000000, 162.000000)">
              <g id="external-link" transform="translate(48.000000, 381.000000)">
                  <path d="M10,6.66666667 L10,10.6666667 C10,11.4030463 9.40304633,12 8.66666667,12 L1.33333333,12 C0.596953667,12 0,11.4030463 0,10.6666667 L0,3.33333333 C0,2.59695367 0.596953667,2 1.33333333,2 L5.33333333,2" id="Path"></path>
                  <polyline id="Path" points="8 0 12 0 12 4"></polyline>
                  <line x1="4.66666667" y1="7.33333333" x2="12" y2="0" id="Path"></line>
              </g>
          </g>
      </g>
  </g>
</svg>
