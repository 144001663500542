<div class="grey-bg no-border-radius">
  <div class="order-detail-web">
    <div class="pop-header notch-space" #sectionHeader>
      <div class="close" (click)="close()">
        <app-back-icon></app-back-icon>
      </div>
      <h2 [class.ion-hide]="showSkeleton">{{'orders.order' | translate}}: {{orderDetails?.order_id}}</h2>
      <ion-skeleton-text [class.ion-hide]="!showSkeleton || showNoDataAlert" [animated]="true" class="order-head-skeleton"></ion-skeleton-text>
    </div>
    <div class="cards-area order-detail-block">
      <ion-card class="order-detail-card customer-details">
        <ion-card-content>
          <h3 [class.ion-hide]="showSkeleton">{{orderDetails?.customer_name}}</h3>
          <h3 [class.ion-hide]="!showSkeleton || showNoDataAlert">
            <ion-skeleton-text [animated]="true" class="customer-name-skeleton"></ion-skeleton-text>
          </h3>
          <!-- Customer location skeleton -->
          <div class="customer-location-block" [class.ion-hide]="!showSkeleton || showNoDataAlert">
            <ion-skeleton-text [animated]="true" class="customer-location-skeleton"></ion-skeleton-text>
          </div>
          <div class="customer-location-block" [class.ion-hide]="showSkeleton">
            <div class="customer-location">
              <span class="country-flag" [class.ion-hide]="!orderDetails?.flag_url"><img [src]="orderDetails?.flag_url"></span>
              <span class="location">{{orderDetails?.shipment?.city}},&nbsp;{{orderDetails?.shipment?.country}}</span>
            </div>
            <div class="order-status on-hold ion-text-capitalize"
              [class.completed]="orderDetails?.status === orderStatus.done" 
              [class.on-hold]="orderDetails?.status !== orderStatus.done">{{'orders.statuses.'+orderDetails?.status | translate}}
              <span *ngIf="orderDetails?.status === orderStatus.done" class="checked"><app-completed-icon></app-completed-icon></span>
            </div>
          </div>
        </ion-card-content>
      </ion-card>
      <ion-card class="order-detail-card overview">
        <ion-card-header>
          <ion-grid>
            <ion-row>
              <ion-col size="12" class="order-detail">
                <h3 [class.ion-hide]="showSkeleton">{{'orders.overview' | translate}}</h3>
                <h3 [class.ion-hide]="!showSkeleton || showNoDataAlert"><ion-skeleton-text [animated]="true" class="overview-skeleton"></ion-skeleton-text></h3>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-header>
        <ion-card-content>
          <div class="four-block-card">
            <ion-grid>
              <!-- Single skeleton -->
              <div [class.ion-hide]="!showSkeleton || showNoDataAlert"><ion-skeleton-text [animated]="true" class="single-block-skeleton"></ion-skeleton-text></div>
              <ion-row [class.ion-hide]="showSkeleton">
                <ion-col size="12">
                  <div class="single-item-top">
                    <div class="label">{{'orders.date' | translate}}</div>
                    <div class="value">{{orderDetails?.order_time| date : 'medium'}}</div>
                  </div>
                </ion-col>
                <ion-col size="6">
                  <div class="single-item-top">
                    <div class="label">{{'orders.total_amount' | translate}}</div>
                    <div class="value">{{orderDetails?.total_amount}}</div>
                  </div>
                </ion-col>
                <ion-col size="6">
                  <div class="single-item-top">
                    <div class="label">{{'orders.status' | translate}}</div>
                    <div class="value ion-text-capitalize">{{'orders.statuses.'+orderDetails?.status | translate}}</div>
                  </div>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </ion-card-content>
      </ion-card>
      <ion-card class="order-detail-card products">
        <ion-card-header>
          <ion-grid>
            <ion-row>
              <ion-col size="12" class="order-detail">
                <h3 [class.ion-hide]="showSkeleton">{{'orders.products' | translate}}</h3>
                <h3 [class.ion-hide]="!showSkeleton || showNoDataAlert"><ion-skeleton-text [animated]="true" class="overview-skeleton"></ion-skeleton-text></h3>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-header>
        <ion-card-content>
          <!-- Products skeleton -->
          <ion-grid [class.ion-hide]="!showSkeleton || showNoDataAlert">
            <ion-row class="product-detail-quantity" *ngFor="let item of productsSeletonArray">
              <ion-col size="3" class="product-image">
                <ion-skeleton-text [animated]="true" class="product-image-skeleton"></ion-skeleton-text>
              </ion-col>
              <ion-col size="9" class="product-inner-details">
                <h3><ion-skeleton-text [animated]="true" class="overview-skeleton"></ion-skeleton-text></h3>
                <div class="product-quantity-details">
                  <ion-grid>
                    <ion-row>
                      <ion-skeleton-text [animated]="true" class="product-quantity-skeleton"></ion-skeleton-text>
                    </ion-row>
                  </ion-grid>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
          <ion-grid *ngIf="orderDetails?.products?.length" [class.ion-hide]="showSkeleton">
            <ion-row class="product-detail-quantity" *ngFor="let product of orderDetails?.products">
              <ion-col size="3" class="product-image">
                <img [src]="product?.image_url" alt="">
              </ion-col>
              <ion-col size="9" class="product-inner-details">
                <h3>{{product?.title}}</h3>
                <div class="product-quantity-details">
                  <ion-grid>
                    <ion-row>
                      <ion-col size="6">
                        <div class="single-item-top">
                          <div class="label">{{'orders.quantity' | translate}}</div>
                          <div class="value">x {{product?.quantity}}</div>
                        </div>
                      </ion-col>
                      <ion-col size="6">
                        <div class="single-item-top">
                          <div class="label">{{'orders.price' | translate}}</div>
                          <div class="value">{{product?.price}}</div>
                        </div>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </div>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-content>
      </ion-card>
      <ion-card class="order-detail-card products-additional-details">
        <ion-card-header>
          <ion-grid>
            <ion-row>
              <ion-col size="12" class="order-detail">
                <h3 [class.ion-hide]="showSkeleton">{{'orders.details' | translate}}</h3>
                <h3 [class.ion-hide]="!showSkeleton || showNoDataAlert"><ion-skeleton-text [animated]="true" class="overview-skeleton"></ion-skeleton-text></h3>
              </ion-col>
            </ion-row>
          </ion-grid>
        </ion-card-header>
        <ion-card-content>
          <!-- Skeleton Structure -->
          <div class="single-block" [class.ion-hide]="!showSkeleton || showNoDataAlert">
            <ion-grid >
              <ion-row class="single-line" *ngFor="let item of detailsSeletonArray">
                <ion-col size="4" class="label"><ion-skeleton-text [animated]="true" class="customer-head-skeleton"></ion-skeleton-text></ion-col>
                <ion-col size="8" class="value"><ion-skeleton-text [animated]="true" class="customer-detail-skeleton"></ion-skeleton-text></ion-col>
              </ion-row>
            </ion-grid>
          </div>
          <div class="single-block" [class.ion-hide]="!orderDetails?.customer">
            <h3>{{'orders.customer' | translate}}</h3>
            <ion-grid>
              <ion-row class="single-line" [class.ion-hide]="!orderDetails?.customer?.full_name">
                <ion-col size="4" class="label">{{'orders.name' | translate}}</ion-col>
                <ion-col size="8" class="value">{{orderDetails?.customer?.full_name}}</ion-col>
              </ion-row>
              <ion-row class="single-line" [class.ion-hide]="!orderDetails?.customer?.email">
                <ion-col size="4" class="label">{{'orders.email' | translate}}</ion-col>
                <ion-col size="8" class="value">
                  <a [href]="'mailto:' + orderDetails?.customer?.email">
                    {{orderDetails?.customer?.email}}
                  </a>
                </ion-col>
              </ion-row>
              <ion-row class="single-line" [class.ion-hide]="!orderDetails?.customer?.phone_number">
                <ion-col size="4" class="label">{{'orders.mobile' | translate}}</ion-col>
                <ion-col size="8" class="value">
                  <a [href]="'tel:' + orderDetails?.customer?.phone_number">
                    {{orderDetails?.customer?.phone_number}}
                  </a>
                </ion-col>
              </ion-row>
            </ion-grid>
          </div>
          <div class="single-block" [class.ion-hide]="!orderDetails?.shipment">
            <h3>{{'orders.shipment' | translate}}</h3>
            <ion-grid>
              <ion-row class="single-line" [class.ion-hide]="!orderDetails?.shipment?.full_name">
                <ion-col size="4" class="label">{{'orders.name' | translate}}</ion-col>
                <ion-col size="8" class="value">{{orderDetails?.shipment?.full_name}}</ion-col>
              </ion-row>
              <ion-row class="single-line" [class.ion-hide]="!orderDetails?.shipment?.address">
                <ion-col size="4" class="label">{{'orders.address' | translate}}</ion-col>
                <ion-col size="8" class="value">{{orderDetails?.shipment?.address}}</ion-col>
              </ion-row>
              <ion-row class="single-line" [class.ion-hide]="!orderDetails?.shipment?.city">
                <ion-col size="4" class="label">{{'orders.city' | translate}}</ion-col>
                <ion-col size="8" class="value">{{orderDetails?.shipment?.city}}</ion-col>
              </ion-row>
              <ion-row class="single-line" [class.ion-hide]="!orderDetails?.shipment?.zipcode">
                <ion-col size="4" class="label">{{'orders.zipcode' | translate}}</ion-col>
                <ion-col size="8" class="value">{{orderDetails?.shipment?.zipcode}}</ion-col>
              </ion-row>
              <ion-row class="single-line" [class.ion-hide]="!orderDetails?.shipment?.country">
                <ion-col size="4" class="label">{{'orders.country' | translate}}</ion-col>
                <ion-col size="8" class="value">{{orderDetails?.shipment?.country}}</ion-col>
              </ion-row>
              <ion-row class="single-line" [class.ion-hide]="!orderDetails?.shipment?.phone_number">
                <ion-col size="4" class="label">{{'orders.mobile' | translate}}</ion-col>
                <ion-col size="8" class="value">
                  <a [href]="'tel:' + orderDetails?.customer?.phone_number">
                    {{orderDetails?.shipment?.phone_number}}
                  </a>
                </ion-col>
              </ion-row>
              <ion-row class="single-line" [class.ion-hide]="!orderDetails?.shipment?.delivery_method">
                <ion-col size="4" class="label">{{'orders.delivery_method' | translate}}</ion-col>
                <ion-col size="8" class="value">{{orderDetails?.shipment?.delivery_method}}</ion-col>
              </ion-row>
              <ion-row class="single-line" [class.ion-hide]="!orderDetails?.shipment?.delivery_cost">
                <ion-col size="4" class="label">{{'orders.delivery_cost' | translate}}</ion-col>
                <ion-col size="8" class="value">{{orderDetails?.shipment?.delivery_cost}}</ion-col>
              </ion-row>
            </ion-grid>
          </div>
        </ion-card-content>
      </ion-card>
    </div>
    <app-no-data-available
      *ngIf="showNoDataAlert || showInternalServerErrorAlert"
      [customTop]="warningDivCustomTop"
      [showInternalServerError]="showInternalServerErrorAlert">
    </app-no-data-available>
  </div>
</div>