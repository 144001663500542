<svg id="Group_205" data-name="Group 205" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="125.536" height="91.506" viewBox="0 0 125.536 91.506">
  <g id="Group_204" data-name="Group 204">
    <path id="Path_100" data-name="Path 100" d="M142.536,185.661l-3.237,2.98,2.573-4.317a33.336,33.336,0,0,0-5.342-6.852s-6.877,6.589-6.877,11.768,3.079,6.988,6.877,6.988,6.877-1.809,6.877-6.988a9.087,9.087,0,0,0-.871-3.58" transform="translate(-76.791 -105.113)" fill="#f2f2f2"/>
    <path id="Path_101" data-name="Path 101" d="M148.4,188.385v.255a13.635,13.635,0,0,1-1.5,6.913c-.014.024-.029.047-.043.071l-.11-.067-.105-.066a13.482,13.482,0,0,0,1.489-6.837c0-.083,0-.168,0-.253,0-1.1-.071-2.2-.178-3.281-.008-.083-.016-.168-.025-.253-.149-1.42-.364-2.778-.584-3.957-.015-.084-.032-.167-.048-.249-.38-1.994-.769-3.439-.857-3.759-.01-.039-.016-.061-.018-.066l.12-.034h0l.121-.034s.023.077.058.211c.135.5.486,1.859.831,3.652.015.081.032.164.047.247.179.957.355,2.026.494,3.148q.053.423.095.834c.01.084.019.169.027.253q.174,1.746.181,3.272" transform="translate(-86.725 -104.696)" fill="#fff"/>
    <path id="Path_102" data-name="Path 102" d="M143.3,185.377c-.085.011-.17.023-.258.031a6.944,6.944,0,0,1-.713.037,6.774,6.774,0,0,1-2.932-.662l-.161.2a7.033,7.033,0,0,0,3.093.713,7.175,7.175,0,0,0,.761-.04c.086-.009.172-.02.257-.033a6.972,6.972,0,0,0,2.016-.611l-.159-.2a6.755,6.755,0,0,1-1.9.568" transform="translate(-82.467 -109.444)" fill="#fff"/>
    <path id="Path_103" data-name="Path 103" d="M141.916,192.382c-.087,0-.174.008-.262.008h-.081a6.818,6.818,0,0,1-5.6-2.923c-.051.075-.1.149-.15.225a7.073,7.073,0,0,0,5.747,2.951h.106c.088,0,.176,0,.263-.008a7.035,7.035,0,0,0,3.747-1.314c-.04-.075-.081-.149-.123-.224a6.77,6.77,0,0,1-3.651,1.286" transform="translate(-80.448 -112.218)" fill="#fff"/>
    <path id="Path_104" data-name="Path 104" d="M140.706,198.148c-.088.008-.177.013-.265.016s-.19.007-.284.007a6.837,6.837,0,0,1-6.554-4.919c-.057.1-.114.2-.168.306a7.087,7.087,0,0,0,6.722,4.866c.095,0,.19,0,.283-.007s.178-.007.267-.014a7.064,7.064,0,0,0,4.663-2.276c-.027-.093-.057-.186-.087-.279a6.812,6.812,0,0,1-4.576,2.3" transform="translate(-79.03 -114.46)" fill="#fff"/>
    <path id="Path_105" data-name="Path 105" d="M82.73,195.889l-2.362,2.174,1.877-3.149a24.321,24.321,0,0,0-3.9-5s-5.017,4.807-5.017,8.585,2.246,5.1,5.017,5.1,5.017-1.319,5.017-5.1a6.626,6.626,0,0,0-.635-2.611" transform="translate(-43.433 -112.484)" fill="#f2f2f2"/>
    <path id="Path_106" data-name="Path 106" d="M87.009,197.877v.186a9.945,9.945,0,0,1-1.093,5.043c-.01.018-.021.034-.031.051l-.08-.049-.077-.048a9.836,9.836,0,0,0,1.086-4.988c0-.061,0-.123,0-.185,0-.8-.051-1.608-.13-2.393-.005-.061-.012-.122-.018-.184-.109-1.036-.265-2.026-.426-2.887-.011-.061-.023-.122-.035-.181-.277-1.455-.56-2.509-.625-2.742l-.013-.048.088-.025h0l.088-.025s.017.056.042.154c.1.365.355,1.356.607,2.664.011.059.023.12.034.181.131.7.259,1.478.361,2.3q.039.308.069.608.011.093.02.185.127,1.273.132,2.387" transform="translate(-50.68 -112.18)" fill="#fff"/>
    <path id="Path_107" data-name="Path 107" d="M83.288,195.683c-.062.008-.124.017-.188.023a5.153,5.153,0,0,1-.52.027,4.941,4.941,0,0,1-2.139-.483l-.117.148a5.131,5.131,0,0,0,2.256.52,5.269,5.269,0,0,0,.555-.029c.062-.007.125-.015.187-.024a5.077,5.077,0,0,0,1.47-.445l-.116-.149a4.934,4.934,0,0,1-1.388.414" transform="translate(-47.574 -115.643)" fill="#fff"/>
    <path id="Path_108" data-name="Path 108" d="M82.278,200.794c-.064,0-.127.006-.191.006h-.059a4.975,4.975,0,0,1-4.082-2.132c-.037.054-.073.108-.11.164a5.159,5.159,0,0,0,4.192,2.153h.077c.064,0,.128,0,.192-.006a5.131,5.131,0,0,0,2.734-.959l-.089-.163a4.942,4.942,0,0,1-2.664.938" transform="translate(-46.101 -117.667)" fill="#fff"/>
    <path id="Path_109" data-name="Path 109" d="M81.4,205c-.064.006-.129.009-.194.012s-.138,0-.208,0a4.988,4.988,0,0,1-4.781-3.588c-.042.075-.083.148-.123.223a5.17,5.17,0,0,0,4.9,3.55c.069,0,.139,0,.207,0s.13-.006.194-.01a5.154,5.154,0,0,0,3.4-1.661c-.02-.068-.042-.135-.064-.2A4.971,4.971,0,0,1,81.4,205" transform="translate(-45.067 -119.302)" fill="#fff"/>
    <rect id="Rectangle_55" data-name="Rectangle 55" width="62.11" height="37.628" transform="translate(33.261)" fill="#f0f0f0"/>
    <path id="Path_110" data-name="Path 110" d="M155.344,38.99l-3.781-3.9a.859.859,0,0,0-.632-.254h-.011a.859.859,0,0,0-.632.254l-3.781,3.9a.834.834,0,0,0-.04,1.179c.013.014.026.027.04.04a.934.934,0,0,0,1.275,0l2.24-2.342V43.9a.9.9,0,0,0,1.806,0V37.867l2.24,2.342a.934.934,0,0,0,1.275,0,.834.834,0,0,0,.04-1.18c-.013-.013-.026-.027-.04-.04" transform="translate(-86.617 -20.635)" fill="#fff"/>
    <path id="Path_111" data-name="Path 111" d="M144.858,43.135a8.873,8.873,0,1,1,8.873-8.873,8.873,8.873,0,0,1-8.873,8.873m0-17.417a8.544,8.544,0,1,0,8.544,8.544,8.544,8.544,0,0,0-8.544-8.544" transform="translate(-80.541 -15.037)" fill="#fff"/>
    <path id="Path_112" data-name="Path 112" d="M84.024,92.426H26.014c-.593,0-1.074-.984-1.074-2.2V60.785c0-1.213.482-2.2,1.074-2.2h58.01c.593,0,1.074.984,1.074,2.2V90.228c0,1.213-.482,2.2-1.074,2.2" transform="translate(-14.771 -34.7)" fill="#fff"/>
    <path id="Path_113" data-name="Path 113" d="M65.964,74.736a5.073,5.073,0,1,1-5.073,5.073,5.073,5.073,0,0,1,5.073-5.073" transform="translate(-36.065 -44.265)" fill="#3f3d56"/>
    <path id="Path_114" data-name="Path 114" d="M76.064,95.284H51.179a.475.475,0,0,1-.093-.008l11.77-20.386a.824.824,0,0,1,1.434,0l7.9,13.681.378.654Z" transform="translate(-30.257 -44.108)" fill="#FF9999"/>
    <path id="Path_115" data-name="Path 115" d="M115.063,104.013H93.54l4.171-6.058.3-.436,5.435-7.9a1.1,1.1,0,0,1,1.545-.176,1.156,1.156,0,0,1,.088.078,1.049,1.049,0,0,1,.078.1Z" transform="translate(-55.402 -52.837)" fill="#FF9999"/>
    <path id="Path_116" data-name="Path 116" d="M84.025,92.426H26.015c-.593,0-1.074-.984-1.074-2.2V60.785c0-1.213.482-2.2,1.074-2.2h58.01c.593,0,1.074.984,1.074,2.2V90.228c0,1.213-.482,2.2-1.074,2.2m-58.01-32.96c-.356,0-.645.591-.645,1.319V90.228c0,.728.289,1.318.645,1.319h58.01c.356,0,.644-.591.645-1.319V60.785c0-.728-.289-1.318-.645-1.319Z" transform="translate(-14.772 -34.7)" fill="#e6e6e6"/>
    <path id="Path_117" data-name="Path 117" d="M125.208,223.852H.328c-.181,0-.328-.09-.328-.2s.147-.2.328-.2h124.88c.181,0,.328.09.328.2s-.147.2-.328.2" transform="translate(0 -132.346)" fill="#cacaca"/>
    <path id="Path_118" data-name="Path 118" d="M233.4,25.446c-1.887-2.657-5.619-2.781-5.619-2.781s-3.637-.387-5.97,3.659c-2.175,3.771.889,8.459.889,8.459.605.036,3.224.052,11.016-.91l.349-1.883,1,1.717,1.3-.158a18.9,18.9,0,0,0-2.969-8.1" transform="translate(-130.91 -13.418)" fill="#2f2e41"/>
    <path id="Path_119" data-name="Path 119" d="M256.6,95.694,256.1,78.5l-3.4,1.489,1.642,15.89a2.077,2.077,0,1,0,2.26-.186Z" transform="translate(-149.669 -46.495)" fill="#ffb8b8"/>
    <path id="Path_120" data-name="Path 120" d="M251.4,67.915a4.744,4.744,0,0,0,4.891-2.552l-.744-3.408a5.215,5.215,0,0,0-3.645-4.973l-.4-.125Z" transform="translate(-148.897 -33.675)" fill="#e6e6e6"/>
    <path id="Path_121" data-name="Path 121" d="M199.892,58.916a.409.409,0,0,1-.086-.009l-4.105-.069-.009-.1v0l.34-8.458a.658.658,0,0,1,.687-.629l3.288.146a.658.658,0,0,1,.628.687l-.323,8.034A.421.421,0,0,1,199.892,58.916Z" transform="translate(-115.904 -29.403)" fill="#6c63ff"/>
    <path id="Path_122" data-name="Path 122" d="M199.21,59.009h-.031l-3.223-.144a.692.692,0,0,1-.659-.721l.344-7.723a.66.66,0,0,1,.687-.629l3.288.147a.659.659,0,0,1,.628.687l-.344,7.723A.691.691,0,0,1,199.21,59.009Z" transform="translate(-115.67 -29.491)" fill="#2f2e41"/>
    <path id="Path_123" data-name="Path 123" d="M200.674,53.587a.471.471,0,1,1-.471.471.47.47,0,0,1,.471-.471" transform="translate(-118.576 -31.739)" fill="#6c63ff"/>
    <path id="Path_124" data-name="Path 124" d="M201.3,70.9v3.1l-3-4.725a1.959,1.959,0,1,0-1.9.957l4.192,7.428a2.088,2.088,0,0,0,3.9-.831L205,71.3Z" transform="translate(-115.288 -39.289)" fill="#ffb8b8"/>
    <path id="Path_125" data-name="Path 125" d="M214.333,68.3a4.468,4.468,0,0,1-4.606-2.4l.7-3.21a4.911,4.911,0,0,1,3.433-4.684l.373-.118Z" transform="translate(-124.217 -34.287)" fill="#e6e6e6"/>
    <path id="Path_126" data-name="Path 126" d="M229.587,206.262l-2.286-.371.343-9,3.375.548Z" transform="translate(-134.626 -116.617)" fill="#ffb6b6"/>
    <path id="Path_127" data-name="Path 127" d="M223.317,219.749l-7.469.007v-.094a2.907,2.907,0,0,1,2.9-2.91h0l4.562,0Z" transform="translate(-127.843 -128.375)" fill="#2f2e41"/>
    <path id="Path_128" data-name="Path 128" d="M249.428,195.433l-2.068,1.043L242.351,189l3.053-1.54Z" transform="translate(-143.54 -111.027)" fill="#ffb6b6"/>
    <path id="Path_129" data-name="Path 129" d="M253.281,207.392l-6.669,3.365-.042-.084a2.907,2.907,0,0,1,1.286-3.905l4.073-2.055Z" transform="translate(-145.854 -121.247)" fill="#2f2e41"/>
    <path id="Path_130" data-name="Path 130" d="M231.046,30.551a4.066,4.066,0,1,1-4.066,4.066,4.066,4.066,0,0,1,4.066-4.066" transform="translate(-134.436 -18.095)" fill="#ffb8b8"/>
    <path id="Path_131" data-name="Path 131" d="M235.032,27.665l-3.687-1.932-5.092.791L225.2,31.177c5.2.748,8.8,1.177,10.009-.088Z" transform="translate(-133.381 -15.241)" fill="#2f2e41"/>
    <path id="Path_132" data-name="Path 132" d="M219,100.56s-3.1,6.34.335,15.721l9.486,24.19,4.535-2.681-4.623-10.414,3.76-12.747a22.155,22.155,0,0,0,.785-8.92c-.271-2.242-.9-4.521-2.268-5.67-3.219-2.7-12.01.521-12.01.521" transform="translate(-128.951 -58.611)" fill="#2f2e41"/>
    <path id="Path_133" data-name="Path 133" d="M228.591,164.139l-3.439,17.436,4.58.864,2.881-15.093Z" transform="translate(-133.353 -97.216)" fill="#2f2e41"/>
    <path id="Path_134" data-name="Path 134" d="M229.986,55.367l-5.257.07-4.6,1.058.273,18.433s8.321,2.354,11.975-.9l2.41-17.252a12.528,12.528,0,0,0-4.8-1.408" transform="translate(-130.377 -32.793)" fill="#e4e4e4"/>
    <path id="Path_135" data-name="Path 135" d="M239.384,17.54a2.6,2.6,0,1,1-2.6,2.6,2.6,2.6,0,0,1,2.6-2.6" transform="translate(-140.24 -10.389)" fill="#2f2e41"/>
    <path id="Path_136" data-name="Path 136" d="M235.9,18.381a2.6,2.6,0,0,1,3.113-2.554,2.6,2.6,0,1,0-1.618,4.908,2.6,2.6,0,0,1-1.495-2.354" transform="translate(-139.365 -9.226)" fill="#2f2e41"/>
  </g>
</svg>

