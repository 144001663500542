import { Component, ViewChild, NgZone } from '@angular/core';
import { NavigationEnd, Router } from '@angular/router';
import { AlertController, Platform, IonRouterOutlet, NavController, IonAccordionGroup, MenuController } from '@ionic/angular';
import { Preferences } from '@capacitor/preferences';
import { TranslateService } from '@ngx-translate/core';
import { SplashScreen } from '@capacitor/splash-screen';
import { App, URLOpenListenerEvent } from '@capacitor/app';
import { Capacitor } from '@capacitor/core';
import { StatusBar } from '@capacitor/status-bar';
import { ScreenOrientation } from '@awesome-cordova-plugins/screen-orientation/ngx';
import { AppService } from './services/app-service/app.service';
import { EventsService } from './services/events/events.service';
import { CustomerOrder, InitializeAppResponse, TeamResponse, UserResponse } from './models/apiResponse';
import { FcmService } from './services/fcm/fcm.service';
import { InitializeAppPayload } from './models/apiRequest';
import { Config } from './services/config';
import { environment as env } from 'src/environments/environment';
import { LanguageService } from './services/language/language.service';
import { IntercomService } from './services/intercom/intercom.service';
import { FirebaseCrashlyticsService } from './services/firebase-crashlytics/firebase-crashlytics.service';
import { UserService } from './services/user/user.service';
import { FacebookLogin, } from '@capacitor-community/facebook-login';
import { AppRoutes } from 'src/app/constants/app-routes';

declare const customWebFunctions;

declare global {
  interface Navigator {
    app;
  }
}

@Component({
  selector: 'app-root',
  templateUrl: 'app.component.html',
  styleUrls: ['app.component.scss'],
})
export class AppComponent {
  @ViewChild(IonRouterOutlet, { static : true }) routerOutlet: IonRouterOutlet;
  @ViewChild('customerAccordionGroup', { static: true }) customerAccordionGroup: IonAccordionGroup;

  public user: UserResponse = null;
  public env = env;
  private rootURL = AppRoutes.onboardPage;
  private hardwareBackExitUrls: string[];
  private enableMenuSwipeUrls: string[];
  public showBookMeetingModal = false;

  constructor(
    private router: Router,
    private menuCtrl: MenuController,
    private alertController: AlertController,
    private platform: Platform,
    private navController: NavController,
    private translate: TranslateService,
    public appService: AppService,
    private events: EventsService,
    private screenOrientation: ScreenOrientation,
    private fcmService: FcmService,
    private zone: NgZone,
    private language: LanguageService,
    public intercomService: IntercomService,
    private crashlyticsService: FirebaseCrashlyticsService,
    private userService: UserService
  ) {
    if (Capacitor.getPlatform() === 'web') {
      customWebFunctions.loadIntercomWeb(env.intercomAppID);
      customWebFunctions.loadAppsFlyerWeb(env.appsFlyerWebId);
    }
    if (Capacitor.getPlatform() === 'ios') {
      StatusBar.hide();
    }
    this.initializeApp();
    this.enableMenuSwipeUrls = [
      this.appService.appRoutes.dashboardPage,
      this.appService.appRoutes.myStorePage,
      this.appService.appRoutes.myWebsitePage,
      this.appService.appRoutes.marketingPage,
      this.appService.appRoutes.insightsPage
    ];

    // Mobile app
    if (Capacitor.isNativePlatform()) {
      this.hardwareBackExitUrls = [
        this.appService.appRoutes.onboardPage,
        ...this.enableMenuSwipeUrls
      ];
    }
  }

  async initializeApp(): Promise<void> {
    this.language.initLanguage();
    if (Capacitor.isNativePlatform()) {
      this.handleHardwareBackButton();
    }
    await FacebookLogin.initialize({
      appId: env.FBAppID,
    });
    this.subscribeEvents();
    this.platform.ready().then(async () => {
      if (Capacitor.isNativePlatform()) {
        setTimeout(async () => {
          await SplashScreen.hide();
          this.navController.navigateRoot(this.appService.appRoutes.onboardPage);
        }, 500);
        this.screenOrientation.lock(this.screenOrientation.ORIENTATIONS.PORTRAIT);
      }
      const userAuth =  await Preferences.get({ key: 'auth' });
      if (userAuth.value) {
        const userData =  await Preferences.get({ key: 'user' });
        if (userData.value) {
          this.user = JSON.parse(userData.value);
          await this.appService.getSelectedTeam(); //To configure auth url in app service
          this.rootURL = this.appService.appRoutes.dashboardPage;
          if (Capacitor.isNativePlatform()) {
            this.crashlyticsService.customizeCrashlytics({}, this.user?.id);
          }
        }
      }
      await this.appService.appInitializationPayload().then(async (payload: InitializeAppPayload) => {
        this.appService.api(payload, Config.apiUrl.initializeApp).subscribe({
          next: async (response: InitializeAppResponse) => {
            this.appService.setCalendlyURL(response?.features);
            this.appService.setTermsAndConditionsURL(response?.current_terms_and_conditions_uri);
            this.appService.setAppFeatureControl(response?.features);
            this.appService.setDynamicLinks(response?.links);
            this.language.setAvailableLocales(response?.available_locales);
            this.appService.setOnboardingIntroConfig(response?.intro);
            this.appService.setUserAuthentications(response?.authentication);
            this.appService.setAvailableCountries(response?.available_countries);
            this.appService.setSignupInitialValues(response?.sign_up);
            this.language.initLanguage();
            this.language.updateTranslations(response?.translations);
            if (Capacitor.isNativePlatform() && response?.update_available && response?.force_update) { // Hide splash and display app update popup
              await SplashScreen.hide();
              this.appService.checkForAppUpdate(response.update_available, response.force_update);
            } else {
              if (Capacitor.isNativePlatform()) {
                this.appService.checkForAppUpdate(response.update_available, response.force_update);
              }
              if (this.user) {
                this.events.publish('customers:fetch');
                await this.appService.fetchAppConfigurations();
              }
              await this.appService.setSessionToken(response?.session_token).then(()=> {
                this.navigateRoot(); //Navigate to Root after session_token
                this.appService.initializeAPILoaded$.next(true);
              });
            }
          }, 
          error: () => {
            if (!this.user) {
              this.appService.presentToast(this.translate.instant('error_messages.initialization_failed'));
            }
            this.appService.initializeAPILoaded$.next(true);
            this.navigateRoot();
          }
        });
      })
    })

    this.intercomService.initIntercom();
  }

  // @param "event: URLOpenListenerEvent"
  initializeAppDeepLinks(): void {
    App.addListener('appUrlOpen', (event: URLOpenListenerEvent) => {
      this.zone.run(() => {
        const slug = event.url.split(env.appWebsite).pop();
        // Added navigation only of the users are already logged in the app
        const URLParams = slug.split('?');
        if (URLParams.length > 1) {
          const params = new URLSearchParams(slug.split('?')[1]);
          const code = params.get('code');
          const successCode = (code && code.split('#').length > 1) ? code.split('#')[0] : code;

          if (URLParams[0] === env.socialLoginsuccessReturnPath && successCode) {
            this.events.publish('socialLogin:success', successCode);
          }
        } else {
          const path = (URLParams[0].split('#').length > 1) ? URLParams[0].split('#')[0] : URLParams[0];
          if (URLParams.length === 1 && path === env.socialLoginsuccessReturnPath) {
            this.events.publish('integrationoOauth2:success');
          }
        }
        if (this.user && slug) {
          const path = (URLParams[0].split('#').length > 1) ? URLParams[0].split('#')[0] : URLParams[0];
          if (URLParams.length === 1 && path !== env.socialLoginsuccessReturnPath) {
            this.appService.hideLoader();
            this.navController.navigateForward(slug);
          }
        }
      });
    });
  }

  async logout(): Promise<void> {
    const alert = await this.alertController.create({
      mode: 'ios',
      cssClass: 'custom-alert',
      header: this.translate.instant('sidemenu.confirm_logout'),
      buttons: [
        {
          text: this.translate.instant('buttons.cancel'),
          role: 'cancel',
          cssClass: 'secondary ion-text-capitalize',
        }, {
          text: this.translate.instant('buttons.ok'),
          cssClass: 'ion-text-capitalize',
          handler: () => {
            this.appService.logoutUser();
          }
        }
      ]
    });
    await alert.present();
  }

  navigatePage(url: string, data = {}): void {
    this.menuCtrl.close().then(()=> {
    if (data) {
        this.router.navigate([url], { state: { data } });
      } else {
        this.navController.navigateForward(url);
      }
    });
  }

  async openModal(modal: string) {
    switch (modal) {
      case 'switch-account':
        this.appService.showLoader();
        this.appService.selectTeam().then(() => {
          const route: string = this.router.url?.split('?') ? this.router.url?.split('?')?.[0] : this.router.url;
          this.appService.hideLoader();
          this.events.publish('domain:updated:' + route);
        }).catch(() => {
          this.appService.hideLoader();
        })
    }
  }

  handleHardwareBackButton(): void {
    this.platform.backButton.subscribeWithPriority(1, () => {
      const navURL = this.router.url;
      if (this.hardwareBackExitUrls.includes(navURL)) {
        this.presentExitAlert();
        return;
      }
      if (this.routerOutlet && this.routerOutlet.canGoBack()) {
        this.routerOutlet.pop();
        return;
      }
    });
  }

  async presentExitAlert(): Promise<void> {
    const exitAlert = await this.alertController.create({
      header: this.translate.instant('sidemenu.confirm_exit'),
      buttons: [
        {
          text: this.translate.instant('buttons.cancel'),
          role: 'cancel',
          cssClass: 'secondary ion-text-capitalize',
          handler: () => {}
        }, {
          text: this.translate.instant('buttons.exit'),
          cssClass: 'ion-text-capitalize',
          handler: () => {
            (navigator as Navigator).app.exitApp();
          }
        }
      ]
    });
    await exitAlert.present();
  }

  subscribeEvents(): void {
    this.events.subscribe('user:update', (user: UserResponse) => {
      this.user = user;
      this.crashlyticsService.customizeCrashlytics({}, this.user?.id || 'No user');
    });

    this.events.subscribe('app:reinitialize', () => {
      this.appService.reInitializeApp();
    });

    // Commenting for future use
    this.events.subscribe('customers:fetch', () => {
      this.appService.fetchCustomers();
    });
  }

  hideSideMenu(): boolean {
    return !this.appService.isLoggedIn();
  }

  hasMenuSwipeGesture(): boolean {
    const navURL = this.router.url;
    return this.enableMenuSwipeUrls.includes(navURL);
  }

  navigateRoot(): void {
    const route: string = this.router.url;
    if (Capacitor.isNativePlatform() || route === '/') {
      this.navController.navigateRoot(this.rootURL).then(async () => {
        await SplashScreen.hide();
        if (this.user) {
          this.events.publish('intercom:initialize');
        }
        if (Capacitor.getPlatform() === 'ios') {
          await StatusBar.show();
        }
      });
      setTimeout(async() => {
        this.initializeAppDeepLinks();
        if (this.user) {
          this.fcmService.initPush(); //Initialize push notifications
        }
      }, 1000);
    }
    this.fetchTeams();

    if (Capacitor.getPlatform() === 'web') {
      this.listenRouterEvents();
    }

    if (!this.user?.id && this.appService.hasDemoIntroFlag()) {
      this.userService.demoLogin();
    }
  }

  listenRouterEvents(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        if (event.url !== this.appService.appRoutes.dashboardPage) {
          this.intercomService.hideIntercomLauncher();
        }
      }
    });
  }

  async fetchTeams(): Promise<void> {
    const userData = await Preferences.get({ key: 'user' });
    if (userData?.value) {
      this.appService.api({}, Config.apiUrl.teams).subscribe(async (teams: TeamResponse[]) => {
        this.appService.setTeams(teams);
      })
    }
  }

  selectCustomerOrder(order: CustomerOrder): void {
    this.navigatePage(this.appService.appRoutes.customerOrderPage, order);
    this.customerAccordionGroup.value = undefined;
  }

  showBookMeeting(): void {
    this.showBookMeetingModal = true;
  }

  hideBookMeeting(): void {
    this.showBookMeetingModal = false;
  }
}
