import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-close-white-icon',
  templateUrl: './close-white-icon.component.html',
  styleUrls: ['./close-white-icon.component.scss'],
})
export class CloseWhiteIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
