import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-low-chart-icon',
  templateUrl: './low-chart-icon.component.html',
  styleUrls: ['./low-chart-icon.component.scss'],
})
export class LowChartIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
