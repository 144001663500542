import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-menu-toggle',
    templateUrl: './menu-toggle.component.html',
    styleUrls: ['./menu-toggle.component.scss'],
    standalone: false
})
export class MenuToggleComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
