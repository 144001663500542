<!-- Container Skeleton -->
<div *ngIf="showSkeleton">
  <ion-grid>
    <ion-row >
      <ion-col size="4">
        <ion-skeleton-text [animated]="true" class="container-skeleton"></ion-skeleton-text>
      </ion-col>
      <ion-col size="4">
        <ion-skeleton-text [animated]="true" class="container-skeleton"></ion-skeleton-text>
      </ion-col>
      <ion-col size="4">
        <ion-skeleton-text [animated]="true" class="container-skeleton"></ion-skeleton-text>
      </ion-col>
    </ion-row>
    <ion-row>
      <ion-col size="12">
        <ion-skeleton-text [animated]="true" class="container-skeleton"></ion-skeleton-text>
      </ion-col>
      <ion-col size="6">
        <ion-skeleton-text [animated]="true" class="container-skeleton"></ion-skeleton-text>
      </ion-col>
      <ion-col size="6">
        <ion-skeleton-text [animated]="true" class="container-skeleton"></ion-skeleton-text>
      </ion-col>
    </ion-row>
  </ion-grid>
</div>

<ng-template #containerTemplate let-container="container">
  <div [class]="'space-'+container?.spacing" [ngClass]="{'ion-card-area' : container.type === containerTypes.sideScroller, 'multiple-graph-area' : container.type === containerTypes.container}">
    <div class="cards-area content-width-fix">
      <div [ngClass]="{'single-block-main' : container.type === containerTypes.container, 'full-width-window' : container.type === containerTypes.sideScroller}">
        <ion-grid>
          <ion-row>
            <ion-col [class]="'h-'+card.format.height" [attr.size]="card.format.xs||card.format.sm" [attr.size-sm]="card.format.sm" [attr.size-md]="card.format.md" [attr.size-lg]="card.format.lg" *ngFor="let card of container?.cards">
              <ion-card [ngClass]="{'stats-chart-single' : container.type === containerTypes.container}" (click)="navigateCardPage(card?.actions)">
                <ion-card-content [class]="'h-'+card.format.height">
                  <div *ngIf="!card?.rows?.length">
                    <!-- Title -->
                    <h4 *ngIf="container.type === containerTypes.container">{{card?.title}}</h4>
                    <!-- Image -->
                    <div class="top-image" *ngIf="card?.image">
                      <img [src]="card.image.image_uri" alt=""> 
                      <p>{{card?.image?.image_text}}</p>       
                    </div>
                    <!-- Title -->
                    <ion-card-title *ngIf="container.type === containerTypes.sideScroller">{{card?.title}}</ion-card-title>
                    <!-- Formatted value -->
                    <div class="value-section text-highlight-green formatted-value" [ngClass]="{'large-text': !card?.chart?.type}" *ngIf="container.type === containerTypes.sideScroller">
                      {{card?.formatted_value}}
                    </div>
                    <h3 class="text-highlight-green formatted-value" [ngClass]="{'large-text': card?.trend}" *ngIf="container.type === containerTypes.container">{{card?.formatted_value}}</h3>
                  </div>
                  <div *ngIf="card?.rows?.length >= 1">
                    <!-- Row Title -->
                    <div class="row-title row-title-divider">
                      <div *ngIf="card?.image?.image_uri"><img [src]="card?.image?.image_uri" alt=""></div>
                      <ion-card-title>{{card?.title}}</ion-card-title>
                    </div>
                  </div>
                  <!-- Graph -->
                  <div class="graph-area" *ngIf="card?.chart?.type">
                    <app-graph [containerCard]="card"></app-graph>
                  </div>
                  <!-- Trends -->
                  <div class="value-section" *ngIf="card?.trend" [ngClass]="{'text-highlight-red': card?.trend?.direction === 'down', 'text-highlight-green': ['up', 'none'].includes(card?.trend?.direction), 'large-text': !card?.formatted_value}">{{card?.trend?.value}}&nbsp;
                    <span *ngIf="card?.trend?.direction !== 'none'" [ngClass]="{'position-down': card?.trend?.direction === 'down', 'green': card?.trend?.direction === 'up'}" class="up">
                      <app-caret-icon></app-caret-icon>
                    </span>
                    <span *ngIf="card?.trend?.direction === 'none'">
                      <app-square-icon></app-square-icon>
                    </span>
                  </div>
                  <!-- List cards -->
                  <div class="main-table-layout compact" *ngIf="card?.list && card?.list.type === 'list'">
                    <div class="table-card">
                      <div class="table-card-content">
                        <ion-list class="simple-list no-bord">
                          <ion-list-header>
                            <ion-row>
                              <ion-col size="6" class="caption"></ion-col>
                              <ion-col size="3" class="value-section ion-text-left">{{card?.list?.configuration?.value_label}}</ion-col>
                              <ion-col size="3" *ngIf="card?.list?.configuration?.trend_label" class="value-section ion-text-left">{{card?.list?.configuration?.trend_label}}</ion-col>
                            </ion-row>
                          </ion-list-header>
                          <ion-grid *ngFor="let data of (showAllFlag[card.id] ? card.list.data : card.list.data.slice(0,minListCollapseCount))">
                            <ion-row>
                              <ion-col size="6" class="caption">{{data?.name}}</ion-col>
                              <ion-col size="3" class="value-section ion-text-left text-highlight-green">{{data?.formatted_value}}</ion-col>
                              <ion-col *ngIf="data?.trend?.value" size="3" class="value-section ion-text-left grey" [ngClass]="{'text-highlight-red': data?.trend?.direction === 'down', 'text-highlight-green': ['up', 'none'].includes(data?.trend?.direction)}">
                                {{data?.trend?.value}}
                              </ion-col>
                            </ion-row>
                          </ion-grid>
                        </ion-list>
                        <div class="table-footer" [ngClass]="{'show-fadeout': !showAllFlag[card.id]}" *ngIf="card.list.data.length > minListCollapseCount">
                          <div class="view-btn" (click)="showAllFlag[card.id] = !showAllFlag[card.id]">
                            {{'filter.view' | translate}} {{(!showAllFlag[card.id] ? 'filter.all' : 'filter.less') | translate | lowercase}}
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                  <!-- Percentage list cards -->
                  <div class="progress-bar-section" *ngIf="card?.list && card?.list.type === 'percentage_list'">
                    <div class="single-progress-item" *ngFor="let data of card.list.data">
                      <div class="left-caption">{{data.name}}</div>
                      <div class="progress-section">
                        <span class="progress-bar"><ion-progress-bar [value]="data?.value ? data?.value/100 : 0"></ion-progress-bar></span>
                      </div>
                      <div class="progress-value">{{data.formatted_value}}</div>
                    </div>
                  </div>
                  <!-- Row value -->
                  <div class="row-card" *ngFor="let row of card?.rows">
                    <div>
                      <div>
                        <span class="row-card-title">{{row?.title}}</span>
                        <span class="info-icon-section" *ngIf="row?.description" (click)="appService.showInformationPopup(row?.description, true)"><app-info-icon></app-info-icon></span>
                      </div> 
                      <div class="row-card-value">{{row?.value}}</div>
                    </div>
                  </div>
                  <!-- Description -->
                  <div class="description-section">{{card?.description}}</div>
                  <!-- Blured card overlay with button, title and description -->
                  <div *ngIf="card?.skeleton" class="blured-card-skeleton">
                    <div class="button-block-desc">
                      <ion-button class="custom-btn-create" shape="round" expand="block" *ngIf="card?.skeleton_configuration?.button">
                        {{card.skeleton_configuration.button_text}}
                      </ion-button>
                    </div>
                    <div class="dec-section-content" *ngIf="card?.skeleton_configuration?.title || card?.skeleton_configuration?.description">
                      <h3 class="ion-no-margin ion-no-padding">{{card.skeleton_configuration.title}}</h3>
                      <p class="ion-no-margin ion-no-padding">{{card.skeleton_configuration.description}}</p>
                    </div>
                  </div>
                </ion-card-content>
              </ion-card>
            </ion-col>
          </ion-row>
        </ion-grid>
      </div>
    </div>
  </div>
</ng-template>

<div *ngFor="let container of containerData?.containers">
  <ng-container *ngTemplateOutlet="containerTemplate; context: { container: container }"></ng-container>
</div>