import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-globe-icon',
    templateUrl: './globe-icon.component.html',
    styleUrls: ['./globe-icon.component.scss'],
    standalone: false
})
export class GlobeIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
