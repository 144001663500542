<div class="grey-bg no-border-radius">
  <div class="order-page-web">
    <div class="option-selector">
      <ion-button shape="round" mode="md" fill="outline" (click)="changeFilter(filters.all)" [class.active]="filter === filters.all" size="small">{{'orders.filters.all' | translate}}</ion-button>
      <ion-button shape="round" mode="md" fill="outline" (click)="changeFilter(filters.new)" [class.active]="filter === filters.new" size="small">{{'orders.filters.new' | translate}}</ion-button>
      <ion-button shape="round" mode="md" fill="outline" (click)="changeFilter(filters.fulfilled)" [class.active]="filter === filters.fulfilled" size="small">{{'orders.filters.fulfilled' | translate}}</ion-button>
    </div>
    <div class="search-bar">
      <ion-list>
        <ion-item lines="none">
          <div class="button-icon" slot="start">
            <app-search-icon></app-search-icon>
          </div>
          <ion-input type="text" [(ngModel)]="searchText" (ionInput)="searchOrder()" placeholder="{{'placeholders.search_orders' | translate}}" [clearInput]="true"></ion-input>
        </ion-item>
      </ion-list>
    </div>
    <div class="cards-area order-card-block" *ngIf="!(showNoDataAlert || showInternalServerErrorAlert)">
      <!-- Skeleton structure -->
      <ion-card class="order-card" *ngFor="let item of skeletonArray" [class.ion-hide]="!showSkeleton || showNoDataAlert">
        <ion-card-content>
          <div class="order-single">
            <div class="customer-name-block">
              <div class="customer-name">
                <ion-skeleton-text [animated]="true" class="customer-name-skeleton"></ion-skeleton-text>
              </div>
              <div class="order-status"><ion-skeleton-text [animated]="true" class="order-status-skeleton"></ion-skeleton-text></div>
            </div>
            <div class="detail-block">
              <div class="three-block-card">
                <ion-grid>
                  <ion-row>
                    <ion-col size="4">
                      <div class="single-item-top">
                        <ion-skeleton-text [animated]="true" class="single-item-skeleton"></ion-skeleton-text>
                      </div>
                    </ion-col>
                    <ion-col size="4">
                      <div class="single-item-top">
                        <ion-skeleton-text [animated]="true" class="single-item-skeleton"></ion-skeleton-text>
                      </div>
                    </ion-col>
                    <ion-col size="4">
                      <div class="single-item-top">
                        <ion-skeleton-text [animated]="true" class="single-item-skeleton"></ion-skeleton-text>
                      </div>
                    </ion-col>
                  </ion-row>
                </ion-grid>
              </div>
            </div>
          </div>
        </ion-card-content>
      </ion-card>
      <div *ngFor="let order of orders">
        <ion-card class="order-card cursor-pointer" (click)="openOrderDetail(order)">
          <ion-card-content>
            <div class="order-single">
              <div class="customer-name-block">
                <div class="customer-name">
                  <span class="country-flag" [class.ion-hide]="!order?.flag_url"><img [src]="order?.flag_url"></span>
                  <span class="name ion-text-capitalize">{{order?.customer_name}}</span>
                </div>
                <div class="order-status ion-text-capitalize"
                  [class.completed]="order?.status === orderStatus.done" 
                  [class.on-hold]="order?.status !== orderStatus.done"
                  >{{'orders.statuses.'+order?.status | translate}}<span [class.ion-hide]="order?.status !== orderStatus.done" class="checked"><app-completed-icon></app-completed-icon></span></div>
              </div>
              <div class="detail-block">
                <div class="three-block-card">
                  <ion-grid>
                    <ion-row>
                      <ion-col size="3" [class.ion-hide]="!order?.order_id">
                        <div class="single-item-top">
                          <div class="label">{{'orders.order_no' | translate}}</div>
                          <div class="value">{{order?.order_id}}</div>
                        </div>
                      </ion-col>
                      <ion-col size="4" [class.ion-hide]="!order?.order_time">
                        <div class="single-item-top">
                          <div class="label">{{'orders.date' | translate}}</div>
                          <div class="value">{{order?.order_time | date : 'MMM'}}&nbsp;{{order?.order_time | date : 'dd'}},<br>{{order?.order_time | date : 'yyyy'}}</div>
                        </div>
                      </ion-col>
                      <ion-col size="4" [class.ion-hide]="!order?.total_amount">
                        <div class="single-item-top">
                          <div class="label">{{'orders.total_amount' | translate}}</div>
                          <div class="value">{{order?.total_amount}}</div>
                        </div>
                      </ion-col>
                      <ion-col size="1">
                        <div class="open-icon">
                          <app-cheveron-right></app-cheveron-right>
                        </div>
                      </ion-col>
                    </ion-row>
                  </ion-grid>
                </div>
              </div>
            </div>
          </ion-card-content>
        </ion-card>
      </div>
    </div>
    <app-no-data-available
      *ngIf="showNoDataAlert || showInternalServerErrorAlert"
      [customTop]="warningDivCustomTop"
      [showInternalServerError]="showInternalServerErrorAlert">
    </app-no-data-available>
    <ion-infinite-scroll (ionInfinite)="loadMoreOrders($event)" *ngIf="!stopInfiniteLoad && orders?.length">
      <ion-infinite-scroll-content [loadingSpinner]="config.loadingSpinner"></ion-infinite-scroll-content>
    </ion-infinite-scroll>
  </div>
</div>