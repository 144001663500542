<svg xmlns="http://www.w3.org/2000/svg" width="39" height="39" viewBox="0 0 39 39">
  <g id="Group_2429" data-name="Group 2429" transform="translate(-45 -358)">
    <g id="Ellipse_6" data-name="Ellipse 6" transform="translate(45 358)" fill="#f5f5f5" stroke="#c4c4c4" stroke-width="1" stroke-dasharray="2">
      <circle cx="19.5" cy="19.5" r="19.5" stroke="none"/>
      <circle cx="19.5" cy="19.5" r="19" fill="none"/>
    </g>
    <path id="exclamation" d="M22.579,17.764a3.29,3.29,0,1,1-3.29-3.29A3.293,3.293,0,0,1,22.579,17.764Zm-6.2-16.727.559,11.184a.987.987,0,0,0,.986.938h2.728a.987.987,0,0,0,.986-.938L22.2,1.036A.987.987,0,0,0,21.213,0H17.366A.987.987,0,0,0,16.381,1.036Z" transform="translate(45 367)" fill="#b5b4b4"/>
  </g>
</svg>

