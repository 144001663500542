import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-book-appointment',
    templateUrl: './book-appointment.component.html',
    styleUrls: ['./book-appointment.component.scss'],
    standalone: false
})
export class BookAppointmentComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
