import { Component, Input } from '@angular/core';
import { OnInit } from '@angular/core';
import { AppService } from 'src/app/services/app-service/app.service';
import { unreadNotificationMaxText } from 'src/app/constants/app-constants';
import { TeamResponse } from 'src/app/models/apiResponse';
import { EventsService } from 'src/app/services/events/events.service';
import { Router } from '@angular/router';
import { NavController } from '@ionic/angular';

@Component({
  selector: 'app-web-header',
  templateUrl: './web-header.component.html',
  styleUrls: ['./web-header.component.scss'],
})
export class WebHeaderComponent implements OnInit {

  @Input() title: string; // Title to handle in future
  public selectedTeam: TeamResponse = null;
  public unreadNotificationMaxText = unreadNotificationMaxText
  public showBookMeetingModal = false;
  constructor(
    public appService: AppService,
    public router: Router,
    private events: EventsService,
    private navCtrl: NavController
  ) { }

  ngOnInit() {
    this.selectedTeam = this.appService.getTeam();
  }

  navigatePage(url: string) {
    this.navCtrl.navigateForward(url);
  }

  // Adding code to switch team from web header
  // switchAccount(): void {
  //   if (this.appService.getTeams()?.length > 1) {
  //     this.appService.showLoader();
  //     this.appService.selectTeam().then(() => {
  //       const route: string = this.router.url;
  //       this.appService.hideLoader();
  //       this.events.publish('domain:updated:' + route);
  //       this.selectedTeam = this.appService.getTeam();
  //     }).catch(() => {
  //       this.appService.hideLoader();
  //     })
  //   }
  // }

  navigateRoot(url: string): void {
    this.navCtrl.navigateRoot(url);
  }

  isTabActive(urls: string[]): boolean {
    const route: string[] = [this.router.url];
    let flag = false;
    urls.forEach(url => {
      if (route.some(str => str.includes(url))) {
        flag = true;
      }
    })
    return flag;
  }

  showBookMeeting(): void {
    this.navCtrl.navigateForward(this.appService.appRoutes.bookMeetingPage);
  }

  hideBookMeeting(): void {
    this.showBookMeetingModal = false;
  }
}
