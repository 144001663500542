import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-mode-icon',
    templateUrl: './mode-icon.component.html',
    styleUrls: ['./mode-icon.component.scss'],
    standalone: false
})
export class ModeIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
