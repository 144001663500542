import { Component, ElementRef, OnInit, Output, ViewChild, EventEmitter, NgZone, OnDestroy } from '@angular/core';
import { AppService } from 'src/app/services/app-service/app.service';
import { EventsService } from 'src/app/services/events/events.service';

declare const Calendly;

@Component({
  selector: 'app-book-meeting',
  templateUrl: './book-meeting.component.html',
  styleUrls: ['./book-meeting.component.scss'],
})

export class BookMeetingComponent implements OnInit, OnDestroy {
  @ViewChild('calendlyWrapper') calendlyWrapper: ElementRef;
  @Output() closeComponent: EventEmitter<string> = new EventEmitter();
  public messageEventCallback;
  public timer: ReturnType<typeof setTimeout>;
  public remainingTime: number;
  public showNoURLAlert = false;
  public defaultTemplateHeight = 120;

  constructor(
    public appService: AppService,
    private events: EventsService,
    private zone: NgZone
  ) {
    this.messageEventCallback = (e) => {
      if (this.isCalendlyEvent(e)) {
        // Handle page view event
        if (e?.data?.event === 'calendly.profile_page_viewed') {
          this.hideLoader();
        }
        // Handle booking success event
        if (e?.data?.event === 'calendly.event_scheduled') {
          this.events.publish('appsFlyer:bookAppointmentEvent');
          this.handleBookingSuccess();
        }
      }
    }
  }

  async ngOnInit(): Promise<void> {
    await this.appService.showLoader();
    const calendlyURL = this.appService.getCalendlyURL();
    if (calendlyURL) {
      this.initializeCalendlyWidget(calendlyURL);
    } else {
      await this.appService.hideLoader();
      this.showNoURLAlert = true;
    }
  }

  initializeCalendlyWidget(calenderURL: string): void {
    setTimeout(() => {
      Calendly.initInlineWidget({
        url: calenderURL + '?background_color=f6f6f6&hide_gdpr_banner=1',
        parentElement: this.calendlyWrapper?.nativeElement
      });
    }, 50); // Start calendly widget after template rendering
    window.addEventListener('message', this.messageEventCallback); // Post message event listener for Calendly
    setTimeout(async () => {
      await this.appService.hideLoader(); //Hide loader when using form
    }, 2500)
  }

  isCalendlyEvent(e): boolean {
    return e?.origin === 'https://calendly.com' && e?.data?.event && e?.data?.event.indexOf('calendly.') === 0;
  };

  async hideLoader(): Promise<void> {
    await this.appService.hideLoader();
  }

  close(): void {
    this.closeComponent.emit();
  }

  handleBookingSuccess(): void {
    this.initiateCloseTimer();
  }

  ngOnDestroy(): void {
    window.removeEventListener('message', this.messageEventCallback);
  }

  initiateCloseTimer(): void {
    this.remainingTime = 10;
    this.startTimer();
  }

  startTimer(): void {
    this.timer = setTimeout(() => {
      this.zone.run(() => {
        this.remainingTime -= 1;
        if (this.remainingTime > 0) {
          this.startTimer();
        } else {
          this.closeComponent.emit();
        }
      });

    }, 1000);
  }
}
