<svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" width="118.773" height="100.469" viewBox="0 0 118.773 100.469">
  <g id="Group_219" data-name="Group 219" transform="translate(10)">
    <g id="Group_218" data-name="Group 218" transform="translate(-10)">
      <path id="Path_203" data-name="Path 203" d="M127.273,74.645H95.261a3.24,3.24,0,0,1-3.236-3.236V29.021a3.24,3.24,0,0,1,3.236-3.236h32.012a3.24,3.24,0,0,1,3.236,3.236V71.409a3.239,3.239,0,0,1-3.236,3.236" transform="translate(-50.78 -14.228)" fill="#e6e6e6"/>
      <path id="Path_204" data-name="Path 204" d="M128.241,75.269H100.729A2.877,2.877,0,0,1,97.856,72.4V34.676a2.877,2.877,0,0,1,2.873-2.873h27.512a2.877,2.877,0,0,1,2.873,2.873V72.4a2.877,2.877,0,0,1-2.873,2.873" transform="translate(-53.998 -17.549)" fill="#fff"/>
      <path id="Path_205" data-name="Path 205" d="M131.527,46.028H109.5a.718.718,0,1,1,0-1.436h22.024a.718.718,0,1,1,0,1.436Z" transform="translate(-60.029 -24.606)" fill="#e4e4e4"/>
      <path id="Path_206" data-name="Path 206" d="M131.528,52.36H109.505a.718.718,0,1,1,0-1.436h22.023a.718.718,0,1,1,0,1.436" transform="translate(-60.03 -28.1)" fill="#e4e4e4"/>
      <path id="Path_207" data-name="Path 207" d="M119.052,58.693h-9.546a.718.718,0,0,1,0-1.436h9.546a.718.718,0,1,1,0,1.436" transform="translate(-60.03 -31.595)" fill="#e4e4e4"/>
      <path id="Path_208" data-name="Path 208" d="M131.527,71.955H109.5a.718.718,0,1,1,0-1.436h22.024a.718.718,0,1,1,0,1.436Z" transform="translate(-60.029 -38.913)" fill="#e4e4e4"/>
      <path id="Path_209" data-name="Path 209" d="M131.528,78.287H109.505a.718.718,0,1,1,0-1.436h22.023a.718.718,0,1,1,0,1.436" transform="translate(-60.03 -42.407)" fill="#e4e4e4"/>
      <path id="Path_210" data-name="Path 210" d="M119.054,84.62h-9.546a.718.718,0,0,1,0-1.436h9.546a.718.718,0,1,1,0,1.436" transform="translate(-60.032 -45.902)" fill="#e4e4e4"/>
      <path id="Path_211" data-name="Path 211" d="M131.527,97.882H109.5a.718.718,0,1,1,0-1.436h22.024a.718.718,0,1,1,0,1.436Z" transform="translate(-60.029 -53.22)" fill="#e4e4e4"/>
      <path id="Path_212" data-name="Path 212" d="M131.528,104.214H109.505a.718.718,0,1,1,0-1.436h22.023a.718.718,0,1,1,0,1.436" transform="translate(-60.03 -56.714)" fill="#e4e4e4"/>
      <path id="Path_213" data-name="Path 213" d="M119.052,110.547h-9.546a.718.718,0,0,1,0-1.436h9.546a.718.718,0,1,1,0,1.436" transform="translate(-60.03 -60.209)" fill="#e4e4e4"/>
      <path id="Path_214" data-name="Path 214" d="M71.556,54.251,47.629,33.786H31.555v-.3H47.74L71.752,54.022Z" transform="translate(-17.412 -18.477)" fill="#2f2e41"/>
      <path id="Path_215" data-name="Path 215" d="M28.585,28.439a2.412,2.412,0,1,1-2.412,2.412,2.412,2.412,0,0,1,2.412-2.412" transform="translate(-14.443 -15.693)" fill="#FF9999"/>
      <path id="Path_216" data-name="Path 216" d="M22.742,50.153H.719a.718.718,0,1,1,0-1.436H22.742a.718.718,0,1,1,0,1.436Z" transform="translate(0 -26.883)" fill="#FF9999"/>
      <path id="Path_217" data-name="Path 217" d="M22.743,56.485H.72a.718.718,0,1,1,0-1.436H22.743a.718.718,0,1,1,0,1.436" transform="translate(-0.001 -30.377)" fill="#FF9999"/>
      <path id="Path_218" data-name="Path 218" d="M10.266,62.818H.72a.718.718,0,0,1,0-1.436h9.546a.718.718,0,1,1,0,1.436" transform="translate(-0.001 -33.871)" fill="#FF9999"/>
      <path id="Path_219" data-name="Path 219" d="M134.958,25.583,158.97,5.045h16.185v.3H159.081L135.154,25.812Z" transform="translate(-74.472 -2.784)" fill="#2f2e41"/>
      <path id="Path_220" data-name="Path 220" d="M221.676,0a2.412,2.412,0,1,1-2.412,2.412A2.412,2.412,0,0,1,221.676,0" transform="translate(-120.992)" fill="#FF9999"/>
      <path id="Path_221" data-name="Path 221" d="M233.238,21.525H211.215a.718.718,0,1,1,0-1.436h22.024a.718.718,0,1,1,0,1.436Z" transform="translate(-116.154 -11.085)" fill="#FF9999"/>
      <path id="Path_222" data-name="Path 222" d="M233.239,27.858H211.216a.718.718,0,1,1,0-1.436h22.023a.718.718,0,1,1,0,1.436" transform="translate(-116.155 -14.58)" fill="#FF9999"/>
      <path id="Path_223" data-name="Path 223" d="M220.762,34.19h-9.546a.718.718,0,0,1,0-1.436h9.546a.718.718,0,1,1,0,1.436" transform="translate(-116.155 -18.074)" fill="#FF9999"/>
      <path id="Path_224" data-name="Path 224" d="M221.756,144.283a1.256,1.256,0,0,1,.25-1.908l-1.028-4.339,2.176.8.643,4a1.262,1.262,0,0,1-2.041,1.453Z" transform="translate(-121.938 -76.17)" fill="#9e616a"/>
      <path id="Path_225" data-name="Path 225" d="M211.036,101.264l.874,2.246s-.374,2.246.25,2.745.374.5.374.874a1.919,1.919,0,0,0,.749,1.373c.5.374,2.745,7.986,2.745,7.986h2.246a7.034,7.034,0,0,1,0-1.747,9.217,9.217,0,0,0-.624-3.494c-.25-.374,0-.874,0-1.622s-.5-1.123-.5-2.371-1.5-10.982-1.373-12.1-1.248-2.121-1.248-2.121h-1.248Z" transform="translate(-116.452 -51.334)" fill="#e6e7e8"/>
      <path id="Path_226" data-name="Path 226" d="M196.688,121.219l-2.076,9.265,13.3-.828-3.618-9.1Z" transform="translate(-107.389 -66.525)" fill="#9e616a"/>
      <path id="Path_227" data-name="Path 227" d="M246.231,189.09a14.192,14.192,0,0,1-1.833,4.033,21.816,21.816,0,0,1-7.32,6.57c-.2.119-.4.234-.6.349h-2.017l-.07-.161h0l-.082-.186-.01-.022,0,.022-.081.349h-.99c-.082-.116-.163-.232-.242-.349-.116-.172-.227-.344-.333-.52a8.362,8.362,0,0,1-.5-.955c-.017-.036-.032-.073-.047-.11l.066-.072-.274-1.2.551-.091-.6-1.862c.022-.069.047-.138.074-.207a4.635,4.635,0,0,1,1.728-2.035,26.367,26.367,0,0,1,2.511-1.463c.39-.218.774-.448,1.147-.692h0q.717-.464,1.39-.992l.022-.016.017-.014a20.639,20.639,0,0,0,5.026-5.7h0c.044-.07.085-.141.126-.212v0a19.952,19.952,0,0,0,1.378-2.878h0c.03-.073.056-.147.084-.22.038.065.074.129.11.194h0a11.222,11.222,0,0,1,.775,8.448" transform="translate(-127.932 -99.573)" fill="#f0f0f0"/>
      <path id="Path_228" data-name="Path 228" d="M248.592,180.552a17.774,17.774,0,0,1-.42,4.034,17.371,17.371,0,0,1-.492,1.793,17.779,17.779,0,0,1-1.484,3.31c-.034.057-.066.113-.1.169h0a14.957,14.957,0,0,1-.842,1.269,7,7,0,0,1-1.67,1.679,4.412,4.412,0,0,1-1.755.678c-.117.022-.237.038-.359.052-.485.057-.986.079-1.466.182a3.2,3.2,0,0,0-.706.227,2.694,2.694,0,0,0-1.4,1.913c-.034.141-.06.283-.08.425-.16,1.091.016,2.225-.075,3.318-.01.116-.023.233-.039.349h-.108l-.07-.161h0c.007-.061.013-.123.019-.186a14.039,14.039,0,0,0,.013-1.613c-.006-.242-.01-.485-.008-.728,0-.195.01-.39.025-.583h0c.01-.12.022-.239.038-.358a4.616,4.616,0,0,1,.267-1.062,2.657,2.657,0,0,1,1.512-1.565,3.945,3.945,0,0,1,.655-.183c.491-.094,1-.117,1.491-.178.053-.006.105-.013.157-.022s.13-.022.195-.034a4.191,4.191,0,0,0,1.782-.772,7.6,7.6,0,0,0,1.609-1.746c.24-.344.469-.7.681-1.058h0l.03-.051,0,0a17.531,17.531,0,0,0,1.972-5.005c.013-.065.028-.129.043-.194a17.709,17.709,0,0,0,.391-3.867h0c0-.062,0-.122,0-.183,0-.126.192-.154.195-.028,0,.061,0,.123,0,.185Z" transform="translate(-131.069 -99.483)" fill="#fff"/>
      <path id="Path_229" data-name="Path 229" d="M256.392,200.385a2.639,2.639,0,0,0,3.324-.793c.075-.1-.081-.223-.156-.121a2.444,2.444,0,0,1-3.094.732c-.113-.058-.187.125-.075.182" transform="translate(-141.449 -110.049)" fill="#fff"/>
      <path id="Path_230" data-name="Path 230" d="M246.734,204.68a5.086,5.086,0,0,1,.18-3.7c.052-.116-.13-.19-.182-.075a5.292,5.292,0,0,0-.179,3.854c.041.12.222.043.181-.076" transform="translate(-135.896 -110.828)" fill="#fff"/>
      <path id="Path_231" data-name="Path 231" d="M259.542,188.334a1.494,1.494,0,0,1-1.029-.976c-.039-.121-.221-.043-.182.076a1.673,1.673,0,0,0,1.135,1.081.1.1,0,0,0,.129-.053A.1.1,0,0,0,259.542,188.334Z" transform="translate(-142.548 -103.353)" fill="#fff"/>
      <path id="Path_232" data-name="Path 232" d="M234.541,187.571h0a19.173,19.173,0,0,1-.391,3.111v0c-.012.07-.028.139-.042.208a27.009,27.009,0,0,1-1.481,4.63q-.262.633-.548,1.253c-.053.117-.107.233-.163.349H231.7l-.07-.161h0l-.082-.186-.01-.022,0,.022-.081.349h-2.095l-.407-.349-.593-.508.829-.905.136-.15.02-.022.066-.072-.274-1.2.551-.091-.6-1.862-.286-.881-.072-.221-.417-1.288-1.26.236-.205-.718a19.93,19.93,0,0,0,.941-2.7c0-.008,0-.013.006-.021a20.737,20.737,0,0,0,.588-7.612h0c-.009-.082-.019-.165-.03-.246v0a19.6,19.6,0,0,0-.633-3.126c-.021-.078-.044-.154-.066-.23l.2.087h0c.107.047.214.1.318.15a11.311,11.311,0,0,1,5.382,6.121c0,.008.006.013.009.021a14.124,14.124,0,0,1,.963,4.311,16.9,16.9,0,0,1,.017,1.718" transform="translate(-125.174 -96.656)" fill="#f0f0f0"/>
      <path id="Path_233" data-name="Path 233" d="M233.626,221.813H233.4c-.074-.119-.152-.234-.232-.349q-.189-.266-.389-.52-.173-.22-.347-.437c-.12-.151-.239-.3-.355-.457l.136-.149c.022.029.044.058.068.088.375.493.781.968,1.128,1.475.078.114.152.23.223.349" transform="translate(-128.06 -121.344)" fill="#fff"/>
      <path id="Path_234" data-name="Path 234" d="M228.5,175.131c.038.048.078.1.114.148q.428.556.81,1.147a17.32,17.32,0,0,1,1.2,2.176c.028.059.056.119.082.179a17.454,17.454,0,0,1,1.441,5.191.492.492,0,0,1,.006.051q.073.629.094,1.261a7.583,7.583,0,0,1-.233,2.361c-.03.1-.062.2-.1.3a4.039,4.039,0,0,1-.329.681,4.086,4.086,0,0,1-.314.453c-.069.088-.141.174-.217.258l-.134.145c-.038.037-.075.075-.113.111-.4.384-.832.74-1.19,1.163l.072.221a11.9,11.9,0,0,1,1.188-1.179,5.542,5.542,0,0,0,.683-.774c.026-.038.054-.076.079-.116a3.95,3.95,0,0,0,.35-.628c.05-.11.1-.223.136-.34a7.017,7.017,0,0,0,.322-2.344,15.168,15.168,0,0,0-.091-1.522c-.006-.066-.013-.13-.022-.2a17.956,17.956,0,0,0-2.824-7.664q-.363-.551-.769-1.077c-.035-.048-.072-.1-.11-.143-.078-.1-.216.039-.139.139" transform="translate(-126.078 -96.544)" fill="#fff"/>
      <path id="Path_235" data-name="Path 235" d="M236.8,191.974a2.64,2.64,0,0,0,2.177-2.635.1.1,0,0,0-.2,0,2.444,2.444,0,0,1-2.03,2.447C236.624,191.805,236.675,192,236.8,191.974Z" transform="translate(-130.601 -104.426)" fill="#fff"/>
      <path id="Path_236" data-name="Path 236" d="M231.553,203.252a5.088,5.088,0,0,1-2.086-3.065c-.028-.124-.218-.074-.19.05a5.293,5.293,0,0,0,2.177,3.185c.105.072.2-.1.1-.17" transform="translate(-126.516 -110.426)" fill="#fff"/>
      <path id="Path_237" data-name="Path 237" d="M231.5,182.823a1.493,1.493,0,0,1-1.409-.16c-.1-.073-.2.1-.1.17a1.675,1.675,0,0,0,1.558.18.1.1,0,0,0,.07-.12A.1.1,0,0,0,231.5,182.823Z" transform="translate(-126.888 -100.786)" fill="#fff"/>
      <path id="Path_238" data-name="Path 238" d="M201.471,210.348h-1.495l-.711-5.765h2.206Z" transform="translate(-109.957 -112.892)" fill="#9e616a"/>
      <path id="Path_239" data-name="Path 239" d="M199.6,219.2h-1.06l-.189-1-.485,1h-2.813a.632.632,0,0,1-.359-1.153l2.246-1.551v-1.012l2.362.141Z" transform="translate(-107.285 -118.908)" fill="#2f2e41"/>
      <path id="Path_240" data-name="Path 240" d="M231.544,208.851l-1.461.317-1.917-5.483,2.156-.467Z" transform="translate(-125.905 -112.138)" fill="#9e616a"/>
      <path id="Path_241" data-name="Path 241" d="M232.383,217.37l-1.036.225-.4-.939-.261,1.081-2.749.6a.632.632,0,0,1-.6-1.05l1.866-1.992L229,214.3l2.339-.363Z" transform="translate(-125.357 -118.055)" fill="#2f2e41"/>
      <path id="Path_242" data-name="Path 242" d="M122.927,223.562c0,.1-.207.174-.463.174H12.721c-.257,0-.466-.078-.466-.174s.208-.174.466-.174h109.74c.257,0,.465.078.466.173Z" transform="translate(-6.762 -123.268)" fill="#ccc"/>
      <path id="Path_243" data-name="Path 243" d="M193.183,145.4c1.049,5.764,1.311,8.253,1.311,8.253s-.787.917-.262,1.441,0,1.572,0,1.572l1.179,12.708.949-.154,2.287-.368.825-.133-.524-11.921s.524-1.049.262-1.311.262-1.834.262-1.834l.655-12.445,4.062,13.624s-.394.524,0,.917a12.933,12.933,0,0,1,1.177,2.358l2.416,8.452.205.718,1.259-.236,1.371-.258.252-.047.448-.085.289-.054.1-.017.475-.089-.144-.637-.074-.336-2.044-9.076-.047-.209-.05-.221a1.062,1.062,0,0,0-.13-1.049,1.023,1.023,0,0,1-.262-.917.337.337,0,0,0,.035-.044.545.545,0,0,0,.094-.214.708.708,0,0,0-.261-.659,1.126,1.126,0,0,1-.186-.267,9.217,9.217,0,0,1-.6-2.353s-.355-12.658-1.515-14.591c-.061-.1-1.293-2.042-1.358-2.081-1.31-.785-12.116.3-12.116.3s-.618,1.66-.646,1.833a29.523,29.523,0,0,0,.306,9.431" transform="translate(-106.3 -73.698)" fill="#2f2e41"/>
      <path id="Path_244" data-name="Path 244" d="M191.286,144.247a1.256,1.256,0,0,0-.25-1.908L192.064,138l-2.176.8-.643,4a1.262,1.262,0,0,0,2.041,1.453Z" transform="translate(-104.342 -76.15)" fill="#9e616a"/>
      <path id="Path_245" data-name="Path 245" d="M195.3,88.148a28,28,0,0,1,3.2-3.728,8.841,8.841,0,0,1,3.744,0c3.12,4.118,3.316,3.845,4.128,6.146A3.243,3.243,0,0,1,206.6,92.2c-.375,1.872.225,11.329.35,11.829s1,1.123.125,1.123-14.1-.5-13.851-.873S195.3,88.148,195.3,88.148" transform="translate(-106.62 -46.47)" fill="#e6e7e8"/>
      <path id="Path_246" data-name="Path 246" d="M194.487,92.992h-1.248s-1.373,1-1.248,2.121-1.372,10.856-1.372,12.1-.5,1.622-.5,2.37.25,1.248,0,1.622a9.21,9.21,0,0,0-.624,3.494,7.032,7.032,0,0,1,0,1.747h2.246s2.246-7.612,2.745-7.986a1.921,1.921,0,0,0,.749-1.373c0-.374-.25-.374.374-.874s.25-2.745.25-2.745l.874-2.246Z" transform="translate(-104.555 -51.314)" fill="#e6e7e8"/>
      <path id="Path_247" data-name="Path 247" d="M201.927,64.986a3.614,3.614,0,1,1-3.613,3.614,3.613,3.613,0,0,1,3.613-3.614" transform="translate(-109.432 -35.86)" fill="#9e616a"/>
      <path id="Path_248" data-name="Path 248" d="M198.715,71.633l-.339-1.486a3.816,3.816,0,0,1,.527-2.919,2.653,2.653,0,0,0,.082-2.708,2.083,2.083,0,0,0-1.815-1.143h-.09v-.09a2.228,2.228,0,0,1,1.949-2.263,1,1,0,0,1,.558-.7,4,4,0,0,1,3.493.053,1.1,1.1,0,0,1,.679-.9,1.087,1.087,0,0,1,.593-.034,1.343,1.343,0,0,1,.378-.866,1.591,1.591,0,0,1,1.411-.324,3.791,3.791,0,0,1,2.2,1.42,11.18,11.18,0,0,1,1.311,2.271,10.787,10.787,0,0,0,1.293,2.232,3.591,3.591,0,0,0,2.1,1.343,2.154,2.154,0,0,0,2.186-.877l.082-.133.074.137a2.445,2.445,0,0,1-.45,2.774,3.323,3.323,0,0,1-2.72.926,5.323,5.323,0,0,1-2.676-1.128,4,4,0,0,1-1.613-2.164,5.883,5.883,0,0,1-.116-.73,2.364,2.364,0,0,0-.433-1.357,2.208,2.208,0,0,0-1.114-.566l-.077-.021a1,1,0,0,1-.426.727.727.727,0,0,1-.233.09c.452,1.82.266,3.214-.553,4.142-1.383,1.569-4.041,1.246-4.153,1.232l-.075-.01,0-.075a4.246,4.246,0,0,0-.074-.6c-.381.917-1.661,3.343-1.833,3.587Z" transform="translate(-108.751 -32.122)" fill="#2f2e41"/>
    </g>
  </g>
</svg>

