import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-platform-icons',
    templateUrl: './platform-icons.component.html',
    styleUrls: ['./platform-icons.component.scss'],
    standalone: false
})
export class PlatformIconsComponent  implements OnInit {

  constructor() { }

  ngOnInit() {}

}
