<div class="option-selector store">
  <ion-button shape="round" mode="md" class="store-button" (click)="filterService.changeFilter(filterService.dateFilters[key])" [class.active]="filterService.filter === filterService.dateFilters[key]" *ngFor="let key of filterService.dateFilterKeys">{{ 'filter.' + key | translate }}</ion-button>
</div>

<div class="date-selector">
  <div class="control left" (click)="filterService.seekFilterArrow(true)">
    <app-arrow-control></app-arrow-control>
  </div>
  <div class="date-list">
    <ng-container *ngIf="filterService.filter !== dateFilters.day">
      <span>{{ filterService.period_start | date: 'dd MMM'}}</span>
      {{ filterService.period_start | date: 'y'}}
      - 
    </ng-container>
    <span>{{ filterService.period_end | date: 'dd MMM'}}</span>
    {{ filterService.period_end | date: 'y'}}
  </div>
  <div class="control right" [class.disabled]="!filterService.canSeekFilterNext()" (click)="filterService.seekFilterArrow()">
    <app-arrow-control></app-arrow-control>
  </div>
</div>