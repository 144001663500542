import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-info-icon',
    templateUrl: './info-icon.component.html',
    styleUrls: ['./info-icon.component.scss'],
    standalone: false
})
export class InfoIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
