import { Injectable } from '@angular/core';
import { Config } from 'src/app/services/config';
import { TranslateService } from '@ngx-translate/core';
import { Device } from '@capacitor/device';
import { AlertInput } from '@ionic/angular';
import { Preferences } from '@capacitor/preferences';
import { EventsService } from '../events/events.service';
import { LanguageList } from 'src/app/models/apiResponse';

const defaultLanguage = Config.defaultLanguage;

@Injectable({
  providedIn: 'root'
})
export class LanguageService {

  public availableLanguages = [];
  public currentLanguage: string = defaultLanguage;
  constructor(
    private translate: TranslateService,
    private events: EventsService
  ) { }

  async initLanguage(): Promise<void> {
    const storageLanguage = (await Preferences.get({key: 'lang'})).value
    const deviceLanguage = (await Device.getLanguageCode()).value;
    const selectedLanguage = storageLanguage || deviceLanguage || defaultLanguage;
    const availableLanguage = this.availableLanguages.filter(language => language.value.includes(selectedLanguage.toLowerCase()));
    const userLanguage = (availableLanguage.length > 0) ? availableLanguage[0].value : storageLanguage || defaultLanguage;
    this.setLanguage(userLanguage);
  }

  setAvailableLocales(languages: LanguageList[]) {
    this.availableLanguages = languages;
    const APIlanguages = [];
    languages.forEach(lang => {
      APIlanguages.push(lang.value.slice(0,2));
    });
    this.translate.addLangs(APIlanguages)
  }

  getChooseLangInputs(): AlertInput[] {
    const arr: AlertInput[] = []
    const languages = this.availableLanguages;
    languages.forEach(lang => {
      arr.push(
        {
          type: 'radio',
          label: lang.name,
          value: lang.value,
          checked: lang.value.slice(0, 2) ===  this.translate.currentLang
        }
      );
    })
    return arr;
  }

  async setLanguage(lang: string, doReInitialize: boolean = false): Promise<void> {
    const selectedLanguage = lang.slice(0,2);
    this.currentLanguage = selectedLanguage;
    Preferences.set({key: 'lang', value: selectedLanguage});
    this.translate.use(selectedLanguage);
    const user = (await Preferences.get({key: 'user'})).value;
    if (user && doReInitialize) {
      this.events.publish('app:reinitialize');
    }
  }

  updateTranslations(translations: object): void {
    this.translate.setTranslation(this.currentLanguage, translations, true);
  }
}
