import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-boost-seo',
  templateUrl: './boost-seo.component.html',
  styleUrls: ['./boost-seo.component.scss'],
})
export class BoostSeoComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
