import { Component, OnInit } from '@angular/core';

@Component({
    selector: 'app-insights-icon',
    templateUrl: './insights-icon.component.html',
    styleUrls: ['./insights-icon.component.scss'],
    standalone: false
})
export class InsightsIconComponent implements OnInit {

  constructor() { }

  ngOnInit() {}

}
